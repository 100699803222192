import { Grid } from '@mui/material';
import * as React from 'react';
import { compose } from 'redux';

import { TitleRowProps } from './TitleRow.types';

const generateTitleRightProps = (props: TitleRowProps): any => {
    const { titleRightSm: sm = 12, titleRightXs: xs = 12, titleRightMd: md = 7 } = props;
    return { xs, sm, md };
};

const calcGrid = (grid: number) => (!grid ? 12 : 12 - grid);

const generateTitleTextProps = (props: TitleRowProps): any => {
    const { titleRightSm: sm = 0, titleRightXs: xs = 0, titleRightMd: md = 0 } = props;
    return {
        xs: calcGrid(xs),
        sm: calcGrid(sm),
        md: calcGrid(md),
    };
};

export const TitleRowBase: React.FC<TitleRowProps> = (props: Readonly<TitleRowProps>) => {
    const { backButton, title, titleRight, 'automation-id': automationId } = props;

    const titleNode = title ? (
        <Grid item {...generateTitleTextProps(props)}>
            {title}
        </Grid>
    ) : null;

    const titleRightNode = titleRight ? (
        <Grid item {...generateTitleRightProps(props)}>
            <Grid container alignContent="flex-end" justifyContent="flex-end">
                <Grid item>{titleRight}</Grid>
            </Grid>
        </Grid>
    ) : null;

    const backButtonNode = backButton ? (
        <Grid item xs={2}>
            {backButton}
        </Grid>
    ) : null;

    return (
        <Grid container direction="row" automation-id={automationId}>
            <Grid item xs={backButton ? 10 : 12}>
                <Grid container alignItems="center">
                    {titleNode}
                    {titleRightNode}
                </Grid>
            </Grid>
            {backButtonNode}
        </Grid>
    );
};

export default compose(TitleRowBase);
