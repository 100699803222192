import {
    configureStore,
    Middleware,
    combineReducers,
    Store,
    Reducer,
    Action,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { ApiClient } from 'state-domains/utils/network/service/ApiClient';

import { interceptors } from './interceptors';
import { BaseStateInterface, IObject } from './store.types';

let store: Store<IObject>;
let asyncReducers = {};

const generateMiddleware = (middlewares?: Middleware<any>) => {
    const debugMiddlewares: any[] = [];

    if (middlewares) {
        debugMiddlewares.push(middlewares);
    }
    debugMiddlewares.push(interceptors.authInterceptor);

    return debugMiddlewares;
};

export const setupStore = (middlewares?: Middleware<any>) => {
    asyncReducers = { [ApiClient.reducerPath]: ApiClient.reducer };

    store = configureStore({
        reducer: combineReducers(asyncReducers),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }).concat(generateMiddleware(middlewares)),
        devTools: process.env.NODE_ENV !== 'production',
    });

    return store;
};

// get store singleton, create store if there isn't one,
export const getStore = (): Store<IObject> => store || setupStore();

// Inject reducer on the fly, specify entry and reducers - they will be registered to the store
export const injectReducer = (namespace: string, reducers: Reducer) => {
    const store = getStore();
    asyncReducers = { ...asyncReducers, [namespace]: reducers };
    store.replaceReducer(combineReducers({ ...asyncReducers }));
};

// get the root of the state tree. .config is reserved.
export const getState = (): BaseStateInterface => getStore().getState() as BaseStateInterface;

export const dispatch = (action: Action) => getStore().dispatch(action);

// redux-toolkit way of using dispatch function from anywhere
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// redux-toolkit way of using store selector from anywhere
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getAsyncReducers = () => asyncReducers;
