import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { SampleWorkflow } from 'state-domains/domain/subscription';
import {
    buildPrimarySampleAnalysisFlowUrl,
    buildSecondrySampleAnalysisFlowUrl,
} from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken } from '../../../utils';

export const modifySampleWorkflows = (
    activityId: string,
    sampleWorkflowId: string,
    isPrimary = true,
): Observable<SampleWorkflow> => {
    const url = isPrimary
        ? buildPrimarySampleAnalysisFlowUrl(activityId)
        : buildSecondrySampleAnalysisFlowUrl(activityId);
    const obs = sendRequestWithXSRFToken(url, {
        _id: sampleWorkflowId,
    });
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<SampleWorkflow>) => {
            const sampleWorkflowResult = convertToCamel<SampleWorkflow>(response);
            return observableOf(sampleWorkflowResult);
        }),
    );
};
