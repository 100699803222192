import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { CustomExportRequest } from 'state-domains/domain/drillhole';

import { customExportUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const customExport = (payload: CustomExportRequest): Observable<any> => {
    const url = customExportUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const exportResponse = convertToCamel(response);
            return observableOf(exportResponse);
        }),
    );
};
