import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildDeleteColourUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';
import { CustomColour, PayloadInfo } from '../types';

export const deleteColour = (payloadInfo: PayloadInfo): Observable<CustomColour> =>
    sendDeleteRequestWithXSRFToken(buildDeleteColourUrl(payloadInfo.id ?? '')).pipe(
        switchMap(({ response }: AjaxResponse<CustomColour>) => {
            const converted = convertToCamel<CustomColour>(response);
            return observableOf(converted);
        }),
    );
