import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildSaveDrillHoleUrl, buildSavePointsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamelWithSpecialParameters } from '../../../utils';
import { Drillhole } from '../types';

export const saveDrillHole = (drillHole: any, type: ACTIVITY_TYPES): Observable<Drillhole> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildSaveDrillHoleUrl(drillHole._id || '')
            : buildSavePointsUrl(drillHole._id || '');
    return sendRequestWithXSRFToken(url, drillHole, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<Drillhole>) => {
            const convertedDrillHole = convertToCamelWithSpecialParameters<Drillhole>(response, {
                id: 'uid',
            });
            return observableOf(convertedDrillHole);
        }),
    );
};
