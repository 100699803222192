import { sanitizeWithoutHtml } from '@local/web-design-system/dist/components/HtmlSection/sanitizeHtml';
import L from 'leaflet';
import { cloneDeep } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';

import { WGS84_GRID_ID } from 'state-domains/constants';
import { ProjectBoundary, configurationState, subscriptionState } from 'state-domains/domain';

import {
    STORE_BOUNDARY_SHOW_DETAILS,
    STORE_BOUNDARY_DEGREE_BUTTON,
    STORE_SELECTED_BOUNDARY_SYSTEM,
} from '../DrillholeMap';
import { styles } from './MapBoundaryControls.styles';
import { CoordinateDropdownProps, CreateBoundaryControlProps } from './MapBoundaryControls.types';
import {
    convertIntToLabel,
    displayCoordinateValue,
    formattedConvertedBoundaries,
} from './MapBoundaryControls.utils';

export const CreateBoundaryControlPanel = (props: CreateBoundaryControlProps) => {
    const { map, originalBoundaryData, currentProject } = props;

    const {
        actions: {
            convertProjectBoundaries,
            editConfigurationProject,
            toggleShowBoundaryInDecimal,
        },
        selectors: {
            convertedProjectBoundaries: convertedProjectBoundariesSelector,
            getCoordinateDecimalState,
            isConvertedProjectBoundariesPending,
            isConvertedProjectBoundariesFailed,
        },
    } = configurationState;

    const {
        selectors: { coordinates: coordinatesSelector },
    } = subscriptionState;

    const dispatch = useDispatch();

    const convertedBoundaryData = useSelector(convertedProjectBoundariesSelector);
    const isUsingDecimal = useSelector(getCoordinateDecimalState);
    const conversionPending = useSelector(isConvertedProjectBoundariesPending);
    const conversionFailed = useSelector(isConvertedProjectBoundariesFailed);
    const coordinates = useSelector(coordinatesSelector);

    const projectData = React.useMemo(() => cloneDeep(currentProject), [currentProject]);
    const convertedData = React.useMemo(
        () =>
            convertedBoundaryData || originalBoundaryData.flatMap((x: any) => x.coordinates).flat(),
        [convertedBoundaryData, originalBoundaryData],
    );
    const [boundarySystem, setBoundarySystem] = React.useState('');

    const dropdownOptions: CoordinateDropdownProps[] = React.useMemo(
        () =>
            Object.values(coordinates ?? {})
                .filter((x) => !!x.geographicCoordinateSystem || !!x.referencesExisting)
                .map((x) => ({ id: x.id, value: sanitizeWithoutHtml(x?.name).trim() }))
                .sort((a, b) => a.value.localeCompare(b.value)),
        [coordinates],
    );

    React.useEffect(() => {
        const system = store.get(STORE_SELECTED_BOUNDARY_SYSTEM);
        const boundarySystem = dropdownOptions.some((x) => x.id === system)
            ? system
            : WGS84_GRID_ID;
        setBoundarySystem(boundarySystem);
    }, []);

    React.useEffect(() => {
        const boundaryDataPanel = document.querySelector('#boundaries-data-pane');
        const decimalButtonContainer = document.querySelector(
            '#decimal-degree-container',
        ) as HTMLElement;
        const boundaryInputText = document.querySelector('#selected-boundary-input-text');

        const boundaryObject = dropdownOptions.filter((x: any) => x.id === boundarySystem)[0];
        if (boundaryDataPanel && convertedData && boundaryInputText && !conversionPending) {
            boundaryInputText.innerHTML = boundaryObject?.value ?? '';
            const formattedBoundaries = formattedConvertedBoundaries(
                originalBoundaryData,
                convertedData,
            );
            boundaryDataPanel.innerHTML = formattedBoundaries
                .map((x: any, idx: number) =>
                    boundaryDataComponent(
                        x.name,
                        x.coordinates,
                        conversionFailed && boundarySystem !== WGS84_GRID_ID ? '' : boundarySystem,
                        isUsingDecimal,
                        idx,
                    ),
                )
                .join('');
            formattedBoundaries.forEach((_, idx: number) => {
                const textField = document.querySelector(`#boundary-text-field-${idx}`);
                textField?.addEventListener('blur', onBoundaryNameChange.bind(this, idx));
                textField?.addEventListener('click', onFocusBoundary.bind(this, idx));
            });
            if (decimalButtonContainer) {
                decimalButtonContainer.style.display =
                    boundarySystem === WGS84_GRID_ID ? 'flex' : 'none';
            }
        }
    }, [
        originalBoundaryData,
        convertedData,
        isUsingDecimal,
        conversionPending,
        conversionFailed,
        dropdownOptions,
        boundarySystem,
    ]);

    // ----------------- EVENT HANDLERS ------------------ //
    const setOpenBoundaryOptions = (value: boolean) => {
        const inputOptionsContainer = document.querySelector(
            '#boundary-input-options',
        ) as HTMLElement;
        if (inputOptionsContainer) {
            inputOptionsContainer.style.display = value ? 'flex' : 'none';
        }
    };

    const onBoundrySystemChange = (systemId: string, originalBoundaryData: ProjectBoundary[]) => {
        if (systemId === store.get(STORE_SELECTED_BOUNDARY_SYSTEM)) return;
        store.set(STORE_SELECTED_BOUNDARY_SYSTEM, systemId);
        setBoundarySystem(systemId);
        setOpenBoundaryOptions(false);
        const coordArray = originalBoundaryData.map((x: any) => x.coordinates[0]);
        const payload = {
            model: {
                coords: [].concat(...coordArray),
                dest: systemId,
                source: WGS84_GRID_ID,
            },
        };
        convertProjectBoundaries(payload)(dispatch);
    };

    const onBoundaryNameChange = (index: number, e: any) => {
        const value = sanitizeWithoutHtml(e.target.value).trim();
        const currentName = currentProject?.boundary.features[index].properties.name ?? '';
        const input = document.querySelector(`#boundary-text-field-${index}`) as HTMLInputElement;
        if (value.length === 0 || currentName === value) {
            if (input) {
                input.value = currentName;
            }
            return;
        }
        if (projectData && currentName !== value) {
            const features = projectData.boundary.features;
            features[index] = {
                ...features[index],
                properties: {
                    ...features[index].properties,
                    name: value,
                },
            };

            const payload = {
                ...projectData,
                boundary: {
                    ...projectData.boundary,
                    features,
                },
            };
            editConfigurationProject(payload, false)(dispatch);
        }
    };

    const onFocusBoundary = (index: number) => {
        const bounds = projectData?.boundary.features[index].geometry.coordinates;
        const focusedBounds = bounds[0].map((x: any) => [x[1], x[0]]);
        map.fitBounds([focusedBounds]);
    };

    const onCollapseBoundaryDetails = () => {
        const boundariesPane = document.querySelector('#boundaries-data-pane') as HTMLElement;
        const boundariesArrow = document.querySelector('#boundary-dropdown-arrow') as HTMLElement;
        const boundariesOpen = store.get(STORE_BOUNDARY_SHOW_DETAILS) ?? true;
        if (boundariesPane) {
            boundariesPane.style.display = boundariesOpen ? 'none' : 'block';
        }
        if (boundariesArrow) {
            boundariesArrow.style.cssText = boundariesOpen ? styles.arrowUp : styles.arrowDown;
        }
        store.set(STORE_BOUNDARY_SHOW_DETAILS, !boundariesOpen);
    };

    const onDecimalDegreePressed = (isDecimal: boolean) => {
        const decimalSelected = store.get(STORE_BOUNDARY_DEGREE_BUTTON) ?? true;
        if ((isDecimal && decimalSelected) || (!isDecimal && !decimalSelected)) return;
        const decimalButton = document.querySelector('#decimal-button') as HTMLElement;
        const degreeButton = document.querySelector('#degree-button') as HTMLElement;
        if (decimalButton && degreeButton) {
            decimalButton.style.cssText = !isDecimal ? styles.buttonActive : styles.buttonDisabled;
            degreeButton.style.cssText = isDecimal ? styles.buttonActive : styles.buttonDisabled;
        }

        toggleShowBoundaryInDecimal()(dispatch);
    };

    const globalClickListener = (e: any) => {
        const menuDiv = document.querySelector('#boundary-input-options') as HTMLElement;
        if (menuDiv?.style.display !== 'none' && !e.target.closest('#boundary-system-type')) {
            setOpenBoundaryOptions(false);
        }
    };

    // ----------------- HTML ELEMENTS ------------------ //
    const createDropdownMenu = () => {
        const boundaryValue = store.get(STORE_SELECTED_BOUNDARY_SYSTEM) ?? WGS84_GRID_ID;
        const boundaryObj =
            dropdownOptions.filter((x: any) => x.id === boundaryValue)?.[0]?.value ?? '';
        const htmlDropdownOptions = dropdownOptions
            .map(
                (x: any) => `
            <div key="${x.id}" style="${styles.inputOption}" id="boundary-option-${x.id}">
                <div style="${styles.inputOptionText}">
                    ${x.value}
                </div>
            </div>
        `,
            )
            .join('');

        return `
            <div style="${styles.selectMenuContainer}">
                <div id="boundary-system-type" style="${styles.selectMenu}">
                    <div id="selected-boundary-input-text" style="${styles.dropdownOption}">
                        ${boundaryObj}
                    </div>
                    <div style="${styles.arrowContainer}">
                        <img id="input-dropdown-arrow" style="${styles.arrowDown};" src="/images/ArrowIcon.svg"/>
                    </div>
                </div>
                <div id="boundary-input-options" style="${styles.optionsContainer}">
                    ${htmlDropdownOptions}
                </div>
            </div>
        `;
    };

    const createDropdownContainer = () => {
        const boundaryValue = store.get(STORE_SELECTED_BOUNDARY_SYSTEM) ?? WGS84_GRID_ID;
        const boundariesOpen = store.get(STORE_BOUNDARY_SHOW_DETAILS) ?? true;
        const decimalSelected = store.get(STORE_BOUNDARY_DEGREE_BUTTON) ?? true;

        return `
            <div style="${styles.topContainer}">
                <div style="${styles.topHalf}">
                    <div style="${styles.title}">Grid</div>
                    <div id="decimal-degree-container" style="${styles.buttonsContainer}display: ${boundaryValue === WGS84_GRID_ID ? 'flex' : 'none'}">
                        <div id="decimal-button" style="${!decimalSelected ? styles.buttonActive : styles.buttonDisabled}">Decimal</div>
                        <div style="${styles.textSlash}">/</div>
                        <div id="degree-button" style="${decimalSelected ? styles.buttonActive : styles.buttonDisabled}">Degree</div>
                    </div>
                </div>
                <div style="${styles.bottomHalf}">
                    ${createDropdownMenu()}
                </div>
            </div>
            <div style="${styles.bottomContainer}">
                <div id="boundaries-dropdown" style="${styles.boundariesContainer}">
                    <div style="${styles.boundariesText}">BOUNDARIES</div>
                    <img id="boundary-dropdown-arrow" style="${boundariesOpen ? styles.arrowDown : styles.arrowUp}" src="/images/ArrowIcon.svg"/>
                </div>
                <div id="boundaries-data-pane" style="${styles.dataContainer}display: ${!boundariesOpen ? 'none' : 'block'}">
                </div>
            </div>
        `;
    };

    const boundaryDataComponent = (
        title: string,
        boundaries: any[],
        selectedBoundarySystem: string,
        decimalSelected: boolean,
        idx: number,
    ) => {
        const left: any = [];
        const right: any = [];
        boundaries.forEach((x: any, idx: number) => {
            const data = `
                <tr>
                    <td style="${styles.coordDataLabel}">
                        ${convertIntToLabel(idx, '')}
                    </td>
                    <td style="${styles.coordDataItem}">
                        |
                    </td>
                    <td style="${styles.coordDataItem}">
                        ${displayCoordinateValue(x[0], selectedBoundarySystem, decimalSelected, true)}
                    </td>
                    <td style="${styles.coordDataItem}">
                        ${displayCoordinateValue(x[1], selectedBoundarySystem, decimalSelected, false)}
                    </td>
                </tr>
            `;
            if (idx === boundaries.length - 1) {
                return; // Removes last coordinate which is duplicate of first coordinate
            }
            if (idx % 2 === 0) {
                left.push(data);
            } else {
                right.push(data);
            }
        });

        return `
            <div style="${styles.dataItem}">
                <div style="${styles.boundariesInputContainer}">
                    <input type="text" id="boundary-text-field-${idx}" value="${sanitizeWithoutHtml(title).trim()}" style="${styles.input}" maxlength="50" autocomplete="off"/>
                </div>
                <div style="${styles.dataGridContainer}">
                    <table>
                        ${left.join('')}
                    </table>
                    <table>
                        ${right.join('')}
                    </table>
                </div>
            </div>
        `;
    };

    // ----------------- MAP CONTROL BUTTONS ------------------ //
    const control = new L.Control({ position: 'topleft' });
    control.onAdd = function () {
        const mainContainer = L.DomUtil.create('div');
        const topContainer = L.DomUtil.create('div');
        mainContainer.id = 'mainContainer';
        mainContainer.style.cssText = styles.container;
        mainContainer.addEventListener('mouseenter', () => {
            map.scrollWheelZoom.disable();
            map.dragging.disable();
        });
        mainContainer.addEventListener('mouseleave', () => {
            map.scrollWheelZoom.enable();
            map.dragging.enable();
        });
        topContainer.innerHTML = createDropdownContainer();

        mainContainer.appendChild(topContainer);

        return mainContainer;
    };

    React.useEffect(() => {
        if (map) {
            map.addControl(control);
            document.addEventListener('click', globalClickListener);
            document
                .querySelector('#boundary-system-type')
                ?.addEventListener('click', () => setOpenBoundaryOptions(true));
            document
                .querySelector('#boundaries-dropdown')
                ?.addEventListener('click', onCollapseBoundaryDetails);
            document
                .querySelector('#decimal-button')
                ?.addEventListener('click', () => onDecimalDegreePressed(true));
            document
                .querySelector('#degree-button')
                ?.addEventListener('click', () => onDecimalDegreePressed(false));
            dropdownOptions.forEach((x: any) => {
                const item = document.querySelector(`#boundary-option-${x.id}`) as HTMLElement;
                item?.addEventListener('click', () =>
                    onBoundrySystemChange(x.id, originalBoundaryData),
                );
                if (item) {
                    item.onmouseover = () => {
                        item.style.backgroundColor = '#265C7F';
                        item.style.color = '#FFFFFF';
                    };
                    item.onmouseleave = () => {
                        item.style.backgroundColor = '#FFFFFF';
                        item.style.color = '#000000';
                    };
                }
            });
        }

        return () => {
            map.removeControl(control);
        };
    }, [map, originalBoundaryData]);

    return <></>;
};
