import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { Row } from 'state-domains/domain';

import { buildCopyCoordinateRowsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const copyCoordinateCells = (
    drillholeId: string,
    coordinateRows: any[],
    type: ACTIVITY_TYPES,
): Observable<Row[]> => {
    const url = buildCopyCoordinateRowsUrl(drillholeId, type);
    return sendRequestWithXSRFToken(url, coordinateRows).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const { Rows } = response;
            const convertedRows = convertToCamel<Row[]>(Rows);
            return observableOf(convertedRows);
        }),
    );
};
