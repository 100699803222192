import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildTableDownloadURL } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';
import { TableDownloadPayload, TableDownloadResponse } from '../types';

// build a post request to download a table passing in a json object
export const dumpTableData = (payload: TableDownloadPayload): Observable<TableDownloadResponse> => {
    const url = buildTableDownloadURL();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<TableDownloadResponse>) => observableOf(response)),
    );
};
