export const i18n = {
    title: {
        id: 'main.generic-error.title',
        defaultMessage: 'Oops!',
    },
    subtitle: {
        id: 'main.generic-error.subtitle',
        defaultMessage: 'Something went wrong',
    },
    defaultMessage: {
        id: 'main.generic-error.default-message',
        defaultMessage: 'If this problem persists, please contact support',
    },
    title404: {
        id: 'main.generic-error.title',
        defaultMessage: '404',
    },
    subtitle404: {
        id: 'main.generic-error.subtitle',
        defaultMessage: 'Page Not Found',
    },
    defaultMessage404: {
        id: 'main.generic-error.default-message',
        defaultMessage: 'Please ensure the URL is correct',
    },
    pageTitle: {
        id: 'main.generic-error.pageTitle',
        defaultMessage: 'Not Found',
    },
};
