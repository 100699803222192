import store from 'store';

import { ACTIVITY_TYPES, CONFIGURATION_TYPES, DATATABLE_TYPES } from 'state-domains/constants';

import { getListingDefaultLimit, LISTING_PAGE_SIZE_OPTIONS } from './PaginationControls';

export const CACHED_PAGES_STORE = 'cached_last_page';

export interface CachedPage {
    offset: number;
    limit: number;
}

export function getDefaultPageCache(userId: string) {
    return {
        offset: 0,
        limit: getListingDefaultLimit(userId),
    };
}

function getCachedPageForComponent(
    component: PAGINATION_COMPONENT_TYPE,
    userId: string,
    subscriptionId: string,
) {
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    if (!!lastPages && !!component && lastPages[component]) {
        return lastPages[component];
    }
    return null;
}

export function getCachedPage(
    component: PAGINATION_COMPONENT_TYPE,
    userId: string,
    subscriptionId: string,
) {
    const cached = getCachedPageForComponent(component, userId, subscriptionId);
    const defaults = getDefaultPageCache(userId);
    return checkAndCorrectValues(cached, defaults);
}

export function updateCachedPage(
    component: PAGINATION_COMPONENT_TYPE,
    userId: string,
    subscriptionId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    const updated = {
        ...lastPages,
        [component]: { offset: currentOffset, limit: currentPageSize },
    };
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, { ...updated });
}

export function clearCachedPage(
    component: PAGINATION_COMPONENT_TYPE,
    userId: string,
    subscriptionId: string,
    newPageSize: number,
) {
    updateCachedPage(component, userId, subscriptionId, 0, newPageSize);
}

export function clearAllCachedPages(userId: string, subscriptionId: string) {
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
}

export function getCachedCollarPage(
    activityType: ACTIVITY_TYPES,
    userId: string,
    subscriptionId: string,
    projectId: string,
): CachedPage {
    const component =
        activityType === ACTIVITY_TYPES.DRILLING
            ? PAGINATION_COMPONENT_TYPE.PROJECTS_DRILLHOLES
            : PAGINATION_COMPONENT_TYPE.PROJECTS_POINTS;
    const cachedPageObj = getCachedPageForComponent(component, userId, subscriptionId);
    if (cachedPageObj) {
        const pageForProject = cachedPageObj[projectId];
        const defaults = getDefaultPageCache(userId);
        return checkAndCorrectValues(pageForProject, defaults);
    }
    return getDefaultPageCache(userId);
}

export function updateCachedCollarPage(
    activityType: ACTIVITY_TYPES,
    userId: string,
    subscriptionId: string,
    projectId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    const component =
        activityType === ACTIVITY_TYPES.DRILLING
            ? PAGINATION_COMPONENT_TYPE.PROJECTS_DRILLHOLES
            : PAGINATION_COMPONENT_TYPE.PROJECTS_POINTS;
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    const pagesForComponent = lastPages[component] ?? {};
    const updated = {
        ...lastPages,
        [component]: {
            ...pagesForComponent,
            [projectId]: { offset: currentOffset, limit: currentPageSize },
        },
    };
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, { ...updated });
}

export function clearCacheCollarPage(
    activityType: ACTIVITY_TYPES,
    userId: string,
    subscriptionId: string,
    projectId: string,
    newPageSize: number,
) {
    updateCachedCollarPage(activityType, userId, subscriptionId, projectId, 0, newPageSize);
}

function getComponentTypeFromDataTableType(type: CONFIGURATION_TYPES | DATATABLE_TYPES) {
    switch (type) {
        case CONFIGURATION_TYPES.LISTS:
        case DATATABLE_TYPES.LISTS:
            return PAGINATION_COMPONENT_TYPE.SITE_SETTINGS_LOGGING_LISTS;
        case CONFIGURATION_TYPES.TABLES:
        case DATATABLE_TYPES.TABLES:
            return PAGINATION_COMPONENT_TYPE.SITE_SETTINGS_LOGGING_TABLES;
        case CONFIGURATION_TYPES.FIELDS:
        case DATATABLE_TYPES.FIELDS:
            return PAGINATION_COMPONENT_TYPE.SITE_SETTINGS_LOGGING_FIELDS;
        case CONFIGURATION_TYPES.HEADERS:
        case DATATABLE_TYPES.HEADERS:
            return PAGINATION_COMPONENT_TYPE.SITE_SETTINGS_LOGGING_HEADERS;
        case CONFIGURATION_TYPES.ACTIVITIES:
        case DATATABLE_TYPES.ACTIVITIES:
            return PAGINATION_COMPONENT_TYPE.ACTIVITY_PROJECTS_ACTIVITIES;
        case CONFIGURATION_TYPES.PROJECTS:
        case DATATABLE_TYPES.PROJECTS:
            return PAGINATION_COMPONENT_TYPE.ACTIVITY_PROJECTS_PROJECTS;
        case CONFIGURATION_TYPES.SAMPLE_LISTS:
        case DATATABLE_TYPES.SAMPLE_LISTS:
            return PAGINATION_COMPONENT_TYPE.SAMPLING_SAMPLE_LISTS;
        case CONFIGURATION_TYPES.SAMPLE_FIELDS:
            return PAGINATION_COMPONENT_TYPE.SAMPLING_DISPATCH_FIELDS;
        case CONFIGURATION_TYPES.SAMPLE_HEADERS:
            return PAGINATION_COMPONENT_TYPE.SAMPLING_DISPATCH_HEADERS;
        case CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS:
            return PAGINATION_COMPONENT_TYPE.SAMPLING_ANALYSIS_WORKFLOW;
        case CONFIGURATION_TYPES.CATEGORIES:
            return PAGINATION_COMPONENT_TYPE.ACCOUNT_CATEGORIES;
        case CONFIGURATION_TYPES.USERS:
            return PAGINATION_COMPONENT_TYPE.ACCOUNT_USERS;
        case DATATABLE_TYPES.JOBS:
            return PAGINATION_COMPONENT_TYPE.JOB_LISTING;
        case DATATABLE_TYPES.GRIDS:
        case CONFIGURATION_TYPES.GRIDS:
            return PAGINATION_COMPONENT_TYPE.ACTIVITY_PROJECTS_GRIDS;
        case CONFIGURATION_TYPES.ACTIVITY_GROUPS:
            return PAGINATION_COMPONENT_TYPE.ACTIVITY_GROUPS;
        default:
            return null;
    }
}
export function getCachedDataTablePage(
    type: CONFIGURATION_TYPES | DATATABLE_TYPES,
    userId: string,
    subscriptionId: string,
): CachedPage {
    const component = getComponentTypeFromDataTableType(type);
    const defaults = getDefaultPageCache(userId);
    if (!component) {
        return defaults; // dont need this if check for component in getCachedPageForComponent function
    }
    const cached = getCachedPageForComponent(component, userId, subscriptionId);
    return checkAndCorrectValues(cached, defaults);
}

export function updateCachedDataTablePage(
    type: CONFIGURATION_TYPES | DATATABLE_TYPES,
    userId: string,
    subscriptionId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    const component = getComponentTypeFromDataTableType(type);
    if (!component) {
        return;
    }
    updateCachedPage(component, userId, subscriptionId, currentOffset, currentPageSize);
}

export function clearCacheDataTablePage(
    type: CONFIGURATION_TYPES | DATATABLE_TYPES,
    userId: string,
    subscriptionId: string,
    newPageSize: number,
) {
    updateCachedDataTablePage(type, userId, subscriptionId, 0, newPageSize);
}

export enum PAGINATION_COMPONENT_TYPE {
    PROJECTS_DRILLHOLES = 'PROJECTS_DRILLHOLES',
    PROJECTS_POINTS = 'PROJECTS_POINTS',
    SITE_SETTINGS_LOGGING_LISTS = 'SITE_SETTINGS_LOGGING_LISTS',
    SITE_SETTINGS_LOGGING_TABLES = 'SITE_SETTINGS_LOGGING_TABLES',
    SITE_SETTINGS_LOGGING_FIELDS = 'SITE_SETTINGS_LOGGING_FIELDS',
    SITE_SETTINGS_LOGGING_HEADERS = 'SITE_SETTINGS_LOGGING_HEADERS',
    SAMPLING_SAMPLE_LISTS = 'SITE_SETTINGS_SAMPLING_LISTS',
    SAMPLING_DISPATCH_FIELDS = 'SITE_SETTINGS_SAMPLING_DISPATCH_FIELDS',
    SAMPLING_DISPATCH_HEADERS = 'SITE_SETTINGS_SAMPLING_DISPATCH_HEADERS',
    SAMPLING_ANALYSIS_WORKFLOW = 'SITE_SETTINGS_SAMPLING_ANALYSIS_WORKFLOW',
    ACTIVITY_PROJECTS_ACTIVITIES = 'SITE_SETTINGS_ACTIVITY_PROJECTS_ACTIVITIES',
    ACTIVITY_PROJECTS_PROJECTS = 'SITE_SETTINGS_ACTIVITY_PROJECTS_PROJECTS',
    ACTIVITY_PROJECTS_GRIDS = 'SITE_SETTINGS_ACTIVITY_PROJECTS_GRIDS',
    ACCOUNT_CATEGORIES = 'SITE_SETTINGS_ACCOUNT_CATEGORIES',
    ACCOUNT_USERS = 'SITE_SETTINGS_ACCOUNT_USERS',
    ACTIVITY_GROUPS = 'SITE_SETTINGS_ACTIVITY_GROUPS',
    PROJECT_LISTING = 'PROJECT_LISTING',
    JOB_LISTING = 'JOB_LISTING',
    NOTIFICATION_LISTING = 'NOTIFICATION_LISTING',
}

/**
 * Checks if values from local storage are modified. Reset to default values if changed.
 * @param valueFromStorage cached object
 * @param defaults default values
 * @returns value from cache, corrected to defaults if modified
 */
function checkAndCorrectValues(
    valueFromStorage: { offset: number; limit: number } | null,
    defaults: { offset: number; limit: number },
) {
    if (!valueFromStorage) {
        return defaults;
    }
    const cachedPage = { ...valueFromStorage };
    const { offset, limit } = cachedPage;
    if (!LISTING_PAGE_SIZE_OPTIONS.includes(limit)) {
        cachedPage.limit = defaults.limit;
    }
    if (offset % limit !== 0) {
        cachedPage.offset = defaults.offset;
    }
    return cachedPage;
}
