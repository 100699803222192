import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Row } from 'state-domains/domain';
import { buildDeleteListRowUrl } from 'state-domains/network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from 'state-domains/utils';

export const deleteListRow = (listId: string, id: string): Observable<string[]> => {
    const url = buildDeleteListRowUrl(listId, id);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const row = convertToCamel<Row>(response);
            return observableOf([row.id]);
        }),
    );
};
