import { createResource, loadResources } from './allResources';
import { fetchNotificationToken } from './auth';
import { deleteMessage, bulkDeleteMessages } from './deleteMessages';
import { deleteResource } from './deleteResource';
import { loadDigestPreferences, updateDigestPreference, createDigestPreference } from './digest';
import { loadFilters, createFilter, updateFilter, deleteFilter } from './filters';
import { loadMessages, loadMessagesLink } from './loadMessages';
import { pollMessages, messagesSeen } from './pollMessages';
import { updateMessage, bulkReadMessages, bulkUnreadMessages } from './updateMessages';

export const notificationsShim = {
    pollMessages,
    messagesSeen,

    loadMessages,
    loadMessagesLink,
    updateMessage,
    bulkReadMessages,
    bulkUnreadMessages,
    deleteMessage,
    bulkDeleteMessages,

    loadFilters,
    updateFilter,
    createFilter,
    deleteFilter,

    loadResources,
    createResource,
    deleteResource,

    loadDigestPreferences,
    createDigestPreference,
    fetchNotificationToken,
    updateDigestPreference,
};
