import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ReIndexResponse } from 'state-domains/domain/drillhole';
import { buildReIndexUrl } from 'state-domains/network';
import { convertToCamel, sendRequestWithXSRFToken } from 'state-domains/utils';

export const reIndex = (payload: {
    collar: string;
    table_view: string;
}): Observable<ReIndexResponse> => {
    const url = buildReIndexUrl();
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<ReIndexResponse>) => {
            const converted = convertToCamel<ReIndexResponse>(response);
            return observableOf(converted);
        }),
    );
};
