import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { Header } from 'state-domains/domain';

import {
    buildConfigurationHeadersUrl,
    buildEditSampleDispatchHeadersUrl,
} from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveHeader = (
    id: string,
    payload: any,
    configType = CONFIGURATION_TYPES.HEADERS,
): Observable<Header> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationHeadersUrl(id)
            : buildEditSampleDispatchHeadersUrl(id);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedHeader = convertToCamel<Header>(response);
            return observableOf(convertedHeader);
        }),
    );
};
