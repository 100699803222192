import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { AssignUnassignResponse } from 'state-domains/domain/configuration';
import { ActivityUser } from 'state-domains/domain/project';

import { buildConfigurationProjectActivityGroupUsersUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const assignUserToProjectActivityGroup = (
    projectId: string,
    activityId: string,
    groupId: string,
    payload: ActivityUser,
): Observable<AssignUnassignResponse> => {
    const url = buildConfigurationProjectActivityGroupUsersUrl(projectId, activityId, groupId);
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ActivityUser>) => {
            const assignGroupUserResult = {
                user: convertToCamel<ActivityUser>(response),
                type: 'group' as const,
                payloadType: 'assign' as const,
                projectId,
                activityId,
                groupId,
            };
            return observableOf(assignGroupUserResult);
        }),
    );
};
