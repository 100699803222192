import { NotificationType } from '@local/web-design-system';
import { isFunction } from 'lodash-es';

import { Nullable } from 'src/types';

import {
    SnackbarNotificationDescriptor,
    SnackbarNotificationShowingArg,
} from '../NotificationsContext.types';

export class SnackbarNotificationData implements SnackbarNotificationShowingArg {
    public readonly descriptor: SnackbarNotificationDescriptor;

    public readonly type: NotificationType;

    public readonly message: string;

    public readonly index: number;

    public readonly onClose?: () => void;

    public timeoutMs?: number;

    public className?: Partial<any>;

    private _autoRemovingPromise: Nullable<Promise<void>>;

    private _autoRemovingTimeoutMs: Nullable<number>;

    private _closed: boolean;

    constructor(arg: SnackbarNotificationShowingArg, index: number) {
        this.descriptor = arg.descriptor;
        this.type = arg.type;
        this.message = arg.message;
        this.onClose = arg.onClose;
        this.className = arg.className;

        this.index = index;

        this._autoRemovingPromise = null;
        this._autoRemovingTimeoutMs = null;

        this._closed = false;
    }

    get isPendingForRemoving() {
        return !!this._autoRemovingPromise;
    }

    get autoRemovingTimeoutMs() {
        return this._autoRemovingTimeoutMs;
    }

    checkIfMatchTarget(
        descriptor: SnackbarNotificationDescriptor,
        index: Nullable<number> = null,
    ): boolean {
        return this.descriptor === descriptor && (index !== null ? this.index === index : true);
    }

    get isClosed(): boolean {
        return this._closed;
    }

    registerForRemoving(timeoutMs: number): Promise<void> {
        if (this._autoRemovingPromise) {
            return this._autoRemovingPromise;
        }

        return (this._autoRemovingPromise = new Promise((resolve) => {
            this._autoRemovingTimeoutMs = timeoutMs;
            const timeoutId = setTimeout(() => {
                clearTimeout(timeoutId);
                resolve();
            }, timeoutMs);
        }));
    }

    handleClose() {
        if (this._closed) {
            const msg =
                'Notification has been already closed;' +
                `descriptor: ${this.descriptor}` +
                `index: ${this.index}`;
            throw new Error(msg);
        }

        if (isFunction(this.onClose)) {
            this.onClose();
        }

        this._closed = true;
    }
}

export default SnackbarNotificationData;
