import { UserProfile, Link } from '@local/web-design-system';
import { Grid } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { userState } from 'state-domains/domain';
import { getClassicMXDepositUrl } from 'state-domains/utils';

import { UserPortalSpinner } from 'src/components';
import { PathConstants, PREFERENCES_PATH } from 'src/routes';
import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';

import { useStyles } from './UserPortalProfile.styles';
import { UserPortalProfileProps } from './UserPortalProfile.types';

const IDP_PROFILE_URL = 'https://id.seequent.com/settings';
const MYS_HELP_URL = 'https://my.seequent.com/support/search?product=38';
const TERMS_URL = 'https://www.seequent.com/legal-privacy/online-services-user-terms';

// this component expects the user.current state to be prepoulated/ing
export const UserPortalProfile = (props: UserPortalProfileProps) => {
    const {
        selectors: { currentRole, isUserStatePending, currentUserEmail, currentUserName },
    } = userState;

    const role = useSelector(currentRole);

    const isPending = useSelector(isUserStatePending);
    const selectedName = useSelector(currentUserName);
    const { useShortDivider } = props;
    const { classes } = useStyles();
    const intl = useIntl();
    const navigate = useNavigate();

    const email = useSelector(currentUserEmail);
    const name = selectedName.length
        ? selectedName
        : intl.formatMessage({
              id: 'user-profile-unknown',
              defaultMessage: 'Unknown',
          });

    if (isPending) {
        return (
            <Grid container className={classes.profilePending}>
                <UserPortalSpinner justify="flex-end" size={20} />
            </Grid>
        );
    }

    const doLogout = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.SIGN_OUT);
        navigate(PathConstants.LOGOUT.ROOT);
    };
    const doPreferences = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.VISIT, 'preferences');
        navigate(PREFERENCES_PATH);
    };
    const goToClassicMXDeposit = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.VISIT, 'classic app');
        // Location coming from userportal config
        window.location.replace(getClassicMXDepositUrl() ?? 'https://dev.mxdeposit.net');
    };
    const goToProfile = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.VISIT, 'my profile');
        window.open(IDP_PROFILE_URL, 'MySeequent', 'noopener');
    };
    const goToTerms = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.VISIT, 'terms');
        window.open(TERMS_URL, 'Seequent', 'noopener');
    };
    const goToSupport = () => {
        trackUserAction(TRACKING_COMPONENTS.USER_MENU, EVENT_TYPES.VISIT, 'support');
        window.open(MYS_HELP_URL, 'MySeequent', 'noopener');
    };

    const links: Link[] = [
        {
            text: intl.formatMessage({
                id: 'mxDeposit.profile',
                defaultMessage: 'My Profile',
            }),
            id: 'mxDeposit.profile',
            action: goToProfile,
        },
        {
            text: intl.formatMessage({
                id: 'mxDeposit.preferences',
                defaultMessage: 'Preferences',
            }),
            action: doPreferences,
            id: 'mxDeposit.preferences',
        },
        {
            text: intl.formatMessage({
                id: 'mxDeposit.help',
                defaultMessage: 'Need Help?',
            }),
            id: 'mxDeposit.help',
            action: goToSupport,
        },
        {
            text: intl.formatMessage({
                id: 'mxDeposit.termsofuse',
                defaultMessage: 'Terms of Use',
            }),
            id: 'mxDeposit.termsofuse',
            action: goToTerms,
        },
        {
            text: intl.formatMessage({
                id: 'mxDeposit.signout',
                defaultMessage: 'Sign Out',
            }),
            action: doLogout,
            id: 'mxDeposit.signout',
        },
        {
            text: intl.formatMessage({
                id: 'mxDeposit.gotoclassicmxdeposit',
                defaultMessage: 'Go to Classic MX Deposit',
            }),
            action: goToClassicMXDeposit,
            id: 'mxDeposit.gotoclassicmxdeposit',
        },
    ];

    let userRole = '';
    if (!!role && role.toLocaleLowerCase() === 'admin') {
        const adminMsg = { id: 'profile.role', defaultMessage: 'ADMIN' };
        userRole = intl.formatMessage(adminMsg);
    }

    const overrideClasses = useShortDivider ? { rootDivider: classes.shortDivider } : {};

    return (
        <Grid automation-id="MXDeposit-user-profile" sx={{ height: '100%' }}>
            <UserProfile
                displayName={name}
                email={email}
                role={userRole}
                links={links}
                classes={overrideClasses}
            />
        </Grid>
    );
};
