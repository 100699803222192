import { LOAD_STATUS_PENDING, CLIENT_SIDE_PAGINATION_LIMIT } from '../../../constants';
import { BaseAction } from '../../../types';
import { LIST_JOBS, REFRESH_STATE, SET_JOB_SEARCH_TERM } from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { JobError, JobState } from '../types';

export const INITIAL_STATE: JobState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    jobs: [],
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
};

function setJobSearchTermReducer(
    state: JobState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): JobState {
    const { searchTerm, offset, limit } = action.payload;
    return completeReducer({
        ...state,
        searchTerm,
        offset,
        limit,
    });
}

const listJobsPendingReducer = (state: JobState): JobState => pendingReducer({ ...state });

const listJobsSuccessReducer = (state: JobState, actions: BaseAction) =>
    completeReducer({ ...state, jobs: actions.payload });

function listJobsFailReducer(state: JobState, action: BaseAction<JobError>) {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
}
const resetJobStateReducer = (_state: JobState, _action: BaseAction) => ({ ...INITIAL_STATE });

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_JOBS)]: listJobsPendingReducer,
    [typeComplete(LIST_JOBS)]: listJobsSuccessReducer,
    [typeFail(LIST_JOBS)]: listJobsFailReducer,
    [typeComplete(SET_JOB_SEARCH_TERM)]: setJobSearchTermReducer,
    [REFRESH_STATE]: resetJobStateReducer,
});
