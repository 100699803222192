import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildRecomputeSampleResultsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const recomputeSampleResults = (activity: string): Observable<{}> => {
    const url = buildRecomputeSampleResultsUrl();
    return sendRequestWithXSRFToken(url, { activity }, 'POST').pipe(
        // Don't care about the job response received from the server
        switchMap(() => observableOf({})),
    );
};
