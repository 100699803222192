import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseApiEndpoint } from 'state-domains/utils/baseApiEndpoint';

// Define a service using a base URL and expected endpoints
export const ApiClient = createApi({
    reducerPath: 'ApiClient',
    baseQuery: fetchBaseQuery({ baseUrl: baseApiEndpoint() }),
    endpoints: () => ({}),
});
