import { fonts, theme } from '@local/web-design-system';
import { makeStyles } from 'tss-react/mui';

import { mainFonts, mainTextFonts } from 'src/styles/common.styles';

export const mainBodySpacing = 37;
export const cancelButtonSpacing = 23;
export const actionButtonSpacing = 24;

export const titleFonts = {
    ...mainFonts,
    fontWeight: fonts.weights.medium,
    fontSize: '14px',
    paddingBottom: theme.spacing(2),
};
export const controlLabel = {
    paddingBottom: theme.spacing(1),
    alignSelf: 'flex-end',
    ...mainFonts,
    fontSize: '14px',
    fontWeight: fonts.weights.medium,
};
export const defaultBody = {
    ...mainTextFonts,
    paddingTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
};
export const iconStyles = {
    marginRight: theme.spacing(2.5),
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    alignItems: 'center',
    justifyContent: 'center',
};
export const iconWarningStyles = {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3.5),
    height: '57px',
    width: theme.spacing(8),
    color: theme.palette.warning.main,
};
export const bodyContent = {
    padding: theme.spacing(2, 8, 4, 8),
};

export const useDeleteStyles = makeStyles()(
    () =>
        ({
            deleteText: {
                paddingLeft: theme.spacing(2),
            },
            typography: {
                wordWrap: 'break-word',
                ...mainTextFonts,
            },
            iconWarningStyles: {
                ...iconWarningStyles,
            },
            title: {
                ...titleFonts,
                paddingTop: theme.spacing(3.5),
                width: theme.spacing(mainBodySpacing),
            },
            dialogContent: {
                padding: theme.spacing(0, 8, 4, 8),
                color: theme.palette.secondary.main,
                width: theme.spacing(mainBodySpacing),
                button: {
                    width: '160px',
                    height: theme.spacing(4),
                },
                primaryColor: {
                    backgroundColor: theme.palette.grey[50],
                },
                textField: {
                    width: theme.spacing(37),
                    height: theme.spacing(5),
                },
            },
        }) as const,
);

export const useCloneStyles = makeStyles()(
    () =>
        ({
            icon: {
                color: theme.palette.primary.main,
                padding: theme.spacing(3.5, 2.5, 0, 3),
            },
            iconContainer: {
                justifyContent: 'center',
                alignItems: 'center',
            },
            title: {
                ...titleFonts,
                padding: theme.spacing(3.5, 0, 0),
            },
            bodyContent,
            mainTextFonts,
            bodyText: {
                ...mainTextFonts,
                wordWrap: 'break-word',
                paddingBottom: theme.spacing(2),
            },
            inputLabel: {
                ...titleFonts,
                paddingBottom: theme.spacing(1),
            },
            inputField: {
                height: theme.spacing(5),
            },
            checkboxLabel: {
                ...mainTextFonts,
            },
            containerWidth: {
                width: '100%',
            },
            textWidth: {
                maxWidth: theme.spacing(36.75),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        }) as const,
);
