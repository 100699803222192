import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildPinProjectUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';
import { Project } from '../types';

export const setPinProject = (projectId: string, pin: boolean): Observable<Project[]> => {
    const obs = sendRequestWithXSRFToken(buildPinProjectUrl(projectId, pin));
    return obs.pipe(switchMap(({ response }: AjaxResponse<any>) => observableOf(response)));
};
