import { createSelector } from 'reselect';

import { ShimState, Uid } from '../../../../types';
import { isPending } from '../../../../utils';
import { instanceResource, projectResource } from '../../shim/translators';
import {
    NotificationsState,
    ProjectTopics,
    Channel,
    FilterQueryProps,
    AsyncFilter,
    AsyncResource,
    topicsDisabledByDefaultForApp,
    topicsDisabledByDefaultForEmail,
    FilterProps,
    FilterMap,
    DigestPeriod,
} from '../../types';
import { buildFilterResource } from '../utils';

function getFilterKey({ resource, topic, channel }: FilterQueryProps) {
    return `${resource}${topic}${channel}`;
}

const notifications = (state: Partial<ShimState>): NotificationsState => {
    const { notifications = {} as NotificationsState } = state || {};
    return notifications;
};

function filterMap(state: Partial<ShimState>): FilterMap {
    return notifications(state)?.filterState?.map ?? {};
}

const filterLink = (state: Partial<ShimState>, props: { filterUid: Uid }): string => {
    const filters = filterMap(state);
    return filters[props.filterUid].self;
};

const filter = (
    state: Partial<ShimState>,
    filterProps: FilterProps,
    channel: Channel,
): AsyncFilter | undefined => {
    const filters = filterMap(state);
    const resource = buildFilterResource(state, filterProps);
    if (!resource) {
        return undefined;
    }
    const filterKey = getFilterKey({ resource, channel, topic: filterProps.topic });
    return filters[filterKey];
};

const resourceByProjectUid = (
    state: Partial<ShimState>,
    props: { projectUid: Uid },
): AsyncResource | undefined => {
    const { instanceUuid, resourceState } = notifications(state);
    const resourceKey = projectResource(instanceUuid, props.projectUid);
    return resourceState.map[resourceKey];
};

const resourceLink = (
    state: Partial<ShimState>,
    props: { resource: string },
): string | undefined => {
    const { resourceState } = notifications(state);
    const { map } = resourceState;
    return map[props.resource]?.self;
};

const resourceTotal = (state: Partial<ShimState>): number => {
    const {
        resourceState: { map },
    } = notifications(state);
    return Object.keys(map).length;
};

const enabled = createSelector(notifications, ({ instanceUuid, resourceState: { map } }) => {
    const resource = instanceResource(instanceUuid);
    const obj = map[resource];
    return obj === undefined;
});

const topicEnabledDefault = (
    state: Partial<ShimState>,
    props: { topic: ProjectTopics; channel: Channel },
): boolean => {
    if (props.channel === Channel.APP) {
        return !topicsDisabledByDefaultForApp.includes(props.topic);
    }
    return !topicsDisabledByDefaultForEmail.includes(props.topic);
};

const getDigestPeriod = createSelector(
    notifications,
    (state) => state.digestState.digestPreference?.period ?? DigestPeriod.MINS30,
);

const getDigestSelfLink = createSelector(
    notifications,
    (state) => state.digestState.digestPreference?.selfLink,
);

const isDigestPending = createSelector(notifications, (state) => isPending(state.digestState));

export const selectors = {
    filter,
    filterLink,
    resourceLink,
    enabled,
    filterMap,
    isDigestPending,
    getDigestPeriod,
    getDigestSelfLink,
    resourceByProjectUid,
    resourceTotal,
    topicEnabledDefault,
};
