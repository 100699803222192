import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { AssignUnassignResponse } from 'state-domains/domain/configuration';
import { ActivityUser } from 'state-domains/domain/project';

import { buildConfigurationProjectActivityGroupUsersUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteUserFromProjectActivityGroup = (
    projectId: string,
    activityId: string,
    groupId: string,
    userId: string,
): Observable<AssignUnassignResponse> => {
    const url = `${buildConfigurationProjectActivityGroupUsersUrl(projectId, activityId, groupId)}/${userId}`;
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<ActivityUser>) => {
            const result = {
                user: convertToCamel<ActivityUser>(response),
                type: 'group' as const,
                payloadType: 'unassign' as const,
                projectId,
                activityId,
                groupId,
            };
            return observableOf(result);
        }),
    );
};
