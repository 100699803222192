import { makeStyles } from 'tss-react/mui';

export const styles = {
    container: `
        width: 356px;
        height: 32px;
    `,
    innerContainer: `
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    `,
    drawButton: `
        height: 32px;
        width: 82px;
        display: flex;
        flex-direction: row;
        background-color: #FFFFFF;
        cursor: pointer;
        align-items: center;
        border-radius: 4px;
    `,
    drawText: `
        width: 100%;
        height: 100%;
        font-size: 13px;
        font-weight: 400;
        line-height: 32px;
        font-family: Roboto;
        text-decoration: none;
        text-align: center;
    `,
    image: `
        width: 20px;
        height: 20px;
        padding-left: 8px;
    `,
    disabled: `
        height: 32px;
        width: 82px;
        display: flex;
        flex-direction: row;
        background-color: #FFFFFF;
        cursor: pointer;
        align-items: center;
        border-radius: 4px;
        background-color: #c6c6c6;
        opacity: 0.8;
    `,
    label: `
        font-size: 13px;
        font-weight: 500;
        line-height: 32px;
        font-family: Roboto;
        text-decoration: none;
        text-align: center;
        color: #FFFFFF;
    `,
};

export const useStyles = makeStyles()(
    () =>
        ({
            iconStyles: {
                background: 'none',
                border: 'none',
                borderRadius: '10px',
                backgroundColor: 'white',
            },
        }) as const,
);
