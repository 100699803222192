import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ActivityMap } from 'state-domains/domain';
import { editRankedColumnUrl } from 'state-domains/network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from 'state-domains/utils';

export const deleteRankedColumn = (
    activityId: string,
    rankedColumnId: string,
): Observable<{ activity: ActivityMap }> => {
    const url = editRankedColumnUrl(activityId, rankedColumnId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf(convertToCamel<{ activity: ActivityMap }>(response)),
        ),
    );
};
