import { BaseAction } from '../../../types';
import {
    LOAD_ACCOUNT_PREFERENCES,
    LOAD_CURRENT_USER,
    LOAD_RECENT_PROJECTS,
    LOGOUT_REQUEST,
    REFRESH_STATE,
    SWITCH_SUBSCRIPTION,
    UPDATE_USER_MODAL_OPEN,
} from '../../../types/actionTypes';
import {
    completeReducer,
    convertToCamel,
    createError,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { INITIAL_RECENT_PROJECT_STATE, INITIAL_USER_STATE, JsonUser, UserState } from '../types';

const loadCurrentUserCompleteReducer = (state: UserState, action: BaseAction) => {
    const usr = convertToCamel<JsonUser>(action.payload);
    const { subscriptions, user, selectedSubscriptionId } = usr;
    return completeReducer({
        ...state,
        subscriptions,
        selected: completeReducer({ id: selectedSubscriptionId }),
        ...user,
    });
};

const loadCurrentUserFailureReducer = (state: UserState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadCurrentUserPendingReducer = (state: UserState, _action: BaseAction) =>
    pendingReducer(state);

const loadAccountPreferencesCompleteReducer = (state: UserState, action: BaseAction) => ({
    ...state,
    accountPreferences: completeReducer(action.payload),
});

const loadAccountPreferencesFailureReducer = (state: UserState, action: BaseAction) => ({
    ...state,
    accountPreferences: failureReducer({ ...state.accountPreferences, error: createError(action) }),
});

const loadAccountPreferencesPendingReducer = (state: UserState, _action: BaseAction) => ({
    ...state,
    accountPreferences: pendingReducer(state.accountPreferences),
});

const loadRecentProjectsCompleteReducer = (state: UserState, action: BaseAction) => {
    const { payload } = action;
    return completeReducer({
        ...state,
        recentProjects: payload,
        loadRecentProjectsState: completeReducer({}),
    });
};

const updateUserModalOpenReducer = (state: UserState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        isUserModalOpen: payload,
    };
};

const resetUserStateReducer = (state: UserState, _action: BaseAction) => ({
    ...state,
    recentProjects: [],
    loadRecentProjectsState: INITIAL_RECENT_PROJECT_STATE,
    isUserModalOpen: false,
});

const switchSubscriptionPendingReducer = (state: UserState, _action: BaseAction) => ({
    ...state,
    selected: pendingReducer({ id: '' }),
});

const switchSubscriptionCompleteReducer = (state: UserState, action: BaseAction) => ({
    ...state,
    selected: completeReducer({ id: action.payload }),
});

const switchSubscriptionFailureReducer = (state: UserState, action: BaseAction) => ({
    ...state,
    selected: failureReducer({
        ...state.selected,
        error: createError(action),
    }),
});

export const reducer = mappedReducer(INITIAL_USER_STATE, {
    [typePending(LOAD_CURRENT_USER)]: loadCurrentUserPendingReducer,
    [typeComplete(LOAD_CURRENT_USER)]: loadCurrentUserCompleteReducer,
    [typeFail(LOAD_CURRENT_USER)]: loadCurrentUserFailureReducer,
    [typePending(LOAD_ACCOUNT_PREFERENCES)]: loadAccountPreferencesPendingReducer,
    [typeComplete(LOAD_ACCOUNT_PREFERENCES)]: loadAccountPreferencesCompleteReducer,
    [typeFail(LOAD_ACCOUNT_PREFERENCES)]: loadAccountPreferencesFailureReducer,

    [typeComplete(LOGOUT_REQUEST)]: () => INITIAL_USER_STATE,

    [typePending(LOAD_RECENT_PROJECTS)]: loadCurrentUserPendingReducer,
    [typeComplete(LOAD_RECENT_PROJECTS)]: loadRecentProjectsCompleteReducer,
    [typeFail(LOAD_RECENT_PROJECTS)]: loadCurrentUserFailureReducer,

    [typePending(SWITCH_SUBSCRIPTION)]: switchSubscriptionPendingReducer,
    [typeComplete(SWITCH_SUBSCRIPTION)]: switchSubscriptionCompleteReducer,
    [typeFail(SWITCH_SUBSCRIPTION)]: switchSubscriptionFailureReducer,

    [UPDATE_USER_MODAL_OPEN]: updateUserModalOpenReducer,

    [REFRESH_STATE]: resetUserStateReducer,
});
