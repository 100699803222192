import { AjaxError } from 'rxjs/ajax';

import {
    CHECK_LOGOUT_STATE,
    LOGOUT_REQUEST,
    OAUTH_LOGIN,
    OAUTH_LOGIN_URL,
    OAUTH_LOGOUT_URL,
    OAUTH_TOKEN,
} from '../../../types/actionTypes';
import { typeComplete, typeFail, typePending } from '../../../utils';
import { authShim } from '../shim';
import { OAuth2TokenOptions } from '../types';
import { OAuth2TokenDetails } from './types';

const logout = () => (dispatch: any, _getState: any) => {
    authShim.logout();

    dispatch({ type: typeComplete(LOGOUT_REQUEST) });
};

const oauth2FetchLoginUrl =
    () =>
    (dispatch: any, _getState: any): void => {
        dispatch({ type: typePending(OAUTH_LOGIN_URL) });
        authShim.oauth2FetchLoginUrl().subscribe({
            next: (redirectUrl: string) => {
                dispatch({ type: typeComplete(OAUTH_LOGIN_URL), payload: redirectUrl });
            },
            error: (err: AjaxError) => {
                dispatch({ type: typeFail(OAUTH_LOGIN_URL), payload: err });
            },
        });
    };

const oauth2FetchLogoutUrl =
    () =>
    (dispatch: any, _getState: any): void => {
        dispatch({ type: typePending(OAUTH_LOGOUT_URL) });
        authShim.oauth2FetchLogoutUrl().subscribe({
            next: (redirectUrl: string) => {
                dispatch({ type: typeComplete(OAUTH_LOGOUT_URL), payload: redirectUrl });
            },
            error: (err: AjaxError) => {
                dispatch({ type: typeFail(OAUTH_LOGOUT_URL), payload: err });
            },
        });
    };

const oauth2Token =
    (options: OAuth2TokenOptions = {}) =>
    (dispatch: any, getState: any) => {
        const preventOAuth2LoginRedirection = options.preventOAuth2LoginRedirection ?? false;
        dispatch({ type: typePending(OAUTH_TOKEN) });
        authShim.oauth2Token().subscribe({
            next: (response: OAuth2TokenDetails) => {
                dispatch({ type: typeComplete(OAUTH_TOKEN), payload: response });
            },
            error: (err: AjaxError) => {
                dispatch({
                    type: typeFail(OAUTH_TOKEN),
                    payload: { ...err, preventOAuth2LoginRedirection },
                });
                logout()(dispatch, getState);
            },
        });
    };

const oauth2LoginFailed = (message: string) => (dispatch: any, getState: any) => {
    dispatch({ type: typeFail(OAUTH_LOGIN), payload: { response: message, status: 401 } });
    logout()(dispatch, getState);
};

const setLogoutState = (loggedOut: boolean) => (dispatch: any) => {
    dispatch({ type: typeComplete(CHECK_LOGOUT_STATE), payload: loggedOut });
};

export const actions = {
    logout,
    oauth2FetchLoginUrl,
    oauth2FetchLogoutUrl,
    oauth2Token,
    oauth2LoginFailed,
    setLogoutState,
};
