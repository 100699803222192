import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { TableViewSpec } from 'state-domains/domain/subscription';
import { buildConfigurationActivityAddTableUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken } from '../../../utils';

export const addConfigurationActivityTable = (
    activityId: string,
    payload: any,
): Observable<TableViewSpec> => {
    const url = buildConfigurationActivityAddTableUrl(activityId);
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<TableViewSpec>) => {
            const res = convertToCamel<TableViewSpec>(response);
            return observableOf(res);
        }),
    );
};
