import { AsyncState, AsyncStateError, LoadStatus } from 'state-domains/types';

import { Drillhole } from '../drillhole';

export interface IntegrationState {
    imago: ImagoIntegration;
    seequentCloud: {
        configuration: SeequentCloudConfigurationResponse;
        state: AsyncState;
    };
}

// #region ========================= Imago =========================

export interface ImagoContextState {
    data: any;
    status: LoadStatus;
    error: AsyncStateError | null;
}

export interface ImagoIntegration {
    loginState: AsyncState;
    connectionStatus: string;
    imageriesCount: number;
    connectionState: AsyncState;
    syncState: AsyncState;
    userContextState: ImagoContextState;
}

export interface ImagoLoginCredentials {
    username: string;
    password: string;
}

export interface ImagoConnectionResponse {
    value: string;
}

export enum ImagoConnectionStatus {
    CONNECTED = 'connected',
    EXPIRED = 'expired',
    DISCONNECTED = 'disconnected',
}

export enum ImagoSyncType {
    IMAGES = 'sync-images',
    INTERVALS = 'sync-intervals',
}

export interface ImagoSyncResponse {
    Collar: Drillhole;
    imageriesCount: number;
}

// #endregion ========================= Imago =========================

// #region ========================= Payloads =========================

export interface ImagoSyncPayload {
    activity: string;
    collar: string;
    collar_name: string;
    column: string;
    project: string;
    table: string;
    tableview: string;
}

// #endregion ========================= Payloads =========================

interface ServiceAccess {
    hubCode: string;
    orgId: string;
    services: string[];
    hubUrl: string;
    hubName: string;
    orgName: string;
}

export interface SeequentCloudConfigurationResponse {
    seequentCloudConfig: {
        projectsMap: {
            [id: string]: ServiceAccess;
        };
    };
}
