import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationListUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';
import { ConfigurationListResponse } from '../types';

export const deleteConfigurationLists = (id: string): Observable<ConfigurationListResponse> => {
    const obs = sendDeleteRequestWithXSRFToken(buildConfigurationListUrl(id));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationListResponse>) => {
            const res = convertToCamel<ConfigurationListResponse>(response);
            return observableOf(res);
        }),
    );
};
