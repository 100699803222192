import { WrappedComponentProps } from 'react-intl';

import { DIMENSION_PREFERENCE_DENOMINATIONS } from 'state-domains/constants';
import { User } from 'state-domains/domain';

import { WithStyles } from 'src/styles/utils';

import { styles } from './PreferencesContent.styles';

export interface PreferencesProps {
    isFailed: boolean;
    isStale: boolean;
    user: User;
    forumUrl: string;
}

export type PreferencesAllProps = PreferencesProps &
    WithStyles<typeof styles> &
    WrappedComponentProps;

export enum DimensionTypes {
    DEFAULT = 46,
    SHORT = 42,
    COMPACT = 38,
}

export interface RowHeight {
    key: string;
    value: DimensionTypes;
}

export interface Preferences {
    rowHeight: string;
    listColourWidth: string;
    showRowNumbers: boolean;
    rowsPerPage?: number;
    pasteOptions: string;
}

export enum PreferencesFields {
    ROW_HEIGHT = 'rowHeight',
    LIST_COLOUR_WIDTH = 'listColourWidth',
    SHOW_ROW_NUMBERS = 'showRowNumbers',
    ROWS_PER_PAGE = 'rowsPerPage',
    PASTE_OPTIONS = 'pasteOptions',
}

export enum PASTE_OPTIONS {
    FAIL_FAST = 'failFast',
    PASTE_AS_MUCH_AS_POSSIBLE = 'pasteAsMuchAsPossible',
}

export const DEFAULT_PREFERENCES: Preferences = {
    rowHeight: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    listColourWidth: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    showRowNumbers: true,
    pasteOptions: PASTE_OPTIONS.FAIL_FAST,
    // rowsPerPage defaults are governed by the PaginationControls
};
