import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) => ({
    projectTilePending: {
        width: theme.spacing(32.5),
        height: `${theme.spacing(18)} !important`,
        marginLeft: '1px',
        marginTop: '1px',
    },
    projectTile: {
        marginRight: theme.spacing(1.75),
        marginLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
        width: theme.spacing(32.75),
        position: 'relative' as const,
        boxShadow: '0 0 5px 0 rgba(29, 31, 35, 0.13)',
        boxSizing: 'border-box' as const,
        minHeight: '212px',
        border: `1px solid #EBEBEB`,
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        backgroundColor: "#FFFFFF",
    },
    
});

export const useStyles = makeStyles()(styles);
