import { Grid } from '@mui/material';
import React from 'react';

import { ContentArea } from 'src/components/ContentArea';
import { SkeletonType } from 'src/components/ContentLoading/ContentLoading';

import { ListLoadingContent } from './ListSkeleton/ListSkeleton';
import { useStyles } from './ProjectSkeleton.styles';
import { TileLoadingContent } from './TileSkeleton/TileSkeleton';

export interface LoadingSkeletonProps {
    type: SkeletonType;
}

export function TableTitlePadding() {
    const { classes } = useStyles();
    return <Grid className={classes.topPadding} />;
}

function ProjectBodySkeleton(props: LoadingSkeletonProps) {
    const { type } = props;
    const { classes } = useStyles();
    return (
        <Grid container className={classes.projectContainer} alignContent="flex-start">
            <Grid alignSelf="flex-end" className={classes.projectSearchPlaceholder} />
            <Grid
                container
                direction="row"
                className={`${classes.projectList} ${
                    type === SkeletonType.ProjectCard ? classes.tileMargin : ''
                }`}
            >
                {type === SkeletonType.ProjectCard ? (
                    <TileLoadingContent />
                ) : (
                    <ListLoadingContent />
                )}
            </Grid>
        </Grid>
    );
}

export function ProjectSkeleton(props: LoadingSkeletonProps) {
    const { type } = props;
    return (
        <ContentArea
            title={<></>}
            titleRight={<TableTitlePadding />}
            content={<ProjectBodySkeleton type={type} />}
            titleRightMd={2}
            titleRightSm={2}
            titleRightXs={2}
        />
    );
}
