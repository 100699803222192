import store from 'store';

import {
    ACTIVITY_TYPES,
    API_REGION,
    CLIENT_SIDE_PAGINATION_LIMIT,
    CONFIGURATION_TYPES,
} from 'state-domains/constants';
import { FilterObject, ImagoSyncType, NSQueryProps, PollQueryProps } from 'state-domains/domain';
import { MAX_DRILLHOLES } from 'state-domains/domain/project/utils';
import { addQueryParams, buildUrl, notificationsUrl } from 'state-domains/utils';

export const baseApiUrl = () => `/api/${store.get(API_REGION) ?? 'us'}`;
export const buildOAuth2TokenUrl = () => `${baseApiUrl()}/auth`;
// Login URL Should Always be of the Main Region
export const buildOAuth2LoginUrl = () => '/api/us/seequentid/signin';
export const buildOAuth2LogoutUrl = () => `${baseApiUrl()}/seequentid/signout`;
export const buildUserAccountsUrl = () => `${baseApiUrl()}/user-profile`;
export const buildAccountPreferencesUrl = () => `${baseApiUrl()}/user-preferences/account`;
export const buildUserUrl = () => `${baseApiUrl()}/user`;
export const buildSwitchSubscriptionsUrl = () => `${baseApiUrl()}/switch-account`;
export const buildProjectListUrl = () =>
    `${baseApiUrl()}/projects/?limit=${CLIENT_SIDE_PAGINATION_LIMIT}`;
export const buildRecentProjectsUrl = () => `${baseApiUrl()}/user/recent-projects`;
export const buildJobListUrl = () => `${baseApiUrl()}/jobs/?limit=${CLIENT_SIDE_PAGINATION_LIMIT}`;
export const buildPinProjectUrl = (projectId: string, pin: boolean) =>
    `${baseApiUrl()}/user/pin-project?project_id=${projectId}&pin=${pin}`;

export const buildAccountUrl = () => `${baseApiUrl()}/account/`;
export function buildOAuth2NotificationTokenUrl() {
    return `${baseApiUrl()}/token/notifications`;
}
export const buildMapsTokenUrl = () => `${baseApiUrl()}/map-token`;

const buildConfigurationUrlWithFilters = (
    buildUrl: () => string,
    fields: string[],
    filters: FilterObject[],
): string =>
    `${buildUrl()}?fields=${JSON.stringify(fields)}&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;

// #region =============== Configuration =======================
export const buildConfigurationListCategoriesUrl = () => `${baseApiUrl()}/list-categories/`;

export const buildConfigurationTableViewsUrl = (filters: FilterObject[]) =>
    `${baseApiUrl()}/table-views/?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "label", "description", "category", "single_table", "special", "lithology_table_view", "published", "used_in", "ismx", "tables"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildConfigurationTableCategoriesUrl = () => `${baseApiUrl()}/table-categories/`;
export const buildConfigurationHeaderCategoriesUrl = () => `${baseApiUrl()}/header-categories/`;
export const buildConfigurationActivityCategoriesUrl = () => `${baseApiUrl()}/activity-categories/`;

export const buildDeleteConfigurationListUrl = (id: string) => `${baseApiUrl()}/lists/${id}`;

// #endregion

// #region =============== Notifications =======================

export function buildNotificationsUrl(...pathElements: string[]) {
    return buildUrl(notificationsUrl(), ...pathElements);
}

export function buildResourcesUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/preferences/unsubscribed/resources');
}

export function buildBulkDeleteMessagesUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/messages/bulk/delete');
}

export function buildFiltersUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/preferences/filters');
}

export function buildDigestsUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/preferences/digest');
}

export function buildBulkReadMessagesUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/messages/bulk/read');
}

export function buildBulkUnreadMessagesUrl(audienceId: string) {
    return buildNotificationsUrl('/audiences', audienceId, '/messages/bulk/unread');
}

export function buildListMessagesUrl(audienceId: string, queryParams: NSQueryProps) {
    let url = buildNotificationsUrl('/audiences', audienceId, '/messages');
    url = addQueryParams(url, queryParams as Record<string, string | number>);
    return url;
}

export function buildPollMessagesUrl(
    audienceId: string,
    props: PollQueryProps,
): { url: string; limit: number } {
    const { limit = 10, cursor } = props;
    let url = buildNotificationsUrl('/audiences', audienceId, '/messages/poll');
    url = addQueryParams(url, { limit, cursor });
    return { url, limit };
}

// #endregion

// #region ======================= Drillhole ===========================
const countUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    type: ACTIVITY_TYPES,
) => {
    const activityType = type === ACTIVITY_TYPES.DRILLING ? 'collars' : 'points';
    return `${baseApiUrl()}/${activityType}/count?activities=${encodeURIComponent(
        JSON.stringify(activities),
    )}&project=${projectId}&state=${encodeURIComponent(JSON.stringify(state))}&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
};
export const buildLoadDrillholeCountUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
) => countUrl(projectId, activities, state, filters, ACTIVITY_TYPES.DRILLING);

export const buildLoadPointsCountUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
) => countUrl(projectId, activities, state, filters, ACTIVITY_TYPES.POINT);

const loadDrillholesUrl =
    (
        projectId: string,
        activities: string[],
        state: string[],
        filters: FilterObject[],
        sortKey: string,
        offset: number,
        pageSize: number,
    ) =>
    (type: ACTIVITY_TYPES) => {
        const activityType = type === ACTIVITY_TYPES.DRILLING ? 'collars' : 'points';
        return `${baseApiUrl()}/${activityType}/?activities=${encodeURIComponent(
            JSON.stringify(activities),
        )}&filters=${encodeURIComponent(
            JSON.stringify(filters),
        )}&limit=${pageSize}&offset=${offset}&project=${projectId}&sort=${sortKey}&state=${encodeURIComponent(
            JSON.stringify(state),
        )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
    };

export const buildLoadDrillholesUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    sortKey: string,
    offset: number,
    pageSize: number,
) =>
    loadDrillholesUrl(
        projectId,
        activities,
        state,
        filters,
        sortKey,
        offset,
        pageSize,
    )(ACTIVITY_TYPES.DRILLING);

export const buildLoadPointsUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    sortKey: string,
    offset: number,
    pageSize: number,
) =>
    loadDrillholesUrl(
        projectId,
        activities,
        state,
        filters,
        sortKey,
        offset,
        pageSize,
    )(ACTIVITY_TYPES.POINT);

const loadDrillholeUrl = (id: string, type: ACTIVITY_TYPES, fields: string[] = []) => {
    const activityType = type === ACTIVITY_TYPES.DRILLING ? 'collars' : 'points';
    return `${baseApiUrl()}/${activityType}/${id}?activities=["all"]${
        fields.length > 0 ? `&fields=[${fields}]` : ''
    }`;
};

export const buildLoadDrillholeUrl = (id: string, fields: string[] = []) =>
    loadDrillholeUrl(id, ACTIVITY_TYPES.DRILLING, fields);
export const buildLoadPointUrl = (id: string, fields: string[] = []) =>
    loadDrillholeUrl(id, ACTIVITY_TYPES.POINT, fields);

export const buildSaveDrillHoleUrl = (drillHoleId = '') => `${baseApiUrl()}/collars/${drillHoleId}`;
export const buildSavePointsUrl = (pointId = '') => `${baseApiUrl()}/points/${pointId}`;

export const buildDataFileGroupUrl = (type: ACTIVITY_TYPES) =>
    type === ACTIVITY_TYPES.DRILLING
        ? buildDrillholeDataFileGroupUrl()
        : buildPointDataFileGroupUrl();
export const buildDrillholeDataFileGroupUrl = () => `${baseApiUrl()}/data-file-groups/`;
export const buildPointDataFileGroupUrl = () => `${baseApiUrl()}/point-data-file-groups/`;
export const exportCollarUrl = () => `${baseApiUrl()}/collars/export/`;

// =================================================================================================
export const customExportUrl = () => `${baseApiUrl()}/collars/custom-export/`;
export const buildCustomExportUrl = () => `${baseApiUrl()}/export/custom/`;
export const buildExportTemplateUrl = () => `${baseApiUrl()}/export-templates/`;

// =================================================================================================

export const buildEmptyDataFileGroupUrl = (
    collarId: string,
    tableViewId: string,
    type: ACTIVITY_TYPES,
) => {
    const query = {
        collar: collarId,
        table_view: tableViewId,
        files: {},
    };
    const ids = ['collar', 'table_view'];
    return `${buildDataFileGroupUrl(type)}?query=${encodeURIComponent(JSON.stringify(query))}&qry_ids=${encodeURIComponent(
        JSON.stringify(ids),
    )}&fields=${encodeURIComponent(JSON.stringify(['_id']))}`;
};
export const buildHeaderFileGroupUrl = () => `${baseApiUrl()}/header-file-groups/`;
export const createRowsUrl = () => `${baseApiUrl()}/row-references/create-rows`;
export const createRowsPointsUrl = () => `${baseApiUrl()}/point-row-references/create-rows`;
export const buildDataFileGroupFilesUrl = (fileGroupId: string, type: ACTIVITY_TYPES) =>
    `${buildDataFileGroupUrl(type)}${fileGroupId}/files`;
export const buildHeaderFileGroupFilesUrl = (fileGroupId: string) =>
    `${baseApiUrl()}/header-file-groups/${fileGroupId}/files`;
export const buildHeaderDownloadUrl = (fileGroupId: string, fileId: string) =>
    `${baseApiUrl()}/header-file/${fileGroupId}/files/${fileId}`;
export const buildDataDownloadUrl = (fileGroupId: string, fileId: string) =>
    `${baseApiUrl()}/data-file/${fileGroupId}/files/${fileId}`;
export const buildAddNewDrillHoleUrl = () => `${baseApiUrl()}/collars/`;
export const buildAddNewPointsUrl = () => `${baseApiUrl()}/points/`;

export const buildDeleteDrillHoleUrl = (drillHoleId = '') =>
    `${baseApiUrl()}/collars/${drillHoleId}`;
export const buildDeletePointsUrl = (drillHoleId = '') => `${baseApiUrl()}/points/${drillHoleId}`;
export const buildCloneDrillHoleUrl = `${baseApiUrl()}/collars/clone`;
export const buildClonePointsUrl = `${baseApiUrl()}/points/clone`;
export const buildUnassignUserFromCollarUrl = (drillHoleId: string, type: ACTIVITY_TYPES) =>
    `${baseApiUrl()}/collars/${drillHoleId}/user?type=${type.toString().toUpperCase()}`;

export const buildReIndexUrl = () => `${baseApiUrl()}/row-references/reindex`;

const saveCoordinatesUrl = (id: string, rowReferenceId: string, type: ACTIVITY_TYPES) => {
    const activityType = type === ACTIVITY_TYPES.DRILLING ? 'collars' : 'points';
    return `${baseApiUrl()}/${activityType}/${id}/coordinates_table/rows${
        rowReferenceId ? `/${rowReferenceId}` : ''
    }`;
};

export const buildSaveCoordinatesUrl = (drillholeId: string, rowReferenceId: string) =>
    saveCoordinatesUrl(drillholeId, rowReferenceId, ACTIVITY_TYPES.DRILLING);
export const buildSaveCoordinatesPointsUrl = (pointId: string, rowReferenceId: string) =>
    saveCoordinatesUrl(pointId, rowReferenceId, ACTIVITY_TYPES.POINT);

export const buildDeleteCoordinatesRowUrl = (drillholeId: string, rowReferenceId: string) =>
    `${baseApiUrl()}/collars/${drillholeId}/coordinates_table/rows/${rowReferenceId}`;
export const buildDeleteCoordinatesRowPointsUrl = (pointId: string, rowReferenceId: string) =>
    `${baseApiUrl()}/points/${pointId}/coordinates_table/rows/${rowReferenceId}`;

// #endregion

// #region ======================= Activity ===========================
export const buildLoadActivitiesUrl = () => `${baseApiUrl()}/activities/`;
export const buildLoadActivityUrl = (activityId: string) =>
    `${baseApiUrl()}/activities/${activityId}`;
export const buildLoadActivityGroupsUrl = () => `${baseApiUrl()}/activity-groups/`;
export const buildConfigurationActivitiesUrlWithFilters = (filters: FilterObject[]) =>
    buildConfigurationUrlWithFilters(
        buildLoadActivitiesUrl,
        [
            '_created_at',
            '_created_by',
            '_updated_at',
            '_updated_by',
            'name',
            'label',
            'description',
            'category',
            'published',
            'used_in',
            'ismx',
            'type',
        ],
        filters,
    );
export const buildConfigurationActivityUrl = (id: string) => `${buildLoadActivitiesUrl()}${id}`;
export const buildConfigutationActivitySortTablesUrl = (id: string) =>
    `${buildConfigurationActivityUrl(id)}/table_views_specs/sort-views`;
export const buildConfigurationActivityAddTableUrl = (id: string) =>
    `${buildConfigurationActivityUrl(id)}/table_views_specs`;
export const buildConfigurationActivityDeleteTableUrl = (activityId: string, id: string) =>
    `${buildConfigurationActivityUrl(activityId)}/table_views_specs/${id}`;
export const buildConfigurationLinkActivityListsUrl = (activity: string) =>
    `${buildConfigurationActivityUrl(activity)}/link-lists`;
export const buildConfigurationActivityColumnAssociationUrl = (activityId: string) =>
    `${buildConfigurationActivityUrl(activityId)}/associate-columns`;
export const buildConfigurationActivityModifyListSpecUrl = (urlMap: { [id: string]: string }) => {
    const valueConditional = (value: string) => (value ? `${value}/` : '');
    const constructedUrl = Object.entries(urlMap)
        .map(([key, value]) => `${key}/${valueConditional(value)}`)
        .join('');

    return `${baseApiUrl()}/${constructedUrl.substring(0, constructedUrl.length - 1)}`;
};
export const buildConfigurationActivityGroupsUrlWithFilters = (filters: FilterObject[]) =>
    `${buildLoadActivityGroupsUrl()}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildConfigurationActivityGroupUrl = (id: string) =>
    `${buildLoadActivityGroupsUrl()}${id}`;
// #endregion

// #region ======================= Project ===========================
export const buildProjectUrl = () => `${baseApiUrl()}/projects/`;
export const buildLoadProjectUrl = (projectId: string) => `${buildProjectUrl()}${projectId}`;
export const buildProjectsFilterUrl = () =>
    `${baseApiUrl()}/projects-filter/?limit=${CLIENT_SIDE_PAGINATION_LIMIT}`;
export const buildProjectThumbnailUrl = (projectId: string) =>
    `${buildLoadProjectUrl(projectId)}/thumbnail`;
export const buildConfigurationProjectUrl = (id: string) => `${buildProjectUrl()}${id}`;
export const buildConfigurationProjectsUrlWithFilters = (filters: FilterObject[]) =>
    `${buildProjectsFilterUrl()}&fields=["_id", "_created_at", "_created_by", "_updated_at", "_updated_by", "name", "description", "users", "activities", "location", "boundary"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildConfigurationProjectActivitiesUrl = (id: string) =>
    `${buildConfigurationProjectUrl(id)}/activities`;
export const buildConfigurationProjectActivityUrl = (projectId: string, activityId: string) =>
    `${buildConfigurationProjectActivitiesUrl(projectId)}/${activityId}`;
export const buildConfigurationProjectActivityUsersUrl = (
    projectId: string,
    activityId: string,
    userId = '',
) => {
    const userPart = userId ? `/${userId}` : '';
    return `${buildConfigurationProjectActivitiesUrl(projectId)}/${activityId}/users${userPart}`;
};
export const buildConfigurationProjectUsersUrl = (projectId: string) =>
    `${buildConfigurationProjectUrl(projectId)}/users`;
export const buildConfigurationProjectActivityUsersRemoveUrl = (
    projectId: string,
    userId: string,
    activityId: string,
) => `${buildConfigurationProjectUrl(projectId)}/users/${userId}/remove/${activityId}`;
export const buildConfigurationProjectActivityGroupUsersRemoveUrl = (
    projectId: string,
    userId: string,
    activityId: string,
    groupId: string,
) =>
    `${buildConfigurationProjectUrl(projectId)}/users/${userId}/remove/${activityId}/group/${groupId}`;
export const buildConfigurationProjectActivityGroupUsersUrl = (
    projectId: string,
    activityId: string,
    groupId: string,
) => `${buildConfigurationProjectActivityUrl(projectId, activityId)}/groups/${groupId}/users`;
export const buildConfigurationProjectUserPermissions = (
    projectId: string,
    userId: string,
    activityId = '',
) => {
    const projectPart = `project=${projectId}`;
    const userPart = `user=${userId}`;
    const activityPart = activityId ? `&activity=${activityId}` : '';

    return `${baseApiUrl()}/user-permissions/?${projectPart}&${userPart}${activityPart}`;
};
export const buildConfigurationSortProjectActivities = (projectId: string) =>
    `${buildConfigurationProjectUrl(projectId)}/activities/sort`;
// #region ======================= Table Views =======================
export const buildConfigurationTableViewBaseUrl = () => `${baseApiUrl()}/table-views/`;
export const buildConfigurationTableViewUrl = (id: string) =>
    `${buildConfigurationTableViewBaseUrl()}${id}`;
// #endregion

// #region ======================= Table =======================
export const buildConfigurationTablesBaseUrl = () => `${baseApiUrl()}/tables/`;
export const buildConfigurationTablesUrl = (id: string) =>
    `${buildConfigurationTablesBaseUrl()}${id}`;
export const buildTableDownloadURL = () => `${baseApiUrl()}/collars/export/`;
export const buildImportTableUrl = () => `${baseApiUrl()}/table-schema/`;
export const buildExportTableUrl = () => `${baseApiUrl()}/table-export/`;
// #endregion

// #region ======================= Configuration | Header =======================
export const buildConfigurationHeadersBaseUrl = () => `${baseApiUrl()}/headers/`;
export const buildConfigurationHeaderSectionBaseUrl = () =>
    `${buildConfigurationHeadersBaseUrl()}header-section/`;
export const buildConfigurationHeadersUrl = (id: string) =>
    `${buildConfigurationHeadersBaseUrl()}${id}`;
export const buildConfigurationHeadersUrlWithFilters = (filters: FilterObject[]) =>
    `${buildConfigurationHeadersBaseUrl()}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "label", "description", "category", "published", "used_in", "ismx","type"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildConfigurationDeleteFieldFromHeaderUrl = (
    headerId: string,
    sectionId: string,
    fieldId: string,
) => `${buildConfigurationHeadersBaseUrl()}${headerId}/sections/${sectionId}/fields/${fieldId}`;
export const buildConfigurationHeaderDeleteSectionUrl = (headerId: string, sectionId: string) =>
    `${buildConfigurationHeaderSectionBaseUrl()}${headerId}/sections/${sectionId}`;
export const buildConfigurationAddHeaderSectionUrl = (headerId: string) =>
    `${buildConfigurationHeaderSectionBaseUrl()}${headerId}/sections`;

export const buildConfigurationHeaderAddFieldUrl = (headerId: string, sectionId: string) =>
    `${buildConfigurationHeadersBaseUrl()}${headerId}/sections/${sectionId}/fields`;

export const buildConfigurationEditHeaderSectionUrl = (headerId: string, sectionId: string) =>
    `${buildConfigurationHeadersBaseUrl()}${headerId}/sections/${sectionId}`;
export const buildConfigurationReorderHeaderFieldsUrl = (
    headerId: string,
    sectionIds: string[],
    multiSection: boolean,
) =>
    `${buildConfigurationEditHeaderSectionUrl(headerId, sectionIds[0])}/${
        multiSection ? `sort-fields-multi-section/${sectionIds[1]}` : 'sort-fields'
    }`;
export const buildConfigurationSaveHeaderFieldUrl = (headerId: string, sectionId: string) =>
    `${buildConfigurationEditHeaderSectionUrl(headerId, sectionId)}/field`;
// #endregion

// #region ======================= Configuration | Users =======================
export const buildConfigurationUserRoleUrl = () => `${baseApiUrl()}/user-role/`;
// #endregion

// #region ======================= Configuration | Fields =======================
export const buildConfigurationHeaderFieldTypesBaseUrl = () =>
    `${baseApiUrl()}/header-field-types/`;
export const buildConfigurationFieldUrl = (id = '') =>
    `${buildConfigurationHeaderFieldTypesBaseUrl()}${id}`;
export const buildConfigurationHeaderFieldTypesUrl = (filters: FilterObject[]) =>
    `${buildConfigurationHeaderFieldTypesBaseUrl()}?filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;

// #endregion

// #region =============== Configuration | Lists =======================
export const buildLoadListWithIdUrl = (id: string) => `${baseApiUrl()}/lists/${id}`;
export const buildConfigurationListsBaseUrl = () => `${baseApiUrl()}/lists/`;
export const buildConfigurationListsExportUrl = (dateFormat: string) => {
    const dateFormatStr = dateFormat ? `?date_format=${dateFormat}` : '';
    return `${baseApiUrl()}/list-export/${dateFormatStr}`;
};
export const buildConfigurationListsUrlWithFilters = (filters: FilterObject[]) =>
    `${buildConfigurationListsBaseUrl()}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "description", "category", "published", "used_in", "ismx", "is_sample"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildLoadListsWithFieldsUrl = () =>
    `${buildConfigurationListsBaseUrl()}?fields=["_created_at","_updated_at","name","description","category","published"]`;

export const buildListFileGroupUrl = () => `${baseApiUrl()}/list-file-groups/`;
export const buildLoadListFileGroupUrl = (groupId: string) =>
    `${buildListFileGroupUrl()}${groupId}`;
export const buildAddFileToListGroup = (groupId: string) =>
    `${buildLoadListFileGroupUrl(groupId)}/files`;
export const buildListFileGroupListUrl = (groupId: string, fileId: string) =>
    `${buildAddFileToListGroup(groupId)}/${fileId}`;
export const buildDownloadListFileUrl = (groupId: string, fileId: string) =>
    `${baseApiUrl()}/list-file/${groupId}/files/${fileId}`;
export const buildEmptyListFileGroupsUrl = (listId: string) => {
    const query = {
        list: listId,
        files: {},
    };

    const ids = ['list'];

    return `${buildListFileGroupUrl()}?query=${encodeURIComponent(JSON.stringify(query))}&qry_ids=${encodeURIComponent(
        JSON.stringify(ids),
    )}&fields=${encodeURIComponent(JSON.stringify(['_id']))}`;
};

export const buildLoadDefaultLabServicesUrl = () => `${baseApiUrl()}/default-lab-services/`;

export const buildImportDefaultLabServicesUrl = () => `${baseApiUrl()}/import-sample-codes`;

export const buildImportListUrl = (listId: string, dateFormat?: string) =>
    `${baseApiUrl()}/list-import?list_id=${listId}${dateFormat ? `&date_format=${dateFormat}` : ''}`;
export const buildBulkValidateImportListUrl = () => `${baseApiUrl()}/validate-bulk-list-import/`;
export const buildBulkImportListUrl = () => `${baseApiUrl()}/bulk-list-import/`;
export const buildConfigurationListUrl = (id: string) => `${buildConfigurationListsBaseUrl()}${id}`;
export const buildValidateCellUrl = () => `${baseApiUrl()}/validate-cell`;
export const buildSaveListRowUrl = (listId: string, id: string) => {
    const idPart = id ? `/${id}` : '';
    return `${buildConfigurationListsBaseUrl()}${listId}/rows${idPart}`;
};
export const buildDeleteListRowUrl = (listId: string, id: string) =>
    `${baseApiUrl()}/lists/${listId}/rows/${id}`;
export const buildDeleteListRowsUrl = () => `${baseApiUrl()}/lists/delete-rows`;

export const buildReorderColumnsUrl = (type: string, id: string) =>
    `${baseApiUrl()}/${type}/${id}/sort-columns`;

export const buildModifyColumnsUrl = (type: string, itemId: string, id: string) => {
    const idPart = id ? `/${id}` : '';
    return `${baseApiUrl()}/${type}/${itemId}/columns${idPart}`;
};

export const buildTableViewsUrl = (tableViewId: string) =>
    `${baseApiUrl()}/table-views/${tableViewId}`;

export const buildReorderTablesUrl = (tableViewId: string) =>
    `${buildTableViewsUrl(tableViewId)}/sort-tables`;

export const buildAddTableToTableViewUrl = (tableViewId: string, tableId = '') => {
    const tableIdPart = tableId ? `/${tableId}` : '';
    return `${buildTableViewsUrl(tableViewId)}/tables${tableIdPart}`;
};

export const buildUpdateMultiValueColumnsUrl = (
    tableViewId: string,
    tableId: string,
    columnId: string,
) => `${buildTableViewsUrl(tableViewId)}/tables/${tableId}/columns/${columnId}`;

export const buildDeleteColumnUrl = (type: CONFIGURATION_TYPES, itemId: string, columnId: string) =>
    `${baseApiUrl()}/${type}/${itemId}/columns/${columnId}`;

export const buildDeleteTableViewTableUrl = (tableViewId: string, tableId: string) =>
    `${buildTableViewsUrl(tableViewId)}/tables/${tableId}`;

// #endregion

// #region ======================= Colours =======================
export const buildLoadColoursUrl = () => `${baseApiUrl()}/colours/`;
export const buildLoadSampleResultsRankedUnits = () =>
    `${baseApiUrl()}/sample-result-ranked-column-units`;
export const buildRecomputeSampleResultsUrl = () =>
    `${baseApiUrl()}/activities/recompute-sample-results`;
export const buildUpdateActualDepthUrl = (activityId: string) =>
    `${baseApiUrl()}/activities/${activityId}/update_actual_depth`;
export const buildSaveColourUrl = () => `${baseApiUrl()}/custom-colours/`;
export const buildDeleteColourUrl = (id: string) => `${baseApiUrl()}/custom-colours/${id}`;
// #endregion

// #region ======================= Headers =======================
export const buildLoadHeadersUrl = () => `${baseApiUrl()}/headers/`;
// #endregion

// #region ======================= Row References =======================

const loadRowReferencesUrl = (collar: string, tableView: string, type: ACTIVITY_TYPES) => {
    const activityType =
        type === ACTIVITY_TYPES.DRILLING ? 'row-references' : 'point-row-references';
    return `${baseApiUrl()}/${activityType}/?query={"collar":"${collar}","table_view":"${tableView}"}&qry_ids=["collar","table_view"]`;
};
export const buildLoadRowReferencesUrl = (collar: string, tableView: string) =>
    loadRowReferencesUrl(collar, tableView, ACTIVITY_TYPES.DRILLING);

export const buildLoadRowReferencesPointsUrl = (collar: string, tableView: string) =>
    loadRowReferencesUrl(collar, tableView, ACTIVITY_TYPES.POINT);

const saveRowReferencesUrl = (type: ACTIVITY_TYPES, rowReferenceId = '', queryParameters = {}) => {
    const activityType =
        type === ACTIVITY_TYPES.DRILLING ? 'row-references' : 'point-row-references';
    let url = `${baseApiUrl()}/${activityType}/${rowReferenceId}`;

    Object.entries(queryParameters).forEach(([key, value], index) => {
        url = url.concat(`${!rowReferenceId && index === 0 ? '?' : '&'}${key}=${value}`);
    });

    return url;
};

export const buildSortRowReferencesUrl = () => `${baseApiUrl()}/row-references/sort`;

export const buildSaveRowReferencesUrl = (rowReferenceId = '', queryParameters = {}) =>
    saveRowReferencesUrl(ACTIVITY_TYPES.DRILLING, rowReferenceId, queryParameters);

export const buildSaveRowReferencesPointsUrl = (rowReferenceId = '', queryParameters = {}) =>
    saveRowReferencesUrl(ACTIVITY_TYPES.POINT, rowReferenceId, queryParameters);

export const buildCopyRowReferencesUrl = (type: ACTIVITY_TYPES, queryParameters = {}) => {
    let url = `${saveRowReferencesUrl(type, '', {})}copy-rows`;

    Object.entries(queryParameters).forEach(([key, value], index) => {
        url = url.concat(`${index === 0 ? '?' : '&'}${key}=${value}`);
    });

    return url;
};

export const buildCopyCoordinateRowsUrl = (drillholeId: string, type: ACTIVITY_TYPES) =>
    `${baseApiUrl()}/${
        type === ACTIVITY_TYPES.DRILLING ? 'collars' : 'points'
    }/${drillholeId}/copy-coordinates-rows`;

const duplicateSamplesUrl = (values: any, parent: string, type: ACTIVITY_TYPES) => {
    const activityType =
        type === ACTIVITY_TYPES.DRILLING ? 'row-references' : 'point-row-references';
    let url = `${baseApiUrl()}/${activityType}/?parent=${parent}`;
    Object.entries(values).forEach(([key, value]) => (url += `&${key}=${value}`));

    return url;
};

export const buildDuplicateSamplesUrl = (values: any, parent: string) =>
    duplicateSamplesUrl(values, parent, ACTIVITY_TYPES.DRILLING);
export const buildDuplicateSamplesPointsUrl = (values: any, parent: string) =>
    duplicateSamplesUrl(values, parent, ACTIVITY_TYPES.POINT);

export const buildFillDownSaveRowReferencesUrl = (rowReferenceId = '') =>
    `${baseApiUrl()}/row-references/${rowReferenceId}/fill-down`;
export const buildFillDownSaveRowReferencesPointsUrl = (rowReferenceId = '') =>
    `${baseApiUrl()}/point-row-references/${rowReferenceId}/fill-down`;
export const buildDeleteRowReferencesUrl = () => `${baseApiUrl()}/row-references/delete`;
export const buildDeleteRowReferencesPointsUrl = () =>
    `${baseApiUrl()}/point-row-references/delete`;
// #endregion

// #region ======================= Table Actions =======================
export const buildCopyIntervalsUrl = () => `${baseApiUrl()}/row-references/copy-intervals`;
export const buildCopyIntervalsPointsUrl = () =>
    `${baseApiUrl()}/point-row-references/copy-intervals`;
// #endregion

// #region ======================= System Filters =======================
export const buildLoadSystemFiltersUrl = () => `${baseApiUrl()}/system-filters`;
// #endregion

// #region ======================= Filter OperatorFields =======================
export const buildLoadFilterOperatorsUrl = () => `${baseApiUrl()}/filter-operators`;
// #endregion

// #region ======================= Default Permissions =======================
export const buildLoadDefaultPermissionsUrl = () => `${baseApiUrl()}/default-permissions/`;
// #endregion

// #region ======================= Samples =======================
const loadSamplesUrl = (collar: string, type: ACTIVITY_TYPES) => {
    const activityType = type === ACTIVITY_TYPES.DRILLING ? 'samples' : 'point-samples';
    return `${baseApiUrl()}/${activityType}/?query={"collar":"${collar}"}&qry_ids=["collar"]`;
};
export const buildLoadSamplesUrl = (collar: string) =>
    loadSamplesUrl(collar, ACTIVITY_TYPES.DRILLING);
export const buildLoadSamplesPointsUrl = (collar: string) =>
    loadSamplesUrl(collar, ACTIVITY_TYPES.POINT);

export const buildLoadSampleUrl = (sampleId: string) => `${baseApiUrl()}/samples/${sampleId}`;
export const buildLoadSamplePointsUrl = (sampleId: string) =>
    `${baseApiUrl()}/point-samples/${sampleId}`;
// #endregion

// #region ======================= Sample Results =======================
const loadSampleResultsUrl = (collar: string, type: ACTIVITY_TYPES) => {
    const activityType =
        type === ACTIVITY_TYPES.DRILLING ? 'sample-results' : 'point-sample-results';
    return `${baseApiUrl()}/${activityType}/?collar=${collar}`;
};
export const buildLoadSampleResultsUrl = (collar: string) =>
    loadSampleResultsUrl(collar, ACTIVITY_TYPES.DRILLING);
export const buildLoadSampleResultsPointsUrl = (collar: string) =>
    loadSampleResultsUrl(collar, ACTIVITY_TYPES.POINT);

export const buildLoadRankedColumns = (activity: string) =>
    `${baseApiUrl()}/activities/${activity}/sample-result-ranked-columns/`;

export const buildPossibleSampleResultsColumns = (activity: string) =>
    `${baseApiUrl()}/activities/${activity}/possible-sample-results-columns`;

export const buildAddSampleResultsRankedColumn = (activity: string) =>
    `${baseApiUrl()}/activities/${activity}/sample-result-ranked-columns/`;

export const editRankedColumnUrl = (activity: string, rankedColumnId: string) =>
    `${baseApiUrl()}/activities/${activity}/sample-result-ranked-columns/${rankedColumnId}`;
// #endregion

// #region ======================= Grids =======================
export const buildCoordinatesUrl = (id?: string) => {
    const url = `${baseApiUrl()}/coordinates/`;
    return id ? `${url}${id}` : url;
};
export const buildConvertCoordiatesUrl = () => `${buildCoordinatesUrl()}convert`;
export const buildGridPreview = () => `${buildCoordinatesUrl()}preview`;
export const buildGridsUrl = (id?: string) => {
    const url = `${baseApiUrl()}/coordinates/`;
    return id ? `${url}${id}` : url;
};
export const buildConfigurationGridsUrlWithFilters = (filters: FilterObject[]) =>
    buildConfigurationUrlWithFilters(
        buildGridsUrl,
        [
            '_created_at',
            '_created_by',
            '_updated_at',
            '_updated_by',
            'name',
            'description',
            'ismx',
            'type',
            'references_existing',
            'can_delete',
        ],
        filters,
    );
export const buildDefaultProjectionsUrl = (queryString: string) =>
    `${baseApiUrl()}/coordinates/coord_ref_system${queryString}`;

// #region ======================= Lab Certificate =======================
export const buildLoadLabCertificateInfo = (labCertId: string) =>
    `${baseApiUrl()}/lab-certificates/${labCertId}/info`;

// #endregion

// #region ======================= Imago Integrations =======================
export const imagoLoginUrl = () => `${baseApiUrl()}/integrations/imago/login`;
export const imagoCheckConnectionUrl = () => `${baseApiUrl()}/integrations/imago/check-connection`;
export const imagoDisconnectUrl = () => `${baseApiUrl()}/integrations/imago/logout`;
export const imagoUserContextUrl = () => `${baseApiUrl()}/integrations/imago/user-context`;
export const imagoSyncUrl = (syncType: ImagoSyncType) =>
    `${baseApiUrl()}/integrations/imago/${String(syncType)}`;
export const seequentCloudConfigurationUrl = () =>
    `${baseApiUrl()}/integrations/seequent-cloud/configuration`;
// #endregion

// #region ======================= Project Overview ===========================
export const buildProjectOverviewCollarsCountUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    polygon: number[][],
) =>
    `${baseApiUrl()}/project/overview/count?activities=${encodeURIComponent(
        JSON.stringify(activities),
    )}&project=${projectId}&state=${encodeURIComponent(JSON.stringify(state))}&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}&geoWithin=${encodeURIComponent(JSON.stringify(polygon))}`;

const projectOverviewCollarsFields = ['_id', 'name', 'geo_json_location', 'type', 'wgs84'];
export const buildProjectOverviewCollarsUrl = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    polygon: number[][],
    sortKey = '-_created_at',
    limit = MAX_DRILLHOLES,
) =>
    `${baseApiUrl()}/project/overview/?activities=${encodeURIComponent(
        JSON.stringify(activities),
    )}&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&limit=${limit}&offset=${0}&sort=${sortKey}&project=${projectId}&state=${encodeURIComponent(
        JSON.stringify(state),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}&geoWithin=${encodeURIComponent(
        JSON.stringify(polygon),
    )}&fields=${encodeURIComponent(JSON.stringify(projectOverviewCollarsFields))}`;

// #endregion

// #region ======= Sample Analysis Flow ============
export const buildSampleWorkflowsUrl = () => `${baseApiUrl()}/sample-workflows/`;
export const buildConfigurationSampleWorkflowsUrlWithFilters = (filters: FilterObject[]) =>
    `${buildSampleWorkflowsUrl()}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "description", "published", "used_in"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildLabCertificateValidationsUrl = (workflowId: string, validationId?: string) => {
    const url = `${buildSampleWorkflowsUrl()}${workflowId}/lab_certificate_validations`;
    return validationId ? `${url}/${validationId}` : url;
};
export const buildLabCertificateSettingsUrl = (workflowId: string, settingId?: string) => {
    const url = `${buildSampleWorkflowsUrl()}${workflowId}/lab_certificate_settings`;
    return settingId ? `${url}/${settingId}` : url;
};
export const buildEditSampleWorkflowsUrl = (id: string) => `${buildSampleWorkflowsUrl()}${id}`;
export const buildPrimarySampleAnalysisFlowUrl = (activityId: string) =>
    `${buildConfigurationActivityUrl(activityId)}/sampling/primary_workflows`;
export const buildSecondrySampleAnalysisFlowUrl = (activityId: string) =>
    `${buildConfigurationActivityUrl(activityId)}/sampling/secondary_workflows`;
export const buildLoadSampleDispatchHeadersUrl = () => `${baseApiUrl()}/sample-dispatch-headers/`;
export const buildConfigurationDispatchHeadersUrlWithFilters = (filters: FilterObject[]) =>
    `${buildLoadSampleDispatchHeadersUrl()}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "label", "description", "category", "published", "used_in", "ismx"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildEditSampleDispatchHeadersUrl = (id: string) =>
    `${buildLoadSampleDispatchHeadersUrl()}${id}`;
export const buildConfigurationHeaderAddSampleFieldUrl = (headerId: string, sectionId: string) =>
    `${buildLoadSampleDispatchHeadersUrl()}${headerId}/sections/${sectionId}/fields`;
export const buildConfigurationAddSampleHeaderSectionUrl = (headerId: string) =>
    `${buildLoadSampleDispatchHeadersUrl()}header-section/${headerId}/sections`;
export const buildConfigurationEditSampleHeaderSectionUrl = (headerId: string, sectionId: string) =>
    `${buildLoadSampleDispatchHeadersUrl()}${headerId}/sections/${sectionId}`;
export const buildConfigurationDeleteFieldFromSampleHeaderUrl = (
    headerId: string,
    sectionId: string,
    fieldId: string,
) => `${buildLoadSampleDispatchHeadersUrl()}${headerId}/sections/${sectionId}/fields/${fieldId}`;
export const buildConfigurationSampleHeaderDeleteSectionUrl = (
    headerId: string,
    sectionId: string,
) => `${buildLoadSampleDispatchHeadersUrl()}header-section/${headerId}/sections/${sectionId}`;
export const buildConfigurationReorderSampleHeaderFieldsUrl = (
    headerId: string,
    sectionIds: string[],
    multiSection: boolean,
) =>
    `${buildConfigurationEditSampleHeaderSectionUrl(headerId, sectionIds[0])}/${
        multiSection ? `sort-fields-multi-section/${sectionIds[1]}` : 'sort-fields'
    }`;
export const buildConfigurationSaveSampleHeaderFieldUrl = (headerId: string, sectionId: string) =>
    `${buildConfigurationEditSampleHeaderSectionUrl(headerId, sectionId)}/field`;
// #endregion

// #region ======= Configuration | Account ============
export const buildConfigurationCategoriesUrl = (type: string) =>
    `${baseApiUrl()}/${type.toLowerCase()}-categories/`;
export const buildLoadConfigurationCategoriesUrl = (filters: FilterObject[], type: string) =>
    `${buildConfigurationCategoriesUrl(type)}?fields=["_created_at", "_created_by", "_updated_at", "_updated_by", "name", "ismx", "type"]&filters=${encodeURIComponent(
        JSON.stringify(filters),
    )}&timezoneoffset=${new Date().getTimezoneOffset()}`;
export const buildConfigurationCategoriesOperationUrl = (type: string, id = '') =>
    `${buildConfigurationCategoriesUrl(type)}${id}`;
// #endregion
