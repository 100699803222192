import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { buildDigestsUrl } from '../../../network';
import {
    addQueryParams,
    sendUpdateRequest,
    sendRetrieveRequest,
    sendCreateRequest,
} from '../../../utils';
import { DigestPreference } from '../types';
import { getRequestOverrides, translateApiDigestPreference } from './translators';
import { DigestListApiResponse, APIDigest } from './types';

function mapListResponse(response: DigestListApiResponse): DigestPreference[] {
    return response.results.map((result) => translateApiDigestPreference(result));
}

function mapSingleResponse(response: APIDigest): DigestPreference {
    return translateApiDigestPreference(response);
}

export function loadDigestPreferences(
    token: string,
    audienceID: string,
): Observable<DigestPreference[]> {
    let url = buildDigestsUrl(audienceID);
    url = addQueryParams(url, { limit: '10000' });
    const ajax$ = sendRetrieveRequest(url, getRequestOverrides(token));
    return ajax$.pipe(map(({ response }) => mapListResponse(response)));
}

export function createDigestPreference(
    token: string,
    audienceID: string,
    resource: string,
    period: number,
): Observable<DigestPreference> {
    const url = buildDigestsUrl(audienceID);
    const ajax$ = sendCreateRequest(url, { resource, period }, 'json', getRequestOverrides(token));
    return ajax$.pipe(map(({ response }) => mapSingleResponse(response)));
}

export function updateDigestPreference(
    token: string,
    digestSelfLink: string,
    period: number,
): Observable<DigestPreference> {
    const ajax$ = sendUpdateRequest(digestSelfLink, { period }, getRequestOverrides(token));
    return ajax$.pipe(map(({ response }) => mapSingleResponse(response)));
}
