import { createSelector } from 'reselect';

import { ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import { Job, JobState } from '../types';

const jobState = (state: Partial<ShimState>): JobState => {
    const { job: jsJob = {} as JobState } = state || {};
    return jsJob;
};

const jobsList = createSelector(jobState, ({ jobs = [] }: JobState): Job[] => jobs);

const isSearchActive = createSelector(
    jobState,
    ({ searchTerm = '' }: JobState): boolean => searchTerm !== '',
);
export const selectors = {
    jobState,
    jobsList,
    isJobListPending: isPendingSelector(jobState),
    isJobListComplete: isCompleteSelector(jobState),
    isJobListFailed: isFailedSelector(jobState),
    isSearchActive,
};
