import { Theme } from '@mui/material';

const breakpoint = 'xs';
const itemBreakpoint = (theme: Theme) => theme.breakpoints.down(breakpoint);

export const styles = (theme: Theme) =>
    ({
        root: {
            backgroundColor: '#EAF3FC',
        },
        checkbox: {
            '&:hover': {
                backgroundColor: 'unset !important',
            },
        },
        trayCheckbox: {
            '&:hover': {
                backgroundColor: 'unset !important',
            },
            paddingTop: theme.spacing(3.2),
        },
        readIcon: {
            fontSize: '12px',
            color: theme.palette.primary.light,
        },
        tableCell: {
            [itemBreakpoint(theme)]: {
                minWidth: `${theme.spacing(4)} !important`,
                display: 'table-cell !important',
            },
        },
        unreadIcon: {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '12px',
            height: '6px',
            width: '6px',
        },
        button: {
            paddingTop: `${theme.spacing(1.3)} !important`,
            paddingBottom: `${theme.spacing(1.3)} !important`,
            paddingLeft: `${theme.spacing(0.5)} !important`,
            paddingRight: `${theme.spacing(0.5)} !important`,
        },
        tooltip: {
            fontSize: '12px',
        },
    } as const);
