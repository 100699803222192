import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ExportCollarRequest } from 'state-domains/domain/drillhole';

import { exportCollarUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const exportCollars = (payload: ExportCollarRequest): Observable<any> => {
    const url = exportCollarUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const exportResponse = convertToCamel(response);
            return observableOf(exportResponse);
        }),
    );
};
