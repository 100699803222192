import { CONFIGURATION_TYPES, DATATABLE_TYPES } from 'state-domains/constants/mxdeposit';
import { Category, FilterOperator, SystemFilter, UserWithType } from 'state-domains/domain';

export interface ConfigurationFilterProps {
    configurationType: CONFIGURATION_TYPES | DATATABLE_TYPES;
    onApply: Function;
}

export interface FilterInfo {
    mainCollection: { [id: string]: any };
    categorySelector: any;
    usedInSearchCollection: { [id: string]: any };
    realtedCollectionsFilters: {
        [id: string]: {
            locationParams: { path: string; type: FilterPathTypes }[];
            filterCollection: any[];
        };
    };
}

export enum FilterPathTypes {
    DICT = 'DICT',
    STR = 'STR',
}

export interface FilterDataCollection {
    mainCollection: { [id: string]: any };
    categories: { [id: string]: Category };
    users: { [id: string]: UserWithType };
    usedInSearchCollection: { [id: string]: any };
    filterOperators: { [id: string]: FilterOperator };
    userId: string;
    systemFilters: { [id: string]: SystemFilter };
    realtedCollectionsFilters: {
        [id: string]: {
            locationParams: { path: string; type: FilterPathTypes }[];
            filterCollection: any[];
        };
    };
}
