import { createSelector } from 'reselect';

import { AsyncState, ShimState } from 'state-domains/types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';

import {
    ImagoContextState,
    ImagoIntegration,
    IntegrationState,
    SeequentCloudConfigurationResponse,
} from '../types';

const integrationState = (state: Partial<ShimState>): IntegrationState => {
    const { integrations: dhIntegrations = {} as IntegrationState } = state || {};
    return dhIntegrations;
};

const imagoState = createSelector(
    integrationState,
    ({ imago = {} as ImagoIntegration }): ImagoIntegration => imago,
);

const imagoConnectionState = createSelector(
    imagoState,
    ({ connectionState = {} as AsyncState }): AsyncState => connectionState,
);

const imagoConnectionStatus = createSelector(
    imagoState,
    ({ connectionStatus = '' }): string => connectionStatus,
);

const imagoImageriesCount = createSelector(
    imagoState,
    ({ imageriesCount = 0 }): number => imageriesCount,
);

const imagoSyncState = createSelector(
    imagoState,
    ({ syncState = {} as AsyncState }): AsyncState => syncState,
);

const imagoUserContextState = createSelector(
    imagoState,
    ({ userContextState = {} as ImagoContextState }): ImagoContextState => userContextState,
);

const seequentCloudConfiguration = createSelector(
    integrationState,
    ({ seequentCloud: { configuration } }): SeequentCloudConfigurationResponse => configuration,
);

const seequentCloudState = createSelector(
    integrationState,
    ({ seequentCloud: { state = {} as AsyncState } }): AsyncState => state,
);

const imagoLoginState = createSelector(
    integrationState,
    ({ imago: { loginState = {} as AsyncState } }): AsyncState => loginState,
);

export const selectors = {
    integrationState,
    imagoState,
    imagoConnectionState,
    imagoConnectionStatus,
    isImagoConnectionStatusPending: isPendingSelector(imagoConnectionState),
    isImagoConnectionStatusComplete: isCompleteSelector(imagoConnectionState),
    isImagoConnectionStatusFailed: isFailedSelector(imagoConnectionState),
    imagoImageriesCount,
    imagoSyncState,
    isImagoSyncPending: isPendingSelector(imagoSyncState),
    isImagoSyncComplete: isCompleteSelector(imagoSyncState),
    isImagoSyncFailure: isFailedSelector(imagoSyncState),
    imagoUserContextState,
    isImagoUserContextPending: isPendingSelector(imagoUserContextState),
    isImagoUserContextComplete: isCompleteSelector(imagoUserContextState),
    isImagoUserContextFailed: isFailedSelector(imagoUserContextState),
    seequentCloudConfiguration,
    isSeequentCloudPending: isPendingSelector(seequentCloudState),
    isSeequentCloudComplete: isCompleteSelector(seequentCloudState),
    isSeequentCloudFailed: isFailedSelector(seequentCloudState),
    imagoLoginState,
    isImagoLoginPending: isPendingSelector(imagoLoginState),
    isImagoLoginComplete: isCompleteSelector(imagoLoginState),
    isImagoLoginFailure: isFailedSelector(imagoLoginState),
};
