import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildSampleWorkflowsUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { SampleWorkflowResult, SampleWorkflow } from '../types';

export const loadSampleWorkflows = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: SampleWorkflow[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const obs = sendRetrieveRequest(buildSampleWorkflowsUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<SampleWorkflowResult>) => {
            const result = convertToCamel<SampleWorkflow[]>(response.SampleWorkflow);
            return observableOf({
                result,
                actionName,
                processResultFunction,
            });
        }),
    );
};
