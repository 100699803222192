import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationTablesUrl, buildConfigurationTableViewUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveTable = (
    id: string,
    payload: any,
    isTableView = false,
): Observable<{ table: any; isTableView: boolean }> => {
    const url = isTableView ? buildConfigurationTableViewUrl(id) : buildConfigurationTablesUrl(id);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedTable = convertToCamel<any>(response);
            return observableOf({ table: convertedTable, isTableView });
        }),
    );
};
