/**
 * Checks if the passed value is a finite number (or can be explicitly cast to it)
 * @param value
 */
export function isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * formats number up to 5 S.F. for nice display purposes
 * @param value
 */
export function niceNumber(value: number | string): string {
    return `${value.toLocaleString('fullwide', {
        useGrouping: true,
        maximumSignificantDigits: 5,
    })}`;
}

export function niceNumbers(values: number[]): string {
    return `(${values.map((value) => niceNumber(value)).join(', ')})`;
}

export function padWithLeadingZeros(num: number, size: number) {
    let padded = num.toString();
    while (padded.length < size) {
        padded = `0${padded}`;
    }
    return padded;
}

export const getDecimalPart = (num: string) => {
    if (isNaN(Number(num)) || Number.isInteger(Number(num))) {
        return '';
    }
    return num.toString().split('.')[1];
};

const findNextTimeInterval = (num: number) => {
    const remainder = num % 1;
    let nextIntervalRemainder: any = '';

    if (remainder !== 0) {
        nextIntervalRemainder = remainder * 60;
    }

    return nextIntervalRemainder;
};

export const convertToDegreesMinutesSeconds = (num: number, isLatitude: boolean) => {
    if (isNaN(Number(num)) || num === 0) {
        return '';
    }

    let minutes = 0;
    let seconds: any = 0;
    const positive = isLatitude ? 'N' : 'E';
    const negative = isLatitude ? 'S' : 'W';
    const bearing = num > 0 ? positive : negative;
    const convertedNumber = num < 0 ? Math.abs(num) : num;
    const degrees = Math.trunc(convertedNumber);
    const minutesInterval = findNextTimeInterval(convertedNumber);

    if (minutesInterval) {
        minutes = Math.trunc(minutesInterval);

        const secondsInterval = findNextTimeInterval(minutesInterval);

        if (secondsInterval) {
            seconds = toFixed(Number(secondsInterval), 1);
        }
    }

    return `${degrees}° ${minutes}' ${seconds}"${bearing}`;
};

export const checkValidPrecision = (val: any, precision: number) => {
    const splitVal = val.toString().split('.');
    return !(splitVal.length > 2 || (splitVal.length === 2 && splitVal[1].length > precision));
};

export function isStringValidNumber(value: string): boolean {
    return value.startsWith('-')
        ? !isNaN(Number(value.substring(1))) && !value.substring(1).includes('-')
        : !isNaN(Number(value));
}

export function isStringValidPositiveNumber(value: string): boolean {
    return !isNaN(Number(value)) && !value.startsWith('-');
}

export function toFixed(value: number, precision?: number): string {
    let usedPrecision = 3;
    if (precision !== undefined && precision !== null && precision >= 0 && precision <= 100) {
        usedPrecision = precision;
    }
    return value.toFixed(usedPrecision);
}
