import { ACTIVITY_TYPES } from 'state-domains/constants';
import { CONFIGURATION_TYPES } from 'state-domains/constants/mxdeposit';

import { trackAction } from './analytics';
import { EVENT_TYPES, TRACKING_COMPONENTS, DIALOGS } from './events';

function makeEventName(
    component: TRACKING_COMPONENTS,
    event: EVENT_TYPES | string,
    details?: string,
    subComponent?: TRACKING_COMPONENTS,
) {
    const baseEvent = `${component}${
        subComponent ? `-${subComponent.toLowerCase()}` : ''
    }-${event}`;
    return details ? `${baseEvent} ${details}` : baseEvent;
}

export function trackUserAction(
    component: TRACKING_COMPONENTS,
    event: EVENT_TYPES | string,
    details?: string,
    additionalInfo?: any,
) {
    trackUserActionWithSubControls(component, undefined, event, details, additionalInfo);
}

export function trackUserActionWithSubControls(
    component: TRACKING_COMPONENTS,
    subControls: TRACKING_COMPONENTS | undefined,
    event: EVENT_TYPES | string,
    details?: string,
    additionalInfo?: any,
) {
    const eventName = makeEventName(component, event, details, subControls);
    trackAction(eventName, additionalInfo);
}

export function getTypeForTracking(type: string) {
    const textMap = {
        [ACTIVITY_TYPES.DRILLING]: 'drill hole',
        [ACTIVITY_TYPES.POINT]: 'point',
        [CONFIGURATION_TYPES.LISTS]: 'list',
        [CONFIGURATION_TYPES.TABLES]: 'table',
        [CONFIGURATION_TYPES.HEADERS]: 'header',
        [CONFIGURATION_TYPES.ACTIVITIES]: 'activity',
        [CONFIGURATION_TYPES.SAMPLE_LISTS]: 'sample list',
        [CONFIGURATION_TYPES.SAMPLE_HEADERS]: 'sample header',
        [CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS]: 'sample analysis workflow',
    };
    return textMap[type as keyof typeof textMap];
}

export function trackDialogAction(dialog: DIALOGS | string, additionalInfo?: any) {
    trackUserAction(TRACKING_COMPONENTS.DIALOG, dialog, 'action', additionalInfo);
}

export function trackDialogCancel(dialog: DIALOGS | string, additionalInfo?: any) {
    trackUserAction(TRACKING_COMPONENTS.DIALOG, dialog, 'cancel', additionalInfo);
}

export function trackCollarDialogAction(
    dialog: DIALOGS,
    collarType: ACTIVITY_TYPES,
    additionalInfo?: any,
) {
    const dialogName = `${dialog} ${getTypeForTracking(collarType)}`;
    trackDialogAction(dialogName, additionalInfo);
}

export function trackCollarDialogCancel(
    dialog: DIALOGS,
    collarType: ACTIVITY_TYPES,
    additionalInfo?: any,
) {
    const dialogName = `${dialog} ${getTypeForTracking(collarType)}`;
    trackDialogCancel(dialogName, additionalInfo);
}

export function trackUserActionInLandingPage(
    activityType: string,
    eventType: EVENT_TYPES,
    details?: string,
    additionalInfo?: any,
) {
    trackUserActionInLandingPageWithSubControls(
        activityType,
        undefined,
        eventType,
        details,
        additionalInfo,
    );
}

export function trackUserActionInLandingPageWithSubControls(
    activityType: string,
    subComponent: TRACKING_COMPONENTS | undefined,
    eventType: EVENT_TYPES,
    details?: string,
    additionalInfo?: any,
) {
    let component;

    switch (activityType) {
        case ACTIVITY_TYPES.DRILLING:
            component = TRACKING_COMPONENTS.DRILLHOLE_LANDING;
            break;
        case ACTIVITY_TYPES.POINT:
            component = TRACKING_COMPONENTS.POINTS_LANDING;
            break;
        case CONFIGURATION_TYPES.LISTS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_LIST_LANDING;
            break;
        case CONFIGURATION_TYPES.SAMPLE_LISTS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_LIST_LANDING;
            break;
        case CONFIGURATION_TYPES.TABLES:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_TABLE_LANDING;
            break;
        case CONFIGURATION_TYPES.HEADERS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_HEADER_LANDING;
            break;
        case CONFIGURATION_TYPES.ACTIVITIES:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_ACTIVITY_LANDING;
            break;
        case CONFIGURATION_TYPES.SAMPLE_HEADERS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_DISPATCH_HEADERS;
            break;
        case CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_ANALYSIS_WORKFLOWS;
            break;
        default:
            component = TRACKING_COMPONENTS.SITE_SETTINGS;
    }

    trackUserActionWithSubControls(component, subComponent, eventType, details, additionalInfo);
}

export function trackUserActionInConfigurationWithSubControls(
    configurationType: CONFIGURATION_TYPES,
    subComponent: TRACKING_COMPONENTS | undefined,
    eventType: EVENT_TYPES,
    details?: string,
    additionalInfo?: any,
) {
    let component;

    switch (configurationType) {
        case CONFIGURATION_TYPES.LISTS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_LIST_LANDING;
            break;
        case CONFIGURATION_TYPES.TABLES:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_TABLE_LANDING;
            break;
        case CONFIGURATION_TYPES.HEADERS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_HEADER_LANDING;
            break;
        case CONFIGURATION_TYPES.ACTIVITIES:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_ACTIVITY_LANDING;
            break;
        case CONFIGURATION_TYPES.SAMPLE_LISTS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_LISTS;
            break;
        case CONFIGURATION_TYPES.SAMPLE_HEADERS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_DISPATCH_HEADERS;
            break;
        case CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS:
            component = TRACKING_COMPONENTS.SITE_SETTINGS_SAMPLE_ANALYSIS_WORKFLOWS;
            break;
        default:
            component = TRACKING_COMPONENTS.SITE_SETTINGS;
    }

    trackUserActionWithSubControls(component, subComponent, eventType, details, additionalInfo);
}

export function trackKeyboardShortcut(
    details?: string,
    subComponent?: TRACKING_COMPONENTS,
    info?: any,
) {
    trackUserActionWithSubControls(
        TRACKING_COMPONENTS.KEYBOARD_NAVIGATION,
        subComponent,
        EVENT_TYPES.USE,
        details,
        info,
    );
}

export function trackUserActionInDataGrid(
    event: EVENT_TYPES | string,
    details?: string,
    info?: any,
) {
    trackUserActionInDataGridWithSubControls(undefined, event, details, info);
}

export function trackUserActionInDataGridWithSubControls(
    subControl: TRACKING_COMPONENTS | undefined,
    event: EVENT_TYPES | string,
    details?: string,
    info?: any,
) {
    trackUserActionWithSubControls(
        TRACKING_COMPONENTS.DATA_ENTRY,
        subControl,
        event,
        details,
        info,
    );
}
