import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationListsExportUrl } from 'state-domains/network';

import { convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const exportConfigurationLists = (payload: {
    listIds: string[];
    dateFormat?: string;
}): Observable<any> => {
    let dateFormat = '';

    if ('dateFormat' in payload) {
        dateFormat = payload.dateFormat ?? '';
        // eslint-disable-next-line no-param-reassign
        delete payload.dateFormat;
    }

    const obs = sendRequestWithXSRFToken(
        buildConfigurationListsExportUrl(dateFormat),
        convertToSnake(payload),
    );
    return obs.pipe(switchMap(({ response }: AjaxResponse<any>) => observableOf({ response })));
};
