import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

import {
    HELPFUL_LINKS,
    MISSING_MXDEPOSIT_LICENSE,
    MISSING_MXDEPOSIT_LICENSE_DETAIL,
    OOPS,
    PAGE_NOT_FOUND,
    PAGE_NOT_FOUND_DETAILED,
} from 'state-domains/constants';

import { useStyles } from './ErrorScreen.styles';

type LinkData = {
    msg: string;
    href: string;
};
type ErrorImage = 'error' | 'restricted';
type Props = {
    msg: string;
    header?: string;
    details?: string;
    links?: LinkData[];
    automationIdPrefix?: string;
    titleBelow?: boolean;
    errorImage?: ErrorImage;
};

export function ErrorScreen(props: Props) {
    const { msg, header, details, links, automationIdPrefix, titleBelow, errorImage } = props;

    const headerAutomationId = automationIdPrefix
        ? `${automationIdPrefix}-error-header`
        : 'error-header';
    const messageAutomationId = automationIdPrefix
        ? `${automationIdPrefix}-error-msg`
        : 'error-msg';
    const detailsAutomationId = automationIdPrefix
        ? `${automationIdPrefix}-error-details`
        : 'error-details';
    const headerMsg = header ?? OOPS;
    const { classes } = useStyles();

    let errorSrc = '/images/error.svg';
    if (errorImage === 'restricted') {
        errorSrc = '/images/restricted-1.svg';
    }

    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} className={classes.imageBlock}>
                <img src={errorSrc} alt={headerMsg} className={classes.image} />
                {!titleBelow && (
                    <div className={classes.floatingTextBlock}>
                        <Typography
                            variant="h1"
                            className={classes.title}
                            automation-id={headerAutomationId}
                        >
                            {headerMsg}
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            automation-id={messageAutomationId}
                        >
                            {msg}
                        </Typography>
                    </div>
                )}
            </Grid>
            <Grid item xs={12} sm={8} md={6} className={classes.messageBlock}>
                {titleBelow && (
                    <Typography
                        variant="h1"
                        className={classes.titleBelow}
                        automation-id={messageAutomationId}
                    >
                        {msg}
                    </Typography>
                )}
                {details && (
                    <Typography variant="subtitle1" automation-id={detailsAutomationId}>
                        {details}
                    </Typography>
                )}
                {links && (
                    <>
                        <br />
                        <Typography variant="subtitle1">{HELPFUL_LINKS}</Typography>
                        {links.map((link) => (
                            <div key={link.msg}>
                                <Link
                                    href={link.href}
                                    target="_blank"
                                    color="inherit"
                                    rel="noopener"
                                    underline="always"
                                >
                                    {link.msg}
                                </Link>
                                <br />
                            </div>
                        ))}
                    </>
                )}
            </Grid>
        </Grid>
    );
}

ErrorScreen.defaultProps = {
    automationIdPrefix: '',
    titleBelow: false,
    errorImage: 'error',
};

export function RestrictedScreen() {
    return (
        <ErrorScreen
            msg={MISSING_MXDEPOSIT_LICENSE}
            details={MISSING_MXDEPOSIT_LICENSE_DETAIL}
            titleBelow
            errorImage="restricted"
        />
    );
}

export function NotFoundErrorScreen() {
    return <ErrorScreen header={OOPS} msg={PAGE_NOT_FOUND} details={PAGE_NOT_FOUND_DETAILED} />;
}

export default ErrorScreen as React.FC<any>;
