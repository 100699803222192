import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildResourcesUrl } from '../../../network';
import { addQueryParams, sendCreateRequest, sendRetrieveRequest } from '../../../utils';
import { ResourcesPayload } from '../types';
import { translateResourceList, translateResource, getRequestOverrides } from './translators';
import { APIResourceBody, APIResource, APIAllResources } from './types';

function handleCreateResource(response: APIResource): Observable<ResourcesPayload> {
    const result = translateResource(response);
    return observableOf({ unsubscribers: [result] });
}

export function createResource(
    token: string,
    audienceId: string,
    payload: APIResourceBody,
): Observable<ResourcesPayload> {
    const url = buildResourcesUrl(audienceId);
    const ajax = sendCreateRequest(url, payload, 'json', getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => handleCreateResource(response)));
}

function handleLoadResources(response: APIAllResources): Observable<ResourcesPayload> {
    const results = translateResourceList(response);
    return observableOf(results);
}

export function loadResources(token: string, audienceId: string): Observable<ResourcesPayload> {
    let url = buildResourcesUrl(audienceId);
    // add a large number limit so that all items are returned.
    // It would be safer to check the response for a null next link however it would be even more efficient
    // to filter by central instance id resource.
    // in the mean time we have this hack
    url = addQueryParams(url, { limit: '1000' });
    const ajax = sendRetrieveRequest(url, getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => handleLoadResources(response)));
}
