import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { imagoLoginUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';
import { ImagoLoginCredentials } from '../types';

export const imagoLogin = (payload: any): Observable<{ result: ImagoLoginCredentials }> => {
    const url = imagoLoginUrl();
    return sendRequestWithXSRFToken(url, { username: payload.u, password: payload.p }).pipe(
        switchMap(() => observableOf({ result: payload })),
    );
};
