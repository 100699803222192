import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildJobListUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { Job, Jobs } from '../types';

export const loadJobList = (): Observable<Job[]> => {
    const obs = sendRetrieveRequest(buildJobListUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Jobs>) => {
            const jobs = convertToCamel<Job[]>(response.Job);
            return observableOf(jobs);
        }),
    );
};
