import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) => ({
    topPadding: {
        height: theme.spacing(5),
    },
    projectSearchPlaceholder: {
        height: theme.spacing(5),
        width: theme.spacing(16.5),
        backgroundColor: '#F2F2F2',
        marginLeft: 'auto',
        marginBottom: theme.spacing(2),
    },
    projectList: {
        overflow: 'hidden',
        maxHeight: '100%',
    },
    projectContainer: {
        maxHeight: 'calc(100vh - 240px)',
        overflow: 'hidden',
    },
    tileMargin: {
        marginTop: '14px',
        marginLeft: '1px',
    },
});

export const useStyles = makeStyles()(styles);
