import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { imagoCheckConnectionUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ImagoConnectionResponse } from '../types';

export const imagoCheckConnection = (
    actionName: string,
): Observable<{ result: string; actionName: string }> => {
    const url = imagoCheckConnectionUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ImagoConnectionResponse>) =>
            observableOf({ result: response.value, actionName }),
        ),
    );
};
