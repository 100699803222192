import { NotificationType } from '@local/web-design-system';
import * as React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { SnackbarNotification } from 'state-domains/domain';

import { WithStyles } from 'src/styles/utils';
import { Nullable } from 'src/types';

import { styles } from './NotificationsContext.styles';
import { SnackbarNotificationData } from './SnackbarNotificationData';

export type SnackbarNotificationDescriptor = string;

type NotificationsContextProviderStyles = typeof styles;

export enum NotificationsShowingType {
    MOST_RECENT_TOP = 1,
    MOST_RECENT_BOTTOM = 2,
}

export enum NotificationsRemovingStrategy {
    NONE = 0,
    AUTO_REMOVE_EXCEPT_LAST = 1,
    AUTO_REMOVE_LAST = NotificationsRemovingStrategy.AUTO_REMOVE_EXCEPT_LAST << 1,
}

export interface SnackbarNotificationShowingArg {
    descriptor: SnackbarNotificationDescriptor;
    type: NotificationType;
    message: string;
    timeoutMs?: number;
    className?: Partial<Record<keyof ReturnType<typeof styles>, string>>;
    onClose?: () => void;
}

export interface NotificationsContextProviderBaseProps {
    showingType: NotificationsShowingType;
    removingStrategy: NotificationsRemovingStrategy;
    isNavDrawerOpen: boolean;

    /**
     * The latest notification will be opaque, other notifications will be translucent
     * Opacity is decreasing in a linear manner
     */
    linearFadingOut?: boolean;

    fadingOutOnAutoRemoving?: boolean;
    notificationsLimit?: number;
    autoRemovingTimeoutMs?: number;
    children?: React.ReactNode;

    deleteSnackbarMessage: Function;
    clearSnackbarMessages: Function;

    notificationList: Array<SnackbarNotification>;
}

export type NotificationsContextProviderExtensionProps =
    WithStyles<NotificationsContextProviderStyles>;

export type NotificationsContextProviderProps = NotificationsContextProviderBaseProps &
    NotificationsContextProviderExtensionProps &
    WrappedComponentProps;

export interface NotificationsContextProviderState {
    activeSnackbarNotificationsDataList: Array<SnackbarNotificationData>;
}

export interface NotificationsContextValue {
    showSnackbarNotification: (arg: SnackbarNotificationShowingArg) => void;
    closeSnackbarNotification: (
        id: SnackbarNotificationDescriptor,
        index?: Nullable<number>,
    ) => void;
    isSnackbarNotificationActive: (
        id: SnackbarNotificationDescriptor,
        index?: Nullable<number>,
    ) => boolean;
    clearNotifications: () => void;
}

/**
 * Used to specify in type of the props of a component
 */
export type WithNotifications = NotificationsContextValue;
