import { Observable } from 'rxjs';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { RowReferenceResponse } from 'state-domains/domain/drillhole';
import { createRowsUrl, createRowsPointsUrl } from 'state-domains/network';

import { getRowReferenceResponseObservable } from './common';

export const createRows = (
    payload: any,
    type: ACTIVITY_TYPES,
): Observable<RowReferenceResponse> => {
    const url = type === ACTIVITY_TYPES.DRILLING ? createRowsUrl() : createRowsPointsUrl();
    return getRowReferenceResponseObservable(url, payload);
};
