import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildModifyColumnsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const modifyColumn = (
    type: string,
    itemId: string,
    payload: any,
    id = '',
): Observable<any> => {
    const url = buildModifyColumnsUrl(type, itemId, id);
    return sendRequestWithXSRFToken(url, payload, id ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedColumn = convertToCamel(response);
            return observableOf(convertedColumn);
        }),
    );
};
