export const styles = {
    mapControlButton: `
        border-radius: 5px;
        width: 48px;
        height: 48px;
        background-image: url("/images/MapMenuFilled.svg");
        background-repeat: no-repeat;
    `,
    mapDropdownContainer: `
        border: 1px solid rgba(151, 151, 151, 0.9); 
        border-radius: 1px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        width: 182px; 
        visibility: hidden; 
        background-color: #FFFFFF;
        margin-right: 400px;
        right: 138px;
        position: relative;
    `,
    buttonControlContainer: `
        display: flex;
        flex-wrap: no-wrap;
        margin-bottom: 8px;
        margin-left: 8px;
    `,
    radioButtonContainer: `
        height: 14px;
        width: 14px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 1px solid #4a4a4c;
        margin-right: 5px;
    `,
    radioButtonSelectedCircle: `
        position: relative;
        height: 10px;
        width: 10px;
        top: 2px;
        left: 2px;
        border-radius: 15px;
        background-color: #265C7F;
        display: none;
    `,
    checkboxContainer: `
        height: 14px;
        width: 28px;
        border-radius: 15px;
        background-color: #FFFFFF;
        border: 1.5px solid #265C7F;
        margin-right: 5px;
    `,
    checkboxToggle: `
        position: relative;
        height: 10px;
        width: 10px;
        top: 2px;
        left: 2px;
        border-radius: 15px;
        background-color: #4a4a4c;
    `,
    controlBoundaryContainer: `
        color: #39393C;
        font-family: Roboto;
        font-size: 13px;
        letter-spacing: 0.4px;
        line-height: 16px;
        width: 152px;
        text-align: left;
        margin-left: 15px;
        margin-top: 15px;
    `,
    boundaryTitle: `
        margin-bottom: 10px;
        font-weight: 500;
    `,
    selected: `
        background-color: #265C7F;
        left: 16px;
    `,
    radioButtonSelected: `
        border: 1px solid #265C7F;
    `,
};
