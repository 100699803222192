import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadActivityGroupsUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ActivityGroupMap, ActivityGroupResponse } from '../types';

export const loadActivityGroups = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: ActivityGroupMap[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadActivityGroupsUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ActivityGroupResponse>) => {
            const { ActivityGroup } = response;
            const result = convertToCamel<ActivityGroupMap[]>(ActivityGroup);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
