import IntervalTree from '@flatten-js/interval-tree';
import { ColDef } from 'ag-grid-community';

import { TableView } from 'state-domains/domain/subscription';

import { DynamicPrimitive, Row, Table } from '..';

import { AsyncState } from '../../types';
import { MXDTimestamp } from '../utils';

export interface DrillholeItemsState extends AsyncState {
    collars: { [key: string]: Drillhole };
}

export type RowReferencesListPointers = {
    firstRowReference?: RowReference;
    lastRowReference?: RowReference;
};
export interface DrillholeState extends AsyncState {
    rows: { [id: string]: any };
    modifiedRowReferencesState: AsyncState;

    deleteDrillHoleState: AsyncState;
    saveDrillHoleState: AsyncState;
    unassignUserState: AsyncState;
    cloneDrillHoleState: AsyncState;

    exportCollarsState: AsyncState;
    rowReferences: { [id: string]: RowReference };
    rowReferencesListPointers: RowReferencesListPointers;
    rowReferencesIntervalTree: IntervalTree | null;
    rowReferencesLoadState: AsyncState;
    samples: { [id: string]: Sample };
    addDrillHoleState: AsyncState;
    modifiedRows: ModifiedRowsInfo;
    tableView: TableView;
    columns: any[];
    excludedFillDownColumns: string[];
    projectId: string;
    count: number;
    items: DrillholeItemsState;
    current: CurrentDrillholeState;
    selectedDrillHoles: Drillhole[];
    fileGroup: FileGroupState;
    emptyFileGroups: { [id: string]: string[] };
    uploadedFiles: any[];
    deletedFile: DeletedFileState;
    filesForGroup: FilesForGroupState;
    filesForGroupLengths: { [id: string]: number };
    downloadedFile: DownloadedFileType | null;
    dumpTableData: {
        status: AsyncState['status'];
        error: AsyncState['error'];
        url: string | null;
    };
    newDrillhole: { [id: string]: Drillhole };
    temporaryTables: { [id: string]: Table };
    labCertificateInfoState: LabCertificateInfoState;
    lithologyIntervalTree: IntervalTree | null;
    linkedToLithoTable: string;
    maxRowSpanCount: number;
    isSorted: boolean;
    isSelectedAll: boolean;
    isClearedAll: boolean;
    unSelectedCollars: { [id: string]: Drillhole };

    exportTemplates: ExportTemplateInfo[];
    customExports: CustomExportInfo[];
    exportTemplatesState: AsyncState;
    customExportState: AsyncState;
    sampleTableId: string;
    lithologyTemporaryRows: string[];
    skipAllTempLithoRows: boolean;
    externalFilter: string;
    filterApplied: boolean;
    searchTerm: string;
}

export interface LoadRowReferenceState {
    rowReferencesIntervalTree: IntervalTree | null;
    rowReferences: { [id: string]: RowReference };
    rowReferencesListPointers: RowReferencesListPointers;
    samples: { [id: string]: Sample };
    temporaryTables: { [id: string]: Table };
    lithologyIntervalTree: IntervalTree | null;
    linkedToLithoTable: string;
}

export interface ModifiedRowsInfo {
    reconstructAllRows: boolean;
    isSequenceChanged: boolean;
    isSorted: boolean;
    rows: RowReference[];
    redrawRows: string[];
}

export interface TableDownloadPayload {
    collarIds: string[];
    tableViewId: string;
    filename: string;
}

export interface TableDownloadResponse {
    url: string;
}

export interface SamplesState extends AsyncState {
    samples: { [id: string]: Sample };
}

export interface LabCertificateInfoState extends AsyncState {
    labCertInfo?: { [id: string]: LabCertificateInfo };
}

export interface FilesForGroupState extends AsyncState {
    filesForGroup: { [id: string]: FilesForGroup };
}

export interface DeletedFileState extends AsyncState {
    deletedFile: FileObject;
}

export interface FileGroupState extends AsyncState {
    fileGroup: DataFileGroupResponse | HeaderFileGroupResponse;
}

export interface DrillholeCountResponse {
    size: number;
}

export interface DrillholeResponse {
    data: Drillhole[];
}

export interface Drillhole {
    errors?: any;
    project: string;
    intervalErrors?: any;
    id: string;
    name: string;
    activity: string;
    type: string;
    actualDepthField: string;
    actualDepths?: { [id: string]: number };
    targetDepthField: string;
    coordinatesTable: DrillholeCoordinatesTable;
    values: { [key: string]: string | number | null | boolean | { date: number } };
    status: DrillHoleStatus;
    state: string;
    wgs84: DrillholeCoordinates;
    assignedUser?: DrillholeUser | DrillholeUserGroup;
    header: string;
    uid?: string;
    updatedAt?: MXDTimestamp;
    updatedBy?: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
}

export interface DrillholeCoordinatesTable {
    rows: { [id: string]: Row };
}

export interface DrillholeCoordinates {
    easting?: number;
    northing?: number;
}

export interface DrillholeUserDetails {
    device: string;
    user: string;
}
export interface DrillholeUser extends DrillholeUserDetails {
    id: string;
}

export interface DrillholeUserGroup {
    groupUsers: { [key: string]: DrillholeUserDetails };
    id: string;
}

export function isGroupUser(obj: DrillholeUser | DrillholeUserGroup): obj is DrillholeUserGroup {
    if ((obj as DrillholeUserGroup).groupUsers) {
        return true;
    }
    return false;
}

export interface DrillholeAssignedUser {
    color: string;
    device: string;
    initials: string;
    name: string;
    user: string;
}

export interface CurrentDrillholeState extends AsyncState {
    id: string;
}
// #region ========================= Row References =========================

export interface RowReferenceResponse {
    RowReference: RowReference[];
    Collar: Drillhole;
    NextRows?: { [id: string]: RowReference };
}

export interface RowReferences {
    RowReference: RowReference[];
}

export interface RowReferenceChildren {
    [id: string]: { rowReference: string; tableView: string };
}

export interface RowReference {
    id: string;
    uid?: string;
    dataRecords: DataRecords;
    createdBy: string;
    values: Values;
    verrors: Errors;
    children?: RowReferenceChildren;
    parent: DynamicPrimitive | null;
    updatedAt: MXDTimestamp;
    project: string;
    index: number;
    tableView: string;
    collar: string;
    activity: string;
    sample?: string;
    updatedBy: string;
    // id: string;
    isvalid: boolean;
    createdAt: MXDTimestamp;
    nextItem?: any;
    prevItem?: any;
    lastRow?: boolean;
    xrfSample?: string;
    xrfRowReference?: string;
    isAddedToIntervalTree?: boolean;
}

export class CellData {
    constructor(value: any = undefined, errors: string[] = []) {
        this.errors = errors;
        this.value = value;
    }

    value: any;

    errors: string[];
}

export interface Interval {
    from?: CellData;
    to?: CellData;
    length?: CellData;
    depth?: CellData;
    parentSample?: string;
}

export interface DataRecords {
    [id: string]: DataRecord;
}

export interface DataRecord {
    errors: Errors;
    tableView: string;
    values: DynamicPrimitive;
    rowReference: string;
    table: string;
    collar: string;
    verrors: Errors;
}

export interface Values {
    from?: number | null;
    to?: number | null;
    depth?: number | null;
}

export interface Errors {
    values: any[];
}

export interface DeleteRowReferenceRequest {
    _ids: string[];
    collar: string;
    tableView: string;
}

export interface DeleteRowReferencesResponse {
    rowsDeleted: string[];
    Collar: Drillhole;
    NextRows?: { [id: string]: RowReference };
}

export interface ReIndexResponse {
    RowReferenceIndexes: { [id: string]: number };
}

// #endregion ========================= Row References =========================

export interface DownloadedFileType {
    blob: Blob;
    fileName: string;
}

export interface FileObject {
    id: string;
    file: string;
    contentType: string;
    originalFilename: string;
    size: number;
    orientation: number;
    createdBy: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
}

export interface FilesForGroup {
    [id: string]: FileObject;
}

export interface EmptyFileGroups {
    [id: string]: string;
}

export interface DataFileGroupResponse {
    id: string;
    project: string;
    collar: string;
    activity: string;
    tableView: string;
    row: string;
    column: string;
    files: { [id: string]: FileObject };
    isvalid: boolean;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
}

export interface HeaderFileGroupResponse {
    id: string;
    collar: string;
    field: string;
    files: { [id: string]: FileObject };
    createdBy?: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
}

export interface CreateRowsPayload {
    collar?: string;
    tableView?: string;
    start?: number | null;
    end?: number | null;
    interval?: number | null;
}

export interface ListCollection {
    rowData: any[];
    columnData: ColDef[];
}

// #region ========================= Samples =========================

export interface Samples {
    Sample: Sample[];
}

export interface Sample {
    id: string;
    createdBy: string;
    rowReference: string;
    createdAt: MXDTimestamp;
    project: string;
    name: string;
    status: string;
    collar: string;
    activity: string;
    isvalid: boolean;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    dispatch?: string[];
    parentSample?: string;
}

// #endregion ========================= Samples =========================

// #region ========================= Sample Results =========================

export interface SampleResultsResponse {
    SampleResults: SampleResults;
}

export interface SampleResults {
    columns: SampleResultColumn[];
    samples: SampleResultRow[];
}

export interface SampleResultRow {
    id: string;
    finalValues: { [id: string]: SampleResultValues };
    holeNumber: string;
    labCerts: string[];
    name: string;
    rowRefIndex: number;
    releasedValues: { [id: string]: { [id: string]: SampleResultValues } };
    type: string;
    values: { [id: string]: SampleResultValues };
    parentSampleName?: string;
    from?: number;
    to?: number;
    depth?: number;
    controlType?: string;
}

export interface SampleResultValues {
    value: number;
    labCertId?: string;
    originalValue?: string;
    symbolAction?: string;
    symbolReason?: string;
    status?: string;
    originalStatus?: string;
    selectedCandidate?: string;
    crmCode?: string;
    crmType?: string;
    crmCertifiedValue?: string;
    crm_1sd?: string;
    crmMinValue?: string;
    crmMaxValue?: string;
    validationAction?: string;
    validationReason?: string;
}

export interface SampleResultColumn {
    analyte: string;
    analyteId: string;
    isRanked: boolean;
    method: string;
    methodDetail: string;
    methodId: string;
    resultType: string;
    unit: string;
    unitId: string;
    id: string;
    name?: string;
    candidateColumns?: string[];
}

// #endregion ========================= Sample Results =========================

// #region ========================= Ranked Columns =========================

export interface RankedColumnResponse {
    candidateColumnsMap: { [id: string]: CandidateColumn };
    rankedColumnsMap: { [id: string]: RankedColumn };
}

export interface CandidateColumn {
    analyte: string;
    analyteId: string;
    code: string;
    codeId: string;
    laboratory: string;
    serviceSchedule: string;
    unit: string;
    unitId: string;
    id: string;
}

export interface RankedColumn {
    activity: string;
    candidateColumns: string[];
    computeState: string;
    isvalid: boolean;
    name: string;
    unit: string;
    unitId: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    id: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

// #endregion ========================= Ranked Columns =========================

// #region ========================= Lab Certificates =========================

export interface LabCertificateInfo {
    activity: string;
    comment: string;
    hasPermission: boolean;
    isNew: boolean;
    isvalid: boolean;
    name: string;
    project: string;
    released: boolean;
    releasedAt: MXDTimestamp;
    releasedBy: string;
    workflow: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    id: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

// #endregion ========================= Lab Certificates =========================

// #region ========================= Payloads =========================

export interface FillDownPayload {
    columnIds: { [id: string]: string };
    numRows: number;
}

export interface AddDrillHoleResponse {
    Collar: Drillhole;
}

export interface DeleteDrillHoleResponse {
    Collar: Drillhole;
}

export interface CloneDrillholeRequest {
    coordinates: boolean;
    files: boolean;
    header: boolean;
    sourceCollar_id: string;
    tableIds: string[];
    clonedCollarNumber?: string;
}

export interface ExportCollarRequest {
    filters: [
        {
            type: string;
            values: string[];
            operator: string;
            field?: string;
            system_filter?: string;
        },
    ];
    activities: string[];
    state: string[];
    project: string;
    encoding: string;
    sort: string;
    timezoneoffset: number;
    type: string;
    destination: string[];
    select_all: boolean;
    included_collars: string[];
    excluded_collars: [];
    date_format: string;
    quotes_mode: string;
    include_images: boolean;
    include_metadata: boolean;
    include_system_fields: boolean;
    include_header_fields: boolean;
    include_child_tables: boolean;
    include_id: boolean;
    filename: string;
    client_session_id: string;
    grid?: string;
}
export interface CustomExportRequest {
    filters: [
        {
            type: string;
            values: string[];
            operator: string;
            field?: string;
            system_filter?: string;
        },
    ];
    activities: string[];
    state: string[];
    export_id: string;
    project: string;
    activity_type: string;
    sort: string;
    timezoneoffset: number;
    file_name: string;
    select_all: boolean;
    included_collars: string[];
    excluded_collars: [];
}
export interface ExportTemplateInfo {
    id: string;
    project: string;
    type: string;
    name: string;
    dateFormat: string;
    destination: string[];
    encoding: string;
    filesToExport: FilesToExport[];
    includeFiles: boolean;
    includeMetadata: boolean;
    isSharedTemplate: boolean;
    isValid: boolean;
    quotesMode: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}
export interface FilesToExport {
    columns: ExportColumns[];
    filters: ExportFilters[];
    reorderedColumns: boolean;
    name: string;
    ranking: { coordinates: { type: string; order: string[] } };
    specialTables: { [id: string]: boolean };
    tableIds: ExportTableIds[];
}

export interface ExportColumns {
    index: number;
    name: string;
    type: string;
    id: string;
    fieldId?: string;
    listColumnId?: string;
    listColumnIndex: number;
    listId: string;
    special?: string;
}

export interface ExportFilters {
    column: string;
    operator: string;
    table: string;
    type: string;
    values: string[];
}

export interface ExportTableIds {
    activity: string;
    headerId?: string;
    tableId: string;
    tableViewId: string;
}

export interface CustomExportInfo {
    id: string;
    name: string;
    description: string;
    ismx: boolean;
    module: string;
    exportParams: ExportParams[];
    exportSources: ExportSources[];
}

export interface ExportParams {
    name: string;
    paramName: string;
    rank: number;
    type: string;
}

export interface NewDrillHoleRequest {
    project: string;
    activity: string;
    name: string;
}

export interface ExportSources {
    cda: string;
    name: string;
    queries: string[];
}

export interface FilterObject {
    type: string;
    values: any[];
    operator: string;
    system_filter?: string;
    field?: string;
    column?: string;
}
// #endregion ========================= Payloads =========================

export enum DrillHoleReferencesIntervalType {
    INTERVAL = 'intervals',
    DEPTH = 'depths',
    DATA = 'data',
    OTHER = 'other',
}

export enum SpecialTableTypes {
    SAMPLES = 'samples',
    SURVERY = 'survey',
    LITHOLOGY = 'lithology',
    NONE = 'none',
}

export const DrillHoleTextTypeMulti = 'multi';

export enum DrillHoleReferencesColumnType {
    NUMERIC = 'numeric',
    LIST = 'list',
    TEXT = 'text',
    DATE = 'date',
    CHECKBOX = 'checkbox',
    TEXTMULTI = 'textMulti',
    ASSOCIATED_COLUMN = 'associatedColumn',
    FILE = 'file',
    IMAGO = 'imago',
    PRIMARY_LIST_COLUMN = 'primary',
    IN_USE = 'inuse',
    ERROR = 'error',
    PROJECTION = 'Projection',
    ROW_DRAG = 'ROW_DRAG',
    RANKED_RESULTS = 'RANKED_RESULTS',
    SAMPLE_RESULTS = 'SAMPLE_RESULTS',
    PARENT_SAMPLE = 'PARENT_SAMPLE',
}

export enum DrillHoleStatus {
    NEW = 'new',
    INPROGRESS = 'inprogress',
}

export interface LoadDrillholesParameters {
    sortKey: string;
    offset?: number;
    limit?: number;
}
