import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { Drillhole, CloneDrillholeRequest } from 'state-domains/domain/drillhole';

import { buildCloneDrillHoleUrl, buildClonePointsUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const cloneDrillHole = (
    payload: CloneDrillholeRequest,
    type: ACTIVITY_TYPES,
): Observable<Drillhole> => {
    const url = type === ACTIVITY_TYPES.DRILLING ? buildCloneDrillHoleUrl : buildClonePointsUrl;
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<Drillhole>) => {
            const collar = { ...response };
            const convertedCollar = convertToCamel<Drillhole>(collar);
            return observableOf(convertedCollar);
        }),
    );
};
