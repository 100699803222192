import { createTheme, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const sliderStyle = createTheme({
    components: {
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    '&::after': {
                        background:
                            'linear-gradient(90deg, transparent, rgba(255, 255, 255, 15%), transparent)',
                    },
                },
            },
        },
    },
});

export const styles = (theme: Theme) => ({
    homePageContainer: {
        width: '100%',
        height: '100%',
    },
    homeSubContainer: {
        height: 'calc(100% - 40px)',
        width: '100%',
        backgroundColor: '#FFFFFF',
    },
    homeTileContainer: {
        marginTop: theme.spacing(1.5),
        '& > div > button': {
            border: '1px solid transparent',
            '&:focus': {
                border: `1px solid  ${theme.palette.primary.main}`,
            },
        },
    },
    homeTilePending: {
        width: '260px',
        marginLeft: '1px',
        marginRight: '1px',
        marginTop: '0.5px',
        height: `145px !important`,
    },
    homeTile: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
        width: theme.spacing(33),
        position: 'relative' as const,
        boxShadow: '0 0 5px 0 rgba(29, 31, 35, 0.13)',
        boxSizing: 'border-box' as const,
        minHeight: '213px',
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: '#FFFFFF',
    },
    homeTableContainer: {
        backgroundColor: '#FAFAFA',
        width: theme.spacing(148),
        height: '239px',
    },
    defaultContentSkeleton: {
        height: `calc(100vh - ${theme.spacing(8)}) !important`,
        width: '100%',
    },

    drawerContainer: {
        width: `${theme.spacing(30)} !important`,
        height: '100vh',
        backgroundColor: theme.palette.secondary.main,
    },
    drawerTitle: {
        position: 'relative' as const,
        width: theme.spacing(17),
        height: `${theme.spacing(4)} !important`,
        marginTop: '15px',
        marginLeft: theme.spacing(4.5),
        marginBottom: '37px',
    },
    drawerItemContainer: {
        width: '100%',
    },
    drawerItem: {
        width: theme.spacing(27),
        height: `${theme.spacing(7)} !important`,
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
    },

    appBar: {
        width: `calc(100vw - ${theme.spacing(30)})`,
        height: theme.spacing(8),
    },
});

export const useStyles = makeStyles()(styles);
