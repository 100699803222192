import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FileObject } from 'state-domains/domain';

import { buildAddFileToListGroup } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const addFileToListGroup = (
    groupId: string,
    file: any,
): Observable<{ file: FileObject; name: string }> => {
    const url = buildAddFileToListGroup(groupId);
    const fileObj = { file };
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, fileObj).pipe(
        switchMap((result: any) => {
            const converted = result.ok === false ? result : convertToCamel(result);
            return observableOf({ file: converted, name: file.name });
        }),
    );
};
