import { getSpacing } from '@local/web-design-system';
import { Theme } from '@mui/material';
import * as React from 'react';
import { CSSObject } from 'tss-react';
import { withStyles as tssWithStyles } from 'tss-react/mui';

export const getMultilineOverflowEllipsisTextStyles = (
    theme: Theme,
    lineHeight: number,
    maxLinesCount: number,
) => {
    // Compute max height based on max lines count and line height of the text
    const maxPossibleHeight = lineHeight * maxLinesCount;

    const spacingRatio = getSpacing(theme, 1);
    // Floor height to a value which can be represented as theme spacing ratio multiplied by some integer
    const height = maxPossibleHeight - (maxPossibleHeight % spacingRatio);

    return {
        height,
        lineHeight: `${lineHeight}px`,

        // Styles below allow text to be broken by the \n symbol
        whiteSpace: 'pre-wrap' as const,
        overflowWrap: 'break-word' as const,

        // Styles below are required to cut off the multiline text with ellipsis
        maxHeight: height,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: maxLinesCount,
        WebkitBoxOrient: 'vertical' as const,
    };
};

export function getTableStyleMinHeight(theme: Theme): string {
    return theme.spacing(40);
}

type ReactComponent<Props> = ((props: Props) => ReturnType<React.FC>) | React.ComponentClass<Props>;

type Style = (theme: Theme, props: any, classes: any) => Record<string, CSSObject>;

// utility function to minimise change when migrating to tss-react
// recommend using tss-react directly for any new uses and preferably makeStyles
export const withStyles =
    (styles: Style) =>
    <C extends ReactComponent<any>, Props extends C extends ReactComponent<infer P> ? P : never>(
        component: C,
    ): ReactComponent<Omit<Props, 'classes'> & { classes?: any }> =>
        tssWithStyles(component, styles);

// tss-react does not have a WithStyles type that MUIv4 had so added them
// ideally the withStyles HOC would export the types correctly but functions like compose
// are unable to infer the types correctly or at all
// there are 2 types, for internal and external to the component
// i.e
/*

function MyComponentBase(props: WithStyles<typeof styles> ) {
    // all style keys will exist on classes prop
    const { classes } = props
    return ( ... )
}

export MyComponent: WithStylesOverride<typeof styles> = withStyles(styles)(MyComponentBase)


function ParentComponent(props) {
    // 
    return (
        // can optionally choose to override a Subset of the classes
        // so the keys must still exist in the styles
        <MyComponent classes={{ test: overrideStyle }}>
    )
}

*/
export interface WithStyles<T extends (...args: any) => any> {
    classes: Record<keyof ReturnType<T>, string>;
}

export interface WithStylesOverride<T extends (...args: any) => any> {
    classes?: Partial<Record<keyof ReturnType<T>, string>>;
}
