import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
    (theme: Theme) =>
        ({
            root: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '360px',
            },
            mainBody: {
                minWidth: '360px',
                backgroundColor: 'white',
                boxShadow:
                    '0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f',
            },
            primaryColor: {
                backgroundColor: theme.palette.grey[50],
            },
            notification: {
                width: theme.spacing(30),
            },
            notificationMessage: {
                color: '#39393C',
                fontFamily: 'Roboto',
                fontSize: '11px',
                lineHeight: '15px',
            },
            divider: {
                width: '100%',
                height: 0,
            },
            errorPanel: {
                backgroundColor: theme.palette.grey[50],
                width: '100%',
                marginTop: theme.spacing(-1),
            },
            error: {
                margin: theme.spacing(8),
                marginTop: 0,
                marginBottom: theme.spacing(2),
                width: 'calc(100% - 130px)',
            },
            btnPanel: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            button: {
                height: theme.spacing(5),
            },
            topScrollPaper: {
                alignItems: 'flex-start',
            },
            topPaperScrollBody: {
                verticalAlign: 'top',
            },
            progressBar: {
                barColorPrimary: theme.palette.common.white,
                colorPrimary: theme.palette.primary.main,
                width: '100%',
                height: theme.spacing(1),
            },
            border: {
                border: `1px solid ${theme.palette.grey[200]}`,
            },
        } as const),
);
