import { Theme } from '@mui/material';

export const styles = (theme: Theme) =>
    ({
        root: {},
        paginationOptionsDropdownWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: '0 !important',
            '& button': {
                height: theme.spacing(5),
            },
        },
        dropdownTitleWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        dropdownTitle: {
            textAlign: 'right',
            overflowWrap: 'break-word',
            maxWidth: '100%',
        },
        paginationWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'stretch',
        },
    } as const);
