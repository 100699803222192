import {
    useSensor,
    useSensors,
    TouchSensor,
    DndContext,
    closestCenter,
    DragEndEvent,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { ComponentType } from 'react';

import {
    MouseSensor,
    KeyboardSensor,
} from 'src/components/Configuration/DragAndDropV2/DragAndDropV2.utils';
import { dragAndDropOptionStyles } from 'src/styles/commonSelect.styles';

export interface GenericDnDProps {
    data: any[];
    ItemComponent: ComponentType<any>;
    additionalProps?: { [id: string]: any };
    onDragComplete: (data: any[]) => void;
    disabled?: boolean;
    context?: React.Context<any>;
}

export interface GenericDndItemProps {
    item: any;
    onClick?: () => void;
    children: any;
    disabled?: boolean;
    context: React.Context<any>;
}

export const reindexDataToArray = (data: any[]) =>
    sortBy(data, (x) => x.index).map((a, i) => ({ ...a, index: i }));
export const reindexDataToObject = (data: any[]) =>
    sortBy(data, (x) => x.index).reduce((a, v, i) => ({ ...a, [v.id]: { ...v, index: i } }), {});

export const GenericDragAndDrop = (props: GenericDnDProps) => {
    const {
        data,
        ItemComponent,
        additionalProps,
        onDragComplete,
        disabled = false,
        context = React.createContext<any>({}),
    } = props;
    const [dataState, setDataState] = React.useState(data);

    React.useEffect(() => {
        setDataState(data);
    }, [data]);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor),
    );

    const onDragEnd = React.useCallback(
        (event: DragEndEvent) => {
            const sectionCopy = [...dataState];

            const dragId = event?.active?.id;
            const overId = event?.over?.id;

            if (event.over && dragId !== overId) {
                const oldIndex = event?.active?.data?.current?.sortable?.index;
                const overIndex = event?.over?.data?.current?.sortable?.index;

                const source = sectionCopy[oldIndex];

                sectionCopy.splice(oldIndex, 1);
                sectionCopy.splice(overIndex, 0, source);

                setDataState(sectionCopy);
                onDragComplete(sectionCopy);
            }
        },
        [dataState],
    );

    return (
        <DndContext
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
            sensors={sensors}
        >
            <SortableContext
                items={dataState.map((x) => x.id)}
                strategy={verticalListSortingStrategy}
            >
                {dataState.map((x: any) => (
                    <GeneralDragAndDropItem
                        key={x.id}
                        item={x}
                        disabled={disabled}
                        context={context}
                    >
                        <ItemComponent {...additionalProps} dataItem={x} />
                    </GeneralDragAndDropItem>
                ))}
            </SortableContext>
        </DndContext>
    );
};

const GeneralDragAndDropItem = (props: GenericDndItemProps) => {
    const { item, onClick, children, disabled = false, context } = props;
    const { selectedItem } = React.useContext(context);

    const [isDragging, setIsDragging] = React.useState(false);

    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
        useSortable({
            id: item.id,
            disabled,
        });

    const sx = {
        transform: CSS.Translate.toString(transform),
        transition,
        display: 'flex',
        minHeight: '48px',
        width: 'calc(100% - 2px)',
        borderRadius: '3px',
        border:
            selectedItem?.[`${selectedItem?.typePrefix}Id`] === item.id
                ? '1px solid #265C7F'
                : '1px solid #E1E6E6',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        flexWrap: 'nowrap' as const,
        marginBottom: '8px',
        cursor: 'pointer',
        minWidth: '400px',
        position: 'relative' as const,
        zIndex: 1,
    };

    const onSideClick = React.useCallback(() => onClick?.(), [onClick]);

    const onFocus = React.useCallback(() => {
        setIsDragging(true);
    }, []);

    const onBlur = React.useCallback(() => {
        setIsDragging(false);
    }, []);

    return (
        <Grid
            ref={setNodeRef}
            sx={{ ...sx, zIndex: `${isDragging ? 400 : 1} !important` }}
            {...(onClick && { onClick })}
        >
            <Grid
                container
                flexDirection="row"
                flexWrap="nowrap"
                sx={{
                    ...dragAndDropOptionStyles,
                    marginLeft: '8px',
                    width: '100%',
                    height: 'auto',
                    alignItems: 'flex-start',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onSideClick}
            >
                <Grid ref={setActivatorNodeRef} {...attributes} {...listeners}>
                    <DragHandle sx={{ cursor: 'grab', paddingTop: '12px' }} />
                </Grid>
                {children}
            </Grid>
        </Grid>
    );
};
