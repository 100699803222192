import { Order } from '@local/web-design-system';

import { SortKeyOrder } from 'state-domains/types';

export interface StringSortProps {
    objectList: any[];
    key: string;
    order?: Order;
}

const parseDate = (date: string) => new Date(date);

export function timestampSort(a: string, b: string): number {
    let aDate;
    let bDate;
    try {
        aDate = parseDate(a);
        bDate = parseDate(b);
    } catch (e) {
        return 0;
    }
    if (aDate < bDate) return -1;
    if (aDate > bDate) return 1;
    return 0;
}

export function numberSort(a: number, b: number): number {
    return a - b;
}

export const caseInsensitiveSort = (a: string, b: string): number => {
    const stringA = a ? String(a).toLowerCase() : '';
    const stringB = b ? String(b).toLowerCase() : '';
    if (stringA < stringB) return -1;
    if (stringA > stringB) return 1;
    return 0;
};

export const alphanumericalSort = (a: string, b: string): number => {
    const numA = !isNaN(Number(a));
    const numB = !isNaN(Number(b));

    if (numA && numB) {
        return Number(a) - Number(b);
    }

    if (numA) return -1;
    if (numB) return 1;

    return caseInsensitiveSort(a, b);
};

export const dateSort = (a: any, b: any): number => {
    const t1 = new Date(a).getTime();
    const t2 = new Date(b).getTime();
    let d1 = t1;
    let d2 = t2;
    if (isNaN(t1)) {
        if (typeof a === 'object') {
            d1 = new Date(a.date).getTime();
            if (isNaN(d1)) return -1;
        } else {
            return -1;
        }
    }

    if (isNaN(t2)) {
        if (typeof b === 'object') {
            d2 = new Date(b.date).getTime();
            if (isNaN(d2)) return 1;
        } else {
            return 1;
        }
    }

    return d1 - d2;
};

/**
 * Used for sorting string arrays alphabetically.
 * This is done by comparing the first value of each array.
 */
export const stringArraySort = (a: string[], b: string[]): number =>
    caseInsensitiveSort(a[0] || '', b[0] || '');

export function stringSort(props: StringSortProps) {
    // sorts a list of objects by key id
    const { objectList = [], key = '', order = 'asc' } = props;
    return objectList.sort((a: any, b: any) => {
        const { [key]: aKey = '' } = a;
        const { [key]: bKey = '' } = b;
        if (order === 'asc') {
            return caseInsensitiveSort(aKey, bKey);
        }
        if (order === 'desc') {
            return caseInsensitiveSort(bKey, aKey);
        }
        // will not sort
        return 0;
    });
}

/**
 * Will create a sorter that provides default values to a null/undefined input.
 */
export function withDefault<T>(
    defaultValue: T,
    sortFunction: (a: T, b: T) => number,
): (a: T, b: T) => number {
    return (a: T, b: T) => {
        const valA = a || defaultValue;
        const valB = b || defaultValue;
        return sortFunction(valA, valB);
    };
}
function getSortFunction(type?: object): Function | null {
    if (type === Date) {
        return dateSort;
    }
    if (type === Number) {
        return numberSort;
    }
    return caseInsensitiveSort;
}

export function applySortingToAnArray<T extends object>(
    target: T[],
    type?: object,
    sortingKeyOrder?: SortKeyOrder,
    sortingFunction?: Function,
): T[] {
    if (!sortingKeyOrder) {
        return target;
    }
    const { key, order } = sortingKeyOrder;

    if (key === undefined || !Array.isArray(target)) {
        return target;
    }
    const sortFunc = sortingFunction ?? getSortFunction(type);

    if (!sortFunc) {
        return target;
    }
    const shallowCopy = [...target];
    shallowCopy.sort((item1: T, item2: T) => {
        const sortedResult = sortFunc((item1 as any)[key], (item2 as any)[key]);
        if (order === Order.DESCENDING) {
            return sortedResult * -1;
        }
        return sortedResult;
    });

    return shallowCopy;
}
