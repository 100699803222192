import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildAccountPreferencesUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { AccountPreference } from '../types';

export const loadAccountPreferences = (): Observable<AccountPreference> => {
    const url = buildAccountPreferencesUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }) => observableOf(convertToCamel<AccountPreference>(response))),
    );
};
