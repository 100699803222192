import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { imagoDisconnectUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ImagoConnectionResponse } from '../types';

export const imagoDisconnect = (): Observable<{ result: string }> => {
    const url = imagoDisconnectUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ImagoConnectionResponse>) =>
            observableOf({ result: response.value }),
        ),
    );
};
