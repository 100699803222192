import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { authState } from 'state-domains/domain';
import { isPending } from 'state-domains/utils';

import { UserPortalState } from 'src/state';

const mapStateToProps = (state: UserPortalState) => {
    const { authentication } = state;
    const { loginRedirectUrl, logoutRedirectUrl } = authentication;
    return {
        loginRedirectUrl,
        logoutRedirectUrl,
        isPending: isPending(authentication.oauth),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    const {
        actions: { oauth2FetchLoginUrl, oauth2FetchLogoutUrl },
    } = authState;
    return {
        oauth2FetchLoginUrl: bindActionCreators(oauth2FetchLoginUrl, dispatch),
        oauth2FetchLogoutUrl: bindActionCreators(oauth2FetchLogoutUrl, dispatch),
    };
};

export const connectToState = (component: React.ComponentType) =>
    connect(mapStateToProps, mapDispatchToProps)(component);
