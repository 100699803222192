import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildRecentProjectsUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { RecentProject } from '../types';

export const loadRecentProjects = (): Observable<RecentProject[]> => {
    const url = buildRecentProjectsUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }) => {
            const recentProjResponse = response['recent projects'];
            const convertedArray = convertToCamel<RecentProject[]>(recentProjResponse);
            return observableOf(convertedArray);
        }),
    );
};
