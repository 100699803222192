import { Observable, of, throwError } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildPollMessagesUrl } from '../../../network';
import { sendRetrieveRequest, sendUpdateRequest } from '../../../utils';
import { PollQueryProps, PollMessagesPayload } from '../types';
import { translateMessagePoll, getRequestOverrides } from './translators';
import { APIPollMessages } from './types';

const TIMEOUT_CODE = 408;

function responseHandler(
    response: APIPollMessages,
    limit: number,
): Observable<PollMessagesPayload> {
    const result = translateMessagePoll(response);
    return of({ ...result, limit });
}

export function redactTimeoutError(error: AjaxError): Observable<AjaxResponse<any>> {
    // Bypass the call to Sentry for known timeout errors
    const doNotLog = error.status === TIMEOUT_CODE;
    // `withToken` will catch this error and will log based on `doNotLog`
    return throwError(() => ({ ...error, doNotLog }));
}

export function pollMessages(
    token: string,
    audienceUid: string,
    queryProps: PollQueryProps,
): Observable<PollMessagesPayload> {
    const { url, limit } = buildPollMessagesUrl(audienceUid, queryProps);
    const ajax = sendRetrieveRequest(url, {
        ...getRequestOverrides(token),
        deferCatch: redactTimeoutError,
    });
    return ajax.pipe(switchMap(({ response }) => responseHandler(response, limit)));
}

export function messagesSeen(token: string, url: string): Observable<boolean> {
    const ajax = sendUpdateRequest(url, {}, getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}
