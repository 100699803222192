import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadListWithIdUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { List } from '../types';

export const loadList = (id: string): Observable<List> => {
    const url = buildLoadListWithIdUrl(id);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<List>) => {
            const result = convertToCamel<List>(response);
            return observableOf(result);
        }),
    );
};
