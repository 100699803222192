import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { FilesForGroup } from 'state-domains/domain/drillhole';

import { buildDataFileGroupFilesUrl, buildHeaderFileGroupFilesUrl } from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRetrieveRequest } from '../../../utils';

export const getFilesForGroup = (
    type: ACTIVITY_TYPES,
    fileGroupId: string,
    header = false,
): Observable<FilesForGroup> => {
    const url = header
        ? buildHeaderFileGroupFilesUrl(fileGroupId)
        : buildDataFileGroupFilesUrl(fileGroupId, type);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<FilesForGroup>) => {
            const result = convertToCamelWithSpecialParameters<FilesForGroup>(response, {
                id: 'uid',
            });
            return observableOf(result);
        }),
    );
};
