import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './CollarSkeleton.styles';

export function CollarSkeleton() {
    const { classes } = useStyles();

    return (
        <Grid className={classes.dataGridContainer}>
            <Grid
                container
                flexDirection="column"
                flexWrap="nowrap"
                className={classes.collarContainer}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    marginBottom="16px"
                >
                    <Grid item>
                        <Skeleton
                            sx={{
                                height: '40px',
                                width: '460px',
                                backgroundColor: '#F2F2F2',
                                transform: 'none',
                                borderRadius: '0',
                            }}
                        />
                    </Grid>
                    <Grid item paddingLeft="0px !important">
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            justifyContent="flex-end"
                            width="382px"
                        >
                            {Array(3)
                                .fill(true)
                                .map((_, idx) => (
                                    <Skeleton
                                        sx={{
                                            height: '40px',
                                            width: '122px',
                                            backgroundColor: '#F2F2F2',
                                            marginLeft: idx > 0 ? '8px' : '0px',
                                            transform: 'none',
                                            borderRadius: '0',
                                        }}
                                        key={idx}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.collarHeader} />
                <Grid className={classes.collarContentContainer}>
                    {Array(100)
                        .fill(true)
                        .map((_, idx) => (
                            <Skeleton
                                key={uuidv4()}
                                variant="rectangular"
                                animation="wave"
                                className={classes.collarItem}
                                sx={{ backgroundColor: `${idx % 2 === 0 ? '#F9F9F9' : '#FFFFFF'}` }} // Alternates row colour
                            />
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
