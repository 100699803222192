import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildBulkReadMessagesUrl, buildBulkUnreadMessagesUrl } from '../../../network';
import { sendCreateRequest, sendUpdateRequest } from '../../../utils';
import { getRequestOverrides } from './translators';

export function bulkReadMessages(
    token: string,
    audienceId: string,
    uuids: string[],
): Observable<boolean> {
    const url = buildBulkReadMessagesUrl(audienceId);
    const ajax = sendCreateRequest(url, { uuids }, 'json', getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}

export function bulkUnreadMessages(
    token: string,
    audienceId: string,
    uuids: string[],
): Observable<boolean> {
    const url = buildBulkUnreadMessagesUrl(audienceId);
    const ajax = sendCreateRequest(url, { uuids }, 'json', getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}

export function updateMessage(token: string, url: string, read: boolean): Observable<boolean> {
    const ajax = sendUpdateRequest(url, { read }, getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}
