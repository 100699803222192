import { makeStyles } from 'tss-react/mui';

export const styles = () => ({
    projectListItem: {
        height: '57px',
        width: '100%',
        borderBottom: '1px solid #EBEBEB',
    },
    projectHeader: {
        width: 'calc(100% + 2px)',
        marginLeft: '-1px',
        marginTop: '-1px',
        height: '61px',
        backgroundColor: '#444243',
    },
});

export const useStyles = makeStyles()(styles);
