import { createSelector } from 'reselect';

import { FileObject } from 'state-domains/domain/drillhole';
import { ProjectBoundary, UserPermissions } from 'state-domains/domain/project';
import {
    GridEntry,
    HeaderType,
    Projection,
    SampleWorkflow,
} from 'state-domains/domain/subscription/types';

import { AsyncState, AsyncStateError, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    ConfigurationList,
    ConfigurationListState,
    ConfigurationState,
    ConfigurationTableState,
    ConfigurationHeaderState,
    ConfigurationHeaderFieldTypeState,
    ConfigurationActivitiesState,
    ConfigurationProjectsState,
    ConfigurationLoggingState,
    DefaultLabServiceCollections,
    DefaultLabServiceCollection,
    ConfigurationDefaultLabServicesState,
    ConfigurationCategory,
    ConfigurationGridState,
    GridPreviewData,
    ConfigurationReducedState,
    UserItem,
    ConfigurationActivityGroup,
} from '../types';

const configurationState = (state: Partial<ShimState>): ConfigurationState => {
    const { configuration = {} as ConfigurationState } = state || {};
    return configuration;
};

// As part of refactoring, use this function to create all error selectors
const createErrorSelector = (stateSelector: (state: any) => AsyncState) =>
    createSelector(stateSelector, ({ error }: AsyncState): AsyncStateError => error ?? {});

const configurationUsersState = createSelector(
    configurationState,
    ({
        usersState = {} as ConfigurationReducedState,
    }: ConfigurationState): ConfigurationReducedState => usersState,
);

const configurationUsers = createSelector(
    configurationUsersState,
    ({ items = [] }: ConfigurationReducedState): UserItem[] => items,
);

const configurationUsersLoadingState = createSelector(
    configurationUsersState,
    ({ loadingState }: ConfigurationReducedState) => loadingState,
);

const configurationUsersOperationState = createSelector(
    configurationUsersState,
    ({ operationState }: ConfigurationReducedState) => operationState,
);

const configurationUsersLoadingStateError = createErrorSelector(configurationUsersLoadingState);
const configurationUsersOperationStateError = createErrorSelector(configurationUsersOperationState);

const configurationListState = createSelector(
    configurationState,
    ({
        listsState: configurationListsData = {} as ConfigurationListState,
    }: ConfigurationState): ConfigurationListState => configurationListsData,
);
const configurationTableState = createSelector(
    configurationState,
    ({ tablesState: csTablesState }: ConfigurationState): ConfigurationTableState => csTablesState,
);

const configurationHeaderState = createSelector(
    configurationState,
    ({ headersState: csHeadersState }: ConfigurationState): ConfigurationHeaderState =>
        csHeadersState,
);

const configurationActivityState = createSelector(
    configurationState,
    ({
        activitiesState: configurationActivitiesData = {} as ConfigurationActivitiesState,
    }: ConfigurationState): ConfigurationActivitiesState => configurationActivitiesData,
);

const configurationGridState = createSelector(
    configurationState,
    ({ gridsState = {} as ConfigurationGridState }: ConfigurationState): ConfigurationGridState =>
        gridsState,
);

const configurationProjectState = createSelector(
    configurationState,
    ({
        projectsState: configurationProjectsData = {} as ConfigurationProjectsState,
    }: ConfigurationState): ConfigurationProjectsState => configurationProjectsData,
);

const configurationHeaderAddFieldState = createSelector(
    configurationState,
    ({ headersState: { addFieldState } }: ConfigurationState): AsyncState => addFieldState,
);

const configurationLists = createSelector(
    configurationListState,
    ({ items: configurationListData = [] }: ConfigurationListState): ConfigurationList[] =>
        configurationListData,
);

const configurationListsImportListState = createSelector(
    configurationListState,
    ({ importState }: ConfigurationListState) => importState,
);

const configurationTablesImportTableState = createSelector(
    configurationTableState,
    ({ importState }: ConfigurationTableState) => importState,
);
const configurationTablesImportTableStateError = createSelector(
    configurationTablesImportTableState,
    ({ error }): AsyncStateError => error ?? {},
);

const configurationListsImportListStateImports = createSelector(
    configurationListsImportListState,
    ({ imports }): any[] => imports ?? [],
);

const configurationListsImportListStateError = createSelector(
    configurationListsImportListState,
    ({ error }): AsyncStateError => error ?? {},
);
const configurationListsExportListState = createSelector(
    configurationListState,
    ({ exportState }: ConfigurationListState) => exportState,
);

const configurationListsExportListStateError = createSelector(
    configurationListsExportListState,
    ({ error }): AsyncStateError => error ?? {},
);

const configurationListsFileState = createSelector(
    configurationListState,
    ({ fileState }): { groupId: string; files: { [id: string]: FileObject } } & AsyncState =>
        fileState,
);

const configurationListsEmptyFileState = createSelector(
    configurationListState,
    ({ emptyFileGroupState }): { [id: string]: boolean } => emptyFileGroupState,
);

const configurationTableViews = createSelector(
    configurationTableState,
    ({ items: csItems }): any[] => csItems,
);
const configurationHeaderFieldTypesState = createSelector(
    configurationState,
    ({ headerFieldTypesState }: ConfigurationState): ConfigurationHeaderFieldTypeState =>
        headerFieldTypesState,
);
const configurationHeaderFieldTypes = createSelector(
    configurationHeaderFieldTypesState,
    ({
        items: configurationHeaderFieldTypeData = [],
    }: ConfigurationHeaderFieldTypeState): HeaderType[] => configurationHeaderFieldTypeData,
);
const configurationHeaders = createSelector(
    configurationHeaderState,
    ({ items: configurationHeaderData = [] }: ConfigurationHeaderState): any[] =>
        configurationHeaderData,
);

const configurationDataGridState = createSelector(
    configurationState,
    ({
        configurationDataGrid: configurationDataGridState = {} as AsyncState,
    }: ConfigurationState): AsyncState => configurationDataGridState,
);
const configurationTableDeleteState = createSelector(
    configurationTableState,
    ({ deleteState }: ConfigurationTableState): AsyncState => deleteState ?? {},
);

const configurationTablesExportState = createSelector(
    configurationTableState,
    ({ exportState }: ConfigurationTableState) => exportState,
);

const configurationModifyColumnState = createSelector(
    configurationState,
    ({
        modifyColumnState: configurationModifyColumnState = {} as AsyncState,
    }: ConfigurationState): AsyncState => configurationModifyColumnState,
);
const configurationModifyColumnStateError = createSelector(
    configurationModifyColumnState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationTableDeleteStateError = createSelector(
    configurationTableDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationTablesAddState = createSelector(
    configurationTableState,
    ({ addState }: ConfigurationTableState): AsyncState => addState ?? {},
);
const configurationAddFieldTypesState = createSelector(
    configurationHeaderFieldTypesState,
    ({ addState }: ConfigurationHeaderFieldTypeState): AsyncState => addState ?? {},
);
const configurationAddFieldTypesStateError = createSelector(
    configurationAddFieldTypesState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationListDeleteState = createSelector(
    configurationListState,
    ({ deleteState }: ConfigurationListState): AsyncState => deleteState ?? {},
);

const configurationActivityDeleteState = createSelector(
    configurationActivityState,
    ({ deleteState }: ConfigurationActivitiesState): AsyncState => deleteState ?? {},
);

const configurationActivityDeleteStateError = createSelector(
    configurationActivityDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationGridDeleteState = createSelector(
    configurationGridState,
    ({ deleteState }: ConfigurationGridState): AsyncState => deleteState ?? {},
);

const configurationGridDeleteStateError = createSelector(
    configurationGridDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationHeaderDeleteState = createSelector(
    configurationHeaderState,
    ({ deleteState }: ConfigurationHeaderState): AsyncState => deleteState ?? {},
);
const configurationHeaderDeleteStateError = createSelector(
    configurationHeaderDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationFieldDeleteState = createSelector(
    configurationHeaderFieldTypesState,
    ({ deleteState }: ConfigurationHeaderFieldTypeState): AsyncState => deleteState ?? {},
);
const configurationFieldDeleteStateError = createSelector(
    configurationFieldDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationFieldAddState = createSelector(
    configurationHeaderFieldTypesState,
    ({ addState }: ConfigurationHeaderFieldTypeState): AsyncState => addState ?? {},
);
const configurationFieldAddStateError = createSelector(
    configurationFieldAddState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationListDeleteStateError = createSelector(
    configurationListDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationListCloneState = createSelector(
    configurationListState,
    ({ cloneState }: ConfigurationListState): AsyncState => cloneState ?? {},
);
const configurationListClonedId = createSelector(
    configurationListCloneState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);
const configurationListCloneStateError = createSelector(
    configurationListCloneState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationListAddState = createSelector(
    configurationListState,
    ({ addState }: ConfigurationListState): AsyncState => addState ?? {},
);

const configurationTablesAddStateError = createSelector(
    configurationTablesAddState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationListCreatedId = createSelector(
    configurationListAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);
const configurationListEditState = createSelector(
    configurationListState,
    ({ editState }: ConfigurationListState): AsyncState => editState ?? {},
);
const configurationHeaderAddState = createSelector(
    configurationHeaderState,
    ({ addState }: ConfigurationHeaderState): AsyncState => addState ?? {},
);
const configurationHeaderAddStateError = createSelector(
    configurationHeaderAddState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationHeaderCreatedId = createSelector(
    configurationHeaderAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);
const configurationHeaderFieldTypeCreatedId = createSelector(
    configurationAddFieldTypesState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);
const configurationTableAddState = createSelector(
    configurationTableState,
    ({ addState }: ConfigurationTableState): AsyncState => addState ?? {},
);
const configurationTableEditState = createSelector(
    configurationTableState,
    ({ editState }: ConfigurationTableState): AsyncState => editState ?? {},
);
const configurationTableEditStateError = createSelector(
    configurationTableEditState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationTableCreatedId = createSelector(
    configurationTableAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationHeaderEditState = createSelector(
    configurationHeaderState,
    ({ editState }: ConfigurationHeaderState): AsyncState => editState ?? {},
);
const configurationHeaderEditStateError = createSelector(
    configurationHeaderEditState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationHeaderAddFieldStateError = createSelector(
    configurationHeaderAddFieldState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationHeaderFocusId = createSelector(
    configurationHeaderState,
    ({ focusId }): string => focusId ?? '',
);

const configurationActivities = createSelector(
    configurationActivityState,
    ({
        items: configurationActivitiesData = [],
    }: ConfigurationActivitiesState): ConfigurationList[] => configurationActivitiesData,
);

const configurationGrids = createSelector(
    configurationGridState,
    ({ items = [] }: ConfigurationLoggingState): GridEntry[] => items,
);

const configurationProjectionState = createSelector(
    configurationGridState,
    ({
        projectionState,
    }: ConfigurationGridState): { projections: { [id: string]: Projection } } & AsyncState =>
        projectionState,
);

const configurationDefaultProjections = createSelector(
    configurationProjectionState,
    ({
        projections,
    }: { projections: { [id: string]: Projection } } & AsyncState): { [id: string]: Projection } =>
        projections,
);

const configurationDefaultGeoProjections = createSelector(
    configurationDefaultProjections,
    (projections: { [id: string]: Projection }): Projection[] =>
        Object.values(projections)
            .filter((x) => !!x.isGeo)
            .sort((a, b) => a.description.localeCompare(b.description)),
);

const configurationProjects = createSelector(
    configurationProjectState,
    ({ items: configurationProjectsData = [] }: ConfigurationProjectsState): ConfigurationList[] =>
        configurationProjectsData,
);

const configurationActivityCloneState = createSelector(
    configurationActivityState,
    ({ cloneState }: ConfigurationActivitiesState): AsyncState => cloneState ?? {},
);

const configurationActivityCloneStateError = createSelector(
    configurationActivityCloneState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationActivityClonedId = createSelector(
    configurationActivityCloneState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationActivityAddState = createSelector(
    configurationActivityState,
    ({ addState }: ConfigurationActivitiesState): AsyncState => addState ?? {},
);

const configurationActivityEditState = createSelector(
    configurationActivityState,
    ({ editState }: ConfigurationActivitiesState): AsyncState => editState ?? {},
);

const configurationActivityEditStateError = createSelector(
    configurationActivityEditState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationActivityCreatedId = createSelector(
    configurationActivityAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationActivitySampleWorkflowState = createSelector(
    configurationState,
    ({ sampleWorkflow }): ConfigurationLoggingState => sampleWorkflow,
);

const configurationSampleWorkflows = createSelector(
    configurationActivitySampleWorkflowState,
    ({ items }): SampleWorkflow[] => items,
);

const configurationRankedColumnState = createSelector(
    configurationState,
    ({ rankedColumnsState }: ConfigurationState) => rankedColumnsState,
);

const configurationPossibleColumns = createSelector(
    configurationRankedColumnState,
    ({ columns, rankedColumnsMap, candidateColumnsMap }) => columns,
);

const configurationRankedColumnMap = createSelector(
    configurationRankedColumnState,
    ({ rankedColumnsMap }) => rankedColumnsMap,
);

const configurationCandidateColumnMap = createSelector(
    configurationRankedColumnState,
    ({ candidateColumnsMap }) => candidateColumnsMap,
);

const configurationRankedColumnStateError = createSelector(
    configurationRankedColumnState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationProjectCloneState = createSelector(
    configurationProjectState,
    ({ cloneState }: ConfigurationProjectsState): AsyncState => cloneState ?? {},
);

const configurationProjectCloneStateError = createSelector(
    configurationProjectCloneState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationProjectClonedId = createSelector(
    configurationProjectCloneState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationProjectDeleteState = createSelector(
    configurationProjectState,
    ({ deleteState }: ConfigurationProjectsState): AsyncState => deleteState ?? {},
);

const configurationProjectDeleteStateError = createSelector(
    configurationProjectDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationAddProjectState = createSelector(
    configurationProjectState,
    ({ addState: psAddState }: ConfigurationProjectsState): AsyncState => psAddState,
);

const configurationEditProjectState = createSelector(
    configurationProjectState,
    ({ editState: psEditState }: ConfigurationProjectsState): AsyncState => psEditState,
);

const configurationEditProjectStateError = createSelector(
    configurationProjectState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const getNewProjectId = createSelector(
    configurationProjectState,
    ({ addState: psAddState }: ConfigurationProjectsState): string => psAddState?.id,
);

const convertedProjectBoundariesState = createSelector(
    configurationProjectState,
    ({ coordinateState: psCoordinateState }: ConfigurationProjectsState): AsyncState =>
        psCoordinateState,
);

const convertedProjectBoundaries = createSelector(
    configurationProjectState,
    ({
        coordinateState: psCoordinateState,
    }: ConfigurationProjectsState): ProjectBoundary[] | null => psCoordinateState?.convertedCoords,
);

const configurationProjectActivitiesState = createSelector(
    configurationProjectState,
    ({ projectActivitiesState: activitiesState }: ConfigurationProjectsState): AsyncState =>
        activitiesState,
);

const configurationProjectActivitiesStateError = createSelector(
    configurationProjectActivitiesState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const getCoordinateDecimalState = createSelector(
    configurationProjectState,
    ({ showCoordsInDecimal }: ConfigurationProjectsState): boolean => showCoordsInDecimal,
);

const configurationProjectUserPermissionsState = createSelector(
    configurationProjectState,
    ({
        projectUserPermissionState: usersPermissionState,
    }: ConfigurationProjectsState): { userPermissions: UserPermissions[] } & AsyncState =>
        usersPermissionState,
);

const configurationProjectUserPermissions = createSelector(
    configurationProjectUserPermissionsState,
    ({ userPermissions }: { userPermissions: UserPermissions[] } & AsyncState): UserPermissions[] =>
        userPermissions ?? [],
);

const configurationSampleWorkflowAddState = createSelector(
    configurationActivitySampleWorkflowState,
    ({ addState }: ConfigurationLoggingState): AsyncState => addState ?? {},
);

const configurationSampleWorkflowEditState = createSelector(
    configurationActivitySampleWorkflowState,
    ({ editState }: ConfigurationLoggingState): AsyncState => editState ?? {},
);

const configurationSampleWorkflowEditStateError = createSelector(
    configurationActivitySampleWorkflowState,
    ({ editState }: ConfigurationLoggingState): AsyncStateError => editState?.error ?? {},
);

const configurationSampleWorkflowCreatedId = createSelector(
    configurationSampleWorkflowAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationSampleWorkflowCloneState = createSelector(
    configurationActivitySampleWorkflowState,
    ({ cloneState }: ConfigurationLoggingState): AsyncState => cloneState ?? {},
);
const configurationSampleWorkflowCloneStateError = createSelector(
    configurationSampleWorkflowCloneState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationSampleWorkflowDeleteState = createSelector(
    configurationActivitySampleWorkflowState,
    ({ deleteState }: ConfigurationLoggingState): AsyncState => deleteState ?? {},
);
const configurationSampleWorkflowDeleteStateError = createSelector(
    configurationSampleWorkflowDeleteState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);
const configurationSampleWorkflowClonedId = createSelector(
    configurationSampleWorkflowCloneState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationDefaultLabServicesState = createSelector(
    configurationState,
    ({
        defaultLabServicesState:
            configurationDefaultLabServicesState = {} as ConfigurationDefaultLabServicesState,
    }: ConfigurationState): { labServices: DefaultLabServiceCollections } & AsyncState =>
        configurationDefaultLabServicesState,
);

const defaultLabServices = createSelector(
    configurationDefaultLabServicesState,
    ({
        labServices,
    }: {
        labServices: DefaultLabServiceCollections;
    }): DefaultLabServiceCollections => labServices,
);

const defaultLabServicesByList = (listId: string) =>
    createSelector(
        defaultLabServices,
        (subLabServices: DefaultLabServiceCollections): DefaultLabServiceCollection =>
            subLabServices?.[listId] ?? {},
    );

const configurationGridAddState = createSelector(
    configurationGridState,
    ({ addState }: ConfigurationLoggingState): AsyncState => addState ?? {},
);

const configurationGridEditState = createSelector(
    configurationGridState,
    ({ editState }: ConfigurationLoggingState): AsyncState => editState ?? {},
);

const configurationGridEditErrorState = createSelector(
    configurationGridEditState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationGridAddErrorState = createSelector(
    configurationGridAddState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const configurationGridCreatedId = createSelector(
    configurationGridAddState,
    ({ id }: { id: string } & AsyncState): string => id ?? '',
);

const configurationGridPreviewState = createSelector(
    configurationGridState,
    ({ previewState }: ConfigurationGridState): AsyncState => previewState ?? {},
);

const gridPreviewData = createSelector(
    configurationGridPreviewState,
    ({ data }: { data: GridPreviewData | {} } & AsyncState): any => data ?? {},
);

const configurationCategoriesState = createSelector(
    configurationState,
    ({ categoriesState }): ConfigurationReducedState => categoriesState,
);

const configurationCategoriesLoadState = createSelector(
    configurationCategoriesState,
    ({ loadingState }): AsyncState => loadingState,
);

const configurationCategoriesLoadError = createSelector(
    configurationCategoriesLoadState,
    ({ error }): AsyncStateError => error ?? {},
);

const configurationCategories = createSelector(
    configurationCategoriesState,
    ({ items }): ConfigurationCategory[] => items ?? [],
);

const configurationCategoriesShouldReload = createSelector(
    configurationCategoriesState,
    ({ shouldReload }): boolean => shouldReload,
);

const configurationCategoriesOperationState = createSelector(
    configurationCategoriesState,
    ({ operationState }): AsyncState => operationState,
);

const configurationCategoriesOperationError = createSelector(
    configurationCategoriesOperationState,
    ({ error }): AsyncStateError => error ?? {},
);

const configurationActivityGroupsState = createSelector(
    configurationState,
    ({
        activityGroupsState: configurationActivityGroups = {} as ConfigurationReducedState,
    }: ConfigurationState): ConfigurationReducedState => configurationActivityGroups,
);

const configurationActivityGroupsLoadingState = createSelector(
    configurationActivityGroupsState,
    ({ loadingState }: ConfigurationReducedState): AsyncState => loadingState ?? {},
);

const configurationActivityGroups = createSelector(
    configurationActivityGroupsState,
    ({
        items: configurationActivityGroupData = [],
    }: ConfigurationReducedState): ConfigurationActivityGroup[] => configurationActivityGroupData,
);

const configurationActivityGroupsOperationState = createSelector(
    configurationActivityGroupsState,
    ({ operationState }: ConfigurationReducedState): AsyncState => operationState ?? {},
);

const configurationActivityGroupsOperationStateError = createSelector(
    configurationActivityGroupsOperationState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const fieldsShouldReload = createSelector(
    configurationHeaderFieldTypesState,
    ({ shouldReload }: ConfigurationHeaderFieldTypeState): boolean => shouldReload,
);

const activityGroupsShouldReload = createSelector(
    configurationActivityGroupsState,
    ({ shouldReload }: ConfigurationReducedState): boolean => shouldReload,
);

const gridsShouldReload = createSelector(
    configurationGridState,
    ({ shouldReload }: ConfigurationGridState): boolean => shouldReload,
);

export const selectors = {
    configurationState,
    configurationUsers,
    configurationUsersOperationState,
    configurationUsersLoadingState,
    configurationListState,
    configurationLists,
    configurationTableViews,
    configurationHeaderFieldTypesState,
    configurationHeaderFieldTypes,
    configurationHeaders,
    configurationDataGridState,
    configurationAddFieldTypesStateError,
    configurationAddFieldTypesState,
    configurationHeaderFieldTypeCreatedId,
    configurationActivities,
    configurationGrids,
    configurationDefaultProjections,
    configurationProjectionState,
    configurationProjects,
    configurationDefaultGeoProjections,

    isPending: isPendingSelector(configurationState),
    isFailed: isFailedSelector(configurationState),
    isComplete: isCompleteSelector(configurationState),

    isConfigurationListsPending: isPendingSelector(configurationListState),
    isConfigurationListsComplete: isCompleteSelector(configurationListState),
    isConfigurationListsFailed: isFailedSelector(configurationListState),
    isConfigurationTablesPending: isPendingSelector(configurationTableState),
    isConfigurationTablesComplete: isCompleteSelector(configurationTableState),
    isConfigurationTablesFailed: isFailedSelector(configurationTableState),
    isConfigurationHeaderFieldTypesPending: isPendingSelector(configurationHeaderFieldTypesState),
    isConfigurationHeaderFieldTypesComplete: isCompleteSelector(configurationHeaderFieldTypesState),
    isConfigurationHeaderFieldTypesFailed: isFailedSelector(configurationHeaderFieldTypesState),
    isConfigurationHeadersPending: isPendingSelector(configurationHeaderState),
    isConfigurationHeadersComplete: isCompleteSelector(configurationHeaderState),
    isConfigurationHeadersFailed: isFailedSelector(configurationHeaderState),
    isConfigurationActivitiesPending: isPendingSelector(configurationActivityState),
    isConfigurationActivitiesComplete: isCompleteSelector(configurationActivityState),
    isConfigurationActivitiesFailed: isFailedSelector(configurationActivityState),
    isConfigurationGridsPending: isPendingSelector(configurationGridState),
    isConfigurationGridsComplete: isCompleteSelector(configurationGridState),
    isConfigurationGridsFailed: isFailedSelector(configurationGridState),
    isDefaultProjectionsPending: isPendingSelector(configurationProjectionState),
    isDefaultProjectionsComplete: isCompleteSelector(configurationProjectionState),
    isDefaultProjectionsFailed: isFailedSelector(configurationProjectionState),
    isConfigurationProjectsPending: isPendingSelector(configurationProjectState),
    isConfigurationProjectsComplete: isCompleteSelector(configurationProjectState),
    isConfigurationProjectsFailed: isFailedSelector(configurationProjectState),

    configurationListDeleteState,
    configurationListCloneState,
    configurationListDeleteStateError,
    configurationTablesAddState,
    configurationHeaderAddState,
    configurationHeaderDeleteState,
    configurationActivityDeleteState,
    configurationActivityDeleteStateError,
    configurationGridDeleteState,
    configurationGridDeleteStateError,
    configurationActivityCloneState,
    configurationActivityCloneStateError,
    configurationActivityClonedId,
    configurationActivityEditState,
    configurationActivityEditStateError,
    isDeleteConfigurationActivityComplete: isCompleteSelector(configurationActivityDeleteState),
    isDeleteConfigurationActivityFailed: isFailedSelector(configurationActivityDeleteState),
    isDeleteConfigurationGridComplete: isCompleteSelector(configurationGridDeleteState),
    isCloneConfigurationActivityComplete: isCompleteSelector(configurationActivityCloneState),
    isCloneConfigurationActivityFailed: isFailedSelector(configurationActivityCloneState),

    configurationListCloneStateError,
    configurationTablesAddStateError,
    configurationHeaderAddStateError,
    configurationHeaderDeleteStateError,
    isDeleteConfigurationListComplete: isCompleteSelector(configurationListDeleteState),
    isDeleteConfigurationListFailed: isFailedSelector(configurationListDeleteState),

    isDeleteConfigurationHeaderComplete: isCompleteSelector(configurationHeaderDeleteState),
    isDeleteConfigurationHeaderFailed: isFailedSelector(configurationHeaderDeleteState),

    configurationFieldDeleteState,
    configurationFieldDeleteStateError,
    isDeleteConfigurationFieldComplete: isCompleteSelector(configurationFieldDeleteState),
    isDeleteConfigurationFieldFailed: isFailedSelector(configurationFieldDeleteState),

    configurationFieldAddState,
    configurationFieldAddStateError,

    configurationTablesExportState,
    isConfigurationTablesExportStatePending: isPendingSelector(configurationTablesExportState),
    isConfigurationTablesExportStateComplete: isCompleteSelector(configurationTablesExportState),
    isConfigurationTablesExportStateFailed: isFailedSelector(configurationTablesExportState),

    configurationTableDeleteState,
    configurationTableDeleteStateError,
    isDeleteConfigurationTableComplete: isCompleteSelector(configurationTableDeleteState),
    isDeleteConfigurationTableFailed: isFailedSelector(configurationTableDeleteState),
    isCloneConfigurationListComplete: isCompleteSelector(configurationListCloneState),
    isCloneConfigurationListFailed: isFailedSelector(configurationListCloneState),
    isAddConfigurationListPending: isPendingSelector(configurationListAddState),
    isAddConfigurationListComplete: isCompleteSelector(configurationListAddState),
    isAddConfigurationListFailed: isFailedSelector(configurationListAddState),
    isEditConfigurationListComplete: isCompleteSelector(configurationListEditState),
    isEditConfigurationListFailed: isFailedSelector(configurationListEditState),
    isAddConfigurationTablePending: isPendingSelector(configurationTableAddState),
    isAddConfigurationTableComplete: isCompleteSelector(configurationTableAddState),
    isAddConfigurationTableFailed: isFailedSelector(configurationTableAddState),
    isAddConfigurationHeaderFieldTypeComplete: isCompleteSelector(configurationAddFieldTypesState),
    isAddConfigurationHeaderFieldTypeFailed: isCompleteSelector(configurationAddFieldTypesState),
    isAddConfigurationHeaderFieldTypePending: isCompleteSelector(configurationAddFieldTypesState),

    isAddConfigurationHeaderPending: isPendingSelector(configurationHeaderAddState),
    isAddConfigurationHeaderComplete: isCompleteSelector(configurationHeaderAddState),
    isAddConfigurationHeaderFailed: isFailedSelector(configurationHeaderAddState),

    configurationTableEditState,
    configurationTableEditStateError,
    isEditConfigurationTablePending: isPendingSelector(configurationTableEditState),
    isEditConfigurationTableComplete: isCompleteSelector(configurationTableEditState),
    isEditConfigurationTableFailed: isFailedSelector(configurationTableEditState),
    isConfigurationDataGridPending: isPendingSelector(configurationDataGridState),
    isConfigurationDataGridComplete: isCompleteSelector(configurationDataGridState),
    isConfigurationDataGridFailed: isFailedSelector(configurationDataGridState),
    configurationHeaderEditState,
    configurationHeaderEditStateError,
    isEditConfigurationHeaderPending: isPendingSelector(configurationHeaderEditState),
    isEditConfigurationHeaderComplete: isCompleteSelector(configurationHeaderEditState),
    isEditConfigurationHeaderFailed: isFailedSelector(configurationHeaderEditState),
    configurationHeaderAddFieldState,
    configurationHeaderAddFieldStateError,
    isAddFieldConfigurationHeaderPending: isPendingSelector(configurationHeaderAddFieldState),
    isAddFieldConfigurationHeaderComplete: isCompleteSelector(configurationHeaderAddFieldState),
    isAddFieldConfigurationHeaderFailed: isFailedSelector(configurationHeaderAddFieldState),
    configurationModifyColumnState,
    isConfigurationModifyColumnPending: isPendingSelector(configurationModifyColumnState),
    isConfigurationModifyColumnComplete: isCompleteSelector(configurationModifyColumnState),
    isConfigurationModifyColumnFailed: isFailedSelector(configurationDataGridState),
    configurationModifyColumnStateError,
    configurationListCreatedId,
    configurationTableCreatedId,
    configurationListClonedId,
    configurationHeaderCreatedId,
    configurationHeaderFocusId,
    configurationListsImportListState,
    configurationListsImportListStateImports,
    configurationListsImportListStateError,
    isConfigurationListsImportListStatePending: isPendingSelector(
        configurationListsImportListState,
    ),
    isConfigurationListsImportListStateComplete: isCompleteSelector(
        configurationListsImportListState,
    ),
    isConfigurationListsImportListStateFailed: isFailedSelector(configurationListsImportListState),

    configurationListsExportListState,
    configurationListsExportListStateError,
    isConfigurationListsExportListStatePending: isPendingSelector(
        configurationListsExportListState,
    ),
    isConfigurationListsExportListStateComplete: isCompleteSelector(
        configurationListsExportListState,
    ),
    isConfigurationListsExportListStateFailed: isFailedSelector(configurationListsExportListState),

    configurationTablesImportTableState,
    configurationTablesImportTableStateError,
    isConfigurationTablesImportTableStatePending: isPendingSelector(
        configurationTablesImportTableState,
    ),
    isConfigurationTablesImportTableStateComplete: isCompleteSelector(
        configurationTablesImportTableState,
    ),
    isConfigurationTablesImportTableStateFailed: isFailedSelector(
        configurationListsImportListState,
    ),
    isAddConfigurationActivitiesPending: isPendingSelector(configurationActivityAddState),
    isAddConfigurationActivitiesComplete: isCompleteSelector(configurationActivityAddState),
    isAddConfigurationActivitiesFailed: isFailedSelector(configurationActivityAddState),

    isEditConfigurationActivitiesPending: isPendingSelector(configurationActivityEditState),
    isEditConfigurationActivitiesComplete: isCompleteSelector(configurationActivityEditState),
    isEditConfigurationActivitiesFailed: isFailedSelector(configurationActivityEditState),
    configurationActivityCreatedId,

    configurationActivitySampleWorkflowState,
    isConfigurationActivitySampleWorkflowComplete: isCompleteSelector(
        configurationActivitySampleWorkflowState,
    ),
    isConfigurationActivitySampleWorkflowFailed: isFailedSelector(
        configurationActivitySampleWorkflowState,
    ),
    isConfigurationActivitySampleWorkflowPending: isPendingSelector(
        configurationActivitySampleWorkflowState,
    ),
    configurationSampleWorkflows,
    configurationRankedColumnState,
    isRankedColumnOperationComplete: isCompleteSelector(configurationRankedColumnState),
    configurationRankedColumnStateError,
    configurationPossibleColumns,
    configurationRankedColumnMap,
    configurationCandidateColumnMap,

    configurationProjectCloneState,
    configurationProjectCloneStateError,
    configurationProjectClonedId,
    isCloneConfigurationProjectComplete: isCompleteSelector(configurationProjectCloneState),
    isCloneConfigurationProjectFailed: isFailedSelector(configurationProjectCloneState),
    configurationProjectDeleteState,
    configurationProjectDeleteStateError,
    isDeleteConfigurationProjectComplete: isCompleteSelector(configurationProjectDeleteState),
    isDeleteConfigurationProjectFailed: isFailedSelector(configurationProjectDeleteState),
    configurationAddProjectState,
    configurationEditProjectState,
    configurationEditProjectStateError,
    isConfigurationEditProjectPending: isPendingSelector(configurationEditProjectState),
    isConfigurationEditProjectComplete: isCompleteSelector(configurationEditProjectState),
    isConfigurationEditProjectFailed: isFailedSelector(configurationEditProjectState),
    isConfigurationAddProjectPending: isPendingSelector(configurationAddProjectState),
    isConfigurationAddProjectComplete: isCompleteSelector(configurationAddProjectState),
    isConfigurationAddProjectFailed: isFailedSelector(configurationAddProjectState),
    getNewProjectId,
    convertedProjectBoundaries,
    convertedProjectBoundariesState,
    isConvertedProjectBoundariesPending: isPendingSelector(convertedProjectBoundariesState),
    isConvertedProjectBoundariesComplete: isCompleteSelector(convertedProjectBoundariesState),
    isConvertedProjectBoundariesFailed: isFailedSelector(convertedProjectBoundariesState),
    configurationProjectActivitiesState,
    configurationProjectActivitiesStateError,
    isConfigurationProjectActivitiesStateComplete: isCompleteSelector(
        configurationProjectActivitiesState,
    ),
    isConfigurationProjectActivitiesStateFailed: isFailedSelector(
        configurationProjectActivitiesState,
    ),
    getCoordinateDecimalState,
    configurationProjectUserPermissions,
    isUserPermissionsPending: isPendingSelector(configurationProjectUserPermissionsState),
    isUserPermissionsComplete: isCompleteSelector(configurationProjectUserPermissionsState),
    isUserPermissionsFailed: isFailedSelector(configurationProjectUserPermissionsState),

    configurationListsFileState,
    isConfigurationListsFileGroupPending: isPendingSelector(configurationListsFileState),
    isConfigurationListsFileGroupComplete: isCompleteSelector(configurationListsFileState),
    isConfigurationListsFileGroupFailed: isFailedSelector(configurationListsFileState),
    configurationListsEmptyFileState,

    configurationSampleWorkflowCreatedId,
    isAddConfigurationSampleWorkflowPending: isPendingSelector(configurationSampleWorkflowAddState),
    isAddConfigurationSampleWorkflowComplete: isCompleteSelector(
        configurationSampleWorkflowAddState,
    ),
    isAddConfigurationSampleWorkflowFailed: isFailedSelector(configurationSampleWorkflowAddState),
    configurationSampleWorkflowEditState,
    configurationSampleWorkflowEditStateError,
    isEditConfigurationSampleWorkflowComplete: isCompleteSelector(
        configurationSampleWorkflowEditState,
    ),
    isEditConfigurationSampleWorkflowFailed: isFailedSelector(configurationSampleWorkflowEditState),
    configurationSampleWorkflowCloneState,
    configurationSampleWorkflowDeleteState,
    isCloneConfigurationSampleWorkflowComplete: isCompleteSelector(
        configurationSampleWorkflowCloneState,
    ),
    isCloneConfigurationSampleWorkflowFailed: isFailedSelector(
        configurationSampleWorkflowCloneState,
    ),
    configurationSampleWorkflowClonedId,
    isDeleteConfigurationSampleWorkflowComplete: isCompleteSelector(
        configurationSampleWorkflowDeleteState,
    ),
    configurationSampleWorkflowCloneStateError,
    configurationSampleWorkflowDeleteStateError,

    isConfigurationUsersLoadingPending: isPendingSelector(configurationUsersLoadingState),
    isConfigurationUsersLoadingComplete: isCompleteSelector(configurationUsersLoadingState),
    isConfigurationUsersLoadingFailed: isFailedSelector(configurationUsersLoadingState),
    configurationUsersLoadingStateError,
    isConfigurationUsersOperationPending: isPendingSelector(configurationUsersOperationState),
    isConfigurationUsersOperationComplete: isCompleteSelector(configurationUsersOperationState),
    isConfigurationUsersOperationFailed: isFailedSelector(configurationUsersOperationState),
    configurationUsersOperationStateError,

    defaultLabServicesByList,
    isLoadDefaultLabServicesComplete: isCompleteSelector(configurationDefaultLabServicesState),
    isLoadDefaultLabServicesPending: isPendingSelector(configurationDefaultLabServicesState),
    isLoadDefaultLabServicesFailed: isFailedSelector(configurationDefaultLabServicesState),

    configurationGridAddState,
    configurationGridEditState,
    configurationGridCreatedId,
    isConfigurationGridAddStateComplete: isCompleteSelector(configurationGridAddState),
    isConfigurationGridAddStatePending: isPendingSelector(configurationGridAddState),
    isConfigurationGridAddStateFailed: isFailedSelector(configurationGridAddState),
    isConfigurationGridEditStateComplete: isCompleteSelector(configurationGridEditState),
    isConfigurationGridEditStatePending: isPendingSelector(configurationGridEditState),
    isConfigurationGridEditStateFailed: isFailedSelector(configurationGridEditState),
    configurationGridEditErrorState,
    configurationGridAddErrorState,

    configurationGridPreviewState,
    isConfigurationGridPreviewStateComplete: isCompleteSelector(configurationGridPreviewState),
    isConfigurationGridPreviewStatePending: isPendingSelector(configurationGridPreviewState),
    isConfigurationGridPreviewStateFailed: isFailedSelector(configurationGridPreviewState),
    gridPreviewData,

    configurationCategories,
    configurationCategoriesShouldReload,
    isLoadConfigurationCategoriesComplete: isCompleteSelector(configurationCategoriesLoadState),
    isLoadConfigurationCategoriesPending: isPendingSelector(configurationCategoriesLoadState),
    isLoadConfigurationCategoriesFailed: isFailedSelector(configurationCategoriesLoadState),
    configurationCategoriesLoadState,
    configurationCategoriesLoadError,
    isConfigurationCategoriesOperationComplete: isCompleteSelector(
        configurationCategoriesOperationState,
    ),
    isConfigurationCategoriesOperationPending: isPendingSelector(
        configurationCategoriesOperationState,
    ),
    isConfigurationCategoriesOperationFailed: isFailedSelector(
        configurationCategoriesOperationState,
    ),
    configurationCategoriesOperationState,
    configurationCategoriesOperationError,

    configurationActivityGroupsState,
    configurationActivityGroups,
    configurationActivityGroupsLoadingState,
    isConfigurationActivityGroupsPending: isPendingSelector(
        configurationActivityGroupsLoadingState,
    ),
    isConfigurationActivityGroupsComplete: isCompleteSelector(
        configurationActivityGroupsLoadingState,
    ),
    isConfigurationActivityGroupsFailed: isFailedSelector(configurationActivityGroupsLoadingState),

    configurationActivityGroupsOperationState,
    configurationActivityGroupsOperationStateError,
    isDeleteConfigurationActivityGroupsComplete: isCompleteSelector(
        configurationActivityGroupsOperationState,
    ),
    isDeleteConfigurationActivityGroupsFailed: isFailedSelector(
        configurationActivityGroupsOperationState,
    ),
    fieldsShouldReload,
    activityGroupsShouldReload,
    gridsShouldReload,
};
