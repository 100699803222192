import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { LOAD_STATUS_FAILED } from 'state-domains/constants';
import { authState, userState } from 'state-domains/domain';
import { AsyncState } from 'state-domains/types';

import { UserPortalState } from 'src/state';

import { WithAuthDispatchProps, WithAuthStateProps } from './withAuth.types';

const mapStateToProps = (state: UserPortalState): WithAuthStateProps => {
    const {
        authentication: { authenticationStatus, loggedOutState },
    } = state;
    const {
        selectors: { isCurrentUserFailed, isCurrentUserLoaded },
    } = userState;
    let hasFailedState = false;
    Object.keys(state).forEach((s) => {
        const asyncState = state[s as keyof UserPortalState] as AsyncState;
        if (
            typeof asyncState !== 'undefined' &&
            asyncState.status === LOAD_STATUS_FAILED &&
            asyncState.error !== null &&
            (asyncState.error.status === 401 || asyncState.error.status === 403)
        ) {
            hasFailedState = true;
        }
    });

    return {
        authenticationStatus,
        hasFailedState,
        loggedOutState,
        isCurrentUserFailed: isCurrentUserFailed(state),
        isCurrentUserLoaded: isCurrentUserLoaded(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): WithAuthDispatchProps => {
    const {
        actions: { oauth2Token },
    } = authState;

    return {
        oauth2Token: bindActionCreators(oauth2Token, dispatch),
    };
};

export const connectToState = (baseComponent: React.ComponentType) =>
    connect(mapStateToProps, mapDispatchToProps)(baseComponent);
