import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { notificationsState, userState } from 'state-domains/domain';
import { isFailed, isPending } from 'state-domains/utils';

import { UserPortalState } from 'src/state';

import { DispatchProps, StateProps } from './NotificationsButton.types';

const {
    actions: { pollMessages, messagesSeen, loadUnsubscribeList },
    selectors: { enabled, notificationsAuth, notificationsToken },
} = notificationsState;

const {
    selectors: { isCurrentUserLoaded },
} = userState;

const mapStateToProps = (state: UserPortalState): StateProps => {
    const { notifications } = state;
    const token = notificationsToken(state);
    return {
        isCurrentUserLoaded: isCurrentUserLoaded(state),
        enabled: enabled(state),
        total: notifications.trayState.total,
        isPending: isPending(notifications.resourceState),
        shouldRedirect: isFailed(notificationsAuth(state)) && token !== null,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadUnsubscribeList: bindActionCreators(loadUnsubscribeList, dispatch),
    pollMessages: bindActionCreators(pollMessages, dispatch),
    messagesSeen: bindActionCreators(messagesSeen, dispatch),
});

export const connectToState = connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
);
