import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { Section } from 'state-domains/domain';

import {
    buildConfigurationEditHeaderSectionUrl,
    buildConfigurationEditSampleHeaderSectionUrl,
} from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const modifyHeaderSection = (
    payload: any,
    headerId: string,
    sectionId: string,
    configType: CONFIGURATION_TYPES,
): Observable<Section> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationEditHeaderSectionUrl(headerId, sectionId)
            : buildConfigurationEditSampleHeaderSectionUrl(headerId, sectionId);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const section = convertToCamel<Section>(response);
            return observableOf(section);
        }),
    );
};
