import { AsyncState } from '../../types';

export enum AuthenticationStatus {
    Unknown,
    Authenticating,
    Authenticated,
    NotAuthenticated,
    ReAuthenticating,
}

export interface AuthenticationState extends AsyncState {
    userId: string;
    userName: string;
    token: string;
    postLoginRedirectPath: string;
    loginRedirectUrl: string;
    logoutRedirectUrl: string;
    registerUrl: string;
    renewTimeout: number;
    authenticationStatus: AuthenticationStatus;
    oauth: AsyncState;
    loggedOutState: boolean;
}

export interface OAuth2TokenOptions {
    preventOAuth2LoginRedirection?: boolean;
}
