import { fonts } from '@local/web-design-system';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            textAlign: 'center',
            padding: `${theme.spacing(4)} 0 0 0`,
        },
        image: {
            padding: `${theme.spacing()} 0`,
        },
        imageBlock: {
            position: 'relative',
        },
        floatingTextBlock: {
            position: 'absolute',
            bottom: '55%',
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
        title: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            padding: `${theme.spacing()} 0`,
            fontSize: '3rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
                padding: `${theme.spacing(0.5)} 0`,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        subtitle: {
            fontWeight: 'bold',
            padding: `${theme.spacing()} 0`,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
                padding: `${theme.spacing(0.5)} 0`,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.67rem',
            },
        },
        messageBlock: {
            padding: `${theme.spacing()} 0`,
        },
        noSeatsSubTitle: {
            color: theme.palette.primary.main,
            fontWeight: fonts?.weights?.regular,
            padding: theme.spacing(1, 0),
        },
        noSeats: {
            textAlign: 'center',
            margin: `${theme.spacing(1)} auto`,
        },
    } as const);

export const useStyles = makeStyles()(styles);
