import {
    CLEAR_SNACKBAR_NOTIFICATIONS,
    DELETE_SNACKBAR_NOTIFICATION,
    SELECT_ROW_COL_IN_GRID,
} from 'state-domains/types/actionTypes';

import { SnackbarNotificationDescriptor } from '../types';

const deleteSnackbarMessage = (descriptor: SnackbarNotificationDescriptor) => (dispatch: any) => {
    dispatch({
        payload: descriptor,
        type: DELETE_SNACKBAR_NOTIFICATION,
    });
};

const clearSnackbarMessages =
    (descriptors?: SnackbarNotificationDescriptor[]) => (dispatch: any) => {
        dispatch({
            payload: descriptors,
            type: CLEAR_SNACKBAR_NOTIFICATIONS,
        });
    };

const selectRowColInGrid =
    (
        selectedRowCol: {
            row: {
                id: string | undefined;
                toValue: number | undefined;
                index: number | undefined;
            };
            col: string;
        } | null,
    ) =>
    (dispatch: any) => {
        dispatch({
            payload: selectedRowCol,
            type: SELECT_ROW_COL_IN_GRID,
        });
    };

export const actions = {
    deleteSnackbarMessage,
    clearSnackbarMessages,
    selectRowColInGrid,
};
