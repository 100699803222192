import { Grid, Skeleton } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';

import { styles } from './HeaderSkeleton.styles';

export const HeaderSkeleton = () => {
    const useStyles = makeStyles()(styles);
    const { classes } = useStyles();

    return (
        <Grid container className={classes.grid} rowSpacing={11} columnSpacing={2}>
            {Array(5)
                .fill(true)
                .map(() => (
                    <Grid key={`section-${uuidv4()}`} item xs={12}>
                        <Grid
                            container
                            key={`container-${uuidv4()}`}
                            columnSpacing={2}
                            className={classes.sectionContainer}
                        >
                            {Array(8)
                                .fill(true)
                                .map(() => (
                                    <Grid
                                        item
                                        key={`section-${uuidv4()}-item-${uuidv4()}`}
                                        xs={3}
                                        className={classes.fieldContainer}
                                    >
                                        <Skeleton
                                            height="40px"
                                            variant="rectangular"
                                            animation="wave"
                                            sx={{ transform: 'scale(1,1)' }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                ))}
        </Grid>
    );
};
