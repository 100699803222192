import React from 'react';

export enum ItemTypes {
    HEADER = 'header',
    LITHOLOGY = 'lithology',
    SAMPLES = 'samples',
    SURVEY = 'survey',
    OTHER_TABLES = 'otherTables',
    SAMPLE_DISPATCH = 'sampleDispatch',
    UNKNOWN = 'unknown',
}

export enum ModuleTypes {
    LOGGING = 'LOGGING',
    SAMPLE_TYPES = 'SAMPLE_TYPES',
    SAMPLE_CONTROL_TYPES = 'SAMPLE_CONTROL_TYPES',
    SAMPLE_ANALYSIS_WORKFLOWS = 'SAMPLE_ANALYSIS_WORKFLOWS',
    SAMPLE_RANKED_COLUMNS = 'SAMPLE_RANKED_COLUMNS',
    UNKNOWN = 'UNKNOWN',
}

export interface SelectedItem {
    id: string;
    itemType: ItemTypes;
    type: ModuleTypes;
    itemName?: string;
    isPrimary?: boolean;
}

export interface ActivitiesDetailFocusContextType {
    selectedItem: SelectedItem;
    setSelectedItem: (newItem: SelectedItem, openSidePanel?: boolean) => void;
    rankedColumnModifiedRef: React.MutableRefObject<boolean>;
    tableToComputeDepthModifiedRef: React.MutableRefObject<boolean>;
}
