import * as React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const withRouter = (WrappedComponent: React.ComponentType<any>) => (props: any) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [_currentQueryParameters, setSearchParams] = useSearchParams();
    return <WrappedComponent {...{ ...props, location, navigate, params, setSearchParams }} />;
};
