import { createSelector } from 'reselect';

import {
    ACTIVITY_TYPES,
    LOAD_STATUS_COMPLETE,
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    MODULES,
} from 'state-domains/constants';
import {
    AccountEAP,
    ActivityMap,
    CustomColour,
    Category,
    DefaultPermission,
    FilterOperator,
    List,
    MXColour,
    SystemFilter,
    Table,
    TableView,
    SelectedClearState,
    SampleResultsRankedUnit,
} from 'state-domains/domain';

import { DataRowType } from 'src/components/DataTable/DataTable.types';

import { AsyncState, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    Account,
    ActivityGroupMap,
    Header,
    HeaderType,
    SampleDispatchHeader,
    SampleWorkflow,
    SubscriptionState,
    UserWithType,
} from '../types';

export const emptySubscriptionState = {
    selectedClearState: {} as SelectedClearState,
    subscriptionDataState: { status: LOAD_STATUS_PENDING, error: null },
    relatedCollectionsState: { status: LOAD_STATUS_COMPLETE, error: null },
    singleItemLoadState: { status: LOAD_STATUS_STALE, error: null },
    subscriptions: [],
    activities: {},
    activityCategories: {},
    account: {} as Account,
    users: {},
    tableViews: {},
    lists: {},
    listCategories: {},
    tables: {},
    tableCategories: {},
    headers: {},
    headerCategories: {},
    headerTypes: {},
    status: LOAD_STATUS_PENDING,
    error: null,
    systemFilters: {},
    filterOperators: {},
    defaultPermissions: {},
    coordinates: {},
    activityGroups: {},
    customColours: {},
    mxColours: {},
    units: [],
    sampleDispatchHeaders: {},
    sampleWorkflows: {},
    selectedItems: {},
    unSelectedItems: {},
    isSelectedAll: false,
    isClearedAll: false,
};

const subscription = (state: Partial<ShimState>): SubscriptionState => {
    const { subscription: subSubscription = { ...emptySubscriptionState } } = state || {};
    return subSubscription;
};

const subDataState = createSelector(
    subscription,
    ({ subscriptionDataState = {} as AsyncState }: SubscriptionState): AsyncState =>
        subscriptionDataState,
);

const activities = createSelector(
    subscription,
    ({ activities: subActivities = {} }: SubscriptionState): { [id: string]: ActivityMap } =>
        subActivities,
);

const activity = (id: string) =>
    createSelector(activities, (subActivities: { [id: string]: ActivityMap }): string =>
        subActivities[id] ? subActivities[id].name : '',
    );

const activityCategories = createSelector(
    subscription,
    ({
        activityCategories: subActivityCategories = {},
    }: SubscriptionState): { [id: string]: Category } => subActivityCategories,
);

const activityObject = (id: string) =>
    createSelector(
        activities,
        (subActivities: { [id: string]: ActivityMap }): ActivityMap => subActivities[id] || {},
    );

const activitiesById = (ids: string[]) =>
    createSelector(
        activities,
        (subActivities: { [id: string]: ActivityMap }): { [id: string]: ActivityMap } =>
            Object.keys(subActivities)
                .filter((key) => ids.includes(key))
                .reduce((res, key) => {
                    res[key] = subActivities[key];
                    return res;
                }, {} as any) || {},
    );

const users = createSelector(
    subscription,
    ({ users: subUsers = {} }: SubscriptionState): { [key: string]: UserWithType } => subUsers,
);

const user = (id: string) =>
    createSelector(users, (subUsers = {}): UserWithType | undefined => subUsers[id] || {});

const tableViews = createSelector(
    subscription,
    ({ tableViews: subTableViews = {} }: SubscriptionState): { [id: string]: TableView } =>
        subTableViews,
);

const tableView = (id: string) =>
    createSelector(
        tableViews,
        (subTableview: { [id: string]: TableView }): TableView => subTableview[id] ?? {},
    );

const tables = createSelector(
    subscription,
    ({ tables: subTables = {} }: SubscriptionState): { [id: string]: Table } => subTables,
);

const tableCategories = createSelector(
    subscription,
    ({ tableCategories: subTableCategories = {} }: SubscriptionState): { [id: string]: Category } =>
        subTableCategories,
);

const table = (id: string) =>
    createSelector(tables, (subTables: { [id: string]: Table }): Table => subTables[id]);

const lists = createSelector(
    subscription,
    ({ lists: subLists = {} }: SubscriptionState): { [id: string]: List } => subLists,
);

const listCategories = createSelector(
    subscription,
    ({ listCategories: subListCategories = {} }: SubscriptionState): { [id: string]: Category } =>
        subListCategories,
);

const list = (listId: string) =>
    createSelector(lists, (subLists: { [id: string]: List }): List => subLists[listId] ?? {});

const headers = createSelector(
    subscription,
    ({ headers: subHeaders = {} }: SubscriptionState): { [id: string]: Header } => subHeaders,
);

const header = (headerId: string) =>
    createSelector(
        headers,
        (subHeaders: { [id: string]: Header }): Header => subHeaders[headerId] ?? {},
    );
const headerCategories = createSelector(
    subscription,
    ({
        headerCategories: subHeaderCategories = {},
    }: SubscriptionState): { [id: string]: Category } => subHeaderCategories,
);

const headerTypes = createSelector(
    subscription,
    ({ headerTypes: subHeaderTypes = {} }: SubscriptionState): { [id: string]: HeaderType } =>
        subHeaderTypes,
);

const systemFilters = createSelector(
    subscription,
    ({ systemFilters = {} }: SubscriptionState): { [id: string]: SystemFilter } => systemFilters,
);

const systemFiltersForModule = (moduleId: string) =>
    createSelector(systemFilters, (filters: { [id: string]: SystemFilter }): SystemFilter[] =>
        Object.values(filters).filter((x) => x.modules[moduleId]),
    );
const systemFiltersForCollarModules = (moduleIds: string[], excludedFilters: string[] = []) =>
    createSelector(
        systemFilters,
        (filters: { [id: string]: SystemFilter }): { [key in ACTIVITY_TYPES]: SystemFilter[] } => {
            const result = {
                [ACTIVITY_TYPES.DRILLING]: [] as SystemFilter[],
                [ACTIVITY_TYPES.POINT]: [] as SystemFilter[],
            };
            moduleIds.forEach((id) => {
                if (id === MODULES.DRILL_HOLES)
                    result[ACTIVITY_TYPES.DRILLING] = Object.values(filters).filter(
                        (x) => x.modules[id] && !excludedFilters.includes(x.id),
                    );
                else if (id === MODULES.POINT_SAMPLES)
                    result[ACTIVITY_TYPES.POINT] = Object.values(filters).filter(
                        (x) => x.modules[id] && !excludedFilters.includes(x.id),
                    );
            });
            return result;
        },
    );
const filterOperators = createSelector(
    subscription,
    ({ filterOperators = {} }: SubscriptionState): { [id: string]: FilterOperator } =>
        filterOperators,
);

const filterOperatorByType = (type: string) =>
    createSelector(
        filterOperators,
        (filterOperators: { [id: string]: FilterOperator }): FilterOperator =>
            filterOperators[type],
    );

const defaultPermissions = createSelector(
    subscription,
    ({ defaultPermissions = {} }: SubscriptionState): { [id: string]: DefaultPermission } =>
        defaultPermissions,
);

const defaultPermission = (id: string) =>
    createSelector(
        defaultPermissions,
        (defaultPermissions: { [id: string]: DefaultPermission }): DefaultPermission =>
            defaultPermissions[id],
    );

const defaultPermissionForCollarModules = (ids: string[]) =>
    createSelector(
        defaultPermissions,
        (defaultPermissions: {
            [id: string]: DefaultPermission;
        }): { [key in ACTIVITY_TYPES]: DefaultPermission } => {
            const result = {
                [ACTIVITY_TYPES.DRILLING]: {} as DefaultPermission,
                [ACTIVITY_TYPES.POINT]: {} as DefaultPermission,
            };
            ids.forEach((id) => {
                if (id === MODULES.DRILL_HOLES)
                    result[ACTIVITY_TYPES.DRILLING] = defaultPermissions[id];
                else if (id === MODULES.POINT_SAMPLES)
                    result[ACTIVITY_TYPES.POINT] = defaultPermissions[id];
            });
            return result;
        },
    );

const activityGroups = createSelector(
    subscription,
    ({ activityGroups = {} }: SubscriptionState): { [id: string]: string } => {
        const dict: { [key: string]: string } = {};
        Object.values(activityGroups).forEach((element: ActivityGroupMap) => {
            dict[element.id] = element.name;
        });
        return dict;
    },
);

const activityGroupsObject = createSelector(
    subscription,
    ({ activityGroups = {} }: SubscriptionState): { [id: string]: ActivityGroupMap } =>
        activityGroups,
);

const mxColours = createSelector(
    subscription,
    ({ mxColours = {} }: SubscriptionState): { [id: string]: MXColour } => mxColours,
);

const rankedColumnUnits = createSelector(
    subscription,
    ({ units = [] }: SubscriptionState): SampleResultsRankedUnit[] => units,
);

const customColours = createSelector(
    subscription,
    ({ customColours = {} }: SubscriptionState): { [id: string]: CustomColour } => customColours,
);

const account = createSelector(
    subscription,
    ({ account = {} as Account }: SubscriptionState): Account => account,
);

const isEAP = createSelector(
    account,
    ({ eap = {} as AccountEAP }: Account): boolean => eap.accessNewApp ?? false,
);

const getSubscriptionResource = (id: string, type: string) =>
    createSelector(subscription, (subs: SubscriptionState) => (subs as any)[type]?.[id] ?? {});

const unSelectedItems = createSelector(
    subscription,
    ({
        selectedClearState = {} as SelectedClearState,
    }: SubscriptionState): { [id: string]: DataRowType } => selectedClearState.unSelectedItems,
);

const selectedItems = createSelector(
    subscription,
    ({
        selectedClearState = {} as SelectedClearState,
    }: SubscriptionState): { [id: string]: DataRowType } => selectedClearState.selectedItems,
);

const isSelectedAll = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): boolean =>
        selectedClearState.isSelectedAll,
);

const isClearedAll = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): boolean =>
        selectedClearState.isClearedAll,
);

const sampleDispatchHeaders = createSelector(
    subscription,
    ({
        sampleDispatchHeaders = {} as { [key: string]: SampleDispatchHeader },
    }: SubscriptionState): { [key: string]: SampleDispatchHeader } => sampleDispatchHeaders,
);

const sampleWorkflows = createSelector(
    subscription,
    ({
        sampleWorkflows = {} as { [key: string]: SampleWorkflow },
    }: SubscriptionState): { [key: string]: SampleWorkflow } => sampleWorkflows,
);

const relatedCollectionsState = createSelector(
    subscription,
    ({ relatedCollectionsState = {} as AsyncState }: SubscriptionState): AsyncState =>
        relatedCollectionsState,
);

const singleItemLoadState = createSelector(
    subscription,
    ({ singleItemLoadState = {} as AsyncState }: SubscriptionState): AsyncState =>
        singleItemLoadState,
);

const coordinates = createSelector(
    subscription,
    ({ coordinates: coords = {} }: SubscriptionState): { [id: string]: any } => coords,
);

const coordinate = (id: string) =>
    createSelector(coordinates, (subCoords: { [id: string]: any }): any => subCoords[id] ?? {});

export const selectors = {
    selectedItems,
    unSelectedItems,
    isSelectedAll,
    isClearedAll,
    subscription,
    activityObject,
    isSubscriptionDataStatePending: isPendingSelector(subDataState),
    isSubscriptionDataStateComplete: isCompleteSelector(subDataState),
    isSubscriptionDataStateFailed: isFailedSelector(subDataState),
    isRelatedCollectionsStatePending: isPendingSelector(relatedCollectionsState),
    isRelatedCollectionsStateComplete: isCompleteSelector(relatedCollectionsState),
    isRelatedCollectionsStateFailed: isFailedSelector(relatedCollectionsState),
    isSingleItemLoadStatePending: isPendingSelector(singleItemLoadState),
    isSingleItemLoadStateComplete: isCompleteSelector(singleItemLoadState),
    isSingleItemLoadStateFailed: isFailedSelector(singleItemLoadState),
    activities,
    activity,
    activityCategories,
    users,
    user,
    tableViews,
    tableView,
    tables,
    tableCategories,
    lists,
    list,
    listCategories,
    header,
    headers,
    headerCategories,
    headerTypes,
    table,
    activitiesById,
    systemFilters,
    systemFiltersForModule,
    filterOperators,
    filterOperatorByType,
    defaultPermissions,
    defaultPermission,
    activityGroups,
    activityGroupsObject,
    mxColours,
    customColours,
    account,
    isEAP,
    defaultPermissionForCollarModules,
    systemFiltersForCollarModules,
    getSubscriptionResource,
    rankedColumnUnits,
    sampleDispatchHeaders,
    sampleWorkflows,
    coordinates,
    coordinate,
};
