import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { notificationsState } from 'state-domains/domain';
import { isPending, isFailed, isComplete } from 'state-domains/utils/redux';

import { UserPortalState } from 'src/state';

import { StateProps, DispatchProps, OwnProps } from './NotificationsTray.types';

const {
    actions: { bulkReadMessages, clearMessages, messagesSeen, subscribeInstance },
    selectors: { enabled, unreadCount, trayMessagesIdList, messagesDataListByIdList },
} = notificationsState;

const mapStateToProps = (state: UserPortalState): StateProps => {
    const { notifications } = state;

    const messagesIdList = trayMessagesIdList(state);
    const messagesDataList = messagesDataListByIdList(state)(messagesIdList);

    return {
        trayMessagesIdList: messagesIdList,
        trayMessagesDataList: messagesDataList,
        unreadCount: unreadCount(state, { uids: messagesIdList }),
        enabled: enabled(state),
        total: notifications.trayState.total,
        isFailed: isFailed(notifications.trayState),
        isPending: isPending(notifications.trayState) || isPending(notifications.resourceState),
        isComplete: isComplete(notifications.trayState) && isComplete(notifications.resourceState),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    bulkReadMessages: bindActionCreators(bulkReadMessages, dispatch),
    clearAllMessages: bindActionCreators(clearMessages, dispatch),
    enableNotifications: bindActionCreators(subscribeInstance, dispatch),
    messagesSeen: bindActionCreators(messagesSeen, dispatch),
});

export const connectToState = connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
);
