import { ColorVariant, TraceProps, WithTrace } from '@local/web-design-system';
import { WrappedComponentProps } from 'react-intl';

import { AsyncMessage } from 'state-domains/domain';

import { WithRouterComponentProps } from 'src/routes/withRouter';
import { AsyncProps } from 'src/state';
import { WithStyles } from 'src/styles/utils';

import { styles } from './NotificationsTray.styles';

export enum TopicID {
    NOTE = 'central:project.note',
    REVISION = 'central:project.revision',
    GRANT_ACCESS = 'central:project.grant_access',
    MENTION = 'central:mention',
    BRANCH = 'central:project.branch',
    SHARE_SCENE = 'central:project.scene.share_project_member',
}

export interface StateProps extends AsyncProps {
    trayMessagesIdList: string[];
    trayMessagesDataList: AsyncMessage[];

    total: number;
    enabled: boolean;
    unreadCount: number;
}

export interface DispatchProps {
    bulkReadMessages: (ids: string[]) => void;
    clearAllMessages: (ids: string[]) => void;
    enableNotifications: () => void;
    messagesSeen: () => void;
}

export interface OwnProps {
    limit: number;
    closeTray: () => void;
    variant?: ColorVariant;
}

export type AllProps = StateProps &
    DispatchProps &
    OwnProps &
    WrappedComponentProps &
    WithStyles<typeof styles> &
    WithRouterComponentProps &
    WithTrace &
    TraceProps;

export interface EmptyProps extends WrappedComponentProps, WithStyles<typeof styles> {
    tray?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    enableNotifications?: () => void;
}
