import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { LimitedColumns } from 'state-domains/domain/subscription';

import { buildReorderColumnsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const reorderColumns = (
    type: string,
    id: string,
    payload: any,
): Observable<LimitedColumns> => {
    const url = buildReorderColumnsUrl(type, id);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedList = convertToCamel<LimitedColumns>(response);
            return observableOf(convertedList);
        }),
    );
};
