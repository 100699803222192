import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) => ({
    collarContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        marginBottom: theme.spacing(3),
    },
    collarHeader: {
        width: '100%',
        minHeight: '56px',
        backgroundColor: '#4A4A4C',
    },
    collarContentContainer: {
        maxHeight: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    collarItem: {
        height: '57px',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    dataGridContainer: {
        width: '100%',
        height: '100%',
        maxHeight: `calc(100vh - ${theme.spacing(30)})`,
        overflow: 'hidden',
    },
});

export const useStyles = makeStyles()(styles);
