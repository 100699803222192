import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildConfigurationHeaderFieldTypesBaseUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { HeaderTypes, HeaderType } from '../types';

export const loadHeaderTypes = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: HeaderType[]; actionName: string; processResultFunction: Function }> => {
    const url = buildConfigurationHeaderFieldTypesBaseUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<HeaderTypes>) => {
            const { HeaderFieldType } = response;
            const result = convertToCamel<HeaderType[]>(HeaderFieldType);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
