import { WithTrace } from '@local/web-design-system';
import { LatLngBounds, Map } from 'leaflet';
import React, { Dispatch, SetStateAction } from 'react';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import {
    ConfigurationProject,
    DrillholeCoordinates,
    GridPreviewData,
    ProjectBoundary,
} from 'state-domains/domain';

import { Nullable } from 'src/types';

export interface MapItemProps {
    id: string;
    name: string;
    type: string | ACTIVITY_TYPES;
    wgs84: DrillholeCoordinates;
}

export interface DrillholeMapProps {
    id: string;
    drillholes: MapItemProps[];
    zoom?: number;
    onExpand?: () => void;
    hideMapMarkerPopup?: boolean;
    projectBoundaries?: ProjectBoundary[];
    propertiesMap?: boolean;
    overviewMap?: boolean;
    locationMap?: boolean;
    onMoveCallback?: (bounds: LatLngBounds, zoomLevel: number) => void;
    onMarkerSelectCallback?: (item: MapItemProps) => void;
    mapBounds?: LatLngBounds;
    disableMap?: boolean;
    preferCanvas?: boolean;
    editBoundaries?: boolean;
    currentProject?: ConfigurationProject;
    previewGrid?: boolean;
    previewGridData?: GridPreviewData;
}

export interface AddMapLayersProps {
    hideLayerSelection?: boolean;
    setShowLabels: Dispatch<SetStateAction<boolean>>;
    setShowBounds: Dispatch<SetStateAction<boolean>>;
    setIsSatellite: Function;
    stopFitBounds: React.MutableRefObject<boolean>;
    showLayersOnly?: boolean;
    previewGrid?: boolean;
}

export interface DrillholeListItem {
    size: 'large' | 'small';
}

export type WrappedLongitudeDict = {
    [Key in string]: number;
};

export interface DrillholeMarkerBaseProps {
    hideMapMarkerPopup?: boolean;
    currentLayer: string;
    locationMap?: boolean;
    overviewMap?: boolean;
    onExpand?: () => void;
    showLabels?: boolean;
    onSelectCallback?: (item: MapItemProps) => void;
    disableMap: boolean;
}

export interface DrillholeMarkerProps extends DrillholeMarkerBaseProps {
    drillhole: MapItemProps;
    wrappedLongitude: number;
}

export interface DrillholeMarkersProps extends DrillholeMarkerBaseProps {
    drillholesWithLatLng: MapItemProps[];
    wrappedLongitudes: WrappedLongitudeDict;
}

export interface MapListItemProps {
    map?: Map | null;
    isAnimating: boolean;
    drillhole: MapItemProps;
    current?: boolean;
    setCurrentDrillholeId?: (drillholeId: Nullable<string>) => void;
    setAnimating?: (animating: boolean) => void;
}

export interface EmptySideBarProps extends WithTrace {}

export interface SideBarProps extends WithTrace {
    map?: Map;
    drillholesWithLatLng: MapItemProps[];
    setCurrentDrillholeId: (drillholeId: Nullable<string>) => void;
    currentDrillholeId: Nullable<string>;
    isAnimating: boolean;
    setAnimating: (animating: boolean) => void;
}

export interface AutoWrapMarkersProps {
    map?: Map;
    drillholesWithLatLng: MapItemProps[];
    setWrappedLongitudes: (wrappedLongitudes: WrappedLongitudeDict) => void;
}

export enum MapLayer {
    SATELLITE = 'Satellite Maps',
    STREET = 'Street Maps',
}
