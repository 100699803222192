import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Project } from 'state-domains/domain/project';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { buildConfigurationProjectUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const modifyConfigurationProject = (payload: Project): Observable<Project> => {
    const obs = sendRequestWithXSRFToken(
        buildConfigurationProjectUrl(payload.id),
        convertToSnake(payload),
        'PUT',
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Project>) => {
            const result = convertToCamel<Project>(response);
            return observableOf(result);
        }),
    );
};
