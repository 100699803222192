import * as React from 'react';

export interface GenericWithProps<T> {
    (baseComponent: React.ReactNode): React.ComponentType<T>;
}

export interface HeaderFieldInfo {
    id: string;
    name: string;
    label: string;
    type: string;
    dynamicFilter: boolean;
    values?: HeaderFieldInfoValues[];
    operatorLabelOverrides?: { [id: string]: string };
}

export interface HeaderFieldInfoValues {
    id: string;
    value: any;
    index: number;
}

export enum ReadOnlyReasons {
    REVIEWER = 'REVIEWER',
    ACTIVITY_GROUP = 'ACTIVITY_GROUP',
    INVALID_DRILLHOLE_STATE = 'INVALID_DRILLHOLE_STATE',
    XRF_TABLE = 'XRF_TABLE',
    ASSIGNED_USER = 'ASSIGNED_USER',
}
