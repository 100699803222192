import { Observable } from 'rxjs';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { RowReferenceResponse } from 'state-domains/domain/drillhole';
import { buildCopyIntervalsUrl, buildCopyIntervalsPointsUrl } from 'state-domains/network';

import { getRowReferenceResponseObservable } from './common';

export const copyIntervals = (
    payload: any,
    type: ACTIVITY_TYPES,
): Observable<RowReferenceResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING ? buildCopyIntervalsUrl() : buildCopyIntervalsPointsUrl();
    return getRowReferenceResponseObservable(url, payload);
};
