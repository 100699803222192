import { loadProjectOverviewCollarsCount, loadProjectOverviewCollars } from './loadOverviewCollars';
import { loadProject } from './loadProject';
import { loadProjectList } from './loadProjectList';
import { setPinProject } from './pinProject';

export const projectShim = {
    loadProject,
    loadProjectList,
    setPinProject,
    loadProjectOverviewCollarsCount,
    loadProjectOverviewCollars,
};
