import { i18nActionConstants } from 'state-domains/constants/mxdeposit.i18n';

export const i18n = {
    inboxError: {
        id: 'notifications.msg-error',
        defaultMessage: 'Failed to get messages',
    },
    inboxEmpty: {
        id: 'notifications.msg-empty',
        defaultMessage: 'No messages for you',
    },
    nextPage: {
        id: 'notifications.next',
        defaultMessage: 'next',
    },
    prevPage: {
        id: 'notifications.prev',
        defaultMessage: 'prev',
    },
    dialogTitle: {
        id: 'notifications.dialog-title',
        defaultMessage: 'Delete notification?',
    },
    dialogContent: {
        id: 'notifications.dialog-content',
        defaultMessage: 'Delete Notification? This action cannot be undone.',
    },
    dialogConfirm: {
        id: 'notifications.confirm-text',
        defaultMessage: 'Yes',
    },
    dialogCancel: i18nActionConstants.cancel,
    markRead: {
        id: 'notifications.mark-all-read',
        defaultMessage: 'Mark all as read',
    },
    markOneRead: {
        id: 'notifications.mark-one-read',
        defaultMessage: 'Mark as read',
    },
    markOneUnread: {
        id: 'notifications.mark-one-unread',
        defaultMessage: 'Mark as unread',
    },
    deleteAll: {
        button: {
            id: 'notifications.delete-all',
            defaultMessage: 'Delete all',
        },
        dialogTitle: {
            id: 'notifications.dialog-title',
            defaultMessage: 'Delete all notifications?',
        },
        dialogContent: {
            id: 'notifications.dialog-content',
            defaultMessage: 'Are you sure you want to delete all your notifications?',
        },
        dialogConfirm: {
            id: 'notifications.confirm-text',
            defaultMessage: 'Yes, delete all',
        },
        dialogCancel: i18nActionConstants.cancel,
    },
    clearAll: {
        id: 'notifications.clear-all',
        defaultMessage: 'Clear all',
    },
    preferences: {
        id: 'notiifications.preferences',
        defaultMessage: 'Preferences',
    },
    filter: {
        id: 'notiifications.filter',
        defaultMessage: 'Filter Notifications',
    },
    trayTitle: {
        id: 'notificaitons.tray-title',
        defaultMessage: 'Notifications {new, plural, =0 {} other {(#)}}',
    },
    moreNotifications: {
        id: 'notifications.more',
        defaultMessage:
            '{extra, plural, =0 {All Notifications} =1 {# more new notification} other {# more new notifications}}',
    },
    emptyTitleTray: {
        id: 'notifications.empty-title-tray',
        defaultMessage: 'No notifications right now',
    },
    emptyContentTray: {
        id: 'notifications.empty-content-tray',
        defaultMessage:
            "You'll receive notifications here when there are new updates to share with you.",
    },
    emptyTitle: {
        id: 'notifications.empty-title',
        defaultMessage: 'No notifications right now',
    },
    emptySubtitle: {
        id: 'notifications.empty-subtitle',
        defaultMessage: 'You are up to date!',
    },
    emptyContent: {
        id: 'notifications.empty-content',
        defaultMessage:
            "You'll receive notifications here when there are updates to share with you.",
    },
    disabledTitle: {
        id: 'notifications.disable-title',
        defaultMessage: 'All notifications have been disabled',
    },
    disabledContent: {
        id: 'notifications.disbale-content',
        defaultMessage:
            'Enable notifications to stay informed on project updates and to timely collaborate with your teams',
    },
    previousLink: {
        id: 'notifications.previous-notifications',
        defaultMessage: 'Notifications',
    },
    disabledNotification: {
        title: {
            id: 'notifications.disabled.title',
            defaultMessage: 'Notifications have been disabled in Central',
        },
        enable: {
            id: 'notifications.disabled.enable',
            defaultMessage: 'Enable all notifications',
        },
        description: {
            id: 'notifications.disabled.description',
            defaultMessage: 'to reactivate your settings and receive notifications.',
        },
        disabled: {
            id: 'notifications.disabled',
            defaultMessage: 'Off',
        },
    },
    paginationLabel: {
        id: 'notifications.pagination.type-label',
        defaultMessage: 'Notifications',
    },
    paginationTitle: {
        id: 'notifications.pagination.title',
        defaultMessage: 'Show',
    },
};
