import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Account, AccountResponse, UsersByType } from 'state-domains/domain';

import { buildAccountUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';

export const loadAccountInformation = (
    actionName?: string,
    processResultFunction?: Function,
): Observable<{ result: any; actionName: string; processResultFunction: Function }> => {
    const url = buildAccountUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<AccountResponse>) => {
            const { account: accountResponse, users_by_type } = response;
            const account = convertToCamel<Account>(accountResponse);
            const users = convertToCamel<UsersByType>(users_by_type);
            return observableOf({
                result: { account, users },
                actionName: actionName ?? loadAccountInformation.name,
                processResultFunction: processResultFunction ?? (() => {}),
            });
        }),
    );
};
