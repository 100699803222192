import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            width: theme.spacing(8.75), // EdgeHTML 18 lacks support for 'min-content', which is about 70px,
            float: 'right', // fix for weird stuff happening with the popper affecting the width on open specifically on Safari.
            backgroundColor: theme.palette.grey[50],
            maxWidth: theme.spacing(37),
            height: '100%',
            paddingLeft: `${theme.spacing(2.5)}`,
            paddingRight: '2.5px',
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[100],
            },
        },
        rootDark: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[800],
            },
        },
        rootMySeequent: {
            borderLeft: `1px solid ${theme.palette.grey[700]}`,
            height: theme.spacing(7),
        },
        icon: {
            color: 'inherit',
        },
        iconDark: {
            color: theme.palette.common.white,
        },
        iconMySeequent: {
            color: theme.palette.grey[400],
        },
        disabledIcon: {
            color: theme.palette.grey[400],
        },
        open: {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: '0 1px 6px 0 rgba(29, 31, 35, 0.13)',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        openDark: {
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: '0 1px 6px 0 rgba(29, 31, 35, 0.13)',
            '&:hover': {
                backgroundColor: theme.palette.grey[800],
            },
        },
        popper: { height: 0 },
        unreadNotificationsCounter: {
            position: 'relative',
            bottom: '11.5px',
            right: '12px',
        },
    } as const);

export const useStyles = makeStyles()(styles);
