import { MapPinIcon, TraceProps, useTrace } from '@local/web-design-system';
import { Grid, Icon, Typography } from '@mui/material';
import { divIcon, latLng } from 'leaflet';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { Marker, Popup, useMapEvent as useLeafletMapEvent } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ACTIVITY_TYPES } from 'state-domains/constants';

import { getProjectIdFromParams, getSelectedDrillHoleUrl, getSelectedPointUrl } from 'src/routes';
import { convertToDegreesMinutesSeconds } from 'src/utilities';

import { useStyles } from './DrillholeMap.styles';
import {
    AutoWrapMarkersProps,
    DrillholeMarkerProps,
    DrillholeMarkersProps,
    MapItemProps,
} from './DrillholeMap.types';
import { i18n } from './Map.i18n';
import { WrapMarkers } from './MapUtils';
import 'leaflet/dist/leaflet.css';

const DrillholeMarkerSatelliteIcon = () => (
    <img src="/images/DrillholeMarker_White.svg" alt="DrillholeMarkerWhite" />
);
const DrillholeMarkerStreetIcon = () => (
    <img src="/images/DrillholeMarker_Blue.svg" alt="DrillholeMarkerBlue" />
);
const PointMarkerSatelliteIcon = () => (
    <img src="/images/PointMarker_White.svg" alt="PointMarkerWhite" />
);
const PointMarkerStreetIcon = () => (
    <img src="/images/PointMarker_Blue.svg" alt="PointMarkerBlue" />
);

function getCollarUrl(collar: MapItemProps, projectId: string) {
    return collar.type === ACTIVITY_TYPES.DRILLING
        ? getSelectedDrillHoleUrl(projectId, collar.id)
        : getSelectedPointUrl(projectId, collar.id);
}
export function DrillholePinIcon(
    { id: drillholeId, type }: MapItemProps,
    applyTrace: (suffix?: string) => TraceProps,
    currentLayer: string,
    largeMap: boolean,
    drillholeName: string,
    showLabels: boolean,
) {
    // The icon to mark the location of the project. We render it in a div, in Leaflet,
    // because it is an SVG, rather than a raster image. Because it is a downward pointing triangle
    // we need to provide the size and anchor point.
    const { classes } = makeStyles()({
        icon: {
            fontSize: '14px',
        },
        label: {
            display: 'inline-block',
            backgroundColor: '#265C7F',
            color: 'white',
            fontSize: '12px',
            minInlineSize: '30px',
            paddingLeft: '4px',
            paddingRight: '4px',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
        },
    })();
    const { formatMessage } = useIntl();
    const width = 14;
    const height = 14;
    const isSatellite = currentLayer === formatMessage(i18n.SatelliteTitle);
    const drillholeIcon = (
        <Icon fontSize="inherit" {...applyTrace(`${drillholeId}`)}>
            {isSatellite ? <DrillholeMarkerSatelliteIcon /> : <DrillholeMarkerStreetIcon />}
        </Icon>
    );
    const pointIcon = (
        <Icon fontSize="inherit" {...applyTrace(`${drillholeId}`)}>
            {isSatellite ? <PointMarkerSatelliteIcon /> : <PointMarkerStreetIcon />}
        </Icon>
    );
    const component = (
        <Grid>
            {type === ACTIVITY_TYPES.DRILLING ? drillholeIcon : pointIcon}
            {showLabels && <Grid className={classes.label}>{drillholeName}</Grid>}
        </Grid>
    );

    return divIcon({
        className: classes.icon,
        iconSize: [width, height],
        iconAnchor: [width / 2, height], // Centre bottom of the icon.
        popupAnchor: [-width / (largeMap ? 1 : 1.8), -height * (largeMap ? 2.35 : 0.95)], // Middle. It just is.
        html: renderToString(component),
    });
}

export function LocationMarkerIcon(applyTrace: (suffix?: string) => TraceProps) {
    // The icon to mark the location of the project. We render it in a div, in Leaflet,
    // because it is an SVG, rather than a raster image. Because it is a downward pointing triangle
    // we need to provide the size and anchor point.
    const { classes } = makeStyles()({
        icon: {
            fontSize: '42px',
        },
    })();
    const width = 17 * 2; // The MapPinIcon svg is a 17 by 23 aspect ratio
    const height = 23 * 2;
    return divIcon({
        className: classes.icon,
        iconSize: [width, height],
        iconAnchor: [width / 2, height], // Centre bottom of the icon.
        popupAnchor: [-width / 2.0, -height * 0.95], // Middle. It just is.
        html: renderToString(
            <MapPinIcon fontSize="inherit" {...applyTrace('location-map-marker')} />,
        ),
    });
}

export function DrillholeMarker({
    drillhole,
    wrappedLongitude,
    hideMapMarkerPopup,
    currentLayer,
    locationMap = false,
    overviewMap = false,
    onExpand = undefined,
    onSelectCallback = undefined,
    showLabels = false,
    disableMap = false,
}: DrillholeMarkerProps) {
    const { classes } = useStyles();
    const params = useParams();
    const projectId = getProjectIdFromParams(params);

    const [drillholeLatLng, setDrillholeLatLng] = React.useState(
        latLng(
            drillhole.wgs84.northing || 0, // These should never be undefined due to this only receiving projects with valid lat lng, just making typescript happy
            wrappedLongitude || drillhole.wgs84.easting || 0,
        ),
    );

    const markerRef = React.useRef(null);
    const applyTrace = useTrace('drillholes-map-marker-icon');

    const location = drillhole?.wgs84;
    React.useEffect(() => {
        if (location) {
            setDrillholeLatLng(
                latLng(
                    location.northing || 0, // These should never be undefined due to this only receiving projects with valid lat lng, just making typescript happy
                    wrappedLongitude || location.easting || 0,
                ),
            );
        }
    }, [location]);

    const drillholeName =
        drillhole?.name?.length > 50 ? `${drillhole.name.substring(0, 50)}...` : drillhole.name;

    return (
        <Marker
            ref={markerRef}
            key={drillhole.id}
            position={drillholeLatLng}
            title=""
            icon={
                locationMap
                    ? LocationMarkerIcon(applyTrace)
                    : DrillholePinIcon(
                          drillhole,
                          applyTrace,
                          currentLayer,
                          overviewMap,
                          drillholeName,
                          showLabels,
                      )
            }
            bubblingMouseEvents={false}
            eventHandlers={{
                click: () => {
                    if (hideMapMarkerPopup && onSelectCallback && !disableMap)
                        onSelectCallback(drillhole);
                    if (hideMapMarkerPopup && onExpand && !disableMap) onExpand();
                },
            }}
        >
            {!hideMapMarkerPopup && !showLabels && (
                <Popup
                    closeOnClick
                    closeOnEscapeKey
                    closeButton={false}
                    className={overviewMap ? classes.popup : classes.smallPopup}
                    autoPan={false}
                >
                    <Grid container direction="column">
                        <Grid item sx={{ width: '100%' }}>
                            <Link
                                to={getCollarUrl(drillhole, projectId)}
                                key={`link_${drillhole.id}`}
                                className={classes.link}
                            >
                                {drillhole.name}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography>{`${convertToDegreesMinutesSeconds(
                                location.northing ?? 0,
                                true,
                            )} ${convertToDegreesMinutesSeconds(
                                location.easting ?? 0,
                                false,
                            )}`}</Typography>
                        </Grid>
                    </Grid>
                </Popup>
            )}
        </Marker>
    );
}

export function AutoWrapMarkers({
    map,
    drillholesWithLatLng,
    setWrappedLongitudes,
}: AutoWrapMarkersProps) {
    useLeafletMapEvent('zoomend', () => {
        if (map) {
            setWrappedLongitudes(WrapMarkers(map.getBounds(), drillholesWithLatLng));
        }
    });
    useLeafletMapEvent('move', () => {
        if (map) {
            setWrappedLongitudes(WrapMarkers(map.getBounds(), drillholesWithLatLng));
        }
    });
    return null;
}

export function DrillholeMarkers(props: DrillholeMarkersProps) {
    const {
        drillholesWithLatLng,
        wrappedLongitudes,
        hideMapMarkerPopup,
        currentLayer,
        locationMap = false,
        overviewMap = false,
        onExpand = undefined,
        showLabels = false,
        onSelectCallback = undefined,
        disableMap,
    } = props;
    return (
        <MarkerClusterGroup
            zoomToBoundsOnClick={false}
            spiderfyOnMaxZoom={!onExpand}
            animate={false}
            eventHandlers={{
                clusterclick: (group: any) => {
                    const mapGroup = { ...group };
                    if (mapGroup.layer.getChildCount() < 10) {
                        mapGroup.target.options.spiderfyDistanceMultiplier = 2;
                    } else if (mapGroup.layer.getChildCount() < 100) {
                        mapGroup.target.options.spiderfyDistanceMultiplier = 1.85;
                    } else {
                        mapGroup.target.options.spiderfyDistanceMultiplier = 1.7;
                    }
                    mapGroup.layer.spiderfy();
                    if (hideMapMarkerPopup && onExpand) onExpand();
                },
            }}
        >
            {drillholesWithLatLng.map((drillhole: MapItemProps) => (
                <DrillholeMarker
                    key={drillhole.id}
                    drillhole={drillhole}
                    wrappedLongitude={wrappedLongitudes[drillhole.id]}
                    hideMapMarkerPopup={hideMapMarkerPopup}
                    currentLayer={currentLayer}
                    locationMap={locationMap}
                    overviewMap={overviewMap}
                    onExpand={onExpand}
                    showLabels={showLabels}
                    onSelectCallback={onSelectCallback}
                    disableMap={disableMap}
                />
            ))}
        </MarkerClusterGroup>
    );
}
