import { useTrace } from '@local/web-design-system';
import { Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getFooterItems } from './ContentAreaFooter.i18n';
import { useStyles } from './ContentAreaFooter.styles';
import { FooterItemProps } from './ContentAreaFooter.types';

export const FooterItem = ({ id, defaultMessage, url, version }: FooterItemProps) => {
    const applyTrace = useTrace('footer');
    const formattedText = (
        <FormattedMessage id={id} defaultMessage={`${defaultMessage} ${version}`} />
    );

    return (
        <Grid item>
            <Typography variant="caption" color="textSecondary" {...applyTrace(id)}>
                {url ? (
                    <Link href={url} target="_blank" rel="noreferrer" color="textSecondary">
                        {formattedText}
                    </Link>
                ) : (
                    formattedText
                )}
            </Typography>
        </Grid>
    );
};

export function FooterRow() {
    const { classes } = useStyles();
    return (
        <Grid item xs={12} className={classes.footerRowRoot}>
            <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                {getFooterItems().map((itemProps: FooterItemProps) => (
                    <FooterItem key={itemProps.id} {...itemProps} />
                ))}
            </Grid>
        </Grid>
    );
}

export default FooterRow;
