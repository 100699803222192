import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildProjectThumbnailUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const deleteProjectThumbnail = (projectId: string): Observable<any> => {
    const url = buildProjectThumbnailUrl(projectId);
    return sendRequestWithXSRFToken(url, '', 'DELETE').pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf({ ...convertToCamel<any>(response) }),
        ),
    );
};
