import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildImportListUrl } from '../../../network/urls';
import {
    convertToCamel,
    sendFileInBodyUploadRequestWithXSRFTokenCatchErrors,
} from '../../../utils';

export const importList = (file: File, listId: string, dateFormat?: string): Observable<any> => {
    const url = buildImportListUrl(listId, dateFormat);
    return sendFileInBodyUploadRequestWithXSRFTokenCatchErrors(url, { file }).pipe(
        switchMap((result: any) => {
            const converted = !result.ok ? result : convertToCamel(result);
            return observableOf(converted);
        }),
    );
};
