import { Dispatch, Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

import { typePending, typeComplete, typeFail } from 'state-domains/utils';

import { ShimState, Uid } from '../../../../types';
import {
    CREATE_FILTERS,
    DELETE_FILTERS,
    DELETE_RESOURCES,
    LOAD_FILTERS,
    LOAD_RESOURCES,
    UPDATE_FILTERS,
    CREATE_RESOURCES,
    UPDATE_DIGEST,
    LOAD_DIGEST,
} from '../../../../types/actionTypes';
import { userState } from '../../../user';
import { createAction } from '../../../utils';
import { notificationsShim as shim } from '../../shim';
import { instanceResource, projectResource } from '../../shim/translators';
import {
    ResourcesPayload,
    FiltersPayload,
    Channel,
    FilterProps,
    ProjectTopics,
    DigestPeriod,
    DigestPreference,
} from '../../types';
import { selectors } from '../selectors';
import { withToken, buildFilterResource } from '../utils';

const {
    selectors: { audienceUid },
} = userState;

// ================================ Email Digest Actions ===============================

const loadDigestPreference = createAction(LOAD_DIGEST, (dispatcher) => {
    const uid = audienceUid(dispatcher.getState());
    const instanceUid = selectors.instanceUid(dispatcher.getState());
    const resource = instanceResource(instanceUid);
    dispatcher.pending();

    const onComplete = (preferences: DigestPreference[]) => {
        const digestPreference = preferences.find((val) => val.resource === resource);
        dispatcher.complete({ digestPreference });
    };

    const onFail = (error: AjaxError) => {
        dispatcher.fail({ error });
    };

    const shimFunction = withToken(
        dispatcher.reduxDispatch,
        dispatcher.getReduxState,
        shim.loadDigestPreferences,
    );

    shimFunction(uid).subscribe({ next: onComplete, error: onFail });
});

const updateEmailDigest = createAction(UPDATE_DIGEST, (dispatcher, newDigest: DigestPeriod) => {
    const selfLink = selectors.getDigestSelfLink(dispatcher.getState());
    dispatcher.pending();

    const onComplete = (digestPreference: DigestPreference) => {
        dispatcher.complete({ digestPreference });
    };

    const onFail = (error: AjaxError) => {
        dispatcher.fail({ error });
    };

    let subscription$;
    if (selfLink) {
        const shimFunction = withToken(
            dispatcher.reduxDispatch,
            dispatcher.getReduxState,
            shim.updateDigestPreference,
        );
        subscription$ = shimFunction(selfLink, newDigest);
    } else {
        const uid = audienceUid(dispatcher.getState());
        const instanceUid = selectors.instanceUid(dispatcher.getState());
        const resource = instanceResource(instanceUid);
        const shimFunction = withToken(
            dispatcher.reduxDispatch,
            dispatcher.getReduxState,
            shim.createDigestPreference,
        );
        subscription$ = shimFunction(uid, resource, newDigest);
    }

    subscription$.subscribe({ next: onComplete, error: onFail });
});

// ================================ Filter Actions ===============================

function loadFilters() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const uid = audienceUid(getState());
        dispatch({ type: typePending(LOAD_FILTERS) });
        const shimFunction = withToken(dispatch, getState, shim.loadFilters);
        shimFunction(uid).subscribe({
            next: (payload: FiltersPayload) => {
                dispatch({ payload, type: typeComplete(LOAD_FILTERS) });
            },
            error: (err: Error) => dispatch({ type: typeFail(LOAD_FILTERS), payload: err }),
        });
    };
}

function createFilter(filterProps: FilterProps, channel: Channel, enabled: boolean) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const uid = audienceUid(getState());
        const { topic } = filterProps;
        const resource = buildFilterResource(getState(), filterProps);
        if (!resource) {
            return;
        }

        // Remove this check when we want to allow the user to change app mention preferences.
        // Currently we do not want users to be able to change mention app permissions. (WEBAPPS-2308)
        // The option will be disabled in the UI, but this is a small failsafe.
        if (filterProps.topic === ProjectTopics.MENTION && channel === Channel.APP) {
            return;
        }

        const payload = { topic, channel };
        const allProps = { topic, channel, resource };
        dispatch({ payload, type: typePending(CREATE_FILTERS) });
        const shimFunction = withToken(dispatch, getState, shim.createFilter);
        shimFunction(uid, allProps, enabled).subscribe({
            next: (payload: FiltersPayload) => {
                dispatch({ payload, type: typeComplete(CREATE_FILTERS) });
            },
            error: (err: Error) =>
                dispatch({ payload: { ...payload, ...err }, type: typeFail(CREATE_FILTERS) }),
        });
    };
}

function updateFilter(filterProps: FilterProps, channel: Channel, enabled: boolean) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const payload = { channel, topic: filterProps.topic };
        const filter = selectors.filter(getState(), filterProps, channel);
        if (filter === undefined) {
            return;
        }

        // Remove this check when we want to allow the user to change app mention preferences.
        // Currently we do not want users to be able to change mention app permissions. (WEBAPPS-2308)
        // The option will be disabled in the UI, but this is a small failsafe.
        if (filterProps.topic === ProjectTopics.MENTION && channel === Channel.APP && !enabled) {
            return;
        }

        dispatch({ payload, type: typePending(UPDATE_FILTERS) });
        const shimFunction = withToken(dispatch, getState, shim.updateFilter);
        shimFunction(filter.self, enabled).subscribe({
            next: (payload: FiltersPayload) => {
                dispatch({ payload, type: typeComplete(UPDATE_FILTERS) });
            },
            error: (err: Error) =>
                dispatch({ payload: { ...payload, ...err }, type: typeFail(UPDATE_FILTERS) }),
        });
    };
}

function toggleFilter(filterProps: FilterProps, channel: Channel, enabled: boolean) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const filter = selectors.filter(getState(), filterProps, channel);
        if (filter === undefined) {
            createFilter(filterProps, channel, enabled)(dispatch, getState);
        } else {
            updateFilter(filterProps, channel, enabled)(dispatch, getState);
        }
    };
}

function deleteFilter(filterProps: FilterProps, channel: Channel) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const payload = { channel, topic: filterProps.topic };
        const filter = selectors.filter(getState(), filterProps, channel);
        if (filter === undefined) {
            return;
        }

        dispatch({ payload, type: typePending(DELETE_FILTERS) });
        const shimFunction = withToken(dispatch, getState, shim.deleteFilter);
        shimFunction(filter.self).subscribe({
            next: () => {
                dispatch({ payload, type: typeComplete(DELETE_FILTERS) });
            },
            error: (err: Error) =>
                dispatch({ payload: { ...payload, ...err }, type: typeFail(DELETE_FILTERS) }),
        });
    };
}

// ================================ Resource Actions ===============================

function loadUnsubscribeList() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        dispatch({ type: typePending(LOAD_RESOURCES) });
        const shimFunction = withToken(dispatch, getState, shim.loadResources);
        shimFunction(audience).subscribe({
            next: (payload: ResourcesPayload) => {
                dispatch({ payload, type: typeComplete(LOAD_RESOURCES) });
            },
            error: (payload: Error) => dispatch({ payload, type: typeFail(LOAD_RESOURCES) }),
        });
    };
}

function unsubscribe(resource: string) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        dispatch({ type: typePending(CREATE_RESOURCES) });
        const shimFunction = withToken(dispatch, getState, shim.createResource);
        shimFunction(audience, { resource }).subscribe({
            next: (payload: ResourcesPayload) => {
                dispatch({ payload, type: typeComplete(CREATE_RESOURCES) });
            },
            error: (payload: Error) => dispatch({ payload, type: typeFail(CREATE_RESOURCES) }),
        });
    };
}

function unsubscribeInstance() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const instanceUid = selectors.instanceUid(getState());
        const resource = instanceResource(instanceUid);
        unsubscribe(resource)(dispatch, getState);
    };
}

function unsubscribeProject(projectUid: Uid) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const instanceUid = selectors.instanceUid(getState());
        const resource = projectResource(instanceUid, projectUid);
        unsubscribe(resource)(dispatch, getState);
    };
}

function subscribe(resource: string) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const payload = { resource };
        const url = selectors.resourceLink(getState(), { resource });
        if (url === undefined) {
            return;
        }
        dispatch({ payload, type: typePending(DELETE_RESOURCES) });
        const shimFunction = withToken(dispatch, getState, shim.deleteResource);
        shimFunction(url).subscribe({
            next: () => {
                dispatch({ payload, type: typeComplete(DELETE_RESOURCES) });
            },
            error: (err: Error) =>
                dispatch({ payload: { ...payload, ...err }, type: typeFail(DELETE_RESOURCES) }),
        });
    };
}

function subscribeInstance() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const instanceUid = selectors.instanceUid(getState());
        const resource = instanceResource(instanceUid);
        subscribe(resource)(dispatch, getState);
    };
}

function subscribeAllProject(projectUidList: Uid[]) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const instanceUid = selectors.instanceUid(getState());
        projectUidList.forEach((projectUid) => {
            const resource = projectResource(instanceUid, projectUid);
            subscribe(resource)(dispatch, getState);
        });
    };
}

export const actions = {
    loadUnsubscribeList,
    unsubscribe,
    unsubscribeInstance,
    unsubscribeProject,
    subscribe,
    subscribeInstance,
    subscribeAllProject,
    updateEmailDigest,
    loadDigestPreference,

    loadFilters,
    toggleFilter,
    createFilter,
    updateFilter,
    deleteFilter,
};
