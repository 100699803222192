import { Notification, NotificationType, theme } from '@local/web-design-system';
import { Divider, Grid, Button, Dialog, LinearProgress, Backdrop } from '@mui/material';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTY_OBJECT } from 'state-domains/constants';
import { userState } from 'state-domains/domain';
import { getErrorFromState } from 'state-domains/domain/utils';
import { AsyncState, AsyncStateError } from 'state-domains/types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';

import { actionButtonSpacing, cancelButtonSpacing } from 'src/styles/modal.styles';

import { i18n as commonErrors } from '../../Errors/Errors.i18n';
import { i18n } from './DrillholeModal.i18n';
import { useStyles } from './DrillholeModal.styles';
import { DrillholeModalProps } from './DrillholeModal.types';

export const MODAL_AUTOMATION_IDS = {
    TITLE: 'modal-title',
    SUBTITLE: (value: string) => `modal-subtitle-${value}`,
    DROPDOWN_INDICATOR: 'modal-dropdown-indicator',
    INPUT_FIELD: 'modal-input',
    DROPDOWN_OPTION: (value: string) => `modal-dropdown-option-${value}`,
    BODY: 'modal-msg',
};

export const DrillholeModal = forwardRef((props: DrillholeModalProps, ref) => {
    const {
        actions: { updateUserModalOpen },
    } = userState;
    const { classes } = useStyles();
    const {
        dialog,
        error: errorState = () => EMPTY_OBJECT as AsyncStateError,
        state = () => EMPTY_OBJECT as AsyncState,
        open,
        handleClose,
        handleApply,
        handleCancel = handleClose,
        applyDisabled = false,
        cancelLabel,
        applyLabel,
        AdditionalFilter = false,
        filterView = false,
        warning = '',
        informationModal = false,
    } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const isPending = useSelector(isPendingSelector(state));
    const isFailed = useSelector(isFailedSelector(state));
    const isComplete = useSelector(isCompleteSelector(state));
    const { error, i18nKey } = getErrorFromState(useSelector(errorState));

    const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

    const [inProgress, setInProgress] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationProps, setNotificationProps] = React.useState<{
        message: string;
        type: NotificationType;
    }>({} as any);

    React.useEffect(() => {
        if (isComplete || isFailed) {
            setDisableEscapeKeyDown(false);
            setInProgress(false);
            if (isFailed && !!error) {
                setNotificationProps({
                    message:
                        i18nKey && (error.message ?? '') in commonErrors
                            ? intl.formatMessage(commonErrors[error.message ?? ''])
                            : error.message!,
                    type: NotificationType.ERROR,
                });
                setShowNotification(true);
            } else {
                setShowNotification(false);
            }
        } else {
            setShowNotification(false);
        }
    }, [isComplete, isFailed, isPending, error]);

    useImperativeHandle(ref, () => ({
        setProgress(isInProgress = true) {
            setDisableEscapeKeyDown(isInProgress);
            setInProgress(isInProgress);
            if (isInProgress && !!warning) {
                setNotificationProps({
                    message: warning,
                    type: NotificationType.WARNING,
                });
                setShowNotification(true);
            } else {
                setShowNotification(false);
            }
        },
    }));

    useEffect(() => {
        if (open) {
            updateUserModalOpen(true)(dispatch);
        }
        return () => {
            updateUserModalOpen(false)(dispatch);
        };
    }, [open]);

    return (
        <>
            {open && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    disableEscapeKeyDown={disableEscapeKeyDown}
                    PaperProps={{ square: true }}
                    sx={{
                        '& .MuiDialog-paper': {
                            maxWidth: '100%',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            top: filterView ? '11vh' : undefined,
                        },
                    }}
                    classes={{
                        scrollPaper: filterView ? classes.topScrollPaper : undefined,
                        paperScrollBody: filterView ? classes.topPaperScrollBody : undefined,
                    }}
                    automation-id="modalContainer"
                >
                    <Backdrop
                        sx={{ backgroundColor: 'transparent', zIndex: 99 }}
                        open={open && inProgress}
                    />
                    <form onSubmit={handleApply}>
                        <Grid container direction="row">
                            <Grid
                                item
                                className={classes.mainBody}
                                sx={{ margin: '10px', height: 'fit-content' }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    className={classes.mainBody}
                                    style={{ maxHeight: '220px' }}
                                />
                                <Grid container className={classes.root} direction="column">
                                    <Grid item container className={classes.primaryColor}>
                                        {dialog}
                                    </Grid>
                                    {showNotification && (
                                        <Grid item className={classes.errorPanel}>
                                            <Notification
                                                {...notificationProps}
                                                classes={{
                                                    notificationMessage: classes.notification,
                                                    root: classes.error,
                                                    contentText: classes.notificationMessage,
                                                    border: classes.border,
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item className={classes.divider}>
                                        <Divider />
                                    </Grid>
                                    <Grid container>
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            justifyContent="center"
                                            className={classes.btnPanel}
                                        >
                                            <Grid
                                                container
                                                width={theme.spacing(cancelButtonSpacing)}
                                            >
                                                {!informationModal && (
                                                    <Button
                                                        fullWidth
                                                        color="secondary"
                                                        variant="contained"
                                                        className={classes.button}
                                                        onClick={handleCancel}
                                                        disabled={inProgress}
                                                        automation-id="modal-cancel-btn"
                                                    >
                                                        {cancelLabel ??
                                                            intl.formatMessage(i18n.cancel)}
                                                    </Button>
                                                )}
                                            </Grid>
                                            <Grid container width={theme.spacing(2)} />

                                            <Grid
                                                container
                                                width={theme.spacing(actionButtonSpacing)}
                                            >
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={applyDisabled || inProgress}
                                                    className={classes.button}
                                                    automation-id="modal-action-btn"
                                                >
                                                    {applyLabel ?? intl.formatMessage(i18n.apply)}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {isPending && inProgress && (
                                        <Grid item sx={{ width: '100%' }}>
                                            <LinearProgress
                                                color="primary"
                                                className={classes.progressBar}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {AdditionalFilter}
                        </Grid>
                    </form>
                </Dialog>
            )}
        </>
    );
});
