import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { EmptyFileGroups } from 'state-domains/domain/drillhole';

import { buildEmptyListFileGroupsUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';

export const loadEmptyListFileGroups = (listId: string): Observable<{ [id: string]: boolean }> => {
    const url = buildEmptyListFileGroupsUrl(listId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<EmptyFileGroups>) => {
            const { ListFileGroup } = response;
            const result = convertToCamel<EmptyFileGroups[]>(ListFileGroup);
            return observableOf(Object.assign({}, ...result.map((x) => ({ [x.id]: true }))));
        }),
    );
};
