import { partition } from 'lodash-es';

import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';
import { getAllUsersForProject } from 'src/utilities/filter-helpers';

import {
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    CLIENT_SIDE_PAGINATION_LIMIT,
} from '../../../constants';
import { BaseAction } from '../../../types';
import {
    LIST_PROJECTS,
    LOGOUT_REQUEST,
    PIN_PROJECT,
    SELECT_PROJECT,
    SET_SEARCH_TERM,
    REFRESH_STATE,
    SELECTED_PROJECT_PERMISSIONS,
    MAP_VIEW_STATE,
    LOAD_OVERVIEW_COLLARS_WITH_COUNT,
    CLEAR_OVERVIEW_COLLARS,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE,
    LOAD_PROJECT,
    CLEAR_PROJECT_LOAD_STATE,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { ActivityTableList, MapTypes, Project, ProjectError, ProjectState } from '../types';

export const INITIAL_OVERVIEW_COLLARS_STATE = {
    status: LOAD_STATUS_STALE,
    error: null,
    items: {},
    count: 0,
};

export const INITIAL_STATE: ProjectState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    singleProjectLoadState: { error: null, status: LOAD_STATUS_STALE },
    projectLoadState: { error: null, status: LOAD_STATUS_PENDING },
    pinProjectState: { error: null, status: LOAD_STATUS_PENDING },
    projects: {},
    allProjects: {},
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
    selectedProjectPermissions: {},
    selectedProjectTablesInActivities: {},
    selectedProjectPermissionsState: {
        error: null,
        status: LOAD_STATUS_PENDING,
    },
    mapType: MapLayer.SATELLITE,
    overviewMapCollars: INITIAL_OVERVIEW_COLLARS_STATE,
};

function setSearchTermReducer(
    state: ProjectState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): ProjectState {
    const { searchTerm, offset, limit } = action.payload;
    return {
        ...state,
        searchTerm,
        offset,
        limit,
    };
}

function selectProjectReducer(state: ProjectState, action: BaseAction<{ projectUid: string }>) {
    const { projectUid } = action.payload;

    return {
        ...state,
        selected: projectUid,
        selectedProjectPermissionsState: pendingReducer({}),
    };
}

function selectedProjectPermissionAndTablesInActivities(
    state: ProjectState,
    action: BaseAction<{
        projectUid: string;
        projectPermissions: any;
        tablesInActivities: ActivityTableList;
    }>,
) {
    const { projectPermissions, tablesInActivities } = action.payload;

    return {
        ...state,
        selectedProjectPermissions: projectPermissions,
        selectedProjectTablesInActivities: tablesInActivities,
        selectedProjectPermissionsState: completeReducer({}),
    };
}

const projectsPendingReducer = (state: ProjectState): ProjectState =>
    pendingReducer({
        ...state,
        projectLoadState: pendingReducer({}),
    });

const listProjectsSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const {
        payload: { projectsList, userId },
    } = actions;
    // Why is the projectsList sometimes undefined?
    const filteredProjects = (projectsList ?? []).filter((p: Project) =>
        getAllUsersForProject(p, Object.values(p.activities ?? {})).includes(userId),
    );
    return {
        ...state,
        allProjects: Object.fromEntries(projectsList.map((p: Project) => [p.id, p])),
        projects: Object.fromEntries(filteredProjects.map((p: Project) => [p.id, p])),
        projectLoadState: completeReducer({}),
    };
};

const loadProjectPendingReducer = (state: ProjectState): ProjectState => ({
    ...state,
    singleProjectLoadState: pendingReducer({}),
});

const loadProjectSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const {
        payload: { project },
    } = actions;
    return {
        ...state,
        allProjects: { ...state.allProjects, [project.id]: project },
        projects: { ...state.projects, [project.id]: project },
        singleProjectLoadState: completeReducer({}),
    };
};

const loadProjectFailReducer = (state: ProjectState, action: BaseAction): ProjectState => ({
    ...state,
    singleProjectLoadState: failureReducer({ error: action.payload.error }),
});

const projectsFailureReducer = (state: ProjectState, action: BaseAction<ProjectError>) => {
    const { error } = action.payload;
    return { ...state, projectLoadState: failureReducer({ ...state.projectLoadState, error }) };
};

const setProjectPinPendingReducer = (state: ProjectState): ProjectState =>
    pendingReducer({
        ...state,
        pinProjectState: pendingReducer({}),
    });

const setProjectPinSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const { projects } = state;
    const projectsToUpdate = { ...projects };
    const {
        payload: { projectId, pin },
    } = actions;
    projectsToUpdate[projectId].pinned = pin;
    return { ...state, projects: projectsToUpdate, pinProjectState: completeReducer({}) };
};

const setProjectPinFailureReducer = (state: ProjectState, action: BaseAction<ProjectError>) => {
    const { error } = action.payload;
    return { ...state, pinProjectState: failureReducer({ ...state.pinProjectState, error }) };
};

const resetProjectStateReducer = (_state: ProjectState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const selectedMapView = (state: ProjectState, action: BaseAction<MapTypes>) => {
    const type = action.payload.mapType;
    return { ...state, mapType: type };
};

const loadOverviewMapCollarsPendingReducer = (state: ProjectState): ProjectState => {
    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: pendingReducer({ ...overviewMapCollarsState }),
    };
};

const loadOverviewMapCollarsSuccessReducer = (state: ProjectState, actions: BaseAction) => {
    const { payload } = actions;
    const result = partition(payload, (x) => Array.isArray(x));
    const items = result[0];
    const sizes = result[1];

    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: completeReducer({
            ...overviewMapCollarsState,
            items: items.flat(),
            count: sizes.reduce(
                (accumulator: number, currentValue: { size: number }) =>
                    accumulator + (currentValue.size ?? 0),
                0,
            ),
        }),
    };
};

const loadOverviewMapCollarsFailureReducer = (
    state: ProjectState,
    action: BaseAction<ProjectError>,
) => {
    const { error } = action.payload;
    const { overviewMapCollars: overviewMapCollarsState } = state;
    return {
        ...state,
        overviewMapCollars: failureReducer({ ...overviewMapCollarsState, error }),
    };
};

const clearOverviewCollarsReducer = (state: ProjectState, _action: BaseAction) => ({
    ...state,
    overviewMapCollars: { ...INITIAL_OVERVIEW_COLLARS_STATE },
});

const clearProjectLoadState = (state: ProjectState, _action: BaseAction) => ({
    ...state,
    singleProjectLoadState: { error: null, status: LOAD_STATUS_STALE },
});

const cudCollectionItemCompleteReducer = (state: ProjectState, action: BaseAction) => {
    // Create/Update/Delete an item in project state collection. Operates on the complete
    // first level object in collection
    const { id, item, type, collectionName } = action.payload;
    const collection = { ...state[collectionName as keyof ProjectState] };
    if (type === 'DELETE') {
        delete collection[id];
    } else {
        collection[id] = item;
    }

    return {
        ...state,
        [collectionName]: collection,
    };
};

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_PROJECTS)]: projectsPendingReducer,
    [typeComplete(LIST_PROJECTS)]: listProjectsSuccessReducer,
    [typeFail(LIST_PROJECTS)]: projectsFailureReducer,

    [typePending(LOAD_PROJECT)]: loadProjectPendingReducer,
    [typeComplete(LOAD_PROJECT)]: loadProjectSuccessReducer,
    [typeFail(LOAD_PROJECT)]: loadProjectFailReducer,

    [typePending(PIN_PROJECT)]: setProjectPinPendingReducer,
    [typeComplete(PIN_PROJECT)]: setProjectPinSuccessReducer,
    [typeFail(PIN_PROJECT)]: setProjectPinFailureReducer,

    [SET_SEARCH_TERM]: setSearchTermReducer,
    [SELECT_PROJECT]: selectProjectReducer,
    [SELECTED_PROJECT_PERMISSIONS]: selectedProjectPermissionAndTablesInActivities,

    [typeComplete(LOGOUT_REQUEST)]: () => INITIAL_STATE,

    [REFRESH_STATE]: resetProjectStateReducer,
    [MAP_VIEW_STATE]: selectedMapView,

    [typePending(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsPendingReducer,
    [typeComplete(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsSuccessReducer,
    [typeFail(LOAD_OVERVIEW_COLLARS_WITH_COUNT)]: loadOverviewMapCollarsFailureReducer,
    [CLEAR_OVERVIEW_COLLARS]: clearOverviewCollarsReducer,
    [typeComplete(CLEAR_PROJECT_LOAD_STATE)]: clearProjectLoadState,
    [typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE)]:
        cudCollectionItemCompleteReducer,
});
