import { createSelector } from 'reselect';

import { ShimState } from '../../../types';
import { NotificationsState, AsyncMessage, TokenState, MessageState, TrayState } from '../types';
import { selectors as pSelectors } from './preferences/selectors';

const notifications = (state: Partial<ShimState>): NotificationsState => {
    const { notifications = {} as NotificationsState } = state || {};
    return notifications;
};

const instanceUid = createSelector(notifications, ({ instanceUuid: instanceUid }) => instanceUid);

const instanceDisplayName = createSelector(
    notifications,
    ({ instanceDisplayName }) => instanceDisplayName || '',
);

const messageState = createSelector(notifications, ({ messageState }) => messageState || {});

const trayState = createSelector(notifications, (state: NotificationsState) => state.trayState);

const notificationsAuth = createSelector(
    notifications,
    ({ notificationsAuth }: NotificationsState) => notificationsAuth,
);

const notificationsToken = createSelector(notificationsAuth, ({ token }: TokenState) => token);

const messageByUid = (state: Partial<ShimState>, props: { messageUid: string }): AsyncMessage => {
    const { map = {} } = messageState(state);
    const message = map[props.messageUid];
    return message || {};
};

const messageUidList = (state: Partial<ShimState>): string[] => {
    const { itemIds = [] } = messageState(state);
    return itemIds;
};

const messagesIdListSelector = createSelector(messageState, ({ itemIds }: MessageState) =>
    Array.isArray(itemIds) ? itemIds : [],
);

const trayMessagesIdListSelector = createSelector(trayState, ({ itemIds }: TrayState) =>
    Array.isArray(itemIds) ? itemIds : [],
);

const messagesDataListByIdListSelector = createSelector(
    messageState,
    (messageState: MessageState) => {
        const { map = {} } = messageState;

        return (messagesIdList: string[]): AsyncMessage[] =>
            messagesIdList.map((messageId) => map[messageId]).filter((data) => !!data);
    },
);

const unreadCount = (state: Partial<ShimState>, props: { uids: string[] }): number => {
    const { map = {} } = messageState(state);
    const { uids } = props;
    return uids.filter((id) => map[id] && !map[id].read).length;
};

const nextLink = createSelector(messageState, ({ next }) => next);

const prevLink = createSelector(messageState, ({ prev }) => prev);

const seenLink = createSelector(notifications, ({ trayState: { seen = '' } }) => seen);

const selfLink = (state: Partial<ShimState>, props: { messageUid: string }): string => {
    const message = messageByUid(state, props);
    return message.self;
};

const trayMessageIDs = createSelector(
    notifications,
    ({ trayState = {} }) => trayState?.itemIds ?? [],
);

export const selectors = {
    unreadCount,
    notifications,
    instanceUid,
    instanceDisplayName,
    notificationsToken,
    notificationsAuth,
    nextLink,
    prevLink,
    seenLink,
    messageByUid,
    messageUidList,
    selfLink,
    trayMessageIDs,

    messagesIdList: messagesIdListSelector,
    trayMessagesIdList: trayMessagesIdListSelector,
    messagesDataListByIdList: messagesDataListByIdListSelector,

    ...pSelectors,
};
