export const i18n = {
    loginButton: {
        id: 'Login.loginButton',
        defaultMessage: 'Sign In',
    },
    logoutButton: {
        id: 'Login.logoutButton',
        defaultMessage: 'Sign Out',
    },
    loading: {
        id: 'Login.loading',
        defaultMessage: 'Loading...',
    },
    registerButton: {
        id: 'Login.registerButton',
        defaultMessage: 'Create a Seequent ID',
    },
    noAccountMessage: {
        id: 'Login.noAccountMessage',
        defaultMessage: "Don't have an account?",
    },
    error: {
        unexpected: {
            id: 'login.error.unexpected',
            description: 'https://http.cat/500',
            defaultMessage:
                'An unexpected error occurred during login. Please contact Seequent support.',
        },
        401: {
            id: 'login.error.unauthorized',
            description: 'https://http.cat/401',
            defaultMessage:
                'Your MX Deposit subscription is invalid. Please contact your MySeequent admin.',
        },
        403: {
            id: 'login.error.forbidden',
            description: 'https://http.cat/403',
            defaultMessage:
                'Your MX Deposit subscription is invalid. Please contact your MySeequent admin.',
        },
    },
};
