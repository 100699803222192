import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        profilePending: {
            paddingRight: theme.spacing(2),
        },
        shortDivider: {
            marginTop: theme.spacing(2.5),
            height: `calc(100% - ${theme.spacing(5)})`,
            backgroundColor: theme.palette.grey[300],
            position: 'absolute', // Because EdgeHTML 18
        },
    } as const);
export const useStyles = makeStyles()(styles);
