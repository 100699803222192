import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Project } from 'state-domains/domain/project';

import { buildProjectUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';
import { ConfigurationCreateProjectRequest } from '../types';

export const addConfigurationProject = (
    payload: ConfigurationCreateProjectRequest,
): Observable<Project> => {
    const url = buildProjectUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<Project>) => {
            const addProjectResponse = convertToCamel<Project>(response);
            return observableOf(addProjectResponse);
        }),
    );
};
