import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationActivityUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';
import { ConfigurationActivitiesResponse } from '../types';

export const deleteConfigurationActivity = (
    id: string,
): Observable<ConfigurationActivitiesResponse> => {
    const obs = sendDeleteRequestWithXSRFToken(buildConfigurationActivityUrl(id));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationActivitiesResponse>) => {
            const res = convertToCamel<ConfigurationActivitiesResponse>(response);
            return observableOf(res);
        }),
    );
};
