import { fonts } from '@local/web-design-system';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) =>
    ({
        root: {
            backgroundImage: `url(images/signin-background.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100vh',
        },
        column: {
            height: '100vh',
            padding: theme.spacing(6),
            paddingTop: '40vh',
            [theme.breakpoints.down('xs')]: {
                height: '50vh',
                '&:first-of-type': {
                    justifyContent: 'center',
                },
                '&:last-of-type': {
                    paddingTop: '10%',
                },
            },
        },
        container: {
            [theme.breakpoints.up('md')]: {
                maxWidth: theme.spacing(40),
            },
        },
        logoTitle: {
            width: theme.spacing(25),
            height: theme.spacing(5),
        },
        formContainer: {
            backgroundImage:
                'linear-gradient(0deg, rgba(29, 31, 35, 0.76) 0%, rgba(29, 31, 35, 0.9) 100%)',
        },
        error: {
            paddingBottom: theme.spacing(),
        },
        loginButton: {
            color: theme.palette.primary.contrastText,
            ...fonts.button,
            width: '100%',
            '&:disabled': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.common.white,
                opacity: 0.8,
            },
        },
        registerButton: {
            color: theme.palette.primary.contrastText,
            backgroundColor: '#4A4A4C',
            ...fonts.button,
            width: '100%',
            '&:disabled': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.common.white,
                opacity: 0.8,
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[800],
            },
        },
        noAccount: {
            margin: theme.spacing(1),
            ...fonts.body2,
            color: theme.palette.primary.contrastText,
            textAlign: 'center',
        },
        noAccountLine: {
            marginTop: theme.spacing(2),
        },
    }) as const;
