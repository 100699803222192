import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AssignUnassignResponse } from 'state-domains/domain/configuration';

import { buildConfigurationProjectActivityGroupUsersRemoveUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const removeUserFromProjectActivityGroup = (
    projectId: string,
    userId: string,
    activityId: string,
    groupId: string,
): Observable<AssignUnassignResponse> => {
    const url = buildConfigurationProjectActivityGroupUsersRemoveUrl(
        projectId,
        userId,
        activityId,
        groupId,
    );
    return sendRequestWithXSRFToken(url, {}, 'POST').pipe(
        switchMap(() => {
            const result = {
                user: { id: userId, user: userId },
                type: 'group' as const,
                payloadType: 'unassign-remove' as const,
                projectId,
                activityId,
                groupId,
            };
            return observableOf(result);
        }),
    );
};
