import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import {
    ConfigurationHeaderFieldTypesResponse,
    FilterObject,
    HeaderType,
} from 'state-domains/domain';

import { buildConfigurationHeaderFieldTypesUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';

export const loadConfigurationHeaderFieldTypes = (
    filters: FilterObject[],
): Observable<{ items: HeaderType[] }> => {
    const obs = sendRetrieveRequest(buildConfigurationHeaderFieldTypesUrl(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationHeaderFieldTypesResponse>) => {
            const items = convertToCamel<HeaderType[]>(response.HeaderFieldType);
            return observableOf({
                items,
            });
        }),
    );
};
