import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildExportTemplateUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { ExportTemplateInfo } from '../types';

export const getExportTemplates = (): Observable<any> => {
    const url = buildExportTemplateUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ ExportTemplate: any[] }>) => {
            const exportResponse = convertToCamel<ExportTemplateInfo[]>(response.ExportTemplate);
            return observableOf(exportResponse);
        }),
    );
};
