import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { FilterObject } from 'state-domains/domain/drillhole';
import { buildConfigurationListsUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ConfigurationList, ConfigurationLists } from '../types';

export const loadConfigurationLists = (
    filters: FilterObject[],
): Observable<{ items: ConfigurationList[] }> => {
    const obs = sendRetrieveRequest(buildConfigurationListsUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationLists>) => {
            const configurationLists = convertToCamel<ConfigurationList[]>(response.List);
            return observableOf({
                items: configurationLists,
            });
        }),
    );
};
