import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadHeadersUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { Header, Headers } from '../types';

export const loadHeaders = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: Header[]; actionName: string; processResultFunction: Function }> => {
    const url = buildLoadHeadersUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<Headers>) => {
            const { Header } = response;
            const result = convertToCamel<Header[]>(Header);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
