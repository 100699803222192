import { NotificationType } from '@local/web-design-system';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { ActivityMap, TableView } from 'state-domains/domain';
import { drillholeState } from 'state-domains/domain/drillhole';
import {
    IMAGO_CHECK_CONNECTION,
    IMAGO_SET_CONNECTION_STATUS,
    IMAGO_SYNC,
    LOAD_DRILLHOLE,
    SEEQUENT_CLOUD_CONFIGURATION,
    ADD_SNACKBAR_NOTIFICATION,
    IMAGO_USER_CONTEXT,
    CLEAR_IMAGO_USER_CONTEXT,
    IMAGO_LOGIN,
    IMAGO_DISCONNECT,
    CLEAR_IMAGO_LOGIN_STATE,
} from 'state-domains/types/actionTypes';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';

import { integrationShim } from '../shim';
import {
    ImagoConnectionStatus,
    ImagoSyncPayload,
    ImagoSyncResponse,
    ImagoSyncType,
} from '../types';

const imagoCheckConnection = () => (dispatch: any) => {
    dispatch({ type: typePending(IMAGO_SET_CONNECTION_STATUS) });
    return integrationShim.imagoCheckConnection(IMAGO_CHECK_CONNECTION).subscribe({
        next: (response) => {
            dispatch({ type: typeComplete(IMAGO_SET_CONNECTION_STATUS), payload: [response] });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(IMAGO_SET_CONNECTION_STATUS),
                payload: { result: ImagoConnectionStatus.DISCONNECTED, error },
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const imagoSync =
    (
        payload: ImagoSyncPayload,
        syncType: ImagoSyncType,
        activity: ActivityMap,
        tableView: TableView,
        type: ACTIVITY_TYPES,
        loadSampleResults: boolean,
    ) =>
    (dispatch: any, getState: any) => {
        dispatch({ type: typePending(IMAGO_SYNC) });

        return integrationShim.imagoSync(payload, syncType).subscribe({
            next: (response: ImagoSyncResponse) => {
                dispatch({ type: typeComplete(IMAGO_SYNC), payload: response.imageriesCount });
                dispatch({ type: typeComplete(LOAD_DRILLHOLE), payload: response.Collar });
                const successPayload =
                    response.imageriesCount > 0
                        ? {
                              type: NotificationType.SUCCESS,
                              message: '',
                              i18nKey: 'imagoSync',
                              i18nValues: { numImageries: response.imageriesCount },
                          }
                        : { type: NotificationType.SUCCESS, message: '', i18nKey: 'imagoNoneSync' };
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: successPayload,
                });

                const isSample = activity?.samples === payload.tableview;
                drillholeState.actions.loadRowReferences(
                    payload.collar,
                    tableView,
                    activity,
                    isSample,
                    false,
                    type,
                    loadSampleResults,
                    false,
                )(dispatch, getState);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(IMAGO_SYNC), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadSeequentCloudIntegration = () => (dispatch: any) => {
    dispatch({ type: typePending(SEEQUENT_CLOUD_CONFIGURATION) });

    return integrationShim.seequentCloudConfiguration().subscribe({
        next: (response) => {
            dispatch({ type: typeComplete(SEEQUENT_CLOUD_CONFIGURATION), payload: response });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(SEEQUENT_CLOUD_CONFIGURATION),
                payload: { error },
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const imagoGetModalContext = () => (dispatch: any) => {
    dispatch({ type: typePending(IMAGO_USER_CONTEXT) });
    return integrationShim.imagoCheckConnection(IMAGO_USER_CONTEXT).subscribe({
        next: (response) => {
            if (response.result === String(ImagoConnectionStatus.CONNECTED)) {
                integrationShim.imagoUserContext().subscribe({
                    next: (response) => {
                        dispatch({
                            type: typeComplete(IMAGO_USER_CONTEXT),
                            payload: {
                                ...response,
                                result: String(ImagoConnectionStatus.CONNECTED),
                            },
                        });
                    },
                    error: (error) => {
                        dispatch({
                            type: typeFail(IMAGO_USER_CONTEXT),
                            payload: { error },
                        });
                    },
                });
            } else {
                dispatch({ type: typeComplete(IMAGO_USER_CONTEXT), payload: response });
            }
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(IMAGO_USER_CONTEXT),
                payload: { result: ImagoConnectionStatus.DISCONNECTED, error },
            });
        },
    });
};

const clearImagoUserContextState = () => (dispatch: any) =>
    dispatch({ type: typeComplete(CLEAR_IMAGO_USER_CONTEXT) });

const imagoLogin = (username: string, password: string) => (dispatch: any) => {
    dispatch({ type: typePending(IMAGO_LOGIN) });
    return integrationShim.imagoLogin({ u: username, p: password }).subscribe({
        next: () => {
            dispatch({ type: typeComplete(IMAGO_LOGIN) });
            imagoCheckConnection()(dispatch);
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(IMAGO_LOGIN),
                payload: {
                    error: {
                        ...error,
                        message: error.response.error,
                        response: undefined,
                        status: 400,
                    },
                },
            });
        },
    });
};

const imagoLogout = () => (dispatch: any) => {
    dispatch({ type: typePending(IMAGO_DISCONNECT) });
    return integrationShim.imagoDisconnect().subscribe({
        next: () => {
            dispatch({ type: typeComplete(IMAGO_DISCONNECT) });
            imagoCheckConnection()(dispatch);
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(IMAGO_DISCONNECT),
                payload: { error },
            });
        },
    });
};

const clearImagoLoginState = () => (dispatch: any) => dispatch({ type: CLEAR_IMAGO_LOGIN_STATE });

export const actions = {
    imagoLogin,
    imagoLogout,
    imagoCheckConnection,
    imagoSync,
    loadSeequentCloudIntegration,
    imagoGetModalContext,
    clearImagoUserContextState,
    clearImagoLoginState,
};
