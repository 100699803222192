import { Dispatch } from 'redux';
import { Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { switchMap, catchError } from 'rxjs/operators';

import { Nullable, ShimState } from '../../../types';
import { logErrorToSentry } from '../../../utils';
import { allProjectResource, instanceResource, projectResource } from '../shim/translators';
import { FilterProps, ResourceType } from '../types';
import { actions } from './actions';
import { selectors } from './selectors';

/**
 * Returns a bool indicating if the error should be logged.
 * This is determined by whether the error object contains a value for `doNotLog`.
 * If the error does not contain the `doNotLog` field it should be logged.
 */
function handleDoNotLog(caughtError: AjaxError & { doNotLog?: boolean }): boolean {
    return !(caughtError?.doNotLog ?? false);
}

/**
 * Calls a notification shim function with the token.
 *
 * If the first call to the shim function returns a 401, it will fetch a new token and try again.
 * Will also handle the sentry logging for the notifications shim.
 * A shim function can throw and error with `doNotLog: true` to avoid logging here.
 */
export function withToken<Args extends any[], ReturnType extends Observable<any>>(
    dispatch: Dispatch,
    getState: () => ShimState,
    shimFunc: (notificationsToken: string, ...args: Args) => ReturnType,
): (...args: Args) => ReturnType {
    return (...args: Args): ReturnType => {
        const action: () => any = actions.fetchNotificationsToken;

        const getSource = () => {
            const token = selectors.notificationsToken(getState()) ?? '';
            return shimFunc(token, ...args);
        };

        return getSource().pipe(
            catchError((error) => {
                if (error.status !== 401) {
                    return throwError(() => error);
                }
                return dispatch(action()).pipe(switchMap(getSource));
            }),
            logErrorToSentry(handleDoNotLog),
        ) as ReturnType;
    };
}

/**
 * Builds a resource string based on the resource type in the filter props.
 */
export function buildFilterResource(
    state: Partial<ShimState>,
    filterProps: FilterProps,
): Nullable<string> {
    const instance = selectors.instanceUid(state);
    switch (filterProps.resourceType) {
        case ResourceType.INSTANCE:
            return instanceResource(instance);
        case ResourceType.ALL_PROJECT:
            return allProjectResource(instance);
        case ResourceType.PROJECT: {
            const projectID = filterProps?.projectID ?? null;
            return projectID !== null ? projectResource(instance, projectID) : null;
        }
        default:
            return null;
    }
}
