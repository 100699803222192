import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Project } from 'state-domains/domain/project';
import { buildConfigurationProjectUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteConfigurationProject = (id: string): Observable<Project> => {
    const obs = sendDeleteRequestWithXSRFToken(buildConfigurationProjectUrl(id));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Project>) => {
            const res = convertToCamel<Project>(response);
            return observableOf(res);
        }),
    );
};
