import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { notificationsState } from 'state-domains/domain/notifications';
import { isPending } from 'state-domains/utils';

import { UserPortalState } from 'src/state';

import { StateProps, DispatchProps, OwnProps } from './NotificationsRow.types';

const {
    actions: { updateMessage, deleteMessage },
    selectors: { instanceUid },
} = notificationsState;

const mapStateToProps = (state: UserPortalState, props: OwnProps): StateProps => {
    const { notifications } = state;
    return {
        isDeleting: isPending(props.messageData) && isPending(notifications.messageState),
        instanceUuid: instanceUid(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    deleteMessage: bindActionCreators(deleteMessage, dispatch),
    setReadStatus: bindActionCreators(updateMessage, dispatch),
});

export const connectToState = connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
);
