import L from 'leaflet';
import 'leaflet-arrowheads';
import React, { useEffect } from 'react';

import { GridPreviewData } from 'state-domains/domain';

export interface MapGridPreviewProps {
    map: any;
    isSatellite: boolean;
    data?: GridPreviewData;
}

export const MapGridPreview = (props: MapGridPreviewProps) => {
    const { map, data, isSatellite } = props;
    const layerRef = React.useRef<any>();
    const arrowRef = React.useRef<any>();

    const getBoundingArea = (bounds: any[][]) => {
        const boundingArea = [];
        const initLength = bounds[0].length - 1;
        const endLength = bounds[bounds.length - 1].length - 1;
        boundingArea.push(bounds[0][0]);
        boundingArea.push(bounds[0][initLength]);
        boundingArea.push(bounds[endLength][0]);
        boundingArea.push(bounds[endLength][initLength]);

        return [boundingArea.map((x) => [x[1], x[0]])];
    };

    useEffect(() => {
        if (layerRef.current) {
            layerRef.current.remove();
        }
        if (arrowRef.current) {
            arrowRef.current.remove();
        }
        if (data?.gridLines) {
            layerRef.current = L.layerGroup();
            data.gridLines.forEach((line) => {
                line.forEach((subLine, i) => {
                    const index = i + 1;
                    if (index >= line.length) {
                        return;
                    }
                    const pointA = new L.LatLng(subLine[1], subLine[0]);
                    const pointB = new L.LatLng(line[index][1], line[index][0]);
                    const pointList = [pointA, pointB];

                    const polyline = new L.Polyline(pointList, {
                        color: 'red',
                        weight: 2,
                        opacity: 0.6,
                        smoothFactor: 1,
                    });
                    layerRef.current.addLayer(polyline);
                });
            });
            const arrowStart = new L.LatLng(data.center[1][0], data.center[0][0]);
            const arrowEnd = new L.LatLng(data.centerVector[1][0], data.centerVector[0][0]);
            arrowRef.current = L.polyline([arrowStart, arrowEnd], {
                color: isSatellite ? 'white' : 'black',
            }).arrowheads({ fill: true });
            layerRef.current.addTo(map);
            arrowRef.current.addTo(map);

            const focusedBounds = getBoundingArea(data.gridLines);
            map.fitBounds(focusedBounds);
        }

        return () => {
            if (layerRef.current) {
                layerRef.current.remove();
            }
            if (arrowRef.current) {
                arrowRef.current.remove();
            }
        };
    }, [data, isSatellite, map, layerRef.current]);

    return <></>;
};
