import { withTrace, Notification, NotificationType } from '@local/web-design-system';
import { Grid, Button, Typography, Link } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, MessageDescriptor, injectIntl } from 'react-intl';
import { compose } from 'redux';

import { LogoTitle, getAppTitle } from 'src/branding';
import { UserPortalSpinner } from 'src/components';
import { setDocumentTitle, PathConstants } from 'src/routes';
import { withRouter } from 'src/routes/withRouter';
import { withStyles } from 'src/styles/utils';

import { injTracker } from '../withDomain/injTracker';
import { connectToState } from './Login.connect';
import { i18n } from './Login.i18n';
import { styles } from './Login.styles';
import { AllLoginProps } from './Login.types';
import { LoginSideBar } from './LoginSideBar/LoginSideBar';

export class LoginBase extends React.Component<AllLoginProps, any> {
    componentDidMount() {
        const { oauth2FetchLoginUrl, oauth2FetchLogoutUrl } = this.props;
        oauth2FetchLoginUrl();
        oauth2FetchLogoutUrl();
    }

    render() {
        const {
            intl,
            classes,
            location,
            loginRedirectUrl,
            logoutRedirectUrl,
            isPending,
            error,
            applyTrace,
            returnUrl: returnUrlOverride = '',
        } = this.props;
        const { pathname, search } = location;

        if (loginRedirectUrl === '') {
            return <UserPortalSpinner />;
        }

        let returnUrl = '/';
        if (returnUrlOverride) {
            returnUrl = returnUrlOverride;
        } else if (
            pathname !== '/' &&
            !pathname.startsWith(PathConstants.LOGIN.ROOT) &&
            !pathname.startsWith(PathConstants.LOGOUT.ROOT)
        ) {
            returnUrl = pathname + search;
        }

        const params = new URLSearchParams(search);
        let errorStatus;
        if (pathname === PathConstants.LOGIN.ROOT) {
            errorStatus = params.get('error_status') || params.get('error') || undefined;
        }

        if (error) {
            errorStatus = error;
        }

        const errorMessage = errorStatusToMessage(errorStatus);
        const finalErrorMessage = intl.formatMessage(errorMessage || i18n.error.unexpected);
        setDocumentTitle([getAppTitle()]);

        return (
            <Grid container className={classes.root}>
                <Grid item sm={12} md={8} lg={9}>
                    <Grid container alignItems="flex-start">
                        <Grid
                            container
                            justifyContent="flex-end"
                            item
                            xs={12}
                            sm={6}
                            className={classes.column}
                        >
                            <LogoTitle className={classes.logoTitle} />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            className={`${classes.formContainer} ${classes.column}`}
                        >
                            <Grid container className={classes.container}>
                                <Grid item xs={12}>
                                    {errorMessage && (
                                        <Grid item xs={12} className={classes.error}>
                                            <Notification
                                                type={NotificationType.ERROR}
                                                message={finalErrorMessage}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        {errorMessage ? (
                                            <OAuth2LogoutButton
                                                classes={classes}
                                                isPending={isPending}
                                                logoutRedirectUrl={`${logoutRedirectUrl}?returnUrl=${encodeURIComponent(
                                                    returnUrl,
                                                )}`}
                                                applyTrace={applyTrace}
                                            />
                                        ) : (
                                            <OAuth2LoginButton
                                                classes={classes}
                                                isPending={isPending}
                                                loginRedirectUrl={`${loginRedirectUrl}?returnUrl=${encodeURIComponent(
                                                    returnUrl,
                                                )}`}
                                                applyTrace={applyTrace}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} lg={3}>
                    <LoginSideBar />
                </Grid>
            </Grid>
        );
    }
}

const OAuth2LoginButton = ({ classes, isPending, loginRedirectUrl, applyTrace }: any) => {
    const displayMessage = isPending ? i18n.loading : i18n.loginButton;

    return (
        <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.loginButton}
            disabled={isPending}
            classes={{ root: classes.loginButton }}
            component={Link}
            href={loginRedirectUrl}
            {...applyTrace('sign-in')}
        >
            <Typography color="inherit" variant="body2">
                <FormattedMessage {...displayMessage} />
            </Typography>
        </Button>
    );
};

const OAuth2LogoutButton = ({ classes, isPending, logoutRedirectUrl, applyTrace }: any) => {
    const displayMessage = isPending ? i18n.loading : i18n.logoutButton;

    return (
        <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.loginButton}
            disabled={isPending}
            classes={{ root: classes.loginButton }}
            component={Link}
            href={logoutRedirectUrl}
            {...applyTrace('sign-out')}
        >
            <Typography color="inherit" variant="body2">
                <FormattedMessage {...displayMessage} />
            </Typography>
        </Button>
    );
};

const errorStatusToMessage = (errorStatus?: string): MessageDescriptor | undefined => {
    let retval;
    if (errorStatus) {
        retval = (i18n.error as any)[errorStatus] ?? i18n.error.unexpected;
    }
    return retval;
};

const ComposedLanding = compose(
    withTrace('Login'),
    withStyles(styles),
    withRouter,
    connectToState,
    injectIntl,
    injTracker('Login'),
)(LoginBase) as any;

export const Login = (props: { error?: string; returnUrl?: string }) => (
    <ComposedLanding {...props} />
);
