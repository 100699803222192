import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadSampleResultsUrl, buildLoadSampleResultsPointsUrl } from '../../../network/urls';
import { sendRetrieveRequest, convertToCamel } from '../../../utils';
import {
    SampleResultColumn,
    SampleResultRow,
    SampleResults,
    SampleResultsResponse,
} from '../types';

export const loadSampleResults = (
    collar: string,
    actionName: string,
    processResultFunction: Function,
    type: ACTIVITY_TYPES,
): Observable<{
    result: SampleResults;
    actionName: string;
    processResultFunction: Function;
    type: ACTIVITY_TYPES;
}> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildLoadSampleResultsUrl(collar)
            : buildLoadSampleResultsPointsUrl(collar);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<SampleResultsResponse>) => {
            const { SampleResults } = response;
            const convertedColumns = convertToCamel<SampleResultColumn[]>(SampleResults.columns);
            const convertedRows = convertToCamel<SampleResultRow[]>(SampleResults.samples);
            return observableOf({
                result: {
                    columns: convertedColumns,
                    samples: convertedRows,
                } as SampleResults,
                actionName,
                processResultFunction,
                type,
            });
        }),
    );
};
