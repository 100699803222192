let BASE_API_ENDPOINT = '';

export function setBaseApiEndpoint(baseEndpoint: string) {
    BASE_API_ENDPOINT = baseEndpoint;
}

export function baseApiEndpoint() {
    return BASE_API_ENDPOINT;
}

let NOTIFICATIONS_URL = '';

export function setBaseNotificationsUrl(url: string) {
    NOTIFICATIONS_URL = url;
}

export function notificationsUrl() {
    return NOTIFICATIONS_URL;
}

let BASE_ENDPOINT = '';

export function setBaseEndpoint(baseEndpoint: string) {
    BASE_ENDPOINT = baseEndpoint;
}

export function baseEndpoint() {
    return BASE_ENDPOINT;
}

let CLASSIC_MX_DEPOSIT_URL: string | undefined;

export function setClassicMXDepositUrl(url: string) {
    CLASSIC_MX_DEPOSIT_URL = url;
}

export function getClassicMXDepositUrl() {
    return CLASSIC_MX_DEPOSIT_URL;
}
