import { fonts } from '@local/web-design-system';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.grey[900],
        height: '100vh',
        padding: theme.spacing(3),
        overflow: 'auto',
    },
    body: {
        margin: theme.spacing(2),
        fontFamily: fonts.fontFamily,
        '& img': {
            maxWidth: '100%',
            padding: `${theme.spacing(2)} 0`,
        },
        '& h1': {
            color: theme.palette.grey[50],
            fontSize: '18px',
            fontWeight: fonts.weights.regular,
            letterSpacing: '0.11px',
            lineHeight: '22px',
            padding: `${theme.spacing(2)} 0`,
            margin: `${theme.spacing(6)} 0 ${theme.spacing(3)}`,
            borderBottom: `1px solid ${theme.palette.grey[200]}40`,
        },
        '& p, & a': {
            color: theme.palette.grey[400],
            fontSize: '13px',
            fontWeight: fonts.weights.regular,
            letterSpacing: '0.4px',
            lineSpacing: '19px',
        },
    },
});
