import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LOAD_STATUS_STALE } from 'state-domains/constants';
import { AzureMapToken, AzureMapTokenState } from 'state-domains/domain';
import { BaseAction } from 'state-domains/types';

import { LOAD_MAP_TOKEN, LOGOUT_REQUEST } from '../../../types/actionTypes';
import { completeReducer, failureReducer, pendingReducer } from '../../../utils';

export const INITIAL_STATE: AzureMapTokenState = {
    accessToken: '',
    expiry: 0,
    tokenType: '',
    azureClientId: '',
    status: LOAD_STATUS_STALE,
    error: null,
};

function isLogoutAction(action: Action) {
    return action.type.startsWith(LOGOUT_REQUEST) && action.type.endsWith('complete');
}

const xidSlice = createSlice({
    name: LOAD_MAP_TOKEN,
    initialState: INITIAL_STATE,
    reducers: {
        loadTokenComplete: (state, action: PayloadAction<AzureMapToken>) => {
            const { payload: data } = action;
            const newState = {
                ...state,
                ...data,
            };
            return completeReducer(newState);
        },
        loadMapTokenFail: (
            state: AzureMapTokenState,
            action: BaseAction<AzureMapTokenState>,
        ): AzureMapTokenState => {
            const { payload } = action;
            return failureReducer({
                ...state,
                error: {
                    status: payload.error?.status,
                    message: payload.error?.message,
                    code: payload.error?.code,
                },
            });
        },
        loadTokenPending: (state: AzureMapTokenState) => pendingReducer(state),
    },
    extraReducers: (builder) => {
        builder.addMatcher(isLogoutAction, () => INITIAL_STATE);
    },
});

export const { reducer, actions } = xidSlice;
