import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { FileObject } from 'state-domains/domain/drillhole';

import { buildDataFileGroupFilesUrl, buildHeaderFileGroupFilesUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteFile = (
    type: ACTIVITY_TYPES,
    fileId: string,
    fileGroup: string,
    header: boolean,
): Observable<FileObject> => {
    let url = header
        ? buildHeaderFileGroupFilesUrl(fileGroup)
        : buildDataFileGroupFilesUrl(fileGroup, type);
    url = `${url}/${fileId}`;
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<FileObject>) => {
            const converted = convertToCamel<FileObject>(response);
            return observableOf(converted);
        }),
    );
};
