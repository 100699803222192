import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildProjectListUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { Project, Projects } from '../types';

export const loadProjectList = (): Observable<Project[]> => {
    const obs = sendRetrieveRequest(buildProjectListUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Projects>) => {
            const result = convertToCamel<Project[]>(response.Project);
            return observableOf(result);
        }),
    );
};
