import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildImportTableUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const importTableSchema = (
    additionalHeaders: { [key: string]: string },
): Observable<any> => {
    const url = buildImportTableUrl();
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, additionalHeaders).pipe(
        switchMap((result: any) => {
            const converted = !result.ok ? result : convertToCamel(result);
            return observableOf(converted);
        }),
    );
};
