import {
    AntSwitch,
    EmptyState,
    FieldDefinition,
    ListCellComponentProps,
    MarkReadIcon,
    MentionIcon,
    NoteIcon,
    PaginatedList,
    RevisionIcon,
    ShareIcon,
    SliderIcon,
    StringWithFooterCell,
    theme,
    UserIcon,
    useTrace,
    withTrace,
} from '@local/web-design-system';
import { Button, Grid, Paper, Typography } from '@mui/material';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import formatDistance from 'date-fns/formatDistance';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

import { AsyncMessage } from 'state-domains/domain';

import { GenericError } from 'src/components/GenericError';
import { notificationPrefEnabled } from 'src/config';
import { PathConstants, PREFERENCES_PATH } from 'src/routes';
import { withRouter } from 'src/routes/withRouter';
import { withStyles } from 'src/styles/utils';
import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';

import { NotificationsDisabledSvg, NotificationsEmptySvg } from '../../Svg';
import { i18n } from '../Notifications.i18n';
import { NotificationsRow, RowData } from '../NotificationsRow';
import { connectToState } from './NotificationsTray.connect';
import { styles } from './NotificationsTray.styles';
import { AllProps, EmptyProps, TopicID } from './NotificationsTray.types';

export function NotificationsTrayMessage({ itemKey, item }: ListCellComponentProps) {
    const rowData = item as RowData;

    // Calculate time since notification created.
    const timestamp = formatDistance(Date.parse(rowData.createdAt), new Date(), {
        addSuffix: true,
    });

    // If the notification is from a different Central instance, we will show the instance name.
    const showInstanceName = Boolean(
        rowData.centralInstanceUuid &&
            rowData.centralInstanceName &&
            rowData.currentInstanceUUID !== rowData.centralInstanceUuid,
    );
    const rightKey = showInstanceName ? 'centralInstanceName' : undefined;

    return (
        <StringWithFooterCell
            itemKey={itemKey}
            leftFooterKey="timestamp"
            rightFooterKey={rightKey}
            item={{ ...rowData, timestamp }}
        />
    );
}

const topicIconMapping: Record<TopicID, React.ComponentType> = {
    [TopicID.NOTE]: NoteIcon,
    [TopicID.REVISION]: RevisionIcon,
    [TopicID.GRANT_ACCESS]: UserIcon,
    [TopicID.MENTION]: MentionIcon,
    [TopicID.BRANCH]: RevisionIcon,
    [TopicID.SHARE_SCENE]: ShareIcon,
};

export function NotificationsIconMap({ itemKey, item }: ListCellComponentProps) {
    const topicId = item[itemKey];
    if (!topicId) {
        return null;
    }
    for (const [topic, IconComponent] of Object.entries(topicIconMapping)) {
        if (topicId.startsWith(topic)) {
            return <IconComponent automation-id={`topic-icon-${topic}`} />;
        }
    }
    return null;
}

const msgListingDef = {
    topic: {
        sortId: 0,
        component: NotificationsIconMap,
        styles: {
            paddingTop: theme.spacing(2.4),
            paddingBottom: theme.spacing(1.5),
            verticalAlign: 'top',
            width: theme.spacing(4),
        },
    },
    message: {
        sortId: 1,
        component: NotificationsTrayMessage,
        styles: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: 0,
            paddingRight: 0,
            verticalAlign: 'top',
            wordBreak: 'break-word', // Maintains col-width for long words, except for Edge V<79
            width: theme.spacing(32),
        },
    },
};

export function EmptyNotifications({
    intl,
    tray = false,
    disabled = false,
    classes,
    enableNotifications = () => undefined,
    onClick = () => {},
}: EmptyProps) {
    const applyTrace = useTrace('notifications-tray-empty');
    const nonDisabledTitle = tray ? i18n.emptyTitleTray : i18n.emptyTitle;
    const title = disabled ? i18n.disabledTitle : nonDisabledTitle;
    const nonDisabledContent = tray ? i18n.emptyContentTray : i18n.emptyContent;
    const content = disabled ? i18n.disabledContent : nonDisabledContent;
    const image = disabled ? <NotificationsDisabledSvg /> : <NotificationsEmptySvg />;
    return (
        <Grid item xs={12}>
            <EmptyState
                {...applyTrace()}
                title={intl.formatMessage(title)}
                message={disabled ? '' : intl.formatMessage(i18n.emptySubtitle)}
                image={image}
                classes={{ body: classes.emptyStateBody }}
            >
                <Typography>{intl.formatMessage(content)}</Typography>
                {!disabled && tray && (
                    <Button className={classes.button} onClick={onClick}>
                        <Typography className={classes.linkText}>
                            {intl.formatMessage(i18n.previousLink)}
                        </Typography>
                    </Button>
                )}
                {disabled && tray && (
                    <Grid container alignContent="center">
                        <Grid item className={classes.disabledToggleContainer}>
                            <Grid container alignContent="center" alignItems="center">
                                <Grid item>
                                    <Typography variant="body2" className={classes.disabledLabel}>
                                        <FormattedMessage {...i18n.disabledNotification.enable} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <AntSwitch
                                        checked={!disabled}
                                        unCheckedLabel={intl.formatMessage(
                                            i18n.disabledNotification.disabled,
                                        )}
                                        onChange={enableNotifications}
                                        {...applyTrace('re-enable-toggle')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </EmptyState>
        </Grid>
    );
}

export class NotificationsTrayBase extends React.Component<AllProps> {
    @autobind
    navigateToPreferences() {
        const { navigate, closeTray } = this.props;
        navigate(PREFERENCES_PATH);
        closeTray();
    }

    @autobind
    navigateToNotifications() {
        const { navigate, closeTray } = this.props;
        const link = PathConstants.NOTIFICATIONS.ROOT;
        navigate(link);
        closeTray();
    }

    getFieldDefinition(): FieldDefinition[] {
        const fields = Object.keys(msgListingDef).map((key: string) => ({
            key,
            ...msgListingDef[key as keyof typeof msgListingDef],
        }));
        return fields.sort(
            (a: { sortId: number }, b: { sortId: number }) => a.sortId - b.sortId,
        ) as unknown as FieldDefinition[];
    }

    getHeader() {
        const { classes, total, applyTrace, variant = 'light' } = this.props;
        return (
            <Grid
                item
                container
                alignItems="center"
                direction="row"
                wrap="nowrap"
                className={classnames(classes.header, { [classes.headerDark]: variant === 'dark' })}
            >
                <Grid item style={{ flexGrow: 2, paddingLeft: '24px' }}>
                    <Typography
                        className={classnames(classes.title, {
                            [classes.titleDark]: variant === 'dark',
                        })}
                        {...applyTrace('title')}
                    >
                        <FormattedMessage {...i18n.trayTitle} values={{ new: total }} />
                    </Typography>
                </Grid>
                <Grid
                    item
                    className={notificationPrefEnabled() ? undefined : classes.hidePreferences}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.navigateToPreferences}
                        {...applyTrace('preferences-button')}
                    >
                        <SliderIcon />
                        <Typography variant="button" color="inherit" className={classes.iconText}>
                            <FormattedMessage {...i18n.preferences} />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    }

    @autobind
    markAllReadAndSeen() {
        const { bulkReadMessages, trayMessagesIdList, messagesSeen } = this.props;
        bulkReadMessages(trayMessagesIdList);
        messagesSeen();
        trackUserAction(
            TRACKING_COMPONENTS.NOTIFICATIONS,
            EVENT_TYPES.CLICK,
            'notifications-tray-mark-all-read',
        );
    }

    getToolbar() {
        const { classes, unreadCount } = this.props;

        return (
            <Grid
                item
                container
                alignItems="center"
                direction="row"
                wrap="nowrap"
                className={classes.toolbar}
            >
                <Grid item style={{ flexGrow: 2 }}>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={this.markAllReadAndSeen}
                        className={classes.justifyLeft}
                        disabled={unreadCount === 0}
                        automation-id="notifications-tray-mark-all-read"
                        data-testid="trayMarkAllReadButton"
                    >
                        <MarkReadIcon />
                        <Typography variant="button" color="inherit" className={classes.iconText}>
                            <FormattedMessage {...i18n.markRead} />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    }

    getFooter() {
        const { total, limit, classes } = this.props;
        const extra = limit > total ? 0 : total - limit;
        const color = extra > 0 ? 'primary' : 'secondary';

        return (
            <>
                <Button
                    automation-id="notifications-all-button"
                    color={color}
                    variant="contained"
                    onClick={this.navigateToNotifications}
                    style={{
                        width: '100%',
                        padding: '20px',
                    }}
                >
                    <Typography
                        variant="button"
                        color="inherit"
                        className={classes.noTextTransform}
                    >
                        <FormattedMessage {...i18n.moreNotifications} values={{ extra }} />
                    </Typography>
                </Button>
            </>
        );
    }

    getContent(fields: FieldDefinition[]) {
        const { trayMessagesDataList, intl, closeTray } = this.props;
        return trayMessagesDataList.map((messageData: AsyncMessage) => (
            <NotificationsRow
                messageData={messageData}
                key={messageData.uuid}
                fields={fields}
                messageUid={messageData.uuid}
                intl={intl}
                closeTray={closeTray}
                tray
            />
        ));
    }

    render() {
        const { intl, isFailed, trayMessagesIdList, classes, enabled, enableNotifications } =
            this.props;
        const isEmptyOrDisabled = trayMessagesIdList.length === 0 || !enabled;
        const errorMessage = <GenericError message={intl.formatMessage(i18n.inboxError)} />;
        const emptyMessage = (
            <EmptyNotifications
                intl={intl}
                disabled={!enabled}
                tray
                classes={classes}
                onClick={this.navigateToNotifications}
                enableNotifications={enableNotifications}
            />
        );
        const fields = this.getFieldDefinition();
        const content = this.getContent(fields);
        const showFooter = !isEmptyOrDisabled && !isFailed;
        const tableClasses = {
            preHeader: classes.preHeader,
            tableWrapper: classes.tableWrapper,
        };
        return (
            <Paper className={classes.root} automation-id="notifications-tray">
                {this.getHeader()}
                <Grid className={classes.content}>
                    <PaginatedList
                        classes={tableClasses}
                        preHeader={this.getToolbar()}
                        header={null}
                        isEmpty={isEmptyOrDisabled}
                        isError={isFailed}
                        emptyMessage={emptyMessage}
                        errorMessage={errorMessage}
                        content={content}
                    />
                </Grid>
                {showFooter && this.getFooter()}
            </Paper>
        );
    }
}

export const withProps = compose(
    connectToState,
    withStyles(styles),
    injectIntl,
    withRouter,
    withTrace('notifications-tray'),
);

export const NotificationsTray = hot(withProps(NotificationsTrayBase));
export default NotificationsTray;
