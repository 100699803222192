import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationListsBaseUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';
import { ConfigurationListResponse } from '../types';

export const addConfigurationList = (payload: any): Observable<ConfigurationListResponse> => {
    const url = buildConfigurationListsBaseUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationListResponse>) => {
            const addListResponse = convertToCamel<ConfigurationListResponse>(response);
            return observableOf(addListResponse);
        }),
    );
};
