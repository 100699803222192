import { MouseSensor as LibMouseSensor, KeyboardSensor as LibKeyboardSensor } from '@dnd-kit/core';
import type { MouseEvent, KeyboardEvent } from 'react';

const isInteractiveElement = (element: HTMLElement | null) => {
    if (!element) {
        return false;
    }
    const interactiveElements = ['button', 'input', 'textarea', 'select', 'option'];

    return interactiveElements.includes(element.tagName.toLowerCase());
};

export class MouseSensor extends LibMouseSensor {
    static readonly activators = [
        {
            eventName: 'onMouseDown' as const,
            handler: ({ nativeEvent: event }: MouseEvent) => {
                if (event.button !== 0 || isInteractiveElement(event.target as HTMLElement)) {
                    return false;
                }

                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

export class KeyboardSensor extends LibKeyboardSensor {
    static readonly activators = [
        {
            eventName: 'onKeyDown' as const,
            handler: ({ nativeEvent: event }: KeyboardEvent<Element>) =>
                shouldHandleEvent(event.target as HTMLElement),
        },
    ];
}

function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element;
    if ((window as any).isAnyPopOverPresent) {
        return false;
    }
    while (cur) {
        if (cur.dataset?.noDnd) {
            (window as any).isAnyPopOverPresent = true;
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}
