import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { LabCertificateSetting, LabCertificateValidation } from 'state-domains/domain/subscription';

import { WorkflowTypes } from 'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowDetail/ActionButton/ActionButton.types';
import { CertificateImportTypes } from 'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowDetail/CertificateImport/CertificateImport.types';

import {
    buildLabCertificateSettingsUrl,
    buildLabCertificateValidationsUrl,
} from '../../../network/urls';
import {
    convertToCamel,
    sendRequestWithXSRFToken,
    convertToSnake,
    sendDeleteRequestWithXSRFToken,
} from '../../../utils';

function handleRequest(
    type: CertificateImportTypes,
    workflowId: string,
    method: 'POST' | 'PUT' | 'DELETE',
    payload?: any,
    itemId?: string,
): Observable<LabCertificateValidation | LabCertificateSetting> {
    const url =
        type === WorkflowTypes.VALIDATION_RULES
            ? buildLabCertificateValidationsUrl(workflowId, itemId)
            : buildLabCertificateSettingsUrl(workflowId, itemId);

    const requestObservable =
        method === 'DELETE'
            ? sendDeleteRequestWithXSRFToken(url)
            : sendRequestWithXSRFToken(url, convertToSnake(payload), method);

    return requestObservable.pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf(convertToCamel<LabCertificateValidation>(response)),
        ),
    );
}

export const addCertificateImport = (
    type: CertificateImportTypes,
    workflowId: string,
    payload: any,
): Observable<LabCertificateValidation | LabCertificateSetting> =>
    handleRequest(type, workflowId, 'POST', payload);

export const editCertificateImport = (
    type: CertificateImportTypes,
    workflowId: string,
    itemId: string,
    payload: any,
): Observable<LabCertificateValidation | LabCertificateSetting> =>
    handleRequest(type, workflowId, 'PUT', payload, itemId);

export const deleteCertificateImport = (
    type: CertificateImportTypes,
    workflowId: string,
    itemId: string,
): Observable<LabCertificateValidation | LabCertificateSetting> =>
    handleRequest(type, workflowId, 'DELETE', undefined, itemId);
