import React, { useEffect, useState } from 'react';

import { Config } from './config.types';
import { fillInObject } from './util/fillInObject';

const USER_PORTAL_CONFIG_URL = `${window.location.origin}/userportal.config.json`;
const CONFIG_URL = `${window.location.origin}/config.json`;
const CONFIG_OVERRIDE_URL = `${window.location.origin}/config.overrides.json`;
const CONFIG = 'config';

interface ProviderProps {
    children: React.JSX.Element;
    useUserPortalConfigJson?: boolean;
    onConfigLoaded: Function;
}

const fetchJson = async (url: string) => {
    const response = await fetch(url, { headers: { Accept: 'application/json' } });
    return response.ok ? response.json() : null;
};

/**
 * Tries to fetch and apply overrides to configs
 * @param config userportal.config.json
 * @returns configs with overridden values if overrides are found, else the original configs
 */
const applyOverrides = async (config: any) => {
    const override = await fetchJson(CONFIG_OVERRIDE_URL);
    if (override) {
        // eslint-disable-next-line no-console
        console.log('Using configuration overrides:');
        // eslint-disable-next-line no-console
        console.log(override);
        return fillInObject(config, override);
    }
    return config;
};

/**
 * Takes the old/ deprecated userPortal.config.json and translates it to match the appConfig.
 * This is needed as a compatibility layer for public.
 * @param userportalConfig userportal.config.json
 * @returns config.json
 */
const userPortalTranslator = (userportalConfig: any): Config => {
    // CENPLAT-10941 delete this function
    const result: Config = {
        ...userportalConfig,
    };
    // Only the segment write key and environment need to be translated other values
    // compatibility hack is only needed for central_segmentWriteKey and that it should never be need for blocksync/ cloud
    result.blocksync_segmentWriteKey = userportalConfig.segment?.writeKey ?? '';
    result.central_segmentWriteKey = userportalConfig.segment?.writeKey ?? '';
    result.environment = userportalConfig.sentryEnvironment ?? 'development';
    return result;
};

/**
 * Renders the children after loading the config into the session.
 * For Local development, it will use the local config.development.json file
 * If the config cannot be found show warning in console and allow consumers to manage error handling with isConfigSet()
 * @param children to render once the config has been loaded
 * @param useUserPortalConfigJson boolean to tell the portal to  use 'userportal.config.json' instead of 'config.json' compatibility fix for public
 * @param onConfigLoaded callback to be called once the config has been loaded
 * @returns children once the config has been loaded
 */
export const ProvideConfig = ({
    children,
    useUserPortalConfigJson,
    onConfigLoaded,
}: ProviderProps) => {
    const [loaded, setLoaded] = useState(false);

    const getConfigData = async () => {
        const configUrl = useUserPortalConfigJson ? USER_PORTAL_CONFIG_URL : CONFIG_URL;
        const config = await fetchJson(configUrl);
        if (!config) {
            return null;
        }
        // only translate if we're in the old infrastructure. ie public
        if (useUserPortalConfigJson) {
            return userPortalTranslator(config);
        }
        // only use overrides if in deployed development slot
        if (config?.environment === 'development') {
            return applyOverrides(config);
        }
        return config;
    };

    const loadConfig = async () => {
        const config = await getConfigData();
        if (config) {
            sessionStorage.setItem(CONFIG, JSON.stringify(config));
        } else {
            // eslint-disable-next-line no-console
            console.warn('Site configuration not present');
        }
        onConfigLoaded();
    };

    useEffect(() => {
        loadConfig()
            .then(() => setLoaded(true))
            .catch(() => setLoaded(false));
    }, []);

    return loaded ? children : null;
};

/**
 * @returns The config from the session.
 */
export const getConfig = (): Config => JSON.parse(sessionStorage.getItem(CONFIG) ?? '{}');

/**
 * Can be used for error handling purposes for when the config fails to load
 * @returns True if there is config in the session storage
 */
export const isConfigSet = (): boolean => {
    const config = sessionStorage.getItem(CONFIG);
    if (config && config !== '{}') {
        return true;
    }
    return false;
};
