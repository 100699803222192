import packageInfo from '../../../../package.json';
import { FooterItemProps } from './ContentAreaFooter.types';

export function getFooterItems(): FooterItemProps[] {
    return [
        {
            id: 'content-area.footer.copyright',
            defaultMessage: '© Seequent, The Bentley Subsurface Company',
            url: 'https://www.seequent.com/',
            version: packageInfo.version,
        },
    ];
}
