import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AssignUnassignResponse } from 'state-domains/domain/configuration';

import { buildConfigurationProjectActivityUsersRemoveUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const removeUserFromProjectActivity = (
    projectId: string,
    userId: string,
    activityId: string,
): Observable<AssignUnassignResponse> => {
    const url = buildConfigurationProjectActivityUsersRemoveUrl(projectId, userId, activityId);
    return sendRequestWithXSRFToken(url, {}, 'POST').pipe(
        switchMap(() => {
            const result = {
                user: { id: userId, user: userId },
                type: 'activity' as const,
                payloadType: 'unassign-remove' as const,
                projectId,
                activityId,
            };
            return observableOf(result);
        }),
    );
};
