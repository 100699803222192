import { getSpacing } from '@local/web-design-system';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {},
        dropdownText: {
            '& p': {
                fontSize: '14px',
                letterSpacing: '0.43px',
                textAlign: 'left',
                whiteSpace: 'normal',
            },
            '& p[class*="noWrap"]': {
                whiteSpace: 'nowrap',
            },
            '& > div': {
                height: '100%',
            },
            '& button': {
                color: theme.palette.grey[900],
                minWidth: 'min-content',
            },
        },
        dropdownWithMaxWidth: {
            '& p': {
                maxWidth: 165,
            },
            '& > div': {
                maxWidth: 165,
            },
            '& button': {
                minWidth: 'initial !important',
                maxWidth: 165,
            },
        },
        dropdownWithoutMinWidth: {
            '& button': {
                minWidth: 'initial !important',
            },
        },
        dropdownPopper: {
            zIndex: 50,
        },
    } as const);

export const styleMaxWidth = makeStyles<{ width: number }>()((theme, { width }) => ({
    setMaxWidth: {
        maxWidth: `${width - getSpacing(theme, 3)}px`,
    },
}));
