import { fonts } from '@local/web-design-system';

export const styles = () =>
    ({
        counter: {
            color: 'white',
            backgroundColor: '#E63B38',
            borderRadius: '80%',
            width: '18px',
            textAlign: 'center',
            fontSize: '11px',
            lineHeight: '1.7',
            fontWeight: fonts.weights.medium,
        },
        counterMount: {
            height: '18px',
        },
    }) as const;
