import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { ajax as rxAjax, AjaxResponse, AjaxError, AjaxConfig } from 'rxjs/ajax';
import { catchError, switchMap } from 'rxjs/operators';

import { logErrorToSentry } from './helpers';

export type RequestType = 'GET' | 'POST' | 'PUT' | 'None' | 'DELETE' | 'PATCH';

const computeContentHeader = function (type: string) {
    switch (type) {
        case 'form':
            return 'application/x-www-form-urlencoded';
        case 'json':
            return 'application/json';
        case 'png':
            return 'image/png';
        default:
            return type;
    }
};

export interface NetworkRequestConfig extends AjaxConfig {
    url: string;
    method?: RequestType;
    contentType?: string;
    payload?: any;
    withCredentials?: boolean;
    deferCatch?: (
        error: AjaxError,
        caught: Observable<AjaxResponse<any>>,
    ) => Observable<AjaxResponse<any>>;
}

/**
 * create ajax observable which sends ajax call to url through subscription.
 *
 * @export
 * @param {NetworkRequestConfig} {
 *     url,
 *     method='GET',
 *     payload,
 *     contentType=DEFAULT_CONTENT_TYPE
 * } - network request config
 * @returns - ajax Observable
 */
export function ajax({
    url,
    method = 'GET',
    payload,
    responseType,
    contentType,
    crossDomain = false,
    headers = {},
    progressSubscriber = undefined,
    withCredentials = true,
    deferCatch,
}: NetworkRequestConfig): Observable<AjaxResponse<any>> {
    const contentTypeHeader = contentType
        ? { 'Content-Type': computeContentHeader(contentType) }
        : {};

    const requestHeaders = {
        ...headers,
        ...contentTypeHeader,
    };

    const request: AjaxConfig = {
        url,
        method,
        crossDomain,
        withCredentials,
        progressSubscriber,
        body: contentType === 'json' ? JSON.stringify(payload) : payload,
        headers: requestHeaders,
    };

    if (responseType) {
        Object.assign(request, {
            responseType,
        });
    }

    return rxAjax(request).pipe(
        switchMap((status) => observableOf(status)),
        logErrorToSentry(() => !deferCatch), // Do not log if deferCatch is provided.
        catchError((error, caught) => {
            if (deferCatch) {
                return deferCatch(error, caught);
            }
            return observableThrowError(() => error);
        }),
    );
}
