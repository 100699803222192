import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { userState, subscriptionState } from 'state-domains/domain';

import { UserPortalState } from 'src/state';

import { AccountSelectDispatchProps } from './AccountSelect.types';

const mapStateToProps = (state: UserPortalState) => {
    const { user } = state;

    const {
        selectors: { subscriptions, currentSubscription },
    } = userState;
    return {
        user,
        subscriptions: subscriptions(state),
        selectedSubscriptionId: currentSubscription(state)?.id ?? '',
    };
};

const mapDispatchToProps = (dispatch: Dispatch): AccountSelectDispatchProps => {
    const {
        actions: { loadSubscription },
    } = subscriptionState;
    const {
        actions: { switchSubscription },
    } = userState;

    return {
        switchSubscription: bindActionCreators(switchSubscription, dispatch),
        loadSubscription: bindActionCreators(loadSubscription, dispatch),
    };
};

export const connectToState = (component: React.ComponentType<any>) =>
    connect(mapStateToProps, mapDispatchToProps)(component);
