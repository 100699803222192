import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import {
    DeleteRowReferenceRequest,
    DeleteRowReferencesResponse,
} from 'state-domains/domain/drillhole';
import {
    buildDeleteRowReferencesUrl,
    buildDeleteRowReferencesPointsUrl,
} from 'state-domains/network/urls';
import {
    sendRequestWithXSRFToken,
    convertToCamelWithSpecialParameters,
    convertToSnake,
} from 'state-domains/utils';

export const deleteRowReferences = (
    deleteRequest: DeleteRowReferenceRequest,
    type: ACTIVITY_TYPES,
): Observable<DeleteRowReferencesResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildDeleteRowReferencesUrl()
            : buildDeleteRowReferencesPointsUrl();
    const requestPayload = convertToSnake(deleteRequest);
    return sendRequestWithXSRFToken(url, requestPayload).pipe(
        switchMap(({ response }: AjaxResponse<DeleteRowReferencesResponse>) => {
            const converted = convertToCamelWithSpecialParameters<DeleteRowReferencesResponse>(
                response,
                {
                    id: 'uid',
                    _id: 'id',
                },
            );
            return observableOf(converted);
        }),
    );
};
