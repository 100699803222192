import { createTheme } from '@mui/material/styles';

export const sliderStyle = createTheme({
    components: {
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    '&::after': {
                        background:
                            'linear-gradient(90deg, transparent, rgba(255, 255, 255, 15%), transparent)',
                    },
                },
            },
        },
    },
});
