import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { LimitedColumns } from 'state-domains/domain/subscription';

import { buildUpdateMultiValueColumnsUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteTableViewColumn = (
    tableViewId: string,
    tableId: string,
    columnId: string,
): Observable<LimitedColumns> => {
    const url = buildUpdateMultiValueColumnsUrl(tableViewId, tableId, columnId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf({ ...convertToCamel<any>(response) }),
        ),
    );
};
