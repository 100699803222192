import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildSaveColourUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';
import { CustomColour, PayloadInfo } from '../types';

export const saveColour = (payloadInfo: PayloadInfo): Observable<CustomColour> =>
    sendRequestWithXSRFToken(buildSaveColourUrl(), payloadInfo.item, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<CustomColour>) => {
            const convertedColour = convertToCamel<CustomColour>(response);
            return observableOf(convertedColour);
        }),
    );
