import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Drillhole } from 'state-domains/domain/drillhole';

import { imagoSyncUrl } from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRequestWithXSRFToken } from '../../../utils';
import { ImagoSyncPayload, ImagoSyncResponse, ImagoSyncType } from '../types';

export const imagoSync = (
    payload: ImagoSyncPayload,
    syncType: ImagoSyncType,
): Observable<ImagoSyncResponse> => {
    const url = imagoSyncUrl(syncType);
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<ImagoSyncResponse>) => {
            const convertedCollar = convertToCamelWithSpecialParameters<Drillhole>(
                response.Collar,
                {
                    id: 'uid',
                    _id: 'id',
                },
            );

            return observableOf({
                Collar: convertedCollar,
                imageriesCount: response.imageriesCount,
            } as ImagoSyncResponse);
        }),
    );
};
