import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
    authState,
    userState,
    subscriptionState,
    integrationState,
    drillholeState,
} from 'state-domains/domain';

import { UserPortalState } from 'src/state';

import { WithCurrentUserDispatchProps, WithCurrentUserStateProps } from './withCurrentUser.types';

const {
    selectors: { currentUserId, isFailedWithAuthError, isCurrentUserFailed, isCurrentUserLoaded },
    actions: { loadCurrentUser, loadAccountPreferences },
} = userState;
const {
    actions: { loadSeequentCloudIntegration },
} = integrationState;
const {
    actions: { logout },
} = authState;

const {
    actions: { loadExportTemplates },
} = drillholeState;

const {
    selectors: { isSubscriptionDataStateFailed, isSubscriptionDataStateComplete, isEAP },
    actions: { loadSubscription },
} = subscriptionState;

const mapStateToProps = (state: UserPortalState): WithCurrentUserStateProps => ({
    currentUserID: currentUserId(state),
    isFailedWithAuthError: isFailedWithAuthError(state),
    isSubscriptionDataStateFailed: isSubscriptionDataStateFailed(state),
    isSubscriptionDataStateComplete: isSubscriptionDataStateComplete(state),
    isCurrentUserFailed: isCurrentUserFailed(state),
    isCurrentUserLoaded: isCurrentUserLoaded(state),
    isEAP: isEAP(state),
});

const mapDispatchToProps = (dispatch: Dispatch): WithCurrentUserDispatchProps => ({
    loadCurrentUser: bindActionCreators(loadCurrentUser, dispatch),
    loadSubscription: bindActionCreators(loadSubscription, dispatch),
    loadSeequentCloudIntegration: bindActionCreators(loadSeequentCloudIntegration, dispatch),
    loadAccountPreferences: bindActionCreators(loadAccountPreferences, dispatch),
    loadExportTemplates: bindActionCreators(loadExportTemplates, dispatch),
    logout: bindActionCreators(logout, dispatch),
});

export const connectToState = (baseComponent: React.ComponentType) =>
    connect(mapStateToProps, mapDispatchToProps)(baseComponent);
