import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        textAlign: 'center',
        padding: `${theme.spacing(4)} 0 0 0`,
    },
    image: {
        padding: `${theme.spacing()} 0`,
        width: '100%',
        maxWidth: '1086px',
    },
    imageBlock: {
        position: 'relative',
    },
    floatingTextBlock: {
        position: 'absolute',
        bottom: '35%',
        left: '50%',
        maxWidth: '28%',
        transform: 'translate(-50%, 0)',
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        padding: `${theme.spacing()} 0`,
        fontSize: '3rem',
        lineHeight: '3rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
            padding: `${theme.spacing(0.5)} 0`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    titleBelow: {
        color: theme.palette.primary.main,
        fontWeight: '500',
        padding: `${theme.spacing()} 0`,
        fontSize: '1.7rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            padding: `${theme.spacing(0.5)} 0`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
    subtitle: {
        fontWeight: 'bold',
        padding: `${theme.spacing()} 0`,
        fontSize: '1rem',
        lineHeight: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
            padding: `${theme.spacing(0.5)} 0`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.67rem',
        },
    },
    messageBlock: {
        padding: `${theme.spacing()} 0`,
    },
}));
