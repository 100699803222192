import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildPossibleSampleResultsColumns } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { PossibleSampleResultsColumnsResponse } from '../types';

export const loadPossibleSampleResultsColumns = (
    activityId: string,
): Observable<PossibleSampleResultsColumnsResponse> => {
    const obs = sendRetrieveRequest(buildPossibleSampleResultsColumns(activityId));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<PossibleSampleResultsColumnsResponse>) =>
            observableOf(convertToCamel<PossibleSampleResultsColumnsResponse>(response)),
        ),
    );
};
