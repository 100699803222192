import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { LimitedColumns } from 'state-domains/domain/subscription';

import { buildDeleteColumnUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const deleteColumn = (
    type: CONFIGURATION_TYPES,
    itemId: string,
    columnId: string,
): Observable<LimitedColumns> => {
    const url = buildDeleteColumnUrl(type, itemId, columnId);
    return sendRequestWithXSRFToken(url, '', 'DELETE').pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf({ ...convertToCamel<any>(response) }),
        ),
    );
};
