import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DIMENSION_PREFERENCE_DENOMINATIONS } from 'state-domains/constants';

export const skeletonRowHeightStyles = makeStyles<number>()((theme, rowHeight) => {
    let outerHeight: string;

    // Need to account for border bottom
    switch (rowHeight) {
        case DIMENSION_PREFERENCE_DENOMINATIONS.SHORT:
            outerHeight = `${DIMENSION_PREFERENCE_DENOMINATIONS.SHORT - 1}px`;
            break;
        case DIMENSION_PREFERENCE_DENOMINATIONS.COMPACT:
            outerHeight = `${DIMENSION_PREFERENCE_DENOMINATIONS.COMPACT - 1}px`;
            break;
        case DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT:
            outerHeight = `${DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT - 1}px`;
            break;
        default:
            outerHeight = '57px';
            break;
    }

    return {
        rowHeights: {
            height: outerHeight,
        },
    };
});

export const styles = (theme: Theme) => ({
    DataGridContainer: {
        width: '100%',
        overflow: 'hidden',
    },
    DataGridHeader: {
        width: '100%',
        height: '57px',
        backgroundColor: '#39393C',
    },
    DataGridContentContainer: {
        width: '100%',
        overflow: 'hidden',
    },
    DataGridItem: {
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    dataGridContainer: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        maxHeight: `calc(100vh - ${theme.spacing(28)})`,
        overflow: 'hidden',
    },
});

export const useStyles = makeStyles()(styles);
