import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ActivityMap } from 'state-domains/domain/subscription';
import { buildConfigurationActivityColumnAssociationUrl } from 'state-domains/network';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken } from '../../../utils';

export const modifyColumnAssociations = (id: string, payload: any): Observable<ActivityMap> => {
    const obs = sendRequestWithXSRFToken(
        buildConfigurationActivityColumnAssociationUrl(id),
        convertToSnake(payload),
        'PUT',
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const res = convertToCamel<any>(response.AssociatedColumns);
            return observableOf(res);
        }),
    );
};
