export const RESTORE_DRAWER = 'RESTORE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const SET_DRAWER_WITHOUT_PERSISTING = 'SET_DRAWER_WITHOUT_PERSISTING';
export const RESET_DRAWER = 'RESET_DRAWER';
export const CONFIG_READY = 'CONFIG_READY';

export const TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL';
export const RESTORE_SIDE_PANEL = 'RESTORE_SIDE_PANEL';
export const RESET_SIDE_PANEL = 'RESET_SIDE_PANEL';
export const SET_SIDE_PANEL_WITHOUT_PERSISTING = 'SET_SIDE_PANEL_WITHOUT_PERSISTING';
