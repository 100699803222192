import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
    grid: {
        padding: theme.spacing(2, 2, 4, 2),
        overflow: 'hidden',
        width: '100%',
        marginLeft: '0px',
        maxHeight: `calc(100vh - ${theme.spacing(23)})`,
    },
    sectionContainer: {
        backgroundColor: '#FAFAFA',
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginTop: '0px',
    },
    fieldContainer: {
        height: theme.spacing(9.25),
    },
});
