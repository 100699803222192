import store from 'store';

import {
    CONFIGURATION_TYPES,
    CLIENT_SIDE_PAGINATION_LIMIT,
} from 'state-domains/constants/mxdeposit';
import { FileObject } from 'state-domains/domain/drillhole';

import { STORE_BOUNDARY_DEGREE_BUTTON } from 'src/components/Drillhole/DrillholeMap';

import { LOAD_STATUS_COMPLETE, LOAD_STATUS_PENDING } from '../../../constants';
import { AsyncState, BaseAction } from '../../../types';
import {
    CLEAR_CONFIGURATION_SECTION,
    CLONE_CONFIGURATION_LIST,
    DELETE_CONFIGURATION_LIST,
    LOAD_CONFIGURATION_LISTS,
    REFRESH_STATE,
    CLEAR_CONFIGURATION_OPS_STATE,
    ADD_CONFIGURATION_LIST,
    EDIT_CONFIGURATION_LIST,
    LOAD_CONFIGURATION_TABLES,
    LOAD_CONFIGURATION_HEADERS,
    CLEAR_LISTS_DATA_ITEMS,
    CLEAR_HEADERS_DATA_ITEMS,
    CLEAR_TABLES_ITEMS,
    MODIFY_CONFIGURATION_DATA_GRID_STATE,
    MODIFY_COLUMN,
    CLEAR_MODIFY_COLUMN,
    ADD_CONFIGURATION_TABLE,
    EDIT_CONFIGURATION_TABLE,
    DELETE_CONFIGURATION_TABLEVIEW,
    ADD_CONFIGURATION_HEADER,
    EDIT_CONFIGURATION_HEADER,
    DELETE_CONFIGURATION_HEADER,
    DELETE_CONFIGURATION_FIELD,
    LOAD_CONFIGURATION_HEADER_FIELD_TYPES,
    CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS,
    ADD_CONFIGURATION_HEADER_FIELD_TYPES,
    EDIT_CONFIGURATION_HEADER_FIELD_TYPES,
    ADD_HEADER_FIELD,
    CLEAR_HEADER_FIELD_TYPES_ADD_STATE,
    CLEAR_HEADER_FIELD_TYPES_DELETE_STATE,
    IMPORT_LIST,
    CLEAR_IMPORT_LIST_STATE,
    CONFIRM_COLUMN_TYPES,
    DELETE_IMPORTED_LIST,
    IMPORT_TABLE,
    CLEAR_IMPORT_TABLE_STATE,
    EXPORT_CONFIGURATION,
    CLEAR_EXPORT_STATE,
    LOAD_CONFIGURATION_ACTIVITIES,
    CLEAR_ACTIVITIES_DATA_ITEMS,
    DELETE_CONFIGURATION_ACTIVITY,
    CLONE_CONFIGURATION_ACTIVITY,
    ADD_CONFIGURATION_ACTIVITY,
    EDIT_CONFIGURATION_ACTIVITY,
    MODIFY_SAMPLE_WORKFLOWS,
    LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS,
    ADD_SAMPLE_RESULTS_RANKED_COLUMN,
    LOAD_CONFIGURATION_RANKED_COLUMNS,
    EDIT_RANKED_COLUMN,
    DELETE_RANKED_COLUMN,
    CLEAR_RANKED_COLUMNS_STATE,
    CLEAR_POSSIBLE_COLUMNS,
    CLEAR_PROJECTS_DATA_ITEMS,
    LOAD_CONFIGURATION_PROJECTS,
    CLONE_CONFIGURATION_PROJECT,
    ADD_CONFIGURATION_PROJECT,
    DELETE_CONFIGURATION_PROJECT,
    EDIT_CONFIGURATION_PROJECT,
    CONVERT_PROJECT_BOUNDARIES,
    ADD_CONFIGURATION_PROJECT_ACTIVITY,
    EDIT_CONFIGURATION_PROJECT_ACTIVITIES,
    DELETE_CONFIGURATION_PROJECT_ACTIVITY,
    ASSIGN_UNASSIGN_USERS,
    CONVERT_BOUNDARY_TO_DECIMAL,
    LOAD_USER_PERMISSIONS,
    MODIFY_LIST_FILE_GROUP,
    MODIFY_LIST_FILES,
    CLEAR_LIST_FILE_STATE,
    EMPTY_LIST_FILE_GROUPS,
    LOAD_CONFIGURATION_SAMPLE_WORKFLOWS,
    CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE,
    CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE,
    ADD_CONFIGURATION_SAMPLE_WORKFLOW,
    EDIT_CONFIGURATION_SAMPLE_WORKFLOW,
    CLONE_CONFIGURATION_SAMPLE_WORKFLOW,
    DELETE_CONFIGURATION_SAMPLE_WORKFLOW,
    LOAD_DEFAULT_LAB_SERVICES,
    LOAD_CONFIGURATION_CATEGORIES,
    CONFIGURATION_CATEGORIES_OPERATION,
    LOAD_CONFIGURATION_ACTIVITY_GROUPS,
    LOAD_CONFIGURATION_GRIDS,
    EDIT_CONFIGURATION_GRID,
    ADD_CONFIGURATION_GRID,
    LOAD_DEFAULT_PROJECTIONS,
    LOAD_GRID_PREVIEW,
    DELETE_CONFIGURATION_GRID,
    CLEAR_GRIDS_DATA_ITEMS,
    UPDATE_GRID_NAME,
    LOAD_CONFIGURATION_USERS,
    OP_CONFIGURATION_USERS,
    ADD_EDIT_DELETE_ACTIVITY_GROUPS,
    RELOAD_CONFIGURATION_ITEMS,
    CLEAR_ACTIVITY_GROUPS_DATA_ITEMS,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import {
    ConfigurationError,
    ConfigurationListState,
    ConfigurationState,
    ConfigurationTableState,
    ConfigurationHeaderState,
    ConfigurationLoggingState,
    ConfigurationHeaderFieldTypeState,
    ConfigurationActivitiesState,
    ConfigurationRankedColumnsState,
    SampleResultsColumn,
    ConfigurationProjectsState,
    ListFileGroup,
    ConfigurationDefaultLabServicesState,
    ConfigurationGridState,
    ConfigurationReducedState,
} from '../types';

export const INITIAL_CONFIGURATION_LOGGING_STATE: ConfigurationLoggingState = {
    items: [],
    status: LOAD_STATUS_PENDING,
    error: null,
    deleteState: staleReducer({}),
    cloneState: staleReducer({ id: '' }),
    addState: staleReducer({ id: '' }),
    editState: staleReducer({}),
    exportState: staleReducer({ url: '' }),
};

export const INITIAL_CONFIGURATION_REDUCED_STATE: ConfigurationReducedState = {
    items: [],
    shouldReload: false,
    loadingState: staleReducer({}),
    operationState: staleReducer({}),
};

export const INITIAL_CONFIGURATION_HEADER_FIELD_TYPE_STATE: ConfigurationHeaderFieldTypeState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    importState: pendingReducer({ errors: [], infos: [], fields: null, success: false }),
    shouldReload: false,
};
export const INITIAL_CONFIGURATION_LIST_STATE: ConfigurationListState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    importState: pendingReducer({ imports: [] }),
    fileState: pendingReducer({ groupId: '', files: {} }),
    emptyFileGroupState: {},
};
export const INITIAL_CONFIGURATION_TABLES_STATE: ConfigurationTableState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    editState: completeReducer({}),
    importState: pendingReducer({
        errors: [],
        infos: [],
        tables: null,
        tableViews: null,
        tableCategories: null,
        success: [],
    }),
};
export const INITIAL_CONFIGURATION_HEADERS_STATE: ConfigurationHeaderState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    focusId: '',
    editState: completeReducer({}),
    addFieldState: pendingReducer({}),
};

export const INITIAL_CONFIGURATION_ACTIVITIES_STATE: ConfigurationActivitiesState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    editState: completeReducer({}),
    activitiesState: pendingReducer({
        activities: null,
    }),
};

export const INITIAL_CONFIGURATION_RANKED_COLUMNS_STATE: ConfigurationRankedColumnsState = {
    ...pendingReducer({
        columns: [],
        candidateColumnsMap: {},
        rankedColumnsMap: {},
    }),
};

export const INITIAL_CONFIGURATION_PROJECTS_STATE: ConfigurationProjectsState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    projectsState: pendingReducer({
        projects: null,
    }),
    coordinateState: completeReducer({
        convertedCoords: null,
    }),
    projectActivitiesState: staleReducer({
        activity: '',
    }),
    projectUserPermissionState: staleReducer({
        userPermissions: [],
    }),
    editState: completeReducer({}),
    showCoordsInDecimal: store.get(STORE_BOUNDARY_DEGREE_BUTTON) ?? true,
};

export const INITIAL_ASYNC_STATE: AsyncState = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
};

export const INITIAL_DEFAULT_LAB_SERVICES_STATE: ConfigurationDefaultLabServicesState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    labServices: {},
};

export const INITIAL_CONFIGURATION_GRIDS_STATE: ConfigurationGridState = {
    ...INITIAL_CONFIGURATION_LOGGING_STATE,
    projectionState: staleReducer({ projections: {} }),
    previewState: staleReducer({ data: {} }),
    shouldReload: false,
};

export const INITIAL_CONFIGURATION_ACTIVITY_GROUPS_STATE: ConfigurationReducedState = {
    items: [],
    loadingState: staleReducer({}),
    operationState: staleReducer({}),
    shouldReload: false,
};

export const INITIAL_STATE: ConfigurationState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    listsState: INITIAL_CONFIGURATION_LIST_STATE,
    tablesState: INITIAL_CONFIGURATION_TABLES_STATE,
    headerFieldTypesState: INITIAL_CONFIGURATION_HEADER_FIELD_TYPE_STATE,
    headersState: INITIAL_CONFIGURATION_HEADERS_STATE,
    activitiesState: INITIAL_CONFIGURATION_ACTIVITIES_STATE,
    activityGroupsState: INITIAL_CONFIGURATION_ACTIVITY_GROUPS_STATE,
    gridsState: INITIAL_CONFIGURATION_GRIDS_STATE,
    projectsState: INITIAL_CONFIGURATION_PROJECTS_STATE,
    rankedColumnsState: INITIAL_CONFIGURATION_RANKED_COLUMNS_STATE,
    configurationDataGrid: INITIAL_ASYNC_STATE,
    modifyColumnState: INITIAL_ASYNC_STATE,
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
    sampleWorkflow: INITIAL_CONFIGURATION_LOGGING_STATE,
    defaultLabServicesState: INITIAL_DEFAULT_LAB_SERVICES_STATE,
    usersState: INITIAL_CONFIGURATION_REDUCED_STATE,
    categoriesState: INITIAL_CONFIGURATION_REDUCED_STATE,
};

const createReducers = (
    stateName: keyof ConfigurationState,
    nestedState: 'loadingState' | 'operationState',
) => ({
    pending: (state: ConfigurationState): ConfigurationState => ({
        ...state,
        [stateName]: {
            ...state[stateName],
            [nestedState]: pendingReducer({ ...state[stateName][nestedState] }),
        },
    }),
    success: (state: ConfigurationState, actions: BaseAction): ConfigurationState => ({
        ...state,
        [stateName]: {
            ...state[stateName],
            shouldReload:
                nestedState === 'loadingState' ? false : state[stateName][nestedState].shouldReload,
            items: actions.payload.items,
            [nestedState]: completeReducer({ ...state[stateName][nestedState] }),
        },
    }),
    fail: (
        state: ConfigurationState,
        { payload: { error } }: BaseAction<ConfigurationError>,
    ): ConfigurationState => ({
        ...state,
        [stateName]: {
            ...state[stateName],
            [nestedState]: failureReducer({ ...state[stateName][nestedState], error }),
        },
    }),
});

const configurationHeaderFieldTypesPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    headerFieldTypesState: pendingReducer({ ...state.headerFieldTypesState }),
});

const loadHeaderFieldTypesSuccessReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    headerFieldTypesState: completeReducer({
        ...state.headerFieldTypesState,
        ...actions.payload,
        shouldReload: false,
    }),
});

const configurationHeaderFieldTypesFailedReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        headerFieldTypesState: failureReducer({ ...state.headerFieldTypesState, error }),
    };
};
const addConfigurationHeaderFieldTypesFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        headerFieldTypesState: {
            ...state.headerFieldTypesState,
            addState: failureReducer({ error }),
        },
    };
};

const addConfigurationHeaderFieldTypesPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    headerFieldTypesState: {
        ...state.headerFieldTypesState,
        addState: pendingReducer({ id: '' }),
    },
});

const addConfigurationHeaderFieldTypesSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => ({
    ...state,
    headerFieldTypesState: {
        ...state.headerFieldTypesState,
        items: [actions.payload, ...state.headerFieldTypesState.items],
        addState: completeReducer({ id: actions.payload.id }),
    },
});
const editConfigurationHeaderFieldTypesSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const items = [...state.headerFieldTypesState.items];

    const newItems = items.map((item, _index) => {
        if (item.id === actions.payload.id) {
            return actions.payload;
        }
        return item;
    });

    return {
        ...state,
        headerFieldTypesState: {
            ...state.headerFieldTypesState,
            items: newItems,
            addState: completeReducer({ id: actions.payload.id }),
        },
    };
};
const configurationListsPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    listsState: pendingReducer({ ...state.listsState }),
});

const loadConfigurationListsSuccessReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    listsState: completeReducer({ ...state.listsState, ...actions.payload }),
});

const configurationListsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return { ...state, listsState: failureReducer({ ...state.listsState, error }) };
};

const loadConfigurationTablesPendingReducer = (state: ConfigurationState): ConfigurationState =>
    pendingReducer({ ...state, tablesState: pendingReducer({ ...state.tablesState }) });

const loadConfigurationTablesSuccessReducer = (state: ConfigurationState, actions: BaseAction) =>
    completeReducer({
        ...state,
        tablesState: completeReducer({ ...state.tablesState, items: actions.payload.items }),
    });

const loadConfigurationTablesFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return failureReducer({
        ...state,
        tablesState: failureReducer({ ...state.tablesState, error }),
    });
};
const clearTablesItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    tablesState: pendingReducer({ ...state.tablesState, items: [] }),
});

const loadConfigurationHeadersPendingReducer = (state: ConfigurationState): ConfigurationState =>
    pendingReducer({ ...state, headersState: pendingReducer({ ...state.headersState }) });

const loadConfigurationHeadersSuccessReducer = (state: ConfigurationState, actions: BaseAction) =>
    completeReducer({
        ...state,
        headersState: completeReducer({ ...state.headersState, items: actions.payload }),
    });
const loadConfigurationHeadersFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return failureReducer({
        ...state,
        headersState: failureReducer({ ...state.headersState, error }),
    });
};
const clearHeadersItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headersState: pendingReducer({ ...state.headersState, items: [] }),
});
const clearHeaderFieldTypesItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headerFieldTypesState: {
        ...INITIAL_CONFIGURATION_HEADER_FIELD_TYPE_STATE,
        shouldReload: state.headerFieldTypesState.shouldReload,
    },
});
const clearHeaderFieldTypesAddState = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headerFieldTypesState: { ...state.headerFieldTypesState, addState: pendingReducer({ id: '' }) },
});
const clearHeaderFieldTypesDeleteState = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headerFieldTypesState: { ...state.headerFieldTypesState, deleteState: pendingReducer({}) },
});
// =================================================================

const clearListsItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    listsState: { ...state.listsState, items: [], status: LOAD_STATUS_PENDING, error: null },
    status: LOAD_STATUS_PENDING,
    error: null,
});

const clearGridsItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    gridsState: { ...state.gridsState, items: [], status: LOAD_STATUS_PENDING, error: null },
    status: LOAD_STATUS_PENDING,
    error: null,
});

const clearActivityItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    activitiesState: {
        ...state.activitiesState,
        items: [],
        status: LOAD_STATUS_PENDING,
        error: null,
    },
    status: LOAD_STATUS_PENDING,
    error: null,
});

const clearProjectItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        items: [],
        status: LOAD_STATUS_PENDING,
        error: null,
    },
    status: LOAD_STATUS_PENDING,
    error: null,
});

const clearActivityGroupsItems = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        items: [],
        loadingState: pendingReducer({}),
    },
});

const resetConfigurationListsStateReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    listsState: { ...INITIAL_CONFIGURATION_LIST_STATE },
});

const clearConfigurationSection = (state: ConfigurationState, action: BaseAction) => {
    const stateMap = {
        [CONFIGURATION_TYPES.LISTS]: {
            stateName: 'lists',
            initialValue: INITIAL_CONFIGURATION_LIST_STATE,
        },
        [CONFIGURATION_TYPES.SAMPLE_LISTS]: {
            stateName: 'lists',
            initialValue: INITIAL_CONFIGURATION_LIST_STATE,
        },
        [CONFIGURATION_TYPES.DATA_GRID]: {
            stateName: 'configurationDataGrid',
            initialValue: INITIAL_ASYNC_STATE,
        },
        [CONFIGURATION_TYPES.TABLES]: {
            stateName: 'tablesState',
            initialValue: INITIAL_CONFIGURATION_TABLES_STATE,
        },
        [CONFIGURATION_TYPES.HEADERS]: {
            stateName: 'headersState',
            initialValue: INITIAL_CONFIGURATION_HEADERS_STATE,
        },
        [CONFIGURATION_TYPES.SAMPLE_HEADERS]: {
            stateName: 'headersState',
            initialValue: INITIAL_CONFIGURATION_HEADERS_STATE,
        },
        [CONFIGURATION_TYPES.FIELDS]: {
            stateName: 'headerFieldTypesState',
            initialValue: {
                ...INITIAL_CONFIGURATION_HEADER_FIELD_TYPE_STATE,
                shouldReload: state.headerFieldTypesState.shouldReload,
            },
        },
        [CONFIGURATION_TYPES.SAMPLE_FIELDS]: {
            stateName: 'headerFieldTypesState',
            initialValue: {
                ...INITIAL_CONFIGURATION_HEADER_FIELD_TYPE_STATE,
                shouldReload: state.headerFieldTypesState.shouldReload,
            },
        },
        [CONFIGURATION_TYPES.ACTIVITIES]: {
            stateName: 'activitiesState',
            initialValue: INITIAL_CONFIGURATION_ACTIVITIES_STATE,
        },
        [CONFIGURATION_TYPES.PROJECTS]: {
            stateName: 'projectsState',
            initialValue: INITIAL_CONFIGURATION_PROJECTS_STATE,
        },
        [CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS]: {
            stateName: 'sampleWorkflow',
            initialValue: INITIAL_CONFIGURATION_LOGGING_STATE,
        },
        [CONFIGURATION_TYPES.ACTIVITY_GROUPS]: {
            stateName: 'activityGroupsState',
            initialValue: {
                ...INITIAL_CONFIGURATION_LOGGING_STATE,
                shouldReload: state.activityGroupsState.shouldReload,
            },
        },
        [CONFIGURATION_TYPES.GRIDS]: {
            stateName: 'gridsState',
            initialValue: {
                ...INITIAL_CONFIGURATION_GRIDS_STATE,
                projectionState: state.gridsState.projectionState,
                shouldReload: state.gridsState.shouldReload,
            },
        },
        [CONFIGURATION_TYPES.CATEGORIES]: {
            stateName: 'categoriesState',
            initialValue: INITIAL_CONFIGURATION_REDUCED_STATE,
        },
        [CONFIGURATION_TYPES.USERS]: {
            stateName: 'usersState',
            initialValue: INITIAL_CONFIGURATION_REDUCED_STATE,
        },
    };

    const stateToUpdate = stateMap[action.payload as keyof typeof stateMap];

    return {
        ...state,
        [stateToUpdate.stateName]: { ...stateToUpdate.initialValue },
    };
};

const deleteConfigurationListSuccessReducer = (state: ConfigurationState, actions: BaseAction) => {
    const { payload } = actions;
    const {
        listsState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        listsState: {
            ...state.listsState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationListPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    listsState: { ...state.listsState, deleteState: pendingReducer({}) },
});

const deleteConfigurationListFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        listsState: { ...state.listsState, deleteState: failureReducer({ error }) },
    };
};

const deleteConfigurationActivitySuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        activitiesState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        activitiesState: {
            ...state.activitiesState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationActivityPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    activitiesState: { ...state.activitiesState, deleteState: pendingReducer({}) },
});

const deleteConfigurationActivityFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        activitiesState: { ...state.activitiesState, deleteState: failureReducer({ error }) },
    };
};

const deleteConfigurationGridSuccessReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        items: state.gridsState.items.filter((x) => x.id !== actions.payload.id),
        deleteState: completeReducer({}),
    },
});

const deleteConfigurationGridPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    gridsState: { ...state.gridsState, deleteState: pendingReducer({}) },
});

const deleteConfigurationGridFailReducer = (
    state: ConfigurationState,
    // { payload: { error } }: BaseAction<ConfigurationError>,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        gridsState: { ...state.gridsState, deleteState: failureReducer({ error }) },
    };
};

const deleteConfigurationFieldSuccessReducer = (state: ConfigurationState, actions: BaseAction) => {
    const { payload } = actions;
    const {
        headerFieldTypesState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        headerFieldTypesState: {
            ...state.headerFieldTypesState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationFieldPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    headerFieldTypesState: { ...state.headerFieldTypesState, deleteState: pendingReducer({}) },
});

const deleteConfigurationFieldFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        headerFieldTypesState: {
            ...state.headerFieldTypesState,
            deleteState: failureReducer({ error }),
        },
    };
};

const deleteConfigurationHeaderSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        headersState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        headersState: {
            ...state.headersState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationHeaderPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    headersState: { ...state.headersState, deleteState: pendingReducer({}) },
});

const deleteConfigurationHeaderFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        headersState: { ...state.headersState, deleteState: failureReducer({ error }) },
    };
};

const deleteConfigurationTableViewSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        tablesState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        tablesState: {
            ...state.tablesState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationTableViewPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    tablesState: { ...state.tablesState, deleteState: pendingReducer({}) },
});

const deleteConfigurationTableViewFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        tablesState: { ...state.tablesState, deleteState: failureReducer({ error }) },
    };
};

const clearConfigurationOperationStates = (state: ConfigurationState) => ({
    ...state,
    modifyColumnState: completeReducer({}),
    listsState: {
        ...state.listsState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: pendingReducer({}),
        fileState: pendingReducer({ groupId: '', files: {} }),
        importState: pendingReducer({ imports: [] }),
    },
    tablesState: {
        ...state.tablesState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: completeReducer({}),
    },
    headersState: {
        ...state.headersState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: completeReducer({}),
    },
    headerFieldTypesState: {
        ...state.headerFieldTypesState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: pendingReducer({}),
        addFieldState: pendingReducer({}),
    },
    activitiesState: {
        ...state.activitiesState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: completeReducer({}),
        activitiesState: pendingReducer({}),
    },
    projectsState: {
        ...state.projectsState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: completeReducer({}),
        projectActivitiesState: staleReducer({}),
        projectUserPermissionState: staleReducer([]),
    },
    sampleWorkflow: {
        ...state.sampleWorkflow,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: staleReducer({}),
    },
    activityGroupsState: {
        ...state.activityGroupsState,
        operationState: staleReducer({}),
    },
    gridsState: {
        ...state.gridsState,
        deleteState: pendingReducer({}),
        cloneState: pendingReducer({}),
        addState: pendingReducer({ id: '' }),
        editState: staleReducer({}),
    },
    categoriesState: {
        ...state.categoriesState,
        operationState: staleReducer({}),
    },
});
const cloneConfigurationListSuccessReducer = (state: ConfigurationState, actions: BaseAction) => {
    const { payload } = actions;
    const {
        listsState: { items },
    } = state;
    return {
        ...state,
        listsState: {
            ...state.listsState,
            items: [...items, payload],
            cloneState: completeReducer({ id: payload.id }),
        },
    };
};

const addConfigurationHeaderPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    headersState: { ...state.headersState, addState: pendingReducer({ id: '' }) },
});

const addConfigurationHeaderFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        headersState: { ...state.headersState, addState: failureReducer({ error }) },
    };
};
const addConfigurationHeaderSuccessReducer = (state: ConfigurationState, actions: BaseAction) => {
    const { payload } = actions;
    const {
        headersState: { items },
    } = state;
    return {
        ...state,
        headersState: {
            ...state.headersState,
            items: [...items, payload],
            addState: completeReducer({ id: payload.id }),
        },
    };
};

const cloneConfigurationListPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    listsState: { ...state.listsState, cloneState: pendingReducer({ id: '' }) },
});

const cloneConfigurationListFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        listsState: { ...state.listsState, cloneState: failureReducer({ error }) },
    };
};

const addListPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    listsState: { ...state.listsState, addState: pendingReducer({}) },
});

const addListSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        listsState: {
            ...state.listsState,
            addState: completeReducer({ id: payload.id }),
        },
    };
};

const addListFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        listsState: { ...state.listsState, addState: failureReducer({ error }) },
    };
};

const addTablePendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    tablesState: { ...state.tablesState, addState: pendingReducer({}) },
});

const addTableSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        tablesState: {
            ...state.tablesState,
            addState: completeReducer({ id: payload.id }),
        },
    };
};
const addTableFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        tablesState: { ...state.tablesState, addState: failureReducer({ error }) },
    };
};

const modifyDataGridStatePendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    configurationDataGrid: pendingReducer({}),
});

const modifyDataGridStateFailReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    configurationDataGrid: failureReducer({
        error: actions.payload,
    }),
});

const modifyDataGridStateCompleteReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    configurationDataGrid: completeReducer({}),
});

const modifyColumnStatePendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    modifyColumnState: pendingReducer({}),
});

const modifyColumnStateFailReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    modifyColumnState: failureReducer({
        error: actions.payload,
    }),
});

const modifyColumnStateCompleteReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    modifyColumnState: completeReducer({ column: actions.payload }),
});

const clearModifyColumn = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    modifyColumnState: INITIAL_ASYNC_STATE,
});

const editConfigurationListSuccessReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    listsState: {
        ...state.listsState,
        editState: completeReducer({}),
    },
});

const editConfigurationListPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    listsState: { ...state.listsState, editState: pendingReducer({}) },
});

const editTablePendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    tablesState: { ...state.tablesState, editState: pendingReducer({}) },
});
const editTableSuccessReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    tablesState: {
        ...state.tablesState,
        editState: completeReducer({ result: actions.payload }),
    },
});
const editTableFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const error = action.payload;
    return {
        ...state,
        tablesState: { ...state.tablesState, editState: failureReducer({ error }) },
    };
};

const editConfigurationListFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        listsState: { ...state.listsState, editState: failureReducer({ error }) },
    };
};

const addHeaderPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headersState: { ...state.headersState, addState: pendingReducer({}) },
});

const addHeaderSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        headersState: {
            ...state.headersState,
            addState: completeReducer({ id: payload.id }),
        },
    };
};
const addHeaderFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        headersState: { ...state.headersState, addState: failureReducer({ error }) },
    };
};

const editHeaderPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headersState: { ...state.headersState, editState: pendingReducer({}), focusId: '' },
});
const editHeaderSuccessReducer = (state: ConfigurationState, actions: BaseAction) => ({
    ...state,
    headersState: {
        ...state.headersState,
        focusId: actions.payload?.id ?? '',
        editState: completeReducer({}),
    },
});
const editHeaderFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const error = action.payload;
    return {
        ...state,
        headersState: { ...state.headersState, editState: failureReducer({ error }) },
    };
};

const addHeaderFieldPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    headersState: { ...state.headersState, addFieldState: pendingReducer({}), focusId: '' },
});
const addHeaderFieldSuccessReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    headersState: {
        ...state.headersState,
        focusId: '',
        addFieldState: completeReducer({}),
    },
});
const addHeaderFieldFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        headersState: { ...state.headersState, addFieldState: failureReducer({ error }) },
    };
};

const importListPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    listsState: {
        ...state.listsState,
        importState: pendingReducer({ imports: state.listsState.importState.imports }),
    },
});

const importListSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const { collection, override, replace = false } = action.payload;

    const newItems = [...(!replace ? (state.listsState.importState.imports ?? []) : [])];

    collection.forEach((x: any) => {
        if (x && x.id) {
            const index = newItems.findIndex((y) => y.id === x.id);

            if (index > -1 && (x.errors.length || override)) {
                newItems[index] = x;
            } else if (index === -1) {
                newItems.push(x);
            }
        } else {
            newItems.push(x);
        }
    });

    return {
        ...state,
        listsState: {
            ...state.listsState,
            importState: completeReducer({ imports: newItems }),
        },
    };
};
const importListFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        listsState: {
            ...state.listsState,
            importState: failureReducer({
                imports: state.listsState.importState.imports,
                error,
            }),
        },
    };
};

const confirmColumnTypes = (state: ConfigurationState, action: BaseAction) => {
    const { id, infos } = action.payload;

    const newItems = [...state.listsState.importState.imports];
    const idx = newItems.findIndex((x) => x.id === id);

    if (idx > -1) {
        newItems[idx].infos = infos;
        newItems[idx].verifiedWarnings = true;
    }

    return {
        ...state,
        listsState: {
            ...state.listsState,
            importState: completeReducer({ imports: newItems }),
        },
    };
};

const deleteImportedList = (state: ConfigurationState, action: BaseAction) => {
    const ids = action.payload;

    const newItems = [...state.listsState.importState.imports];

    ids.forEach((id: string) => {
        const idx = newItems.findIndex((x) => x.id === id);
        if (idx > -1) {
            newItems.splice(idx, 1);
        }
    });

    return {
        ...state,
        listsState: {
            ...state.listsState,
            importState: completeReducer({ imports: newItems }),
        },
    };
};

const clearConfigurationListsImportListState = (
    state: ConfigurationState,
    _action: BaseAction,
) => ({
    ...state,
    listsState: {
        ...state.listsState,
        importState: pendingReducer({ imports: [] }),
    },
});
const exportConfigurationPendingReducer = (state: ConfigurationState, action: BaseAction) => {
    const typeMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.LISTS]: 'listsState',
        [CONFIGURATION_TYPES.TABLES]: 'tablesState',
    };

    const {
        payload: { type },
    } = action;

    const configState = typeMap[type];

    return {
        ...state,
        [configState]: {
            ...state[configState],
            exportState: pendingReducer({ ...state[configState].exportState }),
        },
    };
};

const exportConfigurationSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const typeMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.LISTS]: 'listsState',
        [CONFIGURATION_TYPES.TABLES]: 'tablesState',
    };
    const {
        payload: { response, type },
    } = action;

    const configState = typeMap[type];
    return {
        ...state,
        [configState]: {
            ...state[configState],
            exportState: completeReducer({ ...response }),
        },
    };
};
const exportConfigurationFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const typeMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.LISTS]: 'listsState',
        [CONFIGURATION_TYPES.TABLES]: 'tablesState',
    };
    const { error, type } = action.payload;
    const configState = typeMap[type];
    return {
        ...state,
        [configState]: {
            ...state[configState],
            exportState: failureReducer({ ...state[configState].exportState, error }),
        },
    };
};

const clearConfigurationExportListsState = (state: ConfigurationState, action: BaseAction) => {
    const typeMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.LISTS]: 'listsState',
        [CONFIGURATION_TYPES.TABLES]: 'tablesState',
    };

    const {
        payload: { type },
    } = action;

    const configState = typeMap[type];

    return {
        ...state,
        [configState]: {
            ...state[configState],
            exportState: pendingReducer({ url: '' }),
        },
    };
};

const importTablePendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    tablesState: {
        ...state.tablesState,
        importState: pendingReducer({ ...state.tablesState.importState }),
    },
});

const importTableSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const {
        payload: { result },
    } = action;
    return {
        ...state,
        tablesState: {
            ...state.tablesState,
            importState: completeReducer({ ...result }),
        },
    };
};
const importTableFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        tablesState: {
            ...state.tablesState,
            importState: failureReducer({ ...state.tablesState.importState, ...error }),
        },
    };
};
const clearConfigurationTablesImportListState = (
    state: ConfigurationState,
    _action: BaseAction,
) => ({
    ...state,
    tablesState: {
        ...state.tablesState,
        importState: pendingReducer({ errors: [], infos: [], list: null, success: false }),
    },
});

const configurationGridsPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    gridsState: pendingReducer({ ...state.gridsState }),
});
const configurationGridsSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => ({
    ...state,
    gridsState: {
        ...completeReducer({ ...state.gridsState }),
        items: actions.payload.items,
        shouldReload: false,
    },
});
const configurationGridsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
): ConfigurationState => ({
    ...state,
    gridsState: failureReducer({ ...state.gridsState, error: action.payload.error }),
});

const configurationDefaultProjectionsPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        projectionState: pendingReducer({ projections: {} }),
    },
});
const configurationDefaultProjectionsSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        projectionState: completeReducer({ projections: actions.payload }),
    },
});
const configurationDefaultProjectionsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
): ConfigurationState => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        projectionState: failureReducer({
            ...state.gridsState.projectionState,
            error: action.payload.error,
        }),
    },
});

const configurationActivitiesPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    activitiesState: pendingReducer({ ...state.activitiesState }),
});

const loadConfigurationActivitiesSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => ({
    ...state,
    activitiesState: completeReducer({ ...state.activitiesState, ...actions.payload }),
});

const configurationActivitiesFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return { ...state, activitiesState: failureReducer({ ...state.activitiesState, error }) };
};

const {
    pending: configurationUsersPendingReducer,
    success: configurationUsersSuccessReducer,
    fail: configurationUsersFailReducer,
} = createReducers('usersState', 'loadingState');

const {
    pending: operationConfigurationUsersPendingReducer,
    success: operationConfigurationUsersSuccessReducer,
    fail: operationConfigurationUsersFailReducer,
} = createReducers('usersState', 'operationState');

const cloneConfigurationActivityPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    activitiesState: { ...state.activitiesState, cloneState: pendingReducer({ id: '' }) },
});

const cloneConfigurationActivityFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        activitiesState: { ...state.activitiesState, cloneState: failureReducer({ error }) },
    };
};

const cloneConfigurationActivitySuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        activitiesState: { items },
    } = state;
    return {
        ...state,
        activitiesState: {
            ...state.activitiesState,
            items: [...items, payload],
            cloneState: completeReducer({ id: payload.id }),
        },
    };
};

const editActivityPendingReducer = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    activitiesState: { ...state.activitiesState, editState: pendingReducer({}) },
});
const editActivitySuccessReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    activitiesState: {
        ...state.activitiesState,
        editState: completeReducer({}),
    },
});
const editActivityFailReducer = (state: ConfigurationState, action: BaseAction) => {
    const error = action.payload;
    return {
        ...state,
        activitiesState: { ...state.activitiesState, editState: failureReducer({ error }) },
    };
};

const addConfigurationActivityPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    activitiesState: { ...state.activitiesState, addState: pendingReducer({ id: '' }) },
});

const addConfigurationActivitySuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => {
    const { payload } = actions;
    const {
        activitiesState: { items },
    } = state;
    return {
        ...state,
        activitiesState: {
            ...state.activitiesState,
            items: [...items, payload],
            addState: completeReducer({ id: payload.id }),
        },
    };
};

const addConfigurationActivityFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    activitiesState: {
        ...state.activitiesState,
        addState: failureReducer({ error: action.payload.error }),
    },
});

const editConfigurationActivityPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    activitiesState: { ...state.activitiesState, editState: pendingReducer({}) },
});

const editConfigurationActivitySuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => {
    const payload = { actions } as any;
    return {
        ...state,
        activitiesState: {
            ...state.activitiesState,
            editState: completeReducer({ result: payload }),
        },
    };
};

const editConfigurationActivityFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        activitiesState: { ...state.activitiesState, editState: failureReducer({ error }) },
    };
};

const modifySampleWorkflowPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    sampleWorkflow: pendingReducer({ ...state.sampleWorkflow }),
});

const modifySampleWorkflowCompleteReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => ({
    ...state,
    sampleWorkflow: completeReducer({ ...state.sampleWorkflow, ...actions.payload }),
});

const modifySampleWorkflowFailReducer = (
    state: ConfigurationState,
    actions: BaseAction<ConfigurationError>,
): ConfigurationState => ({
    ...state,
    sampleWorkflow: failureReducer({ ...state.sampleWorkflow, ...actions.payload }),
});
const loadPossibleSampleResultsColumnsSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => {
    const {
        payload: { columns },
    } = action;
    return {
        ...state,
        rankedColumnsState: {
            ...state.rankedColumnsState,
            ...completeReducer({ columns }),
        },
    };
};

const loadConfigurationRankedColumns = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => {
    const {
        payload: {
            result: { candidateColumnsMap, rankedColumnsMap },
        },
    } = action;
    return {
        ...state,
        rankedColumnsState: {
            ...state.rankedColumnsState,
            ...completeReducer({ candidateColumnsMap, rankedColumnsMap }),
        },
    };
};

const rankedColumnsSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => {
    const {
        payload: { candidateColumns, rankedColumn },
    } = action;
    const dict: { [id: string]: SampleResultsColumn } = {
        ...state.rankedColumnsState.candidateColumnsMap,
    };
    candidateColumns.forEach((x: SampleResultsColumn) => {
        if (x.id) {
            dict[x.id] = x;
        }
    });
    return {
        ...state,
        rankedColumnsState: {
            ...state.rankedColumnsState,
            ...completeReducer({
                candidateColumnsMap: dict,
                rankedColumnsMap: {
                    ...state.rankedColumnsState.rankedColumnsMap,
                    [rankedColumn.id]: rankedColumn,
                },
            }),
        },
    };
};

const rankedColumnCompleteStateReducer = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => ({
    ...state,
    rankedColumnsState: { ...state.rankedColumnsState, ...completeReducer({}) },
});

const clearPossibleColumnsReducer = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => ({
    ...state,
    rankedColumnsState: { ...state.rankedColumnsState, ...completeReducer({ columns: [] }) },
});

const rankedColumnsPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    rankedColumnsState: { ...state.rankedColumnsState, ...pendingReducer({}) },
});

const rankedColumnsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        rankedColumnsState: { ...state.rankedColumnsState, ...failureReducer({ error }) },
    };
};

const configurationProjectsPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    projectsState: pendingReducer({ ...state.projectsState }),
});

const configurationProjectsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return { ...state, projectsState: failureReducer({ ...state.projectsState, error }) };
};

const loadConfigurationProjectsSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => ({
    ...state,
    projectsState: completeReducer({ ...state.projectsState, ...actions.payload }),
});

const cloneConfigurationProjectPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    projectsState: { ...state.projectsState, cloneState: pendingReducer({ id: '' }) },
});

const cloneConfigurationProjectFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        projectsState: { ...state.projectsState, cloneState: failureReducer({ error }) },
    };
};

const cloneConfigurationProjectSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        projectsState: { items },
    } = state;
    return {
        ...state,
        projectsState: {
            ...state.projectsState,
            items: [...items, payload],
            cloneState: completeReducer({ id: payload.id }),
        },
    };
};

const addConfigurationProjectPendingReducer = (state: ConfigurationState): ConfigurationState => ({
    ...state,
    projectsState: { ...state.projectsState, addState: pendingReducer({ id: '' }) },
});

const addConfigurationProjectSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => {
    const { payload } = actions;
    const {
        projectsState: { items },
    } = state;
    return {
        ...state,
        projectsState: {
            ...state.projectsState,
            items: [...items, payload],
            addState: completeReducer({ id: payload.id }),
        },
    };
};

const addConfigurationProjectFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        addState: failureReducer({ error: action.payload.error }),
    },
});

const deleteConfigurationProjectSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => {
    const { payload } = actions;
    const {
        projectsState: { items },
    } = state;
    const newItems = items.filter((x: any) => x.id !== payload.id);
    return {
        ...state,
        projectsState: {
            ...state.projectsState,
            items: newItems,
            deleteState: completeReducer({}),
        },
    };
};

const deleteConfigurationProjectPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    projectsState: { ...state.projectsState, deleteState: pendingReducer({}) },
});

const deleteConfigurationProjectFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        projectsState: { ...state.projectsState, deleteState: failureReducer({ error }) },
    };
};

const editConfigurationProjectSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const items = [];

    if (action.payload.projectData) {
        const updatedItem = action.payload.projectData;
        const strippedItems = (state.projectsState.items ?? []).filter(
            (x: any) => x.id !== updatedItem.id,
        );

        items.push(...[...strippedItems, updatedItem]);
    } else {
        items.push(...state.projectsState.items);
    }

    return {
        ...state,
        projectsState: {
            ...state.projectsState,
            editState: completeReducer({}),
            items,
        },
    };
};

const editConfigurationProjectPendingReducer = (
    state: ConfigurationState,
    _actions: BaseAction,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        editState: pendingReducer({}),
    },
});

const editConfigurationProjectFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        editState: failureReducer({ error: action.payload.error }),
    },
});

const projectBoundariesPendingReducer = (state: ConfigurationState) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        coordinateState: pendingReducer({}),
    },
});

const projectBoundariesSuccessReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        coordinateState: completeReducer({
            convertedCoords: action.payload.response.CoordinateList.List,
        }),
    },
});

const projectBoundariesFailureReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        coordinateState: failureReducer({ error: action.payload.error }),
    },
});

const projectActivitiesPendingReducer = (state: ConfigurationState) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectActivitiesState: pendingReducer({}),
    },
});

const projectActivitiesSuccessReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectActivitiesState: completeReducer({}),
    },
});

const projectActivitiesDefaultSuccessReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectActivitiesState: completeReducer({}),
    },
});

const projectActivitiesFailureReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectActivitiesState: failureReducer({ error: action.payload.error }),
    },
});
const toggleBoundaryDecimal = (state: any) => {
    const showCoordsInDecimal = !state.projectsState.showCoordsInDecimal;
    store.set(STORE_BOUNDARY_DEGREE_BUTTON, showCoordsInDecimal);
    return {
        ...state,
        projectsState: {
            ...state.projectsState,
            showCoordsInDecimal,
        },
    };
};

const projectUserPermissionsFailureReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectUserPermissionState: failureReducer({ error: action.payload.error }),
    },
});

const projectUserPermissionsPendingReducer = (state: ConfigurationState) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectUserPermissionState: pendingReducer({}),
    },
});

const projectUserPermissionsSuccessReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    projectsState: {
        ...state.projectsState,
        projectUserPermissionState: completeReducer({ userPermissions: action.payload }),
    },
});

const modifyListFileGroupFailureReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    listsState: {
        ...state.listsState,
        fileState: failureReducer({ ...state.listsState.fileState, error: action.payload }),
    },
});

const modifyListFileGroupPendingReducer = (state: ConfigurationState) => ({
    ...state,
    listsState: {
        ...state.listsState,
        fileState: pendingReducer({ ...state.listsState.fileState }),
    },
});

const modifyListFileGroupSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const payload: ListFileGroup = action.payload;

    return {
        ...state,
        listsState: {
            ...state.listsState,
            fileState: completeReducer({ groupId: payload.id, files: payload.files }),
        },
    };
};

const modifyListFileSuccessReducer = (state: ConfigurationState, action: BaseAction) => {
    const { files, operation } = action.payload;
    const newFiles = { ...state.listsState.fileState.files };
    const newEmptyGroups = { ...state.listsState.emptyFileGroupState };
    let reloadEmptyFileGroups = false;

    if (operation === 'Delete') {
        files.forEach((x: FileObject) => {
            if (x.id in newFiles) {
                delete newFiles[x.id];
            }
        });

        if (!Object.keys(newFiles ?? {}).length) {
            newEmptyGroups[state.listsState.fileState.groupId ?? ''] = true;
            reloadEmptyFileGroups = true;
        }
    } else if (operation === 'Upload') {
        files.forEach((x: FileObject) => (newFiles[x.id] = x));

        if (
            Object.keys(newFiles ?? {}).length &&
            state.listsState.fileState.groupId in newEmptyGroups
        ) {
            delete newEmptyGroups[state.listsState.fileState.groupId ?? ''];
            reloadEmptyFileGroups = true;
        }
    }

    return {
        ...state,
        listsState: {
            ...state.listsState,
            fileState: completeReducer({ ...state.listsState.fileState, files: newFiles }),
            ...(reloadEmptyFileGroups && { emptyFileGroupState: newEmptyGroups }),
        },
    };
};

const clearListFileState = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    listsState: {
        ...state.listsState,
        fileState: pendingReducer({ groupId: '', files: {} }),
    },
});

const loadEmptyListFileGroups = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    listsState: {
        ...state.listsState,
        emptyFileGroupState: action.payload,
    },
});

const loadConfigurationSampleWorkflowsPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    sampleWorkflow: { ...state.sampleWorkflow, ...pendingReducer({}) },
});

const loadConfigurationSampleWorkflowsFailureReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => {
    const { error } = action.payload;
    return {
        ...state,
        sampleWorkflow: { ...state.sampleWorkflow, ...failureReducer({ error }) },
    };
};

const loadConfigurationSampleWorkflowsSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
): ConfigurationState => ({
    ...state,
    sampleWorkflow: completeReducer({ ...state.sampleWorkflow, items: action.payload }),
});

const clearConfigurationSampleWorkflowState = (state: ConfigurationState, _action: BaseAction) => ({
    ...state,
    sampleWorkflow: { ...INITIAL_CONFIGURATION_LOGGING_STATE },
});
const clearConfigurationSampleWorkflowAddState = (
    state: ConfigurationState,
    _action: BaseAction,
) => ({
    ...state,
    sampleWorkflow: { ...state.sampleWorkflow, addState: pendingReducer({ id: '' }) },
});

const addConfigurationSampleWorkflowPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    sampleWorkflow: { ...state.sampleWorkflow, addState: pendingReducer({ id: '' }) },
});

const addConfigurationSampleWorkflowSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
): ConfigurationState => {
    const { payload } = actions;
    const {
        sampleWorkflow: { items },
    } = state;
    return {
        ...state,
        sampleWorkflow: {
            ...state.sampleWorkflow,
            items: [...items, payload],
            addState: completeReducer({ id: payload.id }),
        },
    };
};

const addConfigurationSampleWorkflowFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        addState: failureReducer({ error: action.payload.error }),
    },
});

const editConfigurationSampleWorkflowSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        editState: completeReducer({ result: action.payload }),
    },
});

const editConfigurationSampleWorkflowPendingReducer = (
    state: ConfigurationState,
    _actions: BaseAction,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        editState: pendingReducer({}),
    },
});

const editConfigurationSampleWorkflowFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        editState: failureReducer({ error: action.payload.error }),
    },
});

const cloneConfigurationSampleWorkflowSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
) => {
    const {
        sampleWorkflow: { items },
    } = state;
    return {
        ...state,
        sampleWorkflow: {
            ...state.sampleWorkflow,
            items: [...items, action.payload],
            cloneState: completeReducer({ id: action.payload.id }),
        },
    };
};

const cloneConfigurationSampleWorkflowPendingReducer = (
    state: ConfigurationState,
    _actions: BaseAction,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        cloneState: pendingReducer({}),
    },
});

const cloneConfigurationSampleWorkflowFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        cloneState: failureReducer({ error: action.payload.error }),
    },
});

const deleteConfigurationSampleWorkflowSuccessReducer = (
    state: ConfigurationState,
    action: BaseAction,
) => {
    const {
        sampleWorkflow: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== action.payload.id);
    return {
        ...state,
        sampleWorkflow: {
            ...state.sampleWorkflow,
            items: newItems,
            deleteState: completeReducer({ result: action.payload }),
        },
    };
};

const deleteConfigurationSampleWorkflowPendingReducer = (
    state: ConfigurationState,
    _actions: BaseAction,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        deleteState: pendingReducer({}),
    },
});

const deleteConfigurationSampleWorkflowFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    sampleWorkflow: {
        ...state.sampleWorkflow,
        deleteState: failureReducer({ error: action.payload.error }),
    },
});

const loadDefaultLabServicesSuccessReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    defaultLabServicesState: completeReducer({ labServices: action.payload }),
});

const loadDefaultLabServicesPendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    defaultLabServicesState: pendingReducer({ labServices: {} }),
});

const loadDefaultLabServicesFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    defaultLabServicesState: failureReducer({ error: action.payload.error }),
});

const loadConfigurationActivityGroupsPendingReducer = (
    state: ConfigurationState,
): ConfigurationState => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        loadingState: pendingReducer({}),
    },
});

const loadConfigurationActivityGroupsSuccessReducer = (
    state: ConfigurationState,
    actions: BaseAction,
) => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        items: actions.payload,
        loadingState: completeReducer({}),
        shouldReload: false,
    },
});

const loadConfigurationActivityGroupsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        loadingState: failureReducer({ error: action.payload.error }),
    },
});

const addGridsCompleteReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        addState: completeReducer({ id: action.payload?.id }),
    },
});

const addGridsPendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        addState: pendingReducer({}),
    },
});

const addGridsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        addState: failureReducer({ error: action.payload.error }),
    },
});

const editGridsCompleteReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        editState: completeReducer({}),
    },
});

const editGridsPendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        editState: pendingReducer({}),
    },
});

const editGridsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        editState: failureReducer({ error: action.payload.error }),
    },
});

const loadGridPreviewCompleteReducer = (state: ConfigurationState, action: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        previewState: completeReducer({
            data: {
                center: action.payload.Center,
                centerVector: action.payload.CenterVector,
                gridLines: action.payload.GridLines,
            },
        }),
    },
});

const loadGridPreviewPendingReducer = (state: ConfigurationState, _actions: BaseAction) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        previewState: pendingReducer({}),
    },
});

const loadGridPreviewFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        previewState: failureReducer({ error: action.payload.error }),
    },
});

const updateGridNameCompleteReducer = (state: ConfigurationState, action: BaseAction) => {
    const newItem = action.payload.data;
    const items = [...state.gridsState.items] as any[];
    items[items.findIndex((x) => x.id === newItem.id)] = newItem;
    return {
        ...state,
        gridsState: {
            ...state.gridsState,
            items,
            editState: completeReducer({}),
        },
    };
};

const updateGridNameFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    gridsState: {
        ...state.gridsState,
        editState: failureReducer({ error: action.payload }),
    },
});

const {
    pending: loadConfigurationCategoriesPendingReducer,
    success: loadConfigurationCategoriesSuccessReducer,
    fail: loadConfigurationCategoriesFailureReducer,
} = createReducers('categoriesState', 'loadingState');

const {
    pending: configurationCategoriesOperationPendingReducer,
    success: configurationCategoriesOperationSuccessReducer,
    fail: configurationCategoriesOperationFailureReducer,
} = createReducers('categoriesState', 'operationState');

const addEditDeleteActivityGroupsCompleteReducer = (
    state: ConfigurationState,
    action: BaseAction,
) => {
    const type = action.payload.operationType;
    const payload = action.payload.data;
    let items = [...state.activityGroupsState.items] as any[];

    if (type === 'delete') {
        items = items.filter((x) => x.id !== payload.id);
    }

    return {
        ...state,
        activityGroupsState: {
            ...state.activityGroupsState,
            items,
            operationState: completeReducer({}),
        },
    };
};

const addEditDeleteActivityGroupsFailReducer = (
    state: ConfigurationState,
    action: BaseAction<ConfigurationError>,
) => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        operationState: failureReducer({ error: action.payload }),
    },
});

const addEditDeleteActivityGroupsPendingReducer = (
    state: ConfigurationState,
    _action: BaseAction,
) => ({
    ...state,
    activityGroupsState: {
        ...state.activityGroupsState,
        operationState: pendingReducer({}),
    },
});

const setReloadFlagInState = (state: ConfigurationState, action: BaseAction) => {
    const stateMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.FIELDS]: 'headerFieldTypesState',
        [CONFIGURATION_TYPES.SAMPLE_FIELDS]: 'headerFieldTypesState',
        [CONFIGURATION_TYPES.ACTIVITY_GROUPS]: 'activityGroupsState',
        [CONFIGURATION_TYPES.GRIDS]: 'gridsState',
        [CONFIGURATION_TYPES.CATEGORIES]: 'categoriesState',
    };
    const stateName = stateMap?.[action.payload.stateName] ?? '';

    if (stateName) {
        return {
            ...state,
            [stateName]: {
                ...state[stateName],
                shouldReload: true,
            },
        };
    }

    return state;
};

export const reducer = mappedReducer(INITIAL_STATE, {
    [typeComplete(EDIT_CONFIGURATION_HEADER_FIELD_TYPES)]:
        editConfigurationHeaderFieldTypesSuccessReducer,
    [typePending(ADD_CONFIGURATION_HEADER_FIELD_TYPES)]:
        addConfigurationHeaderFieldTypesPendingReducer,
    [typeComplete(ADD_CONFIGURATION_HEADER_FIELD_TYPES)]:
        addConfigurationHeaderFieldTypesSuccessReducer,
    [typeFail(ADD_CONFIGURATION_HEADER_FIELD_TYPES)]: addConfigurationHeaderFieldTypesFailReducer,
    [typePending(LOAD_CONFIGURATION_HEADER_FIELD_TYPES)]:
        configurationHeaderFieldTypesPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_HEADER_FIELD_TYPES)]: loadHeaderFieldTypesSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_HEADER_FIELD_TYPES)]: configurationHeaderFieldTypesFailedReducer,

    [typePending(LOAD_CONFIGURATION_LISTS)]: configurationListsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_LISTS)]: loadConfigurationListsSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_LISTS)]: configurationListsFailReducer,

    [typePending(LOAD_CONFIGURATION_TABLES)]: loadConfigurationTablesPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_TABLES)]: loadConfigurationTablesSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_TABLES)]: loadConfigurationTablesFailReducer,

    [typePending(LOAD_CONFIGURATION_HEADERS)]: loadConfigurationHeadersPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_HEADERS)]: loadConfigurationHeadersSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_HEADERS)]: loadConfigurationHeadersFailReducer,

    [typePending(LOAD_CONFIGURATION_ACTIVITIES)]: configurationActivitiesPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_ACTIVITIES)]: loadConfigurationActivitiesSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_ACTIVITIES)]: configurationActivitiesFailReducer,

    [typePending(LOAD_CONFIGURATION_GRIDS)]: configurationGridsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_GRIDS)]: configurationGridsSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_GRIDS)]: configurationGridsFailReducer,

    [typePending(LOAD_DEFAULT_PROJECTIONS)]: configurationDefaultProjectionsPendingReducer,
    [typeComplete(LOAD_DEFAULT_PROJECTIONS)]: configurationDefaultProjectionsSuccessReducer,
    [typeFail(LOAD_DEFAULT_PROJECTIONS)]: configurationDefaultProjectionsFailReducer,

    [typePending(LOAD_CONFIGURATION_USERS)]: configurationUsersPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_USERS)]: configurationUsersSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_USERS)]: configurationUsersFailReducer,
    [typePending(OP_CONFIGURATION_USERS)]: operationConfigurationUsersPendingReducer,
    [typeComplete(OP_CONFIGURATION_USERS)]: operationConfigurationUsersSuccessReducer,
    [typeFail(OP_CONFIGURATION_USERS)]: operationConfigurationUsersFailReducer,

    [typePending(DELETE_CONFIGURATION_ACTIVITY)]: deleteConfigurationActivityPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_ACTIVITY)]: deleteConfigurationActivitySuccessReducer,
    [typeFail(DELETE_CONFIGURATION_ACTIVITY)]: deleteConfigurationActivityFailReducer,

    [typePending(DELETE_CONFIGURATION_GRID)]: deleteConfigurationGridPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_GRID)]: deleteConfigurationGridSuccessReducer,
    [typeFail(DELETE_CONFIGURATION_GRID)]: deleteConfigurationGridFailReducer,

    [typePending(DELETE_CONFIGURATION_LIST)]: deleteConfigurationListPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_LIST)]: deleteConfigurationListSuccessReducer,
    [typeFail(DELETE_CONFIGURATION_LIST)]: deleteConfigurationListFailReducer,

    [typeComplete(DELETE_CONFIGURATION_TABLEVIEW)]: deleteConfigurationTableViewSuccessReducer,
    [typePending(DELETE_CONFIGURATION_TABLEVIEW)]: deleteConfigurationTableViewPendingReducer,
    [typeFail(DELETE_CONFIGURATION_TABLEVIEW)]: deleteConfigurationTableViewFailReducer,

    [typeComplete(DELETE_CONFIGURATION_HEADER)]: deleteConfigurationHeaderSuccessReducer,
    [typePending(DELETE_CONFIGURATION_HEADER)]: deleteConfigurationHeaderPendingReducer,
    [typeFail(DELETE_CONFIGURATION_HEADER)]: deleteConfigurationHeaderFailReducer,

    [typeComplete(DELETE_CONFIGURATION_FIELD)]: deleteConfigurationFieldSuccessReducer,
    [typePending(DELETE_CONFIGURATION_FIELD)]: deleteConfigurationFieldPendingReducer,
    [typeFail(DELETE_CONFIGURATION_FIELD)]: deleteConfigurationFieldFailReducer,

    [typeComplete(ADD_CONFIGURATION_HEADER)]: addConfigurationHeaderSuccessReducer,
    [typePending(ADD_CONFIGURATION_HEADER)]: addConfigurationHeaderPendingReducer,
    [typeFail(ADD_CONFIGURATION_HEADER)]: addConfigurationHeaderFailReducer,

    [typePending(CLONE_CONFIGURATION_LIST)]: cloneConfigurationListPendingReducer,
    [typeComplete(CLONE_CONFIGURATION_LIST)]: cloneConfigurationListSuccessReducer,
    [typeFail(CLONE_CONFIGURATION_LIST)]: cloneConfigurationListFailReducer,

    [typePending(CLONE_CONFIGURATION_ACTIVITY)]: cloneConfigurationActivityPendingReducer,
    [typeComplete(CLONE_CONFIGURATION_ACTIVITY)]: cloneConfigurationActivitySuccessReducer,
    [typeFail(CLONE_CONFIGURATION_ACTIVITY)]: cloneConfigurationActivityFailReducer,

    [typePending(MODIFY_CONFIGURATION_DATA_GRID_STATE)]: modifyDataGridStatePendingReducer,
    [typeComplete(MODIFY_CONFIGURATION_DATA_GRID_STATE)]: modifyDataGridStateCompleteReducer,
    [typeFail(MODIFY_CONFIGURATION_DATA_GRID_STATE)]: modifyDataGridStateFailReducer,

    [typePending(MODIFY_COLUMN)]: modifyColumnStatePendingReducer,
    [typeComplete(MODIFY_COLUMN)]: modifyColumnStateCompleteReducer,
    [typeFail(MODIFY_COLUMN)]: modifyColumnStateFailReducer,

    [typePending(ADD_CONFIGURATION_LIST)]: addListPendingReducer,
    [typeComplete(ADD_CONFIGURATION_LIST)]: addListSuccessReducer,
    [typeFail(ADD_CONFIGURATION_LIST)]: addListFailReducer,

    [typePending(EDIT_CONFIGURATION_LIST)]: editConfigurationListPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_LIST)]: editConfigurationListSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_LIST)]: editConfigurationListFailReducer,

    [typePending(ADD_CONFIGURATION_TABLE)]: addTablePendingReducer,
    [typeComplete(ADD_CONFIGURATION_TABLE)]: addTableSuccessReducer,
    [typeFail(ADD_CONFIGURATION_TABLE)]: addTableFailReducer,

    [typePending(EDIT_CONFIGURATION_TABLE)]: editTablePendingReducer,
    [typeComplete(EDIT_CONFIGURATION_TABLE)]: editTableSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_TABLE)]: editTableFailReducer,

    [typePending(ADD_CONFIGURATION_HEADER)]: addHeaderPendingReducer,
    [typeComplete(ADD_CONFIGURATION_HEADER)]: addHeaderSuccessReducer,
    [typeFail(ADD_CONFIGURATION_HEADER)]: addHeaderFailReducer,

    [typePending(EDIT_CONFIGURATION_HEADER)]: editHeaderPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_HEADER)]: editHeaderSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_HEADER)]: editHeaderFailReducer,

    [typePending(ADD_HEADER_FIELD)]: addHeaderFieldPendingReducer,
    [typeComplete(ADD_HEADER_FIELD)]: addHeaderFieldSuccessReducer,
    [typeFail(ADD_HEADER_FIELD)]: addHeaderFieldFailReducer,

    [typePending(EDIT_CONFIGURATION_ACTIVITY)]: editActivityPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_ACTIVITY)]: editActivitySuccessReducer,
    [typeFail(EDIT_CONFIGURATION_ACTIVITY)]: editActivityFailReducer,

    [typeComplete(CLEAR_HEADER_FIELD_TYPES_ADD_STATE)]: clearHeaderFieldTypesAddState,
    [typeComplete(CLEAR_HEADER_FIELD_TYPES_DELETE_STATE)]: clearHeaderFieldTypesDeleteState,

    [typePending(IMPORT_LIST)]: importListPendingReducer,
    [typeComplete(IMPORT_LIST)]: importListSuccessReducer,
    [typeFail(IMPORT_LIST)]: importListFailReducer,
    [typeComplete(CLEAR_IMPORT_LIST_STATE)]: clearConfigurationListsImportListState,

    [CONFIRM_COLUMN_TYPES]: confirmColumnTypes,
    [DELETE_IMPORTED_LIST]: deleteImportedList,

    [typePending(IMPORT_TABLE)]: importTablePendingReducer,
    [typeComplete(IMPORT_TABLE)]: importTableSuccessReducer,
    [typeFail(IMPORT_TABLE)]: importTableFailReducer,
    [typeComplete(CLEAR_IMPORT_TABLE_STATE)]: clearConfigurationTablesImportListState,

    [typePending(EXPORT_CONFIGURATION)]: exportConfigurationPendingReducer,
    [typeComplete(EXPORT_CONFIGURATION)]: exportConfigurationSuccessReducer,
    [typeFail(EXPORT_CONFIGURATION)]: exportConfigurationFailReducer,
    [typeComplete(CLEAR_EXPORT_STATE)]: clearConfigurationExportListsState,
    [CLEAR_TABLES_ITEMS]: clearTablesItems,
    [CLEAR_LISTS_DATA_ITEMS]: clearListsItems,
    [CLEAR_GRIDS_DATA_ITEMS]: clearGridsItems,
    [CLEAR_ACTIVITIES_DATA_ITEMS]: clearActivityItems,
    [CLEAR_PROJECTS_DATA_ITEMS]: clearProjectItems,
    [CLEAR_ACTIVITY_GROUPS_DATA_ITEMS]: clearActivityGroupsItems,
    [CLEAR_MODIFY_COLUMN]: clearModifyColumn,
    [CLEAR_HEADERS_DATA_ITEMS]: clearHeadersItems,
    [CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS]: clearHeaderFieldTypesItems,
    [REFRESH_STATE]: resetConfigurationListsStateReducer,

    [CLEAR_CONFIGURATION_SECTION]: clearConfigurationSection,
    [CLEAR_CONFIGURATION_OPS_STATE]: clearConfigurationOperationStates,

    [typePending(ADD_CONFIGURATION_ACTIVITY)]: addConfigurationActivityPendingReducer,
    [typeComplete(ADD_CONFIGURATION_ACTIVITY)]: addConfigurationActivitySuccessReducer,
    [typeFail(ADD_CONFIGURATION_ACTIVITY)]: addConfigurationActivityFailReducer,

    [typePending(EDIT_CONFIGURATION_ACTIVITY)]: editConfigurationActivityPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_ACTIVITY)]: editConfigurationActivitySuccessReducer,
    [typeFail(EDIT_CONFIGURATION_ACTIVITY)]: editConfigurationActivityFailReducer,

    [typePending(MODIFY_SAMPLE_WORKFLOWS)]: modifySampleWorkflowPendingReducer,
    [typeComplete(MODIFY_SAMPLE_WORKFLOWS)]: modifySampleWorkflowCompleteReducer,
    [typeFail(MODIFY_SAMPLE_WORKFLOWS)]: modifySampleWorkflowFailReducer,

    [typePending(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS)]: rankedColumnsPendingReducer,
    [typeComplete(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS)]:
        loadPossibleSampleResultsColumnsSuccessReducer,
    [typeFail(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS)]: rankedColumnsFailReducer,

    [typePending(LOAD_CONFIGURATION_RANKED_COLUMNS)]: rankedColumnsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_RANKED_COLUMNS)]: loadConfigurationRankedColumns,
    [typeFail(LOAD_CONFIGURATION_RANKED_COLUMNS)]: rankedColumnsFailReducer,

    [typeComplete(CLEAR_RANKED_COLUMNS_STATE)]: rankedColumnCompleteStateReducer,
    [typeComplete(CLEAR_POSSIBLE_COLUMNS)]: clearPossibleColumnsReducer,

    [typePending(ADD_SAMPLE_RESULTS_RANKED_COLUMN)]: rankedColumnsPendingReducer,
    [typeComplete(ADD_SAMPLE_RESULTS_RANKED_COLUMN)]: rankedColumnsSuccessReducer,
    [typeFail(ADD_SAMPLE_RESULTS_RANKED_COLUMN)]: rankedColumnsFailReducer,

    [typePending(EDIT_RANKED_COLUMN)]: rankedColumnsPendingReducer,
    [typeComplete(EDIT_RANKED_COLUMN)]: rankedColumnsSuccessReducer,
    [typeFail(EDIT_RANKED_COLUMN)]: rankedColumnsFailReducer,

    [typePending(DELETE_RANKED_COLUMN)]: rankedColumnsPendingReducer,
    [typeComplete(DELETE_RANKED_COLUMN)]: rankedColumnCompleteStateReducer,
    [typeFail(DELETE_RANKED_COLUMN)]: rankedColumnsFailReducer,

    [typePending(LOAD_CONFIGURATION_PROJECTS)]: configurationProjectsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_PROJECTS)]: loadConfigurationProjectsSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_PROJECTS)]: configurationProjectsFailReducer,

    [typePending(ADD_CONFIGURATION_PROJECT)]: addConfigurationProjectPendingReducer,
    [typeComplete(ADD_CONFIGURATION_PROJECT)]: addConfigurationProjectSuccessReducer,
    [typeFail(ADD_CONFIGURATION_PROJECT)]: addConfigurationProjectFailReducer,

    [typePending(CLONE_CONFIGURATION_PROJECT)]: cloneConfigurationProjectPendingReducer,
    [typeComplete(CLONE_CONFIGURATION_PROJECT)]: cloneConfigurationProjectSuccessReducer,
    [typeFail(CLONE_CONFIGURATION_PROJECT)]: cloneConfigurationProjectFailReducer,

    [typePending(DELETE_CONFIGURATION_PROJECT)]: deleteConfigurationProjectPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_PROJECT)]: deleteConfigurationProjectSuccessReducer,
    [typeFail(DELETE_CONFIGURATION_PROJECT)]: deleteConfigurationProjectFailReducer,

    [typePending(EDIT_CONFIGURATION_PROJECT)]: editConfigurationProjectPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_PROJECT)]: editConfigurationProjectSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_PROJECT)]: editConfigurationProjectFailReducer,

    [typePending(CONVERT_PROJECT_BOUNDARIES)]: projectBoundariesPendingReducer,
    [typeComplete(CONVERT_PROJECT_BOUNDARIES)]: projectBoundariesSuccessReducer,
    [typeFail(CONVERT_PROJECT_BOUNDARIES)]: projectBoundariesFailureReducer,

    [typePending(ADD_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesPendingReducer,
    [typeComplete(ADD_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesSuccessReducer,
    [typeFail(ADD_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesFailureReducer,

    [typePending(EDIT_CONFIGURATION_PROJECT_ACTIVITIES)]: projectActivitiesPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_PROJECT_ACTIVITIES)]: projectActivitiesSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_PROJECT_ACTIVITIES)]: projectActivitiesFailureReducer,

    [typePending(DELETE_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesSuccessReducer,
    [typeFail(DELETE_CONFIGURATION_PROJECT_ACTIVITY)]: projectActivitiesFailureReducer,

    [typePending(ASSIGN_UNASSIGN_USERS)]: projectActivitiesPendingReducer,
    [typeComplete(ASSIGN_UNASSIGN_USERS)]: projectActivitiesDefaultSuccessReducer,
    [typeFail(ASSIGN_UNASSIGN_USERS)]: projectActivitiesFailureReducer,
    [CONVERT_BOUNDARY_TO_DECIMAL]: toggleBoundaryDecimal,

    [typePending(LOAD_USER_PERMISSIONS)]: projectUserPermissionsPendingReducer,
    [typeComplete(LOAD_USER_PERMISSIONS)]: projectUserPermissionsSuccessReducer,
    [typeFail(LOAD_USER_PERMISSIONS)]: projectUserPermissionsFailureReducer,

    [typePending(MODIFY_LIST_FILE_GROUP)]: modifyListFileGroupPendingReducer,
    [typeComplete(MODIFY_LIST_FILE_GROUP)]: modifyListFileGroupSuccessReducer,
    [typeFail(MODIFY_LIST_FILE_GROUP)]: modifyListFileGroupFailureReducer,
    [typeComplete(MODIFY_LIST_FILES)]: modifyListFileSuccessReducer,
    [typeComplete(CLEAR_LIST_FILE_STATE)]: clearListFileState,
    [EMPTY_LIST_FILE_GROUPS]: loadEmptyListFileGroups,

    [typePending(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS)]:
        loadConfigurationSampleWorkflowsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS)]:
        loadConfigurationSampleWorkflowsSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS)]: loadConfigurationSampleWorkflowsFailureReducer,
    [CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE]: clearConfigurationSampleWorkflowState,
    [CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE]: clearConfigurationSampleWorkflowAddState,

    [typePending(ADD_CONFIGURATION_SAMPLE_WORKFLOW)]: addConfigurationSampleWorkflowPendingReducer,
    [typeComplete(ADD_CONFIGURATION_SAMPLE_WORKFLOW)]: addConfigurationSampleWorkflowSuccessReducer,
    [typeFail(ADD_CONFIGURATION_SAMPLE_WORKFLOW)]: addConfigurationSampleWorkflowFailReducer,

    [typePending(EDIT_CONFIGURATION_SAMPLE_WORKFLOW)]:
        editConfigurationSampleWorkflowPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_SAMPLE_WORKFLOW)]:
        editConfigurationSampleWorkflowSuccessReducer,
    [typeFail(EDIT_CONFIGURATION_SAMPLE_WORKFLOW)]: editConfigurationSampleWorkflowFailReducer,

    [typePending(CLONE_CONFIGURATION_SAMPLE_WORKFLOW)]:
        cloneConfigurationSampleWorkflowPendingReducer,
    [typeComplete(CLONE_CONFIGURATION_SAMPLE_WORKFLOW)]:
        cloneConfigurationSampleWorkflowSuccessReducer,
    [typeFail(CLONE_CONFIGURATION_SAMPLE_WORKFLOW)]: cloneConfigurationSampleWorkflowFailReducer,

    [typePending(DELETE_CONFIGURATION_SAMPLE_WORKFLOW)]:
        deleteConfigurationSampleWorkflowPendingReducer,
    [typeComplete(DELETE_CONFIGURATION_SAMPLE_WORKFLOW)]:
        deleteConfigurationSampleWorkflowSuccessReducer,
    [typeFail(DELETE_CONFIGURATION_SAMPLE_WORKFLOW)]: deleteConfigurationSampleWorkflowFailReducer,

    [typePending(LOAD_DEFAULT_LAB_SERVICES)]: loadDefaultLabServicesPendingReducer,
    [typeComplete(LOAD_DEFAULT_LAB_SERVICES)]: loadDefaultLabServicesSuccessReducer,
    [typeFail(LOAD_DEFAULT_LAB_SERVICES)]: loadDefaultLabServicesFailReducer,

    [typePending(LOAD_CONFIGURATION_ACTIVITY_GROUPS)]:
        loadConfigurationActivityGroupsPendingReducer,
    [typeComplete(LOAD_CONFIGURATION_ACTIVITY_GROUPS)]:
        loadConfigurationActivityGroupsSuccessReducer,
    [typeFail(LOAD_CONFIGURATION_ACTIVITY_GROUPS)]: loadConfigurationActivityGroupsFailReducer,

    [typePending(EDIT_CONFIGURATION_GRID)]: editGridsPendingReducer,
    [typeComplete(EDIT_CONFIGURATION_GRID)]: editGridsCompleteReducer,
    [typeFail(EDIT_CONFIGURATION_GRID)]: editGridsFailReducer,

    [typePending(ADD_CONFIGURATION_GRID)]: addGridsPendingReducer,
    [typeComplete(ADD_CONFIGURATION_GRID)]: addGridsCompleteReducer,
    [typeFail(ADD_CONFIGURATION_GRID)]: addGridsFailReducer,

    [typePending(LOAD_GRID_PREVIEW)]: loadGridPreviewPendingReducer,
    [typeComplete(LOAD_GRID_PREVIEW)]: loadGridPreviewCompleteReducer,
    [typeFail(LOAD_GRID_PREVIEW)]: loadGridPreviewFailReducer,

    [typePending(UPDATE_GRID_NAME)]: editGridsPendingReducer,
    [typeComplete(UPDATE_GRID_NAME)]: updateGridNameCompleteReducer,
    [typeFail(UPDATE_GRID_NAME)]: updateGridNameFailReducer,

    [typeComplete(LOAD_CONFIGURATION_CATEGORIES)]: loadConfigurationCategoriesSuccessReducer,
    [typePending(LOAD_CONFIGURATION_CATEGORIES)]: loadConfigurationCategoriesPendingReducer,
    [typeFail(LOAD_CONFIGURATION_CATEGORIES)]: loadConfigurationCategoriesFailureReducer,

    [typeComplete(CONFIGURATION_CATEGORIES_OPERATION)]:
        configurationCategoriesOperationSuccessReducer,
    [typePending(CONFIGURATION_CATEGORIES_OPERATION)]:
        configurationCategoriesOperationPendingReducer,
    [typeFail(CONFIGURATION_CATEGORIES_OPERATION)]: configurationCategoriesOperationFailureReducer,

    [typePending(ADD_EDIT_DELETE_ACTIVITY_GROUPS)]: addEditDeleteActivityGroupsPendingReducer,
    [typeComplete(ADD_EDIT_DELETE_ACTIVITY_GROUPS)]: addEditDeleteActivityGroupsCompleteReducer,
    [typeFail(ADD_EDIT_DELETE_ACTIVITY_GROUPS)]: addEditDeleteActivityGroupsFailReducer,

    [RELOAD_CONFIGURATION_ITEMS]: setReloadFlagInState,
});
