import { Observable } from 'rxjs';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { RowReferenceResponse } from 'state-domains/domain/drillhole';
import { buildSaveRowReferencesUrl, buildSaveRowReferencesPointsUrl } from 'state-domains/network';

import { getRowReferenceWithNextRowsResponseObservable } from './common';

export const saveRowReference = (
    rowReference: any,
    type: ACTIVITY_TYPES,
    queryParameters: any = {},
): Observable<RowReferenceResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildSaveRowReferencesUrl(rowReference._id || '', queryParameters)
            : buildSaveRowReferencesPointsUrl(rowReference._id || '', queryParameters);
    return getRowReferenceWithNextRowsResponseObservable(
        url,
        rowReference,
        rowReference._id ? 'PUT' : 'POST',
    );
};
