export function onBit(source: number, bitValue: number): number {
    return source | bitValue;
}

export function offBit(source: number, bitValue: number): number {
    return source ^ (source & bitValue);
}

export function hasBit(source: number, bitValue: number) {
    return (source & bitValue) === bitValue;
}
