import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { Projection } from 'state-domains/domain/subscription';
import { buildDefaultProjectionsUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadDefaultProjections = (
    queryString = '',
): Observable<{ [id: string]: Projection }> => {
    const obs = sendRetrieveRequest(buildDefaultProjectionsUrl(queryString));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const items: { [id: string]: Projection } = Object.assign(
                {},
                ...convertToCamel<Projection[]>(response.geo_systems)
                    .sort((a, b) =>
                        `${a.authName ?? ''}:${a.authId ?? ''}`.localeCompare(
                            `${b.authName ?? ''}:${b.authId ?? ''}`,
                        ),
                    )
                    .map((x) => ({ [x.id]: x })),
            );

            return observableOf(items);
        }),
    );
};
