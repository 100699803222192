import { i18nStatusConstants } from './mxdeposit.i18n';

export const API_REGION = 'apiRegion';
export const CLIENT_SIDE_PAGINATION_LIMIT = 2000;
export const CACHED_SORT_OPTIONS_KEY = 'cached_sort_options';
export const CONFIGURATION_CHARACTER_LIMIT = 50;

export const emptySampleTypeID = '0fc7ff8f-436d-40e5-8245-21f8bad10887';
export const LITHOLOGY_TABLE_ID = '55a001298283de28fc74059d';
export const MODULES = {
    ACTIVITY_FEED: '5942ce3a3f16cf0970152e57',
    DRILL_HOLES: '5942e6ec3f16cf0970152e6c',
    PROJECT_SUMMARY: '5943d0dd3f16cf0970152e7c',
    POINT_SAMPLES: '5943d0eb3f16cf0970152e7d',
    SAMPLE_DISPATCH: '5943d0f33f16cf0970152e7e',
    ASSAYS_QAQC: '5943d0fb3f16cf0970152e7f',
    IMPORT: '5943d0fe3f16cf0970152e80',
    EXPORT: '5943d1013f16cf0970152e81',
    REPORTS: '5943d1053f16cf0970152e82',
};

export enum ACTIVITY_TYPES {
    DRILLING = 'drilling',
    POINT = 'point',
}

export enum HEADER_TYPES {
    LOGGING = 'logging',
    DISPATCH = 'dispatch',
}

export const MODULE_ACTIONS = {
    CREATE: 'create',
    DELETE: 'delete',
    UNASSIGN: 'unassign',
    STATE: 'state',
};

export enum DATATABLE_TYPES {
    JOBS = 'jobs',
    LISTS = 'lists',
    SAMPLE_LISTS = 'sample_lists',
    TABLES = 'tables',
    FIELDS = 'fields',
    HEADERS = 'headers',
    ACTIVITIES = 'activities',
    ACTIVITY_GROUPS = 'activity_groups',
    PROJECTS = 'projects',
    GRIDS = 'grids',
    USERS = 'users',
}
export enum CONFIGURATION_TYPES {
    LISTS = 'lists',
    TABLES = 'tables',
    FIELDS = 'fields',
    HEADERS = 'headers',
    ACTIVITIES = 'activities',
    ACTIVITY_GROUPS = 'activity_groups',
    PROJECTS = 'projects',
    SAMPLE_LISTS = 'sample_lists',
    SAMPLE_FIELDS = 'sample_fields',
    SAMPLE_HEADERS = 'sample_headers',
    SAMPLE_ANALYSIS_WORKFLOWS = 'analysis_workflows',
    USERS = 'users',
    CATEGORIES = 'categories',
    NONE = 'none',
    DATA_GRID = 'data_grid',
    GRIDS = 'grids',
}

export enum CONFIGURATION_PARENT_TYPES {
    LOGGING = 'Logging',
    ACTIVITIES_PROJECTS = 'Activities and Projects',
    SAMPLING = 'Sampling',
    ACCOUNT = 'Account',
}

export const COLOUR = 'COLOUR';

export const EMPTY_TEMPLATE_ID = '? undefined:undefined ?';

export const COLUMN_MIN_WIDTH = 60;
export const IMAGO_COLUMN_MIN_WIDTH = 120;
export const SAMPLE_TYPE_MIN_WIDTH = 105;
export const PROJECT_THUMBNAIL_RATIO = {
    WIDTH: 16,
    HEIGHT: 9,
};

export const XRF_TABLE = '5afddba079eae00f4c081718';
export const STRUCTURE_TABLES = [
    '5b113eca79eae03f2451a896',
    '5b113eca79eae03f2451a897',
    '5b113eca79eae03f2451a898',
    '5b113eca79eae03f2451a895',
];

export const XRF_COLUMN = '5afddbbb79eae00f4c081719';

export const hardCodedColumns = new Set([
    '594176e16f23596e75519142',
    '5b27c03242a87e43550957c5',
    '5f4ab309a01a053dc233a7f4',
    '5f7391d6969b3132b7eb3f01',
    '5aaff7f16e2e8962dbbf5019',
    '5a7b5e2942a87e62ddea70d3',
    '5a7b5e3542a87e62ddea70d4',
    '5a7b5e4142a87e62ddea70d5',
    '5a7b5e5442a87e62ddea70d6',
    '5a7b5e6342a87e62ddea70d7',
    '60185bc465e98d07ac96f518',
    '5f36c017d0357f3a5f46a2e1',
    '5aa9769b6e2e8962dbbf06f4',
    '5b10506c42a87e013449ff82',
    '5b10507742a87e013449ff83',
    '5fa14ca01709b245b353ee5a',
    '5fa14ca01709b245b353ee5b',
    '5fc93fcd613fd04b1cd3179f',
    '5fa14ca01709b245b353ee5c',
    '5fa14ca01709b245b353ee5d',
    '5fa14ca01709b245b353ee68',
    '5fa14ca01709b245b353ee69',
    '5fa14ca01709b245b353ee6a',
    '5fa14ca01709b245b353ee6b',
    '5fa14ca01709b245b353ee6c',
    '5fa14ca01709b245b353ee6d',
    '5fa14ca01709b245b353ee6e',
    '5fa14ca01709b245b353ee6f',
    '5fa14ca01709b245b353ee70',
    '5fa14ca01709b245b353ee71',
    '5fa14ca01709b245b353ee72',
    '5fa14ca01709b245b353ee73',
    '5fa14ca01709b245b353ee74',
    '5fa14ca01709b245b353ee75',
    '5fa14ca01709b245b353ee76',
    '5fa14ca01709b245b353ee77',
    '5fa14ca01709b245b353ee78',
    '5fa14ca01709b245b353ee79',
    '5fa14ca01709b245b353ee7a',
    '5e32e2170c53ea05da8e4359',
    '5e6baa6ab9d01287b60c5172',
    '5e32e20c7ea61a05d8bb40b5',
    '5e32e2297ea61a05d8bb40c5',
    '5e32e22ee7db4205e01907dc',
    '5aaff7f16e2e8962dbbf5019',
    '5b113fbb79eae03f2451a898',
    '5a5e5f7442a87e0cb62c9251',
    '60185bca65e98d07ac96f52e',
    '5f36c1ccd0357f3a5f46a371',
    '5f35a0eb9b7aa23a63fc5ccc',
    '5f3682fcd0357f3a5f46151e',
    '5e6f59df1eed443bdefaa1f9',
    '60185be765e98d07ac96f550',
    '5f36c320fd23493a6067d2e9',
    '5f51571e328eb13e99f89123',
    '5f5157427308303ea4f0dc0c',
    '5ed0087fc8766848c5f04f28',
    '60185b73692d9207b36a8128',
    '5f36b273d0357f3a5f469d9f',
    '5ef36af5b9519f23397df408',
    '5ef36aff10ccdd2348c25c17',
    '5ef36adb10ccdd2348c25c16',
    '5ef36ae55b61e32331fbfba2',
    '5b113feb79eae03f2451a899',
    '5b1576bf79eae03730aefecc',
    '5a5e5f7b42a87e0cb62c9252',
    '60185bca65e98d07ac96f52f',
    '5f36c1d9fd23493a6067d202',
    '5f35a0fafd23493a606724e2',
    '5f36831b9b7aa23a63fce8e4',
    '5e6f59f11eed443bdefaa1fa',
    '60185be765e98d07ac96f551',
    '5f36c32c9b7aa23a63fd111b',
    '5ed00890eaf9e848e307b5c3',
    '60185b73692d9207b36a8129',
    '6166633df57f6c405a27aab4',
]);

export const FILTER_TYPES = {
    NUMERIC: 'numeric',
    LIST: 'list',
    LIST_OBJECT: 'list_object',
    EXISTS: 'exists',
    TEXT: 'text',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    FILE: 'file',
    USER: 'user',
    OTHER: 'other',
};
export const OPERATOR_TYPES = {
    ONEOF: 'one_of',
};

export const specialFields = ['from', 'to', 'depth'];

export const ALL_ITEM_KEY = 'all';

export const DRILL_HOLE_STATES = {
    ACTIVE: 'active',
    LOCKED: 'locked',
    ARCHIVED: 'archived',
};

export const DEFAULT_FILTERS = () => ({
    activities: [ALL_ITEM_KEY],
    state: [DRILL_HOLE_STATES.ACTIVE, DRILL_HOLE_STATES.LOCKED],
    filters: [] as any[],
});

export const EMPTY_FILE = 'EMPTY_FILE';

export const SAMPLE_TYPE_COL_ID = 'b9d3982a0730473a8d32dcc5';
export const SAMPLE_NUMBER_COL_ID = '80ecd22164034d4487a06705';
export const SAMPLE_TYPE_LIST_ID = '08c431b983554f06b75573e0';
export const SAMPLE_CONTROL_TYPE_LIST_ID = '1987dc795f78486188120b2b';
export const SAMPLE_CONTROL_TYPE_ID = 'ad8f309c31584d08947b9e2a';
export const SAMPLE_TYPE_LAB_CONTROL = 'b31b277f12cc4d3bb30504d1';
export const SAMPLE_CONTROL_TYPE_COL_ID = '078f878ca9294251aaf8a4e9';
export const SAMPLE_TABLE_IDS = [
    '55a7f9b4811a142edd3409cb',
    '59edf36616b37a1f37980755',
    '59edf36616b37a1f37980756',
];
export const SAMPLE_CRM_COLUMN_ID = '913efd86defb4411ab3749e4';
export const REF_MATERIAL_TYPE_COL_ID = '58e2789916b37a1cb179d117';
export const REF_MATERIAL_TYPE_LIST_ID = '58e27a2d16b37a1dc363ac2e';

export const SURVEY_TABLE_ID = '55a7f9b4811a142edd3409cc';

export const ROCK_TYPE_COL_ID = '55a003ab8283de28fc74059e';
export const ROW_SPAN = 'ROW_SPAN';
export const PARENT_ROW_SPAN = 'PARENT_ROW_SPAN';
export const PADDED_ROW_SPAN = 'PADDED_ROW_SPAN';
export const PARENT_PADDED_ROW_SPAN = 'PARENT_PADDED_ROW_SPAN';
export const COLUMN_PADDED_ROW = 'from';
export const MAX_ROW_BUFFER = 20;
export const LITHOLOGY_MIN_FROM = 'LITHOLOGY_MIN_FROM';
export const LITHOLOGY_MAX_TO = 'LITHOLOGY_MAX_TO';
export const PARENT_SKIP_LITHOLOGY_ROW = 'PARENT_SKIP_LITHOLOGY_ROW';
export const SKIP_LITHOLOGY_ROW = 'SKIP_LITHOLOGY_ROW';
export const BUFFER_PARENT_ID = 'BUFFER_PARENT_ID';
// If we change this padding, we'll have to update column min widths
export const GRID_PADDING = 8;

export const OUT_OF_ORDER = 'OUT_OF_ORDER';

export const COORDINATES_ID = 'header-coordinates-table';
export const COORDINATES_LABEL = 'Coordinates';

export const CURRENT_VIEWS_STORE = 'currentTableViews';

export const SAMPLE_RESULTS = 'SAMPLE_RESULTS';
export const RANKED_RESULTS = 'RANKED_RESULTS';
export const SAMPLE_INFO_COLUMNS = 'SAMPLE_INFO_COLUMNS';
export const SAMPLE_TYPE_STATE = 'SAMPLE_TYPE_STATE';
export const SAMPLE_STATUS = 'SAMPLE_STATUS';
export const ROW_DRAG = 'ROW_DRAG';
export const ROW_NUMBER = 'ROW_NUMBER';
export const ROW_NUMBER_GROUP = 'ROW_NUMBER_GROUP';

export const PARENT_SAMPLE_NAME = 'Parent Sample Number';
export const PARENT_SAMPLE = 'PARENT_SAMPLE';

export const LITHOLOGY_COMMON_GROUP_ID = 'common-lithology';
export const LITHOLOGY_LINKED_GROUP_ID = 'linked-to-lithology';
export const LITHOLOGY_GROUP_ACTIONS = 'LITHOLOGY_GROUP_ACTIONS';
export const LITHOLOGY_ACTIONS = 'LITHOLOGY_ACTIONS';

export const INFO_COLUMN = 'INFO_COLUMN';

export enum SAMPLE_TYPES {
    PARENT = 'Parent',
    DUPLICATE = 'Duplicate',
    CONTROL = 'Control',
}

export const WGS84_PROJECTION_ID = '5668b48238ee357a1e9a8113';
export const WGS84_GRID_ID = '56df2b4279eae02804b40bc6';

export const COORDINATES_TYPE_COLUMN_ID = '5908c68a16b37a4ed1032d66';
export const GRID_COLUMN_ID = '55f19ae08283de4df4d578ab';
export const EASTING_COLUMN_ID = '55f19ae08283de4df4d578ac';
export const NORTHING_COLUMN_ID = '55f19ae08283de4df4d578ad';
export const DRILLHOLE_UPDATED_FIELDS = [
    '"_updated_by"',
    '"_updated_at"',
    '"wgs84"',
    '"coordinates_table"',
    '"header"',
];
export const TEXT_SINGLE_FIELD_MAX_LENGTH = 32;
export const TEXT_FIELD_MAX_LENGTH = 256;

export const DIMENSION_PREFERENCE_DENOMINATIONS = {
    DEFAULT: 46,
    SHORT: 42,
    COMPACT: 38,
};

export const COMPACT_WIDTH_PREFERENCE = 0.33;

export const LIST_EDITOR_WIDTH = 456;
export const LIST_EDITOR_HEIGHT = 300;
export const COLOUR_PICKER_WIDTH = 225;
export const COLOUR_SIDE_PANEL_WIDTH = 324;
export const COLOUR_PICKER_HEIGHT = 224;
export const MAX_PART_LENGTH_TO_DISPLAY = 50;
export const MODAL_WIDTH = 424;
export const MODAL_HORIZONTAL_PADDING = 3;
export const MODAL_VERTICAL_PADDING = 2;
export const IMPORT_MODAL_WIDTH = 448;

export const USE_NEW_APP_COOKIE = 'use_new_app';
export const EPSILON_MULTIPLIER = 10000000000;
export const TIMEOUT = 50;

export const ERROR_DIRECTION = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS',
};

export const LAB_NAME_COL_ID = '57c0874579eae0342c779224';
export const LAB_SCHEDULE_COL_ID = '57c4680979eae01690302bbc';
export const LAB_CODE_COL_ID = '57c086f779eae0342c779220';
export const LAB_CATEGORY_COL_ID = '57c4681e79eae01690302bbd';
export const LAB_TYPE_COL_ID = '57c4682e79eae01690302bbe';
export const LAB_DESCRIPTION_COL_ID = '57c086f779eae0342c779221';
export const LAB_SAMPLE_DECOMPOSITION_COL_ID = '57c089ab79eae0342c779227';
export const LAB_ANALYTICAL_METHODS_COL_ID = '57c468e379eae01690302bc0';
export const LAB_URL_COL_ID = '57c0876779eae0342c779225';
export const LAB_WEB_ACCESS_COL_ID = '57c0877b79eae0342c779226';
export const LAB_WEB_ACCESS_URL_COL_ID = '57c447cf79eae022947ab68a';
export const LAB_SERVICE_AREA_COL_ID = '57c448c479eae022947ab68b';
export const LAB_SERVICE_REGION_COL_ID = '57c467f079eae01690302bbb';
export const LAB_APPLICATION_COL_ID = '57c4684479eae01690302bbf';
export const LAB_SERVICE_TYPE_COL_ID = '57c089ab79eae0342c779228';

export const REFERENCE_MATERIAL_SUPPLIER_ID = '3279061afd054594a6a50ee7';
export const REFERENCE_MATERIAL_CATEGORY_ID = '17b1c076e7104554baa5492a';
export const REFERENCE_MATERIAL_REFTYPE_ID = '58e2789916b37a1cb179d117';
export const REFERENCE_MATERIAL_DESC_ID = '5848099679eae01fb449ddb2';
export const REFERENCE_MATERIAL_CODE_ID = '913efd86defb4411ab3749e4';
export const METHOD_DETAILS_ANALYTE_COL_ID = '57c712fb79eae00298fdb7ce';
export const METHOD_DETAILS_UNIT_COL_ID = '57c7132279eae00298fdb7d1';
export const METHOD_DETAILS_LOW_LIMIT_COL_ID = '57c7134579eae00298fdb7d2';
export const METHOD_DETAILS_HIGH_LIMIT_COL_ID = '57c7135879eae00298fdb7d3';
export const SAMPLE_DECOMPOSITION_LIST_DESCRIPTION_COL_ID = '57c0792c79eae01fbc300ece';
export const ANALYTICAL_METHODS_LIST_DESCRIPTION_COL_ID = '57c07e1a79eae031682124e1';

export const LAB_SERVICES_LIST_ID = '57c086f779eae0342c779223';
export const METHOD_DETAILS_LIST_ID = '57c712fb79eae00298fdb7d0';
export const ANALYTICAL_METHODS_LIST_ID = '57c07e1a79eae031682124e3';
export const SAMPLE_DECOMPOSITION_LIST_ID = '57c0792d79eae01fbc300ed0';
export const ANALYTE_LIST_ID = '57c084d979eae02a4059f4d9';
export const UNIT_LIST_ID = '57c067f379eae02c6077d534';
export const SAMPLE_PREP_VALUE = '57d0277979eae0080c150932';
export const SAMPLE_ANALYSIS_VALUE = '57d0274779eae0080c150930';

export const DETAIL_GRID_HEIGHT = 400;

export const DETAIL_ROWS = 'DETAIL_ROWS';
export const DETAIL_LIST = 'DETAIL_LIST';

export const DISABLED_BACKGROUND_COLOR_IMPORTANT = '#FAFAFA !important';
export const DISABLED_BACKGROUND_COLOR = '#FAFAFA';
export const DISABLED_TEXT_COLOR_IMPORTANT = '#A6A6A6 !important';

export const STATUS_TYPES: {
    [key: string]: { in18nId: { defaultMessage: string; id: string }; color: string };
} = {
    // both lowercase and camelcase are sometimes provided
    inprogress: { in18nId: i18nStatusConstants.statusInprogress, color: '#61A887' },
    inProgress: { in18nId: i18nStatusConstants.statusInprogress, color: '#61A887' },

    new: { in18nId: i18nStatusConstants.statusNew, color: '#979797' },
    sent: { in18nId: i18nStatusConstants.statusSent, color: '#D97B21' },
    received: { in18nId: i18nStatusConstants.statusReceived, color: '#265C7F' },
    notReleased: { in18nId: i18nStatusConstants.statusNotReleased, color: '#61A887' },
    released: { in18nId: i18nStatusConstants.statusReleased, color: '#265C7F' },

    Warning: { in18nId: i18nStatusConstants.statusWarning, color: '#D97B21' },
    Passed: { in18nId: i18nStatusConstants.statusPassed, color: '#61A887' },
    Failed: { in18nId: i18nStatusConstants.statusFailed, color: '#E63B38' },
    Imported: { in18nId: i18nStatusConstants.statusImported, color: '#979797' },
    'Accepted with failure': {
        in18nId: i18nStatusConstants.statusAcceptedWithFailure,
        color: '#265C7F',
    },
    Undefined: { in18nId: i18nStatusConstants.statusUndefined, color: '#D97B21' },

    // these are from older code, and my have outdated colors or no longer exist

    ending: { in18nId: i18nStatusConstants.statusPending, color: '#DF4343' },
    onHold: { in18nId: i18nStatusConstants.statusOnHold, color: '#DF4343' },
    complete: { in18nId: i18nStatusConstants.statusComplete, color: '#0E98E7' },
    zeroState: { in18nId: i18nStatusConstants.statusZeroState, color: '#eeeeee' },

    // is there an active status?
};

export const HELPFUL_LINKS = 'Some helpful links:';
export const MISSING_MXDEPOSIT_LICENSE = 'Missing MXDeposit license';
export const MISSING_MXDEPOSIT_LICENSE_DETAIL =
    "You don't appear to have a license for this service. Please contact your organisation admin for access";
export const OOPS = 'Oops!';
export const PAGE_NOT_FOUND = 'Page Not Found';
export const PAGE_NOT_FOUND_DETAILED = 'We were unable to find the page you requested';
export const ERROR_BOUNDARY_MESSAGE = 'Something has gone wrong.';
export const ERROR_BOUNDARY_DETAILS =
    'This has been logged and we will investigate what happened. Please refresh your browser to continue.';

export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];

export enum TableEditStatus {
    READ_ONLY = 'ro',
    READ_WRITE = 'rw',
}

export enum SPECIAL_TABLE_TYPES {
    LITHOLOGY = 'lithology',
    SAMPLES = 'samples',
    STRUCTURE = 'structure',
    SURVEY = 'survey',
    XRF = 'xrf',
    OTHER = 'other',
}

export const DEFAULT_LAB_SERVICE_KEY_MAP: { [id: string]: string } = {
    lab: LAB_NAME_COL_ID,
    serviceSchedule: LAB_SCHEDULE_COL_ID,
    code: LAB_CODE_COL_ID,
    desc: LAB_DESCRIPTION_COL_ID,
    laboratoryUrl: LAB_URL_COL_ID,
    webAccess: LAB_WEB_ACCESS_COL_ID,
    webAccessUrl: LAB_WEB_ACCESS_URL_COL_ID,
    serviceArea: LAB_SERVICE_AREA_COL_ID,
    serviceRegion: LAB_SERVICE_REGION_COL_ID,
    category: LAB_CATEGORY_COL_ID,
    type: LAB_TYPE_COL_ID,
    application: LAB_APPLICATION_COL_ID,
    serviceTypes: LAB_SERVICE_TYPE_COL_ID,
    sampleDecomposition: LAB_SAMPLE_DECOMPOSITION_COL_ID,
    analyticalMethod: LAB_ANALYTICAL_METHODS_COL_ID,
};
