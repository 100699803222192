import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import {
    buildConfigurationTablesBaseUrl,
    buildConfigurationTableViewBaseUrl,
} from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addConfigurationTable = (
    payload: any,
    isTableView = false,
): Observable<{ table: any; isTableView: boolean }> => {
    const url = isTableView
        ? buildConfigurationTableViewBaseUrl()
        : buildConfigurationTablesBaseUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const addTableResponse = convertToCamel<any>(response);
            return observableOf({ table: addTableResponse, isTableView });
        }),
    );
};
