import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ActivityMap } from 'state-domains/domain/subscription';

import { buildLoadActivitiesUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken, convertToSnake } from '../../../utils';
import { ConfigurationCreateActivityRequest } from '../types';

export const addConfigurationActivity = (
    payload: ConfigurationCreateActivityRequest,
): Observable<ActivityMap> => {
    const url = buildLoadActivitiesUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ActivityMap>) => {
            const addActivityResponse = convertToCamel<ActivityMap>(response);
            return observableOf(addActivityResponse);
        }),
    );
};
