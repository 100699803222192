import mixpanel from 'mixpanel-browser';

export function initializeMixPanel(token: string) {
    mixpanel.init(token);
}

export function trackAction(eventName: string, details?: any) {
    mixpanel.track(eventName, details, { transport: 'sendBeacon' });
}

export function identifyUser(id: string) {
    mixpanel.identify(id);
}

export function trackPage(pageName: string) {
    trackAction(`Visited page-${pageName}`, {});
}
