import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadSamplesUrl, buildLoadSamplesPointsUrl } from '../../../network/urls';
import { sendRetrieveRequest, convertToCamel } from '../../../utils';
import { Sample, Samples } from '../types';

export const loadSamples = (
    collar: string,
    actionName: string,
    processResultFunction: Function,
    type: ACTIVITY_TYPES,
): Observable<{
    result: Sample[];
    actionName: string;
    processResultFunction: Function;
    type: ACTIVITY_TYPES;
}> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildLoadSamplesUrl(collar)
            : buildLoadSamplesPointsUrl(collar);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<Samples>) => {
            const { Sample } = response;
            const result = convertToCamel<Sample[]>(Sample);
            return observableOf({ result, actionName, processResultFunction, type });
        }),
    );
};
