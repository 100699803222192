import { Theme } from '@mui/material';

export const styles = (theme: Theme) =>
    ({
        buttonDefault: {
            backgroundColor: theme.palette.grey[50],
            fontSize: '13px',
            height: '100%',
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            paddingTop: theme.spacing(1.5),
        },
        popoverClasses: {
            marginTop: theme.spacing(2),
        },
        mySeequentMenuLabel: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: 'white',
        },
        mySeequentMenuItem: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            '& p': {
                fontSize: '13px',
                letterSpacing: '0.4px',
            },
        },
        mySeequentPaper: {
            paddingBottom: '10px',
            overflowX: 'hidden',
        },
    } as const);
