import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildUpdateActualDepthUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const updateActualDepth = (activityId: string): Observable<{}> => {
    const url = buildUpdateActualDepthUrl(activityId);
    return sendRequestWithXSRFToken(url, { _id: activityId }, 'PUT').pipe(
        switchMap(() => observableOf({})),
    );
};
