import { trackError } from '@local/metrics';
import { BellOffIcon, BellIcon, withTrace } from '@local/web-design-system';
import { Grow, ClickAwayListener, Popper, Grid } from '@mui/material';
import { Instance } from '@popperjs/core';
import classnames from 'classnames';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { compose } from 'redux';

import { PathConstants } from 'src/routes';
import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';

import { ItemsCounterIcon } from '../../ItemsCounterIcon';
import { NotificationsTray } from '../NotificationsTray';
import { connectToState } from './NotificationsButton.connect';
import { useStyles } from './NotificationsButton.styles';
import { AllProps } from './NotificationsButton.types';

const TRAY_LIMIT = 10;
export const MAX_UNREAD_NOTIFICATIONS_COUNT = 99;

function RefreshTokenRedirect() {
    const location = useLocation();
    const { pathname, search } = location;
    const r = pathname + search;
    const params = new URLSearchParams();
    params.append('r', r);
    const paramsString = params.toString();
    if (paramsString.length > 0 && pathname !== PathConstants.REFRESH_TOKEN_PATH.ROOT) {
        return (
            <Navigate
                to={`${PathConstants.REFRESH_TOKEN_PATH.ROOT}?${params.toString()}`}
                replace
            />
        );
    }
    return <Navigate to={PathConstants.REFRESH_TOKEN_PATH.ROOT + search} replace />;
}

export function NotificationsButtonBase(props: AllProps) {
    const { classes } = useStyles();
    const [isDisabled, setIsDisabled] = React.useState(true);
    React.useEffect(() => {
        if (isDisabled && !props.isPending) {
            setIsDisabled(false);
        }
    }, [props.isPending]);
    React.useEffect(() => {
        if (props.isCurrentUserLoaded) {
            // do it once on component mount
            props.loadUnsubscribeList();
            props.pollMessages({ limit: TRAY_LIMIT });
        }
    }, [props.isCurrentUserLoaded]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [open, setOpen] = React.useState(false);

    const closeTray = React.useCallback((event?: MouseEvent | TouchEvent) => {
        event?.stopPropagation();
        if (open) {
            props.messagesSeen();
        }
        setOpen(false);
    }, []);

    const openTray = React.useCallback(() => {
        // When already opened, closing calls messagesSeen
        if (open) {
            props.messagesSeen();
        }
        if (!isDisabled) {
            trackUserAction(
                TRACKING_COMPONENTS.HEADER,
                !open ? EVENT_TYPES.OPEN : EVENT_TYPES.CLOSE,
                'notifications',
            );
            setOpen(!open);
        }
    }, [open, isDisabled]);

    let popperRef: Instance | null = null;
    React.useEffect(() => {
        // Do this after every render
        if (!props.shouldRedirect && popperRef) {
            // We need to manually trigger an update.
            // Popper has issues knowing when to update its position after its attached dom elements change
            popperRef
                .update()
                .then()
                .catch((error) => {
                    trackError(error.message);
                    // eslint-disable-next-line no-console
                    console.warn(error.message);
                });
        }
    });

    const popperRefFn = React.useCallback((ref: any) => {
        popperRef = ref;
    }, []);

    if (props.shouldRedirect) {
        return <RefreshTokenRedirect />;
    }

    let counterIconNode: React.ReactNode;
    const darkTheme = false;
    const iconClass = classnames({ [classes.icon]: !darkTheme, [classes.iconDark]: darkTheme });
    if (props.enabled) {
        counterIconNode = <BellIcon automation-id="bell-icon" className={iconClass} />;
    } else {
        counterIconNode = <BellOffIcon automation-id="bell-off-icon" className={iconClass} />;
    }
    return (
        <>
            <Grid
                xs
                item
                container
                wrap="nowrap"
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                onMouseDown={openTray}
                ref={(ref: HTMLDivElement | null) => setAnchorEl(ref)}
                className={classnames(
                    classes.root,
                    { [classes.rootDark]: darkTheme },
                    {
                        [classes.openDark]: open && darkTheme,
                        [classes.open]: open,
                        [classes.disabledIcon]: isDisabled,
                    },
                )}
                automation-id={
                    !isDisabled
                        ? 'MXDeposit-notifications-global-bell-button'
                        : 'MXDeposit-notifications-global-bell-button-disabled'
                }
            >
                <ItemsCounterIcon
                    count={props.total}
                    maxNumberOfItems={MAX_UNREAD_NOTIFICATIONS_COUNT}
                    keepCounterMounted
                    showEmptyEllipseIfExceeds
                    iconNode={counterIconNode}
                    classes={{
                        counter: classes.unreadNotificationsCounter,
                    }}
                />
            </Grid>

            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                disablePortal
                modifiers={[{ name: 'preventOverflow', options: { enabled: true, padding: 0 } }]}
                className={classes.popper}
                popperRef={popperRefFn}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={closeTray} mouseEvent="onMouseDown">
                        <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
                            <Grid container>
                                <NotificationsTray
                                    closeTray={closeTray}
                                    limit={TRAY_LIMIT}
                                    variant="light"
                                />
                            </Grid>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}

export const withProps = compose(connectToState, withTrace('notification-button'));

export const NotificationsButton = withProps(NotificationsButtonBase);
export default NotificationsButton;
