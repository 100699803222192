import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildLoadLabCertificateInfo } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { LabCertificateInfo } from '../types';

export const loadLabCertificateInfo = (labCertId: string): Observable<LabCertificateInfo> => {
    const url = buildLoadLabCertificateInfo(labCertId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<LabCertificateInfo>) => {
            const result = convertToCamel<LabCertificateInfo>(response);
            return observableOf(result);
        }),
    );
};
