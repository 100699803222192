import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ConvertCoordinatesProps } from 'src/components/Drillhole/DrillholeMap/MapBoundaryControls/MapBoundaryControls.types';

import { buildConvertCoordiatesUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken, convertToSnake } from '../../../utils';

export const convertProjectBoundaries = (payload: ConvertCoordinatesProps): Observable<any> => {
    const url = buildConvertCoordiatesUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertCoordinatesResponse = convertToCamel<any>(response);
            return observableOf(convertCoordinatesResponse);
        }),
    );
};
