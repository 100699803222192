import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { Header } from 'state-domains/domain';

import {
    buildConfigurationSaveHeaderFieldUrl,
    buildConfigurationSaveSampleHeaderFieldUrl,
} from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveHeaderField = (
    headerId: string,
    sectionId: string,
    payload: any,
    configType: CONFIGURATION_TYPES,
): Observable<Header> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationSaveHeaderFieldUrl(headerId, sectionId)
            : buildConfigurationSaveSampleHeaderFieldUrl(headerId, sectionId);
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedHeader = convertToCamel<Header>(response);
            return observableOf(convertedHeader);
        }),
    );
};
