import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { Drillhole, AddDrillHoleResponse } from 'state-domains/domain/drillhole';

import { buildAddNewDrillHoleUrl, buildAddNewPointsUrl } from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRequestWithXSRFToken } from '../../../utils';

export const addDrillHole = (
    payload: any,
    type: ACTIVITY_TYPES,
): Observable<AddDrillHoleResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING ? buildAddNewDrillHoleUrl() : buildAddNewPointsUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<AddDrillHoleResponse>) => {
            const collar = { ...response };
            const convertedCollar = convertToCamelWithSpecialParameters<Drillhole>(collar, {
                id: 'uid',
                _id: 'id',
            });
            return observableOf({ Collar: convertedCollar });
        }),
    );
};
