/**
 * Adds all elements of the overrides object to base.
 *
 * This recursively traverses the overrides object so that values in nested objects get inserted
 * into corresponding nested objects in base. For example:
 *
 * fillInObject({nested: {k1: 'v1', k2: 'v2'}}, {nested: {k2: 'newValue'}})
 * will return
 * {nested: {k1: 'v1', k2: 'newValue'}}
 * i.e. the nested object keeps the k1 key.
 *
 */
const fillInObject = (base: any, overrides: any) => {
    const out = { ...base };
    for (const [key, overrideValue] of Object.entries(overrides)) {
        const baseValue = base[key];
        if (overrideValue instanceof Object) {
            out[key] = fillInObject(baseValue ?? {}, overrideValue);
        } else {
            out[key] = overrideValue;
        }
    }
    return out;
};

export { fillInObject };
