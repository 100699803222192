import {
    BULK_DELETE_MESSAGES,
    BULK_READ_MESSAGES,
    BULK_UNREAD_MESSAGES,
    CLEAR_MESSAGES,
    CREATE_FILTERS,
    CREATE_RESOURCES,
    DELETE_FILTERS,
    DELETE_MESSAGE,
    DELETE_RESOURCES,
    GET_TOKEN,
    LIST_MESSAGES,
    LOAD_DIGEST,
    LOAD_FILTERS,
    LOAD_RESOURCES,
    POLL_MESSAGES,
    UPDATE_DIGEST,
    UPDATE_FILTERS,
    UPDATE_MESSAGE,
} from 'state-domains/types/actionTypes';

// Notifications and preferences used actions
export const usedNotificationActions = [
    LIST_MESSAGES,
    POLL_MESSAGES,
    UPDATE_MESSAGE,
    BULK_READ_MESSAGES,
    BULK_UNREAD_MESSAGES,
    DELETE_MESSAGE,
    BULK_DELETE_MESSAGES,
    CLEAR_MESSAGES,
    GET_TOKEN,
];

export const usedPreferencesActions = [
    CREATE_FILTERS,
    DELETE_FILTERS,
    DELETE_RESOURCES,
    LOAD_FILTERS,
    LOAD_RESOURCES,
    UPDATE_FILTERS,
    CREATE_RESOURCES,
    UPDATE_DIGEST,
    LOAD_DIGEST,
];
