import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { HeaderType } from 'state-domains/domain/subscription/types';
import { buildConfigurationFieldUrl } from 'state-domains/network/urls';

import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addConfigurationHeaderFieldType = (payload: any, id = ''): Observable<HeaderType> => {
    const url = buildConfigurationFieldUrl(id);
    return sendRequestWithXSRFToken(url, payload, id ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<HeaderType>) => {
            const r = convertToCamel<HeaderType>(response);
            return observableOf(r);
        }),
    );
};
