import { Action } from 'redux';
import { createSelector } from 'reselect';
import store from 'store';

import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';

import {
    TOGGLE_DRAWER,
    RESET_DRAWER,
    SET_DRAWER_WITHOUT_PERSISTING,
    TOGGLE_SIDE_PANEL,
    SET_SIDE_PANEL_WITHOUT_PERSISTING,
    RESET_SIDE_PANEL,
} from '../types';

import { UserPortalState } from '..';

// Local Storage (STORE_) items
export const STORE_LAST_DRAWER_STATE = 'last_drawer_state';
export const STORE_LAST_PANEL_STATE = 'last_side_panel_state';

export interface DrawerState {
    isNavDrawerOpen: boolean;
    isSidePanelOpen: boolean;
}

interface SetDrawerAction extends Action {
    isNavDrawerOpen: boolean;
}
interface SetPanelAction extends Action {
    isSidePanelOpen: boolean;
    trackingDetails?: string;
}

export const INITIAL_STATE = {
    isNavDrawerOpen: store.get(STORE_LAST_DRAWER_STATE, true),
    isSidePanelOpen: store.get(STORE_LAST_PANEL_STATE, false),
};

// action creators
export const toggleNavDrawer = (dispatch: any) => () => dispatch({ type: TOGGLE_DRAWER });
export const setNavDrawerWithoutPersisting = (isNavDrawerOpen: boolean): SetDrawerAction => ({
    type: SET_DRAWER_WITHOUT_PERSISTING,
    isNavDrawerOpen,
});
export const resetNavDrawer = (dispatch: any) => () => dispatch({ type: RESET_DRAWER });

export const toggleSidePanel = (dispatch: any) => () => dispatch({ type: TOGGLE_SIDE_PANEL });
export const setSidePanelOpen =
    (isSidePanelOpen: boolean, trackingDetails?: string) =>
    (dispatch: any): SetPanelAction =>
        dispatch({
            type: SET_SIDE_PANEL_WITHOUT_PERSISTING,
            isSidePanelOpen,
            trackingDetails,
        });
export const resetSidePanel = (dispatch: any) => () => dispatch({ type: RESET_SIDE_PANEL });

// reducer
export const toggleDrawerReducer = (state: DrawerState) => {
    const isNavDrawerOpen = !state.isNavDrawerOpen;
    store.set(STORE_LAST_DRAWER_STATE, isNavDrawerOpen);
    trackUserAction(
        TRACKING_COMPONENTS.NAV_BAR,
        isNavDrawerOpen ? EVENT_TYPES.COLLAPSE : EVENT_TYPES.EXPAND,
        'main menu',
    );
    return { ...state, isNavDrawerOpen };
};

const setDrawerWithoutPersistingReducer = (state: DrawerState, action: SetDrawerAction) => {
    const { isNavDrawerOpen } = action;
    return { ...state, isNavDrawerOpen };
};

const resetDrawerReducer = (state: DrawerState) => {
    const isNavDrawerOpen = store.get(STORE_LAST_DRAWER_STATE);
    return { ...state, isNavDrawerOpen };
};
export const toggleSidePanelReducer = (state: DrawerState) => {
    const isSidePanelOpen = !state.isSidePanelOpen;
    store.set(STORE_LAST_PANEL_STATE, isSidePanelOpen);
    trackUserAction(
        TRACKING_COMPONENTS.PROPERTIES_PANEL,
        isSidePanelOpen ? EVENT_TYPES.COLLAPSE : EVENT_TYPES.EXPAND,
    );
    return { ...state, isSidePanelOpen };
};

const setSidePanelWithoutPersistingReducer = (state: DrawerState, action: SetPanelAction) => {
    const { isSidePanelOpen, trackingDetails } = action;
    store.set(STORE_LAST_PANEL_STATE, isSidePanelOpen);
    trackUserAction(
        TRACKING_COMPONENTS.PROPERTIES_PANEL,
        isSidePanelOpen ? EVENT_TYPES.COLLAPSE : EVENT_TYPES.EXPAND,
        trackingDetails,
    );
    return { ...state, isSidePanelOpen };
};

const resetSidePanelReducer = (state: DrawerState) => {
    const isSidePanelOpen = store.get(STORE_LAST_PANEL_STATE);
    return { ...state, isSidePanelOpen };
};

export const reducer = (state: DrawerState = INITIAL_STATE, action: Action) => {
    const { type } = action;
    switch (type) {
        case TOGGLE_DRAWER:
            return toggleDrawerReducer(state);
        case SET_DRAWER_WITHOUT_PERSISTING:
            return setDrawerWithoutPersistingReducer(state, action as SetDrawerAction);
        case RESET_DRAWER:
            return resetDrawerReducer(state);
        case TOGGLE_SIDE_PANEL:
            return toggleSidePanelReducer(state);
        case SET_SIDE_PANEL_WITHOUT_PERSISTING:
            return setSidePanelWithoutPersistingReducer(state, action as SetPanelAction);
        case RESET_SIDE_PANEL:
            return resetSidePanelReducer(state);
        default:
            return state;
    }
};

// selectors
const getDrawer = (state: UserPortalState) => {
    const { drawer = INITIAL_STATE } = state || {};
    return drawer;
};

export const isNavDrawerOpen = createSelector(getDrawer, ({ isNavDrawerOpen }) => isNavDrawerOpen);
export const isSidePanelOpen = createSelector(getDrawer, ({ isSidePanelOpen }) => isSidePanelOpen);
