function parseTextError(field: string, message: string): string {
    const splittedMessage = message.split(field);
    return splittedMessage[splittedMessage.length - 1].trim();
}

export function parseErrorMessage(message: any): string {
    if (!message) return '';

    if (typeof message === 'string') {
        if (message.includes('Hint:')) {
            return parseTextError('Hint:', message);
        }
        if (message.includes('Error:')) {
            return parseTextError('Error:', message);
        }
        return message;
    }

    // it's an object - so check for specific fields before serializing it
    if (message.error) return parseErrorMessage(message.error);

    // use message property as message if available
    if (message.message) return parseErrorMessage(message.message);

    // use title property as message if available
    if (message.title) return parseErrorMessage(message.title);

    // no expected field - just serialize what is available
    return message.toString ? message.toString() : `${message}`;
}
