export const styles = {
    container: `
        width: 500px;
        height: 100%;
        background-color: #FFFFFF;
        min-height: 120px;
    `,
    topContainer: `
        height: 94px;
        width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        padding: 0px 8px;
    `,
    topHalf: `
        height: 44px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `,
    bottomHalf: `
        height: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    baseFont: `
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    title: `
        font-size: 15px;
        font-weight: 600;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    bottomContainer: `
        height: 100%;
        width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        padding: 0px 8px;
    `,
    boundariesContainer: `
        height: 20px;
        width: 100%;
        border-top: 1px solid #EBEBEB;
        border-bottom: 1px solid #EBEBEB;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
    `,
    boundariesInputContainer: `
        height: 20px;
        width: 100%;
        border-bottom: 1px solid #EBEBEB;
        display: flex;
        align-items: center;
        cursor: pointer;
    `,
    boundariesText: `
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    dataContainer: `
        height: 100%;
        width: 100%;
        max-height: 316px;
        overflow-y: auto;
    `,
    dataItem: `
        height: 100%;
        width: 100%;
        padding-top: 8px;
        border-bottom: 1px solid #EBEBEB;
    `,
    boundaryInputContainer: `
        height: 30px;
        width: 100%;
        padding-bottom: 16px;
    `,
    dataGridContainer: `
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        padding: 4px 0px;
        grid-row-gap: 4px;
    `,
    coordDataContainer: `
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    `,
    coordDataLabel: `
        font-size: 13px;
        font-weight: 600;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
    `,
    coordDataItem: `
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
    `,
    input: `
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        border: none;
        outline: none !important;
        position: relative;
        right: 2px;
        cursor: pointer;
    `,
    buttonActive: `
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #265C7F;
        cursor: pointer;
    `,
    buttonDisabled: `
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    buttonsContainer: `
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 108px
    `,
    textSlash: `
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 4px;
    `,
    arrowDown: `
        height: 12px;
        width: 12px;
        transform: rotate(90deg);
    `,
    arrowUp: `
        height: 12px;
        width: 12px;
        transform: rotate(270deg);
    `,
    optionsContainer: `
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;
        z-index: 2;
        background-color: #FFFFFF;
        display: none;
        max-height: 350px;
        overflow-y: auto;
    `,
    inputOption: `
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
    `,
    inputOptionText: `
        height: 100%;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        padding-left: 8px;
    `,
    dropdownOption: `
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        font-family: Roboto;
        text-decoration: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 16px;
    `,
    selectMenuContainer: `
        height: 48px;
        width: 100%;
        dispay: flex;
        flex-direction: column;
        cursor: pointer;
    `,
    selectMenu: `
        height: 43px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #F2F2F2;
    `,
    arrowContainer: `
        padding-right: 16px;
    `,
};
