import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildBulkValidateImportListUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';
import { BulkValidateImportList } from '../types';

export const bulkValidateImportList = (payload: any): Observable<BulkValidateImportList> => {
    const url = buildBulkValidateImportListUrl();
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, payload).pipe(
        switchMap((response: any) => {
            const { result } = response;
            const converted = convertToCamel<BulkValidateImportList>(result);
            return observableOf(converted);
        }),
    );
};
