import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationLinkActivityListsUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const linkActivityLists = (activity: string, payload: any): Observable<any> => {
    const url = buildConfigurationLinkActivityListsUrl(activity);
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedResponse = convertToCamel<any>(response);
            return observableOf(convertedResponse);
        }),
    );
};
