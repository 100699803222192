import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildSwitchSubscriptionsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const switchSubscription = (subscriptionId: string): Observable<any> => {
    const url = buildSwitchSubscriptionsUrl();
    return sendRequestWithXSRFToken(url, { subscription: subscriptionId }).pipe(
        switchMap(({ response }) => observableOf(response)),
    );
};
