import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationHeaderCategoriesUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { HeaderCategoriesResponse, Category } from '../types';

export const loadHeaderCategories = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: Category[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const obs = sendRetrieveRequest(buildConfigurationHeaderCategoriesUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<HeaderCategoriesResponse>) => {
            const results = convertToCamel<Category[]>(response.HeaderCategory);
            return observableOf({ result: results, actionName, processResultFunction });
        }),
    );
};
