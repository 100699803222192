import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import {
    buildOAuth2LoginUrl,
    buildOAuth2LogoutUrl,
    buildOAuth2TokenUrl,
} from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { OAuth2TokenDetails } from '../state/types';
import { OAuth2TokenResponse } from './types';

const processUserId = (token: OAuth2TokenResponse) => ({
    userId: token.id,
    userName: token.name,
    expiry_timeout: token.expiry_timeout,
});

const buildAuth = (token: OAuth2TokenResponse): Observable<OAuth2TokenDetails> =>
    observableOf({ ...token, ...processUserId(token) });

export const oauth2FetchLoginUrl = (): Observable<string> => observableOf(buildOAuth2LoginUrl());

export const oauth2Token = () => {
    const obs = sendRetrieveRequest(buildOAuth2TokenUrl());
    return obs.pipe(switchMap(({ response }: AjaxResponse<any>) => buildAuth(response)));
};

export const oauth2FetchLogoutUrl = (): Observable<string> => observableOf(buildOAuth2LogoutUrl());
