import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { imagoUserContextUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';

export const imagoUserContext = (): Observable<any> => {
    const url = imagoUserContextUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => observableOf(response)),
    );
};
