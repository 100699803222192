import { AuthenticationStatus } from 'state-domains/domain/authentication/types';
import {
    usedNotificationActions,
    usedPreferencesActions,
} from 'state-domains/domain/notifications/used-actions';
import { ERROR_LOGOUT } from 'state-domains/types/actionTypes';

import { buildOAuth2LoginUrl, buildOAuth2LogoutUrl, buildOAuth2TokenUrl } from '../../network/urls';

const ERROR_LOGOUT_TIMEOUT = 5000;

export const authInterceptor = (dispatch: any) => (next: any) => (action: any) => {
    const payload = action?.payload;
    const endPointName = action?.meta?.arg?.endpointName ?? '';

    // Don't intercept 401/403 for notification or their preferences related actions
    let isNotificationPreferencesRelatedAction = false;
    if (action?.type) {
        const actionName = action.type.split('.');
        if (
            usedNotificationActions.includes(actionName[0]) ||
            usedPreferencesActions.includes(actionName[0])
        ) {
            isNotificationPreferencesRelatedAction = true;
        }
    }

    if (
        (((payload?.status === 401 || payload?.status === 403) &&
            !payload?.message?.includes(buildOAuth2TokenUrl()) &&
            !payload?.preventOAuth2LoginRedirection) ||
            (payload?.error?.status === 403 &&
                !payload?.error?.message?.includes(buildOAuth2TokenUrl()) &&
                !payload?.error.preventOAuth2LoginRedirection)) &&
        !isNotificationPreferencesRelatedAction
    ) {
        if (
            dispatch.getState().authentication.authenticationStatus ===
                AuthenticationStatus.Authenticated &&
            endPointName !== 'loadAzureMapToken' &&
            action?.type !== 'LOAD_MAP_TOKEN/loadMapTokenFail' // Ignore map token end point from logging the user out. Will be removed after id token is fixed on the backend
        ) {
            setTimeout(
                () =>
                    dispatch.dispatch({
                        type: ERROR_LOGOUT,
                    }),
                ERROR_LOGOUT_TIMEOUT,
            );
        } else if (
            dispatch.getState().authentication.authenticationStatus ===
            AuthenticationStatus.NotAuthenticated
        ) {
            if (dispatch.getState().authentication.loggedOutState) {
                window.location.href = buildOAuth2LogoutUrl();
            } else {
                window.location.href = `${buildOAuth2LoginUrl()}?returnUrl=${encodeURIComponent(
                    window.location.pathname,
                )}`;
            }
        }
    } else {
        next(action);
    }
};
