import { common } from '@local/web-design-system';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        pageContainer: {
            ...common(theme).thinScrollBar,
            height: 600,
            width: 600,
        },
        mapContainer: {
            height: 600,
            width: 600,
            '.leaflet-tooltip': {
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                lineHeight: '1rem',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontSize: '13px',
                color: '#FAFAFA',
                fontWeight: 550,
            },
            '.leaflet-tooltip-top:before': {
                borderTopColor: theme.palette.primary.main,
            },
        },
        overviewMap: {
            height: '100%',
            width: '100%',
            '.leaflet-right .leaflet-control:nth-of-type(2)': {
                right: 1,
                padding: 0,
                paddingLeft: '-5%',
                border: 'none',
                height: '64px',
                borderRadius: '30px',
            },
            '.leaflet-tooltip': {
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                lineHeight: '1rem',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontSize: '13px',
                color: '#FAFAFA',
                fontWeight: 550,
            },
            '.leaflet-tooltip-top:before': {
                borderTopColor: theme.palette.primary.main,
            },
        },
        locationMapContainer: {
            height: 146,
            border: `1px solid ${theme.palette.grey[400]}`,
            cursor: 'pointer',
        },
        panelMapContainer: {
            height: 125,
            width: 208,
            marginLeft: theme.spacing(3),
            border: `1px solid ${theme.palette.grey[400]}`,
            cursor: 'pointer',
        },
        popup: {
            width: theme.spacing(31),
            marginBottom: '4px',
            marginLeft: '14px',
            '&.leaflet-popup .leaflet-popup-content-wrapper': {
                borderRadius: '0px',
            },
            '.leaflet-popup-content': {
                margin: '12px 24px 12px 24px',
            },
            '.leaflet-popup-content p': {
                margin: 0,
                fontSize: '12px',
                color: '#979797',
            },
        },
        smallPopup: {
            width: theme.spacing(20),
            marginBottom: '24px',
            marginLeft: '7px',
            '&.leaflet-popup .leaflet-popup-content-wrapper': {
                borderRadius: '0px',
            },
            '.leaflet-popup-content': {
                margin: '6px 12px 6px 12px',
            },
            '.leaflet-popup-content p': {
                margin: 0,
                fontSize: '10px',
                color: '#979797',
            },
        },
        link: {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: 'normal',
            fontFamily: '"Roboto", sans-serif',
            textDecoration: 'none',
            display: 'block',
            width: '135px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        smallLink: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 'normal',
            fontFamily: '"Roboto", sans-serif',
            textDecoration: 'none',
        },
        nonInteraction: {
            cursor: 'default',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
