import { withTrace } from '@local/web-design-system';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { compose } from 'redux';

import { withStyles } from 'src/styles/utils';
import { getNextPageNumber } from 'src/utilities';

import { getPreferences } from '../Drillhole/Drillhole.utils';
import { DropdownSelect } from '../DropdownSelect';
import { DropdownItem } from '../DropdownSelect/DropdownSelect.types';
import { Pagination } from './Pagination/Pagination';
import { i18n } from './PaginationControls.i18n';
import { styles } from './PaginationControls.styles';
import {
    PaginationControlsBaseComponentType,
    PaginationControlsProps,
} from './PaginationControls.types';

export const LISTING_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const LISTING_DEFAULT_LIMIT = LISTING_PAGE_SIZE_OPTIONS[1];
export const LISTING_PAGE_SIZE_DROPDOWN_ITEMS: DropdownItem[] = LISTING_PAGE_SIZE_OPTIONS.map(
    (option: number) =>
        ({
            id: option,
            name: String(option),
        }) as DropdownItem,
);

export const getListingDefaultLimit = (currentUserId: string) =>
    // future proofing in case the values in preferences are not in the current options
    LISTING_PAGE_SIZE_OPTIONS.find((l) => l === getPreferences(currentUserId)?.rowsPerPage) ??
    LISTING_DEFAULT_LIMIT;

export const PaginationControlsBase = (props: PaginationControlsProps) => {
    const onRowsPerPageChanged = (nextRowsPerPage: number) => {
        const { rowsPerPage: currRowsPerPage, currentPage } = props;
        const nextPageNumber = getNextPageNumber(currRowsPerPage, currentPage, nextRowsPerPage);
        handleViewParamsChanged(nextRowsPerPage, nextPageNumber);
    };

    const onBackButtonClicked = () => {
        handlePageNumberIncremented(-1);
    };

    const onNextButtonClicked = () => {
        handlePageNumberIncremented(1);
    };

    const handlePageNumberIncremented = (increment: 1 | -1) => {
        const { rowsPerPage, currentPage, onPageNumberIncremented } = props;

        if (onPageNumberIncremented) {
            onPageNumberIncremented(increment);
        }
        const nextPageNumber = currentPage + increment;
        handleViewParamsChanged(rowsPerPage, nextPageNumber);
    };

    const handleViewParamsChanged = (nextRowsPerPage: number, nextPage: number) => {
        const { onPaginationInfoChanged } = props;
        onPaginationInfoChanged(nextRowsPerPage, nextPage);
    };

    const {
        applyTrace,
        intl,
        classes,
        numberOfResults,
        rowsPerPage,
        currentPage,
        dropdownTitle,
        paginationTypeLabel,
        optionDropdownItems,
        ariaLabelPrevious,
        ariaLabelNext,
        labelEnabled = true,
        paginationButtonsDisabled = false,
    } = props;

    return (
        <Grid item container direction="row" spacing={1} wrap="nowrap" className={classes.root}>
            {dropdownTitle ? (
                <Grid item className={classes.dropdownTitleWrapper}>
                    <Typography
                        variant="body2"
                        className={classes.dropdownTitle}
                        data-testid="dropdownTitle"
                    >
                        {dropdownTitle}
                    </Typography>
                </Grid>
            ) : null}
            <Grid item className={classes.paginationOptionsDropdownWrapper}>
                <DropdownSelect
                    automationId="rows-per-page"
                    selected={onRowsPerPageChanged}
                    list={optionDropdownItems ?? LISTING_PAGE_SIZE_DROPDOWN_ITEMS}
                    itemId={rowsPerPage}
                    menuType="1"
                    setWidth={60}
                    attachMenu
                />
            </Grid>
            <Grid item className={classes.paginationWrapper} flexWrap="nowrap">
                <Pagination
                    showOptions={false}
                    numberOfRows={numberOfResults}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    typeLabel={paginationTypeLabel}
                    pageSizeOptions={[]}
                    ariaLabelPrevious={
                        ariaLabelPrevious || intl.formatMessage(i18n.ariaLabelPrevious)
                    }
                    ariaLabelNext={ariaLabelNext || intl.formatMessage(i18n.ariaLabelNext)}
                    handleChangeRowsPerPage={onRowsPerPageChanged}
                    handleBackButtonClick={onBackButtonClicked}
                    handleNextButtonClick={onNextButtonClicked}
                    applyTrace={applyTrace}
                    labelEnabled={labelEnabled}
                    paginationButtonsDisabled={paginationButtonsDisabled}
                />
            </Grid>
        </Grid>
    );
};

export default compose(
    withStyles(styles),
    withTrace('PaginationControls'),
)(PaginationControlsBase) as PaginationControlsBaseComponentType;
