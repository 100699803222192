import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildBulkImportListUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const bulkImportList = (payload: any): Observable<any> => {
    const url = buildBulkImportListUrl();
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, payload).pipe(
        switchMap((response: any) => {
            const { result } = response;
            const converted = response?.ok === false ? response : convertToCamel(result);
            return observableOf(converted);
        }),
    );
};
