import * as React from 'react';

import { withStyles, WithStyles } from 'src/styles/utils';

const styles = () => ({
    root: {
        maxWidth: '100%',
    },
});

type ErrorSvgProps = WithStyles<typeof styles>;

const ErrorSvgBase = function ErrorSvgBase({ classes }: ErrorSvgProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1087"
            height="400"
            viewBox="0 0 1087 400"
            className={classes.root}
        >
            <defs>
                <path
                    id="404-error-b"
                    d="M0,283 C0,283 143.508806,283 370.526417,283 C401.170552,283 432.87729,398 481.203675,398 C513.236294,398 586.79889,398 626.600306,398 C664.143915,398 675.276668,283 719.732676,283 C784.715109,283 905.470884,283 1082,283"
                />
                <filter
                    id="404-error-a"
                    width="100.6%"
                    height="105.2%"
                    x="-.3%"
                    y="-2.6%"
                    filterUnits="objectBoundingBox"
                >
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="2"
                        result="shadowSpreadOuter1"
                    />
                    <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feMorphology in="SourceAlpha" radius="2" result="shadowInner" />
                    <feOffset in="shadowInner" result="shadowInner" />
                    <feComposite
                        in="shadowOffsetOuter1"
                        in2="shadowInner"
                        operator="out"
                        result="shadowOffsetOuter1"
                    />
                    <feColorMatrix
                        in="shadowOffsetOuter1"
                        values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0"
                    />
                </filter>
                <polygon
                    id="404-error-d"
                    points="30 266.5 133.522 123.5 222 218.5 279.021 169.5 339 242.419 368.5 279.68 18.5 279.68"
                />
                <filter
                    id="404-error-c"
                    width="103%"
                    height="104.6%"
                    x="-1.5%"
                    y="-2.7%"
                    filterUnits="objectBoundingBox"
                >
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="1"
                        result="shadowSpreadOuter1"
                    />
                    <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        in="shadowOffsetOuter1"
                        values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0"
                    />
                </filter>
                <polygon
                    id="404-error-f"
                    points="827.451 108 928 209.5 972.5 177.5 1066 279 709 279"
                />
                <filter
                    id="404-error-e"
                    width="102.9%"
                    height="104.2%"
                    x="-1.3%"
                    y="-2.4%"
                    filterUnits="objectBoundingBox"
                >
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="1"
                        result="shadowSpreadOuter1"
                    />
                    <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        in="shadowOffsetOuter1"
                        values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(2.5)">
                <g strokeLinecap="round">
                    <use fill="#000" filter="url(#404-error-a)" href="#404-error-b" />
                    <use stroke="#D8D8D8" strokeWidth="2" href="#404-error-b" />
                </g>
                <g fillRule="nonzero">
                    <use fill="#000" filter="url(#404-error-c)" href="#404-error-d" />
                    <use fill="#F2F2F2" href="#404-error-d" />
                </g>
                <g fillRule="nonzero">
                    <use fill="#000" filter="url(#404-error-e)" href="#404-error-f" />
                    <use fill="#F2F2F2" href="#404-error-f" />
                </g>
                <polygon
                    fill="#FAFAFA"
                    fillRule="nonzero"
                    points="70.795 223 132.022 121.5 227.06 228 159.5 186.5 151.408 186.5 148 175.848 132.022 193.146 132.022 159.917"
                />
                <polygon
                    fill="#FAFAFA"
                    fillRule="nonzero"
                    points="740 160 792.491 113.99 879.492 237.492 800.848 154.5 784.308 154.5 779.714 145.938"
                    transform="matrix(-1 0 0 1 1619.492 0)"
                />
                <path
                    fill="#FAFAFA"
                    fillRule="nonzero"
                    d="M227.059896,217.178385 L279.521951,171 L286.398438,182 C281.729292,180.562078 275.089535,182.395411 266.479167,187.5 C257.868798,192.604589 244.729041,202.497384 227.059896,217.178385 Z"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    d="M250.406121,50 C251.169819,47.2260869 251.229297,44.216379 250.395415,41.2441565 C248.721705,35.2876194 243.963466,31.0820092 238.38681,29.8921528 C238.592604,29.1001244 238.71156,28.2718199 238.71156,27.4132854 C238.71156,22.0323292 234.420818,17.6707317 229.127277,17.6707317 C226.126017,17.6707317 223.449508,19.0746171 221.691339,21.2693217 C216.406125,11.8883199 205.734584,6.41667349 194.658593,8.40702266 C181.469944,10.7758526 172.382897,22.8836085 173.288152,36.1691289 C172.459029,35.9430078 171.588271,35.813623 170.684206,35.813623 C165.335945,35.813623 161,40.0385805 161,45.2502481 C161,46.9842461 161.487719,48.604579 162.326359,50 L250.406121,50 Z"
                    opacity=".529"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    d="M772.577686,30 C773.12076,28.0186335 773.163056,25.8688422 772.570073,23.7458261 C771.379879,19.4911567 767.996242,16.4871494 764.03062,15.637252 C764.176963,15.0715174 764.261553,14.4798714 764.261553,13.8666324 C764.261553,10.0230923 761.210359,6.90766549 757.446063,6.90766549 C755.311835,6.90766549 753.408539,7.91044079 752.158285,9.47808693 C748.399911,2.77737137 740.81126,-1.13094751 732.935,0.290730475 C723.556405,1.98275184 717.094505,10.631149 717.738242,20.1208063 C717.148643,19.9592913 716.529437,19.8668736 715.886546,19.8668736 C712.083339,19.8668736 709,22.8847003 709,26.6073201 C709,27.8458901 709.346823,29.0032707 709.943189,30 L772.577686,30 Z"
                    opacity=".508"
                    transform="matrix(-1 0 0 1 1482 0)"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    d="M360.571088,123 C361.122647,121.018633 361.165603,118.868842 360.563356,116.745826 C359.354565,112.491157 355.918059,109.487149 351.890474,108.637252 C352.039103,108.071517 352.125015,107.479871 352.125015,106.866632 C352.125015,103.023092 349.026146,99.9076655 345.203033,99.9076655 C343.035457,99.9076655 341.102422,100.910441 339.832633,102.478087 C336.015534,95.7773714 328.308311,91.8690525 320.308984,93.2907305 C310.783849,94.9827518 304.220981,103.631149 304.874777,113.120806 C304.275965,112.959291 303.647085,112.866874 302.994149,112.866874 C299.131516,112.866874 296,115.8847 296,119.60732 C296,120.84589 296.352242,122.003271 296.957926,123 L360.571088,123 Z"
                    opacity=".508"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    d="M983.41272,70 C984.167932,67.358178 984.226749,64.4917895 983.402133,61.6611014 C981.747019,55.988209 977.04165,51.9828659 971.526956,50.8496693 C971.730464,50.0953566 971.848098,49.3064952 971.848098,48.4888432 C971.848098,43.364123 967.605031,39.2102207 962.370307,39.2102207 C959.402395,39.2102207 956.755624,40.5472544 955.01699,42.6374492 C949.790501,33.7031618 939.237533,28.49207 928.284609,30.3876406 C915.2425,32.6436691 906.256421,44.1748653 907.151617,56.8277418 C906.331707,56.6123884 905.470624,56.4891648 904.576604,56.4891648 C899.287768,56.4891648 895,60.5129338 895,65.4764268 C895,67.1278535 895.4823,68.6710276 896.311622,70 L983.41272,70 Z"
                    opacity=".508"
                    transform="matrix(-1 0 0 1 1879 0)"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M339.527043,173.748743 L304.758549,173.69173 C301.76197,173.686824 299.314243,176.133989 299.319156,179.129881 L299.363276,206.032993 L275.292571,205.993527 C272.295992,205.988614 269.848265,208.435779 269.853171,211.431671 L269.897674,238.563561 C269.902587,241.559453 272.358354,244.014658 275.354933,244.019571 L359.825782,244.158076 C362.825085,244.162986 365.270088,241.715817 365.265175,238.719925 L365.250047,229.4953 C365.529564,228.842111 365.683662,228.123351 365.682432,227.368931 L365.679852,225.800173 L339.527043,173.748743 Z"
                    transform="rotate(-1 317.768 208.925)"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M365.827323,281.446611 L290.896493,281.323717 C275.439592,281.298374 262.776018,268.63498 262.750641,253.184346 C262.725341,237.733713 275.347448,225.111776 290.804349,225.137081 L365.735179,225.259975 C381.19208,225.285318 393.855655,237.948713 393.881031,253.399346 C393.906332,268.84998 381.284224,281.471916 365.827323,281.446611 Z"
                    transform="rotate(-1 328.316 253.292)"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M377.014347,264.145214 C371.039174,270.119015 361.332981,270.103101 355.338181,264.109674 C349.343382,258.116247 349.327468,248.412279 355.302641,242.438478 C361.277815,236.464677 370.984008,236.480592 376.978807,242.474019 C382.973606,248.467445 382.98952,258.171413 377.014347,264.145214 Z"
                    transform="rotate(-1 366.158 253.292)"
                />
                <path
                    fill="#D1DFE7"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M302.920527,264.145214 C296.945353,270.119015 287.23916,270.103101 281.244361,264.109674 C275.249562,258.116247 275.233648,248.412279 281.208821,242.438478 C287.183994,236.464677 296.890187,236.480592 302.884987,242.474019 C308.879786,248.467445 308.8957,258.171413 302.920527,264.145214 Z"
                    transform="rotate(-1 292.065 253.292)"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M337.723286,207.675791 L312.690951,207.634741 C309.694372,207.629828 307.238605,205.174623 307.233692,202.178731 L307.206488,185.595106 C307.201582,182.599213 309.649309,180.152049 312.645888,180.156954 L330.047842,180.185494 C333.047146,180.190411 334.448116,182.311616 335.862713,186.09692 L343.162686,202.23764 C343.167592,205.233532 340.719865,207.680697 337.723286,207.675791 Z"
                    transform="rotate(-1 325.185 193.916)"
                />
                <g fillRule="nonzero" transform="rotate(8 -2046.111 3296.807)">
                    <polygon
                        fill="#FFF"
                        stroke="#265C7F"
                        strokeWidth="3"
                        points="65.734 28.761 95.974 8 106.02 17.666 78.644 53.754"
                        transform="rotate(137 85.877 30.877)"
                    />
                    <polygon
                        fill="#FFF"
                        stroke="#265C7F"
                        strokeWidth="3"
                        points="87.773 75.342 67.903 75.31 42.149 49.037 51.9 39.289"
                        transform="rotate(137 64.961 57.316)"
                    />
                    <polygon
                        fill="#D1DFE7"
                        stroke="#265C7F"
                        strokeWidth="3"
                        points="34.752 57.776 23.398 57.722 23.593 52.54 31.362 47.092 25.705 32.876 12 26.38 12.025 11.706 46.538 11.871 46.482 49.246"
                        transform="rotate(137 29.269 34.74)"
                    />
                    <path
                        fill="#FFF"
                        stroke="#265C7F"
                        strokeWidth="3"
                        d="M95.2160335,54.6812611 C95.2236597,59.3385118 91.4541602,63.1071454 86.7958414,63.0995193 C82.1375225,63.0918699 78.3583661,59.3108596 78.3507167,54.6536089 C78.343095,49.9990817 82.1098658,46.22772 86.7681847,46.2353462 C91.4265036,46.2429955 95.2083887,50.0267339 95.2160335,54.6812611 Z"
                        transform="rotate(137 86.783 54.667)"
                    />
                    <polygon
                        fill="#265C7F"
                        fillOpacity=".3"
                        points="16.362 58.111 25.26 56.696 32.878 50.85 32.81 18.412 30.119 21.298 25.015 49.993"
                        transform="rotate(137 24.62 38.261)"
                    />
                </g>
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M273.848691,225.093063 C274.566386,224.323429 303.811767,223.536725 361.584835,222.73295 L356.951825,218.412599 L288.749712,219.5634 L281.384491,217.011907 L272.758879,210.587151 C272.767725,221.027393 273.130995,225.862697 273.848691,225.093063 Z"
                    opacity=".668"
                    transform="rotate(-1 317.172 217.88)"
                />
                <path
                    stroke="#265C7F"
                    strokeWidth="2"
                    d="M367.519729,214.023792 C372.560258,212.511633 373.188075,201.422469 367.519729,201.422469 C362.4792,201.422469 371.048099,211.503527 377.600787,207.975157 C381.482288,205.885118 386.42082,199.562073 384.138707,194.851077 C380.651844,187.6531 372.76983,193.605997 378.78216,198.862826 C384.31033,203.696332 389.151537,200.52659 392.551333,195.212997"
                    opacity=".5"
                    transform="rotate(-10 379.276 202.774)"
                />
                <path
                    stroke="#265C7F"
                    strokeWidth="2"
                    d="M379.43964,223.22594 C382.799992,222.217834 379.756398,210.580796 377.100666,215.575501 C375.629335,218.342673 384.833888,220.325769 389.202346,217.973522 C391.790014,216.580163 394.879256,213.745847 393.357847,210.605183 C391.033272,205.806531 386.773084,208.93405 390.781304,212.438602 C394.466751,215.66094 396.902846,213.00781 399.169377,209.465415"
                    opacity=".504"
                    transform="rotate(-163 388.056 215.689)"
                />
                <path
                    fill="#265C7F"
                    fillRule="nonzero"
                    d="M550.641907,376.228516 L561.850454,376.228516 L567.454727,385.568971 L561.461854,394.904456 L551.030508,394.904456 L545.97168,385.568971 L550.641907,376.228516 Z M556.713204,390.168365 C559.254751,390.168365 561.315083,388.108033 561.315083,385.566486 C561.315083,383.024938 559.254751,380.964607 556.713204,380.964607 C554.171656,380.964607 552.111325,383.024938 552.111325,385.566486 C552.111325,388.108033 554.171656,390.168365 556.713204,390.168365 Z"
                    opacity=".45"
                />
                <path
                    fill="#265C7F"
                    fillRule="nonzero"
                    stroke="#265C7F"
                    d="M575.041798,371.739323 L575.374546,371.352332 L595.483318,387.91175 L595.520403,392.290614 L590.908581,392.382319 L570.932718,377.010367 L569.915246,378.266599 L563.907545,379.421853 L560.357514,377.280947 L565.175734,370.827364 L567.279322,371.740455 L569.168124,369.543751 L567.523974,368.096327 L572.441978,362.65987 L575.436199,364.646594 L575.041798,371.739323 Z"
                    opacity=".45"
                    transform="rotate(90 200.418 0)"
                />
                <path
                    stroke="#FFF"
                    strokeLinecap="square"
                    strokeWidth="2"
                    d="M578.213145,371.961638 L579.252107,383.020714"
                    transform="scale(1 -1) rotate(-83 152.056 0)"
                />
                <path
                    stroke="#FFF"
                    strokeLinecap="square"
                    strokeWidth="2"
                    d="M583.16664,378.525188 L583.6384,388.19913"
                    transform="scale(1 -1) rotate(-83 150.09 0)"
                />
                <path
                    stroke="#FFF"
                    strokeLinecap="square"
                    strokeWidth="2"
                    d="M587.469304,384.897081 L587.733485,393.638947"
                    transform="scale(1 -1) rotate(-83 147.614 0)"
                />
                <path
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M558.534793,330.129203 C556.416065,331.186197 553.659495,331.517363 552.178611,333.300183 C543.181958,344.131158 564.225998,342.95724 557.863544,353.106752 C556.018401,356.05016 552.010003,357.12172 549.083233,359.129203"
                    transform="rotate(177 554.083 344.63)"
                />
                <path
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M566.284723,312.550638 C565.070788,314.593067 556.329096,325.925569 561.080895,330.465908 C566.137642,335.297626 573.426976,334.268488 567.405266,341.550638"
                    transform="scale(-1 1) rotate(3 0 -21237.123)"
                />
                <path
                    stroke="#265C7F"
                    strokeWidth="3"
                    d="M557.741881,310.810818 L560.741881,307.810818"
                    transform="scale(-1 1) rotate(3 0 -21047.275)"
                />
            </g>
        </svg>
    );
};

export const ErrorSvg = withStyles(styles)(ErrorSvgBase);
