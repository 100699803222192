import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { commonStyles } from 'src/styles';

const styles = (theme: Theme) => ({
    footerRowRoot: {
        ...commonStyles(theme).row,
        paddingTop: theme.spacing(),
        opacity: 0.7,
    },
});

export const useStyles = makeStyles()(styles);
