import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildReorderTablesUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const reorderTables = (tableViewId: string, payload: any): Observable<any> => {
    const url = buildReorderTablesUrl(tableViewId);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(switchMap(() => observableOf({})));
};
