import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildOAuth2NotificationTokenUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';

export function fetchNotificationToken(): Observable<string> {
    const url = buildOAuth2NotificationTokenUrl();
    return sendRetrieveRequest(url).pipe(switchMap(({ response }) => of(response)));
}
