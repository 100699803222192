import {
    ArrowDown,
    ArrowLeft,
    ArrowLeftDouble,
    ArrowRight,
    ArrowRightDouble,
    withTrace,
    WithTrace,
} from '@local/web-design-system';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import * as React from 'react';

import { withStyles } from 'src/styles/utils';

import { styles } from './Pagination.styles';
import { PaginationProps } from './PaginationControls.types';

export function PaginationBase(props: PaginationProps & WithTrace) {
    const {
        numberOfRows,
        rowsPerPage,
        page,
        typeLabel,
        pageSizeOptions,
        ariaLabelPrevious,
        ariaLabelBackward,
        ariaLabelNext,
        ariaLabelForward,
        showOptions = true,
        extendedPagination = false,
        handleBackButtonClick,
        handleNextButtonClick,
        handleFastBackwardButtonClick = () => {},
        handleFastForwardButtonClick = () => {},
        handleChangeRowsPerPage,
        classes,
        applyTrace,
        popoverClasses,
        labelEnabled = true,
        paginationButtonsDisabled = false,
    } = props;
    const start = numberOfRows === 0 ? numberOfRows : rowsPerPage * page + 1;
    const end = Math.min(rowsPerPage * (page + 1), numberOfRows);
    const pageCount = Math.ceil(numberOfRows / rowsPerPage);

    return (
        <Grid container spacing={1} alignItems="center" flexWrap="nowrap">
            {showOptions && (
                <Grid item automation-id="pagination-show-options">
                    <FormControl fullWidth>
                        <Select
                            className={classes.select}
                            classes={{ select: classes.selectMenu, icon: classes.icon }}
                            value={rowsPerPage}
                            onChange={(event) => {
                                handleChangeRowsPerPage(
                                    // eslint-disable-next-line radix
                                    parseInt(event.target.value as string, 0),
                                    page,
                                );
                            }}
                            IconComponent={ArrowDown}
                            MenuProps={{
                                classes: { paper: classes.paper },
                                MenuListProps: { disablePadding: true },
                                PopoverClasses: popoverClasses,
                            }}
                            {...applyTrace('menu')}
                        >
                            {pageSizeOptions.map((option) => (
                                <MenuItem
                                    key={option.toString()}
                                    value={option}
                                    className={classes.menuitem}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {extendedPagination && (
                <Grid item className={classes.arrowLeftDoubleIcon}>
                    <IconButton
                        className={classes.button}
                        onClick={() => handleFastBackwardButtonClick()}
                        disabled={page === 0 || paginationButtonsDisabled}
                        aria-label={ariaLabelBackward}
                        {...applyTrace('backward')}
                        size="large"
                    >
                        <ArrowLeftDouble className={classes.icon} />
                    </IconButton>
                </Grid>
            )}
            <Grid item>
                <IconButton
                    className={classes.button}
                    onClick={() => handleBackButtonClick(page)}
                    disabled={page === 0 || paginationButtonsDisabled}
                    aria-label={ariaLabelPrevious}
                    {...applyTrace('prev')}
                    size="large"
                    automation-id="pagination-back-button"
                >
                    <ArrowLeft className={classes.icon} />
                </IconButton>
            </Grid>
            <Grid
                item
                className={classnames({ [classes.arrowRightExtendedIcon]: extendedPagination })}
            >
                <IconButton
                    className={classes.button}
                    onClick={() => handleNextButtonClick(page)}
                    disabled={page >= pageCount - 1 || paginationButtonsDisabled}
                    aria-label={ariaLabelNext}
                    {...applyTrace('next')}
                    size="large"
                    automation-id="pagination-next-button"
                >
                    <ArrowRight className={classes.icon} />
                </IconButton>
            </Grid>
            {extendedPagination && (
                <Grid item className={classes.arrowRightDoubleIcon}>
                    <IconButton
                        className={classes.button}
                        onClick={() => handleFastForwardButtonClick()}
                        disabled={page >= pageCount - 1 || paginationButtonsDisabled}
                        aria-label={ariaLabelForward}
                        {...applyTrace('forward')}
                        size="large"
                    >
                        <ArrowRightDouble className={classes.icon} />
                    </IconButton>
                </Grid>
            )}
            {labelEnabled ? (
                <Grid item flexWrap="nowrap" sx={{ flex: '0 0 auto' }}>
                    {typeLabel ? (
                        <Typography variant="body2" automation-id="number-of-results">
                            {`${start} - ${end} of ${numberOfRows} ${typeLabel}`}
                        </Typography>
                    ) : (
                        <Typography variant="body2" automation-id="number-of-results">
                            {`${start} - ${end} / ${numberOfRows}`}
                        </Typography>
                    )}
                </Grid>
            ) : null}
        </Grid>
    );
}

const tracedComponent = withTrace('pagination')<PaginationProps>(PaginationBase);
export const Pagination = withStyles(styles)(tracedComponent);
