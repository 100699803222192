import { PaginationInfo } from 'state-domains/types';
import {
    DEFAULT_PAGINATION_PAGE,
    DEFAULT_PAGINATION_PAGE_SIZE,
    DEFAULT_PAGINATION_PAGE_SIZE_OPTIONS,
} from 'state-domains/utils';

import { DropdownItem } from '../components/DropdownSelect/DropdownSelect.types';
import { isValidNumber } from './numbers';

export function getNextPageNumber(
    currRowsPerPage: number,
    currentPage: number,
    nextRowsPerPage: number,
): number {
    return Math.floor((currentPage * currRowsPerPage) / nextRowsPerPage);
}

export function mapPageSizeOptionsIntoDropdownItems(pageSizeOptions: Array<number>) {
    return pageSizeOptions.map(
        (option: number) =>
            ({
                id: option,
                name: String(option),
            } as DropdownItem),
    );
}

export function validatePaginationPage(pageQueryValue: any): boolean {
    const isNum = isValidNumber(pageQueryValue);
    if (!isNum) {
        return false;
    }

    const pageQueryValueNum = +pageQueryValue;

    return pageQueryValueNum >= 1;
}

export function validatePaginationItemsCount(itemsCountQueryValue: any): boolean {
    const isNum = isValidNumber(itemsCountQueryValue);
    if (!isNum) {
        return false;
    }

    const itemsCountQueryValueNum = +itemsCountQueryValue;

    return DEFAULT_PAGINATION_PAGE_SIZE_OPTIONS.includes(itemsCountQueryValueNum);
}

export function getPageFromPaginationQuery(pageQueryValue: any, defaultValue?: number): number {
    if (!validatePaginationPage(pageQueryValue)) {
        return defaultValue ?? Number.NaN;
    }

    return +pageQueryValue - 1;
}

export const getItemsCountFromPaginationQuery = (
    itemsCountQueryValue: any,
    defaultValue?: number,
): number => {
    if (!validatePaginationItemsCount(itemsCountQueryValue)) {
        return defaultValue ?? Number.NaN;
    }

    return +itemsCountQueryValue;
};

export function getDefaultPaginationInfo(): PaginationInfo {
    return {
        page: DEFAULT_PAGINATION_PAGE,
        itemCount: DEFAULT_PAGINATION_PAGE_SIZE,
    };
}
