import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ActivityMap, RankedColumnType } from 'state-domains/domain';
import { editRankedColumnUrl } from 'state-domains/network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from 'state-domains/utils';

export const editRankedColumn = (
    activityId: string,
    rankedColumnId: string,
    payload: RankedColumnType,
): Observable<{ activity: ActivityMap }> => {
    const url = editRankedColumnUrl(activityId, rankedColumnId);
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<{ activity: ActivityMap }>) =>
            observableOf(convertToCamel<{ activity: ActivityMap }>(response)),
        ),
    );
};
