type QueryParams = Partial<Record<string, string | number>>;

/**
 * Adds query parameters to a given url. Existing query params will remain.
 * Will URI encode query values.
 *
 * Normally query params would be added with `URL.searchParams.append`. However with this
 * approach it automatically encodes the value, and replaces spaces with `+`. Some of our python APIs
 * expect spaces to be encoded as `%20` so the default encoding for this method does not suit.
 *
 * Therefore this function will manually parse and update query params, and use the built in
 * encodeURIComponent function for encoding values.
 *
 * @param {string} url The url to add query params to.
 * @param {QueryParams} queryParams A query param key-value mapping.
 */
export function addQueryParams(url: string, queryParams: QueryParams): string {
    const urlObject = new URL(url);
    const encodedKeyPairs = Array.from(generateKeyPairs(urlObject, queryParams));

    if (encodedKeyPairs.length > 0) {
        urlObject.search = `?${encodedKeyPairs.join('&')}`;
    }

    return urlObject.href;
}

function* generateKeyPairs(url: URL, queryParams: QueryParams) {
    // Yield any existing query params.
    // We use `any` here as the types seem to be broken for `URL.searchParams.entries()`.
    for (const [key, value] of Array.from((url.searchParams as any).entries()) as any) {
        if (value !== undefined) {
            yield `${key}=${encodeURIComponent(String(value))}`;
        }
    }

    // Yield new query params.
    for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined) {
            yield `${key}=${encodeURIComponent(String(value))}`;
        }
    }
}

/**
 * Builds a url for the given endpoint.
 * Strings passed to the function will be added to the url path.
 *
 * This function will automatically add `/` and filter out any `//` from the path.
 *
 * @param baseEndpoint The base url to build the path from.
 * @param {string[]} pathElements Elements to combine to create the url path.
 */
export function buildUrl(baseEndpoint: string, ...pathElements: string[]): string {
    const url = parseUrl(baseEndpoint);
    const pathParts: string[] = [...splitAndFilterPath(url.pathname)];
    pathElements.forEach((element) => {
        pathParts.push(...splitAndFilterPath(element));
    });
    url.pathname = pathParts.join('/');
    return url.href;
}

function parseUrl(baseEndpoint: string): URL {
    try {
        return new URL(baseEndpoint);
    } catch {
        return new URL(location.origin);
    }
}

function splitAndFilterPath(path: string): string[] {
    let filtered: string[] = [];
    if (path) {
        const pathParts = path.split('/');
        filtered = pathParts.filter((part) => part.length > 0);
    }

    return filtered;
}

/**
 * Maps an object into the other object with included urlSegment property
 * @param target -- object to map
 * @param keysToTransform -- keys of the target object which is used to create a url segment
 * @param transformFn -- a function which is used to transform target properties into url segment
 */
export function mapWithUrlSegment<T extends Record<string, any>>(
    target: T,
    keysToTransform: Array<keyof T>,
    transformFn: (...args: any[]) => string,
): T & { urlSegment: string } {
    const valuesToTransform = keysToTransform.map((key) => target[key]);
    return {
        ...target,
        urlSegment: transformFn(...valuesToTransform),
    };
}
