import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { FileObject } from 'state-domains/domain/drillhole';

import { buildListFileGroupListUrl } from '../../../network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteListFile = (groupId: string, fileId: string): Observable<FileObject> => {
    const url = buildListFileGroupListUrl(groupId, fileId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<FileObject>) => {
            const converted = convertToCamel<FileObject>(response);
            return observableOf(converted);
        }),
    );
};
