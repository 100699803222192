import Cookies from 'js-cookie';

const COOKIE_SET_DEFAULT_OPTIONS = { secure: true };

export const getCookie = (cookieName: string) => Cookies.get(cookieName);

export const removeCookie = (cookieName: string) => Cookies.remove(cookieName);

export const setCookieByName = (name: string, value: string) => {
    removeCookie(name);
    Cookies.set(name, value, COOKIE_SET_DEFAULT_OPTIONS);
};

const XSRF_TOKEN_COOKIE_NAME = '_xsrf';
export const getXSRFToken = () => getCookie(XSRF_TOKEN_COOKIE_NAME);
