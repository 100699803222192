import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';
import { Row } from 'state-domains/domain';

import { buildSaveCoordinatesUrl, buildSaveCoordinatesPointsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveCoordinatesTable = (
    drillholeId: string,
    payload: any,
    rowReferenceId: string,
    type: ACTIVITY_TYPES,
): Observable<Row> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildSaveCoordinatesUrl(drillholeId, rowReferenceId)
            : buildSaveCoordinatesPointsUrl(drillholeId, rowReferenceId);
    return sendRequestWithXSRFToken(url, payload, rowReferenceId ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedResponse = convertToCamel<Row>(response);
            return observableOf(convertedResponse);
        }),
    );
};
