import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { FilterObject } from 'state-domains/domain/drillhole';
import { buildConfigurationProjectsUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ConfigurationProjects, ConfigurationProject } from '../types';

export const loadConfigurationProjects = (
    filters: FilterObject[],
): Observable<{ items: ConfigurationProject[] }> => {
    const obs = sendRetrieveRequest(buildConfigurationProjectsUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationProjects>) => {
            const configurationProjects = convertToCamel<ConfigurationProject[]>(response.Project);
            return observableOf({
                items: configurationProjects,
            });
        }),
    );
};
