import { i18nActionConstants } from 'state-domains/constants/mxdeposit.i18n';

export const i18n = {
    EditMarkerTooltip: {
        id: 'maps.edit.marker-tooltip',
        defaultMessage: "Click the 'Set Location' button to save this location",
    },
    EmptyListText: {
        id: 'maps.overview.empty-list-text',
        defaultMessage:
            "No projects have a valid location. Project owners and editors can set a project's location from the project overview page.",
    },
    EmptyListTitle: {
        id: 'maps.overview.empty-list-title',
        defaultMessage: 'No Locations',
    },
    ViewLocation: {
        id: 'maps.overview.view-location',
        defaultMessage: 'View Location',
    },
    OpenProject: {
        id: 'maps.overview.open-project',
        defaultMessage: 'Open Project',
    },
    OsmTitle: {
        id: 'maps.title.colour',
        defaultMessage: 'Street Maps',
    },
    SatelliteTitle: {
        id: 'maps.title.satellite',
        defaultMessage: 'Satellite Maps',
    },
    BoundaryControlDraw: {
        id: 'maps.boundary-control.draw',
        defaultMessage: 'Draw',
    },
    BoundaryControlEdit: i18nActionConstants.edit,
    BoundaryControlDelete: i18nActionConstants.delete,
    BoundaryControlCancel: i18nActionConstants.cancel,
};
