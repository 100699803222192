import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildListFileGroupUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';
import { ListFileGroup } from '../types';

export const addListFileGroup = (payload: any): Observable<ListFileGroup> => {
    const url = buildListFileGroupUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ListFileGroup>) => {
            const fileGroup = convertToCamel<ListFileGroup>(response);
            return observableOf(fileGroup);
        }),
    );
};
