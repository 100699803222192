export const i18n = {
    body: {
        id: 'Login.sidebar.body',
        defaultMessage: `
<h1>Welcome!</h1>
<p>Collect, manage, share, and access your drill hole and sample data in the Cloud.</p>
<p><a1>Learn more about MX Deposit</a1></p>

<h1>Training and Support</h1>
<p>We have a wide range of help and support available online.</p>
<p><a2>Getting Started with MX Deposit</a2></p>
<p><a3>MX Deposit Help</a3></p>
<p>Learn more about <a4>training options and resources available</a4></p>

<h1>Questions?</h1>
<p>Still have questions? Our dedicated team of specialists will be able to help you as needed.</p>
<p><a5>Contact Support</a5></p>
        `,
    },
};

export const templateValues = {
    learnMoreUrl: 'https://www.seequent.com/products-solutions/mx-deposit/',
    onboardingUrl: 'https://www.seequent.com/help-support/mx-deposit-onboarding/',
    supportUrl: 'https://www.seequent.com/help-support/mx-deposit-help-and-support/',
    trainingUrl: 'https://www.seequent.com/help-support/training/',
    supportRequestUrl: 'https://my.seequent.com/support/request/',
};
