import { createSelector } from 'reselect';

import {
    LOAD_STATUS_COMPLETE,
    LOAD_STATUS_FAILED,
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    UPLOAD_PROGRESS,
} from '../constants';
import { AsyncState, AsyncStateError } from '../types';

export { getStore, getState, injectReducer, setupStore } from '../store';

export const passReducer = <T>(state: T): T => state;
// pending/completed/fail type state helpers
export const typeStale = (type: string) => `${type}.${LOAD_STATUS_STALE}`;
export const typePending = (type: string) => `${type}.${LOAD_STATUS_PENDING}`;
export const typeComplete = (type: string) => `${type}.${LOAD_STATUS_COMPLETE}`;
export const typeFail = (type: string) => `${type}.${LOAD_STATUS_FAILED}`;
export const typeProgress = (type: string) => `${type}.${UPLOAD_PROGRESS}`;

// reducer map creator
const callHandler = (action: any, map: any, state: any) => {
    const { type = '' } = action || {};
    const handler = type ? map[type] : undefined;
    return handler ? handler(state, action) : state;
};

export const mappedReducer =
    (initState: any, handlerMap: any) =>
    (state = initState, action: any) =>
        callHandler(action, handlerMap, state);

export const failureReducer = <T>(
    state: T & { error: AsyncStateError | null }, // would be better if we did not allow null here
): T & AsyncState => ({
    ...state,
    status: LOAD_STATUS_FAILED,
});

export function completeReducer<T>(state: T): T & AsyncState {
    return {
        ...state,
        status: LOAD_STATUS_COMPLETE,
        error: null,
    };
}

export const pendingReducer = <T>(state: T): T & AsyncState => ({
    ...state,
    status: LOAD_STATUS_PENDING,
    error: null,
});

export const staleReducer = <T>(state: T): T & AsyncState => ({
    ...state,
    status: LOAD_STATUS_STALE,
    error: null,
});

export const isStale = (state: AsyncState | undefined): boolean => {
    const { status = '' } = state || {};
    return status === LOAD_STATUS_STALE;
};
export const isComplete = (state: AsyncState | undefined): boolean => {
    const { status = '' } = state || {};
    return status === LOAD_STATUS_COMPLETE;
};
export const isPending = (state: AsyncState | undefined): boolean => {
    const { status = '' } = state || {};
    return status === LOAD_STATUS_PENDING;
};
export const isFailed = (state: AsyncState | undefined): boolean => {
    const { status = '' } = state || {};
    return status === LOAD_STATUS_FAILED;
};

export const isCompleteSelector = (asyncState: (state: any) => AsyncState) =>
    createSelector(asyncState, (state: AsyncState) => isComplete(state));

export const isStaleSelector = (asyncState: (state: any) => AsyncState) =>
    createSelector(asyncState, (state: AsyncState) => isStale(state));

export const isFailedSelector = (asyncState: (state: any) => AsyncState) =>
    createSelector(asyncState, (state: AsyncState) => isFailed(state));

export const isPendingSelector = (asyncState: (state: any) => AsyncState) =>
    createSelector(asyncState, (state: AsyncState) => isPending(state));
