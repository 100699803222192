import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildImportDefaultLabServicesUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const importSampleCodes = (payload: any): Observable<string[]> => {
    const url = buildImportDefaultLabServicesUrl();
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<any>) => observableOf(response.importLogs)),
    );
};
