import { MessageDescriptor } from 'react-intl';

export const i18n: Record<string, MessageDescriptor> = {
    errorGeneral: {
        id: 'errors.general',
        defaultMessage: 'An unexpected error has occurred.',
    },
    isProjectFailed: {
        id: 'errors.project',
        defaultMessage: 'Error: Could not retrieve project listing.',
    },
    isProjectUpdateFailed: {
        id: 'errors.project-update',
        defaultMessage: 'Error: Project update failed.',
    },
    isUserFailed: {
        id: 'errors.user',
        defaultMessage: 'Error: Could not retrieve user listing.',
    },
    isLicenseFailed: {
        id: 'errors.user',
        defaultMessage: 'Error: License information failed to load.',
    },
    isEventFailed: {
        id: 'errors.event',
        defaultMessage: 'Error: Unable to retrieve the events listing.',
    },
    isBranchesFailed: {
        id: 'errors.branches',
        defaultMessage: 'Error: Unable to retrieve the branch list for this project.',
    },
    isSelectedBranchFailed: {
        id: 'errors.selected-branch',
        defaultMessage: 'Error: Branch update failed.',
    },
    isPermissionFailed: {
        id: 'errors.permission',
        defaultMessage: 'Error: Unable to retrieve user permissions list.',
    },
    isPermissionUpdateFailed: {
        id: 'errors.project-update',
        defaultMessage: 'Error: Permission update failed.',
    },
    isFileFailed: {
        id: 'errors.file',
        defaultMessage: 'Error: Unable to retrieve files listing.',
    },
    isNotificationsAuthFailed: {
        id: 'errors.notificationsAuth',
        defaultMessage: 'Error: Unable to authenticate with notifications service.',
    },
    isPublicSceneFailed: {
        id: 'errors.publicScene',
        defaultMessage: 'There was a problem with a public scene. Please contact',
    },
    isAzureMapsFailed: {
        id: 'errors.azuremaps',
        defaultMessage: 'There was a problem with Satellite Maps, switching to Street Maps.',
    },
    supportTeam: {
        id: 'errors.supportTeam',
        defaultMessage: 'Our Support Team',
    },
    internalServerError: {
        id: 'errors.internal-server-error',
        defaultMessage: 'There was an error processing the request. Please contact support.',
    },
    gatewayTimeoutError: {
        id: 'errors.gateway-timeout',
        defaultMessage: 'Request submitted exceeded timeout. Please contact support.',
    },
    serverUnavailable: {
        id: 'errors.serverUnavailable',
        defaultMessage: 'Server unavailable. Please contact support.',
    },
    resourceNotFound: {
        id: 'errors.resourceNotFound',
        defaultMessage: 'Resource not found.',
    },
};
