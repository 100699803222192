import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { from as observableFrom, mergeMap, toArray } from 'rxjs';

import {
    processLoadedAccountInformation,
    processLoadedActivities,
    processLoadedActivityGroups,
    processLoadedColours,
    processLoadedCoordinates,
    processLoadedDefaultPermissions,
    processLoadedFilterOperators,
    processLoadedHeaders,
    processLoadedHeaderTypes,
    processLoadedLists,
    processLoadedCategories,
    processLoadedSystemFilters,
    processLoadedTables,
    processLoadedTableViews,
    processLoadedSampleResultsRankedUnits,
    SubscriptionActionNames,
    processLoadedSampleDispatchHeaders,
    processLoadedSampleWorkflows,
} from 'state-domains/domain/subscription/utils';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';

import { DataRowType } from 'src/components/DataTable';

import {
    CLEAR_ALL_CONFIGURATION_ITEMS,
    SELECT_ALL_CONFIGURATION_ITEMS,
    SELECT_CONFIGURATION_ITEMS,
    UNSELECT_CONFIGURATION_ITEMS,
    LOAD_ACCOUNT_INFORMATION,
    LOAD_ACCOUNT_ACTIVITIES,
    LOAD_TABLE_VIEWS,
    LOAD_TABLES,
    LOAD_LISTS,
    LOAD_LIST_CATEGORIES,
    LOAD_HEADERS,
    LOAD_HEADER_TYPES,
    LOAD_SYSTEM_FILTERS,
    LOAD_FILTER_OPERATORS,
    LOAD_DEFAULT_PERMISSIONS,
    LOAD_COORDINATES,
    LOAD_ACTIVITY_GROUPS,
    LOAD_SUBSCRIPTION_STATE,
    ADD_SNACKBAR_NOTIFICATION,
    LOAD_TABLE_CATEGORIES,
    LOAD_HEADER_CATEGORIES,
    LOAD_COLOURS,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE,
    LOAD_LIST_WITH_ID,
    LOAD_ACTIVITY_CATEGORIES,
    LOAD_SAMPLE_DISPATCH_HEADERS,
    LOAD_SAMPLE_RESULTS_RANKED_UNITS,
    LOAD_SAMPLE_WORKFLOWS,
    LOAD_RELATED_COLLECTIONS_STATE,
    LOAD_ACCOUNT_ACTIVITY,
} from '../../../types/actionTypes';
import { subscriptionShim } from '../shim';
import { ActivityMap, LoadCollectionType, ModifySubscriptionInfo, PayloadInfo } from '../types';

const loadAccountInformation = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_ACCOUNT_INFORMATION) });
    return subscriptionShim
        .loadAccountInformation(SubscriptionActionNames.loadAccountInformation, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_ACCOUNT_INFORMATION), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_ACCOUNT_INFORMATION), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};
const loadAccountActivities = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_ACCOUNT_ACTIVITIES) });
    return subscriptionShim
        .loadAccountActivities(SubscriptionActionNames.loadAccountActivities, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_ACCOUNT_ACTIVITIES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_ACCOUNT_ACTIVITIES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadAccountActivity = (activityId: string) => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_ACCOUNT_ACTIVITY) });
    return subscriptionShim.loadAccountActivity(activityId).subscribe({
        next: (response: { result: ActivityMap }) => {
            dispatch({ type: typeComplete(LOAD_ACCOUNT_ACTIVITY), payload: response });
        },
        error: (err: any) => {
            dispatch({ type: typeFail(LOAD_ACCOUNT_ACTIVITY), payload: err });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: err },
            });
        },
    });
};

const loadTableViews = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_TABLE_VIEWS) });
    return subscriptionShim
        .loadTableViews(SubscriptionActionNames.loadTableViews, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_TABLE_VIEWS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_TABLE_VIEWS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadTables = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_TABLES) });
    return subscriptionShim
        .loadTables(SubscriptionActionNames.loadTables, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_TABLES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_TABLES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadTableCategories = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_TABLE_CATEGORIES) });
    return subscriptionShim
        .loadListCategories(SubscriptionActionNames.loadTableCategories, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_TABLE_CATEGORIES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_TABLE_CATEGORIES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadHeaderCategories = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_HEADER_CATEGORIES) });
    return subscriptionShim
        .loadHeaderCategories(SubscriptionActionNames.loadHeaderCategories, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_HEADER_CATEGORIES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_HEADER_CATEGORIES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadLists = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_LISTS) });
    return subscriptionShim
        .loadLists(SubscriptionActionNames.loadLists, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_LISTS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_LISTS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadListWithId = (id: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_LIST_WITH_ID) });
    return subscriptionShim.loadList(id).subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(LOAD_LIST_WITH_ID), payload: response });
        },
        error: (err: any) => {
            dispatch({ type: typeFail(LOAD_LIST_WITH_ID), payload: err });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: err },
            });
        },
    });
};

const loadListCategories = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_LIST_CATEGORIES) });
    return subscriptionShim
        .loadListCategories(SubscriptionActionNames.loadListCategories, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_LIST_CATEGORIES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_LIST_CATEGORIES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadHeaders = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_HEADERS) });
    return subscriptionShim
        .loadHeaders(SubscriptionActionNames.loadHeaders, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_HEADERS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_HEADERS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadHeaderTypes = () => (dispatch: Dispatch, _getState: any) => {
    dispatch({ type: typePending(LOAD_HEADER_TYPES) });
    return subscriptionShim
        .loadHeaderTypes(SubscriptionActionNames.loadHeaderTypes, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_HEADER_TYPES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_HEADER_TYPES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadActivityCategories = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_ACTIVITY_CATEGORIES) });
    return subscriptionShim
        .loadActivityCategories(SubscriptionActionNames.loadActivityCategories, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_ACTIVITY_CATEGORIES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_LIST_CATEGORIES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadSystemFilters = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_SYSTEM_FILTERS) });
    return subscriptionShim
        .loadSystemFilters(SubscriptionActionNames.loadSystemFilters, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_SYSTEM_FILTERS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_SYSTEM_FILTERS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadFilterOperators = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_FILTER_OPERATORS) });
    return subscriptionShim
        .loadFilterOperators(SubscriptionActionNames.loadFilterOperators, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_FILTER_OPERATORS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_FILTER_OPERATORS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadDefaultPermissions = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_DEFAULT_PERMISSIONS) });
    return subscriptionShim
        .loadDefaultPermissions(SubscriptionActionNames.loadDefaultPermissions, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_DEFAULT_PERMISSIONS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_DEFAULT_PERMISSIONS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadCoordinates = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_COORDINATES) });
    return subscriptionShim
        .loadCoordinates(SubscriptionActionNames.loadCoordinates, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_COORDINATES), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_COORDINATES), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadActivityGroups = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_ACTIVITY_GROUPS) });
    return subscriptionShim
        .loadActivityGroups(SubscriptionActionNames.loadActivityGroups, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_ACTIVITY_GROUPS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_ACTIVITY_GROUPS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadColours = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_COLOURS) });
    return subscriptionShim
        .loadColours(SubscriptionActionNames.loadColours, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_COLOURS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_COLOURS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadSampleDispatchHeaders = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_SAMPLE_DISPATCH_HEADERS) });
    return subscriptionShim
        .loadSampleDispatchHeaders(SubscriptionActionNames.loadSampleDispatchHeaders, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(LOAD_SAMPLE_DISPATCH_HEADERS), payload: response });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_SAMPLE_DISPATCH_HEADERS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadSampleResultsRankedUnits = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_SAMPLE_RESULTS_RANKED_UNITS) });
    return subscriptionShim
        .loadSampleResultsRankedUnits(
            SubscriptionActionNames.loadSampleResultsRankedUnits,
            () => {},
        )
        .subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_SAMPLE_RESULTS_RANKED_UNITS),
                    payload: response,
                });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(LOAD_SAMPLE_RESULTS_RANKED_UNITS), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
};

const loadSampleWorkflows = (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_SAMPLE_WORKFLOWS) });
    return subscriptionShim
        .loadSampleWorkflows(SubscriptionActionNames.loadSampleWorkflows, () => {})
        .subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_SAMPLE_WORKFLOWS),
                    payload: response,
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_SAMPLE_WORKFLOWS), payload: { error } });
            },
        });
};

export const loadRelatedCollections =
    (collections: LoadCollectionType[]) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(LOAD_RELATED_COLLECTIONS_STATE) });
        return observableFrom(collections)
            .pipe(
                mergeMap(
                    (subShim: {
                        action: Function;
                        processResultFunction: Function;
                        actionName: string;
                    }) => {
                        const { actionName, processResultFunction } = subShim;
                        return subShim.action(actionName, processResultFunction);
                    },
                    5,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(LOAD_RELATED_COLLECTIONS_STATE),
                        payload: response,
                    });
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(LOAD_RELATED_COLLECTIONS_STATE),
                        payload: { error },
                    });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

export const loadSubscription = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_SUBSCRIPTION_STATE) });
    const subscription = observableFrom([
        {
            action: subscriptionShim.loadAccountInformation,
            processResultFunction: processLoadedAccountInformation,
            actionName: SubscriptionActionNames.loadAccountInformation,
        },
        {
            action: subscriptionShim.loadAccountActivities,
            processResultFunction: processLoadedActivities,
            actionName: SubscriptionActionNames.loadAccountActivities,
        },
        {
            action: subscriptionShim.loadTableViews,
            processResultFunction: processLoadedTableViews,
            actionName: SubscriptionActionNames.loadTableViews,
        },
        {
            action: subscriptionShim.loadTableCategories,
            processResultFunction: processLoadedCategories,
            actionName: SubscriptionActionNames.loadTableCategories,
        },
        {
            action: subscriptionShim.loadHeaderCategories,
            processResultFunction: processLoadedCategories,
            actionName: SubscriptionActionNames.loadHeaderCategories,
        },
        {
            action: subscriptionShim.loadTables,
            processResultFunction: processLoadedTables,
            actionName: SubscriptionActionNames.loadTables,
        },
        {
            action: subscriptionShim.loadLists,
            processResultFunction: processLoadedLists,
            actionName: SubscriptionActionNames.loadLists,
        },
        {
            action: subscriptionShim.loadListCategories,
            processResultFunction: processLoadedCategories,
            actionName: SubscriptionActionNames.loadListCategories,
        },
        {
            action: subscriptionShim.loadHeaders,
            processResultFunction: processLoadedHeaders,
            actionName: SubscriptionActionNames.loadHeaders,
        },
        {
            action: subscriptionShim.loadHeaderTypes,
            processResultFunction: processLoadedHeaderTypes,
            actionName: SubscriptionActionNames.loadHeaderTypes,
        },
        {
            action: subscriptionShim.loadSystemFilters,
            processResultFunction: processLoadedSystemFilters,
            actionName: SubscriptionActionNames.loadSystemFilters,
        },
        {
            action: subscriptionShim.loadDefaultPermissions,
            processResultFunction: processLoadedDefaultPermissions,
            actionName: SubscriptionActionNames.loadDefaultPermissions,
        },
        {
            action: subscriptionShim.loadFilterOperators,
            processResultFunction: processLoadedFilterOperators,
            actionName: SubscriptionActionNames.loadFilterOperators,
        },
        {
            action: subscriptionShim.loadCoordinates,
            processResultFunction: processLoadedCoordinates,
            actionName: SubscriptionActionNames.loadCoordinates,
        },
        {
            action: subscriptionShim.loadActivityGroups,
            processResultFunction: processLoadedActivityGroups,
            actionName: SubscriptionActionNames.loadActivityGroups,
        },
        {
            action: subscriptionShim.loadColours,
            processResultFunction: processLoadedColours,
            actionName: SubscriptionActionNames.loadColours,
        },
        {
            action: subscriptionShim.loadActivityCategories,
            processResultFunction: processLoadedCategories,
            actionName: SubscriptionActionNames.loadActivityCategories,
        },
        {
            action: subscriptionShim.loadSampleResultsRankedUnits,
            processResultFunction: processLoadedSampleResultsRankedUnits,
            actionName: SubscriptionActionNames.loadSampleResultsRankedUnits,
        },
        {
            action: subscriptionShim.loadSampleDispatchHeaders,
            processResultFunction: processLoadedSampleDispatchHeaders,
            actionName: SubscriptionActionNames.loadSampleDispatchHeaders,
        },
        {
            action: subscriptionShim.loadSampleWorkflows,
            processResultFunction: processLoadedSampleWorkflows,
            actionName: SubscriptionActionNames.loadSampleWorkflows,
        },
    ]);
    return subscription
        .pipe(
            mergeMap(
                (subShim: {
                    action: Function;
                    processResultFunction: Function;
                    actionName: string;
                }) => {
                    const { actionName, processResultFunction } = subShim;
                    return subShim.action(actionName, processResultFunction);
                },
                5,
            ),
        )
        .pipe(toArray())
        .subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_SUBSCRIPTION_STATE),
                    payload: response,
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(LOAD_SUBSCRIPTION_STATE),
                    payload: { error },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

export const replaceItemInSubscriptionCollection =
    (item: any, key: string, type = 'PUT') =>
    (dispatch: Dispatch) => {
        dispatch({
            type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
            payload: { id: item.id, item, type, collectionName: key },
        });
    };

export const updateSubscriptionSubState =
    (stateName: string, id: string, item: any, replaceKey: string, deleteIds = []) =>
    (dispatch: Dispatch) => {
        dispatch({
            type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
            payload: {
                collectionName: stateName,
                id,
                item,
                keyInCollection: replaceKey,
                deleteIds,
            },
        });
    };

export const modifySubscriptionCollection =
    (payloadInfo: PayloadInfo, subscriptionName: string) => (dispatch: Dispatch) => {
        const collectionMap: { [id: string]: ModifySubscriptionInfo } = {
            [SubscriptionActionNames.saveColour]: {
                action: subscriptionShim.saveColour,
                type: 'POST',
                stateName: 'customColours',
            },
            [SubscriptionActionNames.deleteColour]: {
                action: subscriptionShim.deleteColour,
                type: 'DELETE',
                stateName: 'customColours',
            },
        };

        const callInfo = collectionMap[subscriptionName];
        dispatch({ type: typePending(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE) });

        return callInfo.action(payloadInfo).subscribe({
            next: (item: any) => {
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: item.id,
                        item,
                        type: callInfo.type,
                        collectionName: callInfo.stateName,
                    },
                });
            },
            error: (err: any) => {
                dispatch({
                    type: typeFail(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: err,
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
    };
const selectAllItems = (items: DataRowType[]) => (dispatch: any, _getState: any) => {
    dispatch({
        type: SELECT_ALL_CONFIGURATION_ITEMS,
        payload: { items },
    });
};
const clearAllItems = () => (dispatch: any, _getState: any) => {
    dispatch({ type: CLEAR_ALL_CONFIGURATION_ITEMS });
};

const selectItems = (items: DataRowType[]) => (dispatch: any) => {
    dispatch({
        type: SELECT_CONFIGURATION_ITEMS,
        payload: { items },
    });
};

const unSelectItems = (items: DataRowType[]) => (dispatch: any, _getState: any) => {
    dispatch({
        type: UNSELECT_CONFIGURATION_ITEMS,
        payload: { items },
    });
};
export const actions = {
    selectAllItems,
    clearAllItems,
    selectItems,
    unSelectItems,
    loadAccountInformation,
    loadAccountActivities,
    loadTableViews,
    loadTables,
    loadTableCategories,
    loadLists,
    loadListWithId,
    loadListCategories,
    loadHeaderCategories,
    loadHeaders,
    loadHeaderTypes,
    loadSystemFilters,
    loadFilterOperators,
    loadDefaultPermissions,
    loadCoordinates,
    loadActivityGroups,
    loadActivityCategories,
    loadColours,
    loadSubscription,
    modifySubscriptionCollection,
    replaceItemInSubscriptionCollection,
    updateSubscriptionSubState,
    loadSampleResultsRankedUnits,
    loadSampleDispatchHeaders,
    loadSampleWorkflows,
    loadRelatedCollections,
    loadAccountActivity,
};
