export const i18n = {
    ariaLabelPrevious: {
        id: 'pagination-controls.aria-label-previous',
        defaultMessage: 'Previous Page',
    },
    ariaLabelNext: {
        id: 'pagination-controls.aria-label-next',
        defaultMessage: 'Next Page',
    },
};
