import { imagoCheckConnection } from './imagoCheckConnection';
import { imagoDisconnect } from './imagoDisconnect';
import { imagoLogin } from './imagoLogin';
import { imagoSync } from './imagoSync';
import { imagoUserContext } from './imagoUserContext';
import { seequentCloudConfiguration } from './seequentCloudConfiguration';

export const integrationShim = {
    imagoLogin,
    imagoCheckConnection,
    imagoDisconnect,
    imagoSync,
    seequentCloudConfiguration,
    imagoUserContext,
};
