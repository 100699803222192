import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildEditSampleWorkflowsUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveWorkflow = (id: string, payload: any): Observable<any> =>
    sendRequestWithXSRFToken(buildEditSampleWorkflowsUrl(id), payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedWorkflow = convertToCamel<any>(response);
            return observableOf(convertedWorkflow);
        }),
    );
