import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
    CONFIGURATION_TYPES,
    MODULES,
    SAMPLE_ANALYSIS_VALUE,
    SAMPLE_PREP_VALUE,
} from 'state-domains/constants';
import { projectState, userState } from 'state-domains/domain';

import { PathConstants } from './pathConstants';
import {
    CONFIGURATION_LOGGING_PATH,
    CONFIGURATION_LISTS_ITEM_PATH,
    CONFIGURATION_TABLES_ITEM_PATH,
    getSubscriptionObject,
    PREFERENCES_PATH,
    RemoveTrailingSlash,
    SELECTED_DRILLHOLE_PATH,
    SELECTED_DRILLHOLE_TABLEVIEW_PATH,
    SELECTED_POINTS_PATH,
    SELECTED_POINTS_TABLEVIEW_PATH,
    SELECTED_PROJECT_PATH,
    CONFIGURATION_HEADERS_ITEM_PATH,
    CONFIGURATION_FIELDS_ITEM_PATH,
    CONFIGURATION_LISTS_IMPORT_PATH,
    CONFIGURATION_ACTIVITIES_PROJECTS_PATH,
    CONFIGURATION_ACTIVITIES_ITEM_PATH,
    CONFIGURATION_ACTIVITIES_ITEM_CONFIGURE_LISTS_PATH,
    CONFIGURATION_ACTIVITIES_ITEM_ASSOCIATE_COLUMNS_PATH,
    CONFIGURATION_PROJECTS_ITEM_PATH,
    CONFIGURATION_EDIT_PROJECT_BOUNDARIES_PATH,
    CONFIGURATION_EDIT_PROJECT_PERMISSIONS_PATH,
    CONFIGURATION_SAMPLING_PATH,
    CONFIGURATION_SAMPLING_LISTS_ITEM_PATH,
    CONFIGURATION_SAMPLING_DISPATCH_HEADERS_ITEM_PATH,
    CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PATH,
    CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PREP_PACKAGES,
    CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_ANALYSIS_PACKAGES,
    CONFIGURATION_SAMPLING_LISTS_IMPORT_FROM_LIBRARY_PATH,
    CONFIGURATION_ACTIVITY_GROUPS_PATH,
    CONFIGURATION_GRIDS_ITEM_PATH,
    CONFIGURATION_GRIDS_PREVIEW_PATH,
    CONFIGURATION_GRIDS_SELECT_FROM_LIST,
    CONFIGURATION_ACCOUNT_PATH,
} from './Utils';

const Logout = React.lazy(() => import('src/components/Logout/Logout'));
const Notifications = React.lazy(() => import('src/components/Notifications/Notifications'));
const Home = React.lazy(() => import('src/components/Home/Home'));
const Preferences = React.lazy(() => import('src/components/Preferences/Preferences'));
const ProjectListing = React.lazy(
    () => import('src/components/Projects/ProjectListing/ProjectListing'),
);
const ProjectLanding = React.lazy(
    () => import('src/components/Projects/ProjectLanding/ProjectLanding'),
);
const ProjectOverview = React.lazy(
    () => import('src/components/Projects/ProjectOverview/ProjectOverview'),
);
const DrillHoleTableLanding = React.lazy(
    () => import('src/components/Drillhole/DrillholeTableLanding/DrillholeTableLandingBase'),
);
const DrillholeListing = React.lazy(
    () => import('src/components/Drillhole/DrillholeListing/DrillholeListing'),
);
const PointDataListing = React.lazy(
    () => import('src/components/Drillhole/PointDataListing/PointDataListing'),
);
const EventLanding = React.lazy(() => import('src/components/Events/EventLanding/EventLanding'));
const JobListing = React.lazy(() => import('src/components/Events/Jobs/JobListing/JobListing'));
const ConfigurationLanding = React.lazy(() => import('src/components/Configuration/Configuration'));
const ConfigurationLogging = React.lazy(
    () => import('src/components/Configuration/Logging/Logging'),
);

const ConfigurationActivitiesProjects = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/ActivitiesProjects'),
);

const ConfigurationSampling = React.lazy(
    () => import('src/components/Configuration/Sampling/Sampling'),
);

const ListsMasterDetail = React.lazy(
    () => import('src/components/Configuration/Logging/Lists/ListsMasterDetail/ListsMasterDetail'),
);
const ImportMasterDetail = React.lazy(
    () =>
        import('src/components/Configuration/Logging/Lists/ImportMasterDetail/ImportMasterDetail'),
);
const ConfigurationLists = React.lazy(
    () => import('src/components/Configuration/Logging/Lists/Lists'),
);
const ConfigurationFields = React.lazy(
    () => import('src/components/Configuration/Logging/Fields/Fields'),
);
const TablesMasterDetail = React.lazy(
    () =>
        import('src/components/Configuration/Logging/Tables/TablesMasterDetail/TablesMasterDetail'),
);
const ConfigurationTables = React.lazy(
    () => import('src/components/Configuration/Logging/Tables/Tables'),
);

const ConfigurationHeaders = React.lazy(
    () => import('src/components/Configuration/Logging/Headers/Headers'),
);

const HeadersMasterDetail = React.lazy(
    () =>
        import(
            'src/components/Configuration/Logging/Headers/HeadersMasterDetail/HeadersMasterDetail'
        ),
);

const ActivitiesMasterDetail = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Activities/ActivitiesMasterDetail/ActivitiesMasterDetail'
        ),
);

const ActivitiesMoreDetails = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Activities/ActivitiesMasterDetail/ActivitiesMoreDetails'
        ),
);

const ProjectsMoreDetails = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Projects/ProjectsMasterDetail/ProjectsMoreDetails'
        ),
);

const ConfigurationActivities = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/Activities/Activities'),
);

const ConfigurationActivityGroups = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/ActivityGroups/ActivityGroups'),
);

const ConfigurationProjects = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/Projects/Projects'),
);
const ConfigurationGrids = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/Grids/Grids'),
);

const NotFound = React.lazy(() => import('src/components/GenericError/GenericError'));
const ProjectsMasterDetail = React.lazy(
    () => import('src/components/Configuration/ActivitiesProjects/Projects/ProjectsMasterDetail'),
);
const FieldsMasterDetail = React.lazy(
    () => import('src/components/Configuration/Logging/Fields/FieldsMasterDetail'),
);

const ConfigurationSamplingAnalysisWorkflows = React.lazy(
    () => import('src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflows'),
);

const ConfigurationSamplingAnalysisWorkflowsMasterDetail = React.lazy(
    () =>
        import(
            'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowMasterDetail'
        ),
);

const ConfigurationSamplingAnalysisWorkflowsMoreDetails = React.lazy(
    () =>
        import(
            'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowMoreDetails'
        ),
);

const ConfigurationSampleListsImportFromLibrary = React.lazy(
    () => import('src/components/Configuration/Logging/Lists/ImportFromLibrary/ImportFromLibrary'),
);

const GridsMasterDetail = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Grids/GridsMasterDetail/GridsMasterDetail'
        ),
);

const GridsMoreDetail = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Grids/GridsMasterDetail/GridsMoreDetail'
        ),
);

const SelectGridsFromList = React.lazy(
    () =>
        import(
            'src/components/Configuration/ActivitiesProjects/Grids/SelectFromList/SelectFromList'
        ),
);

const ConfigurationAccount = React.lazy(
    () => import('src/components/Configuration/Account/Account'),
);
const ConfigurationUsers = React.lazy(
    () => import('src/components/Configuration/Account/Users/Users'),
);

const ConfigurationCategories = React.lazy(
    () => import('src/components/Configuration/Account/Categories/Categories'),
);

export const MXDepositRoutes = () => {
    const {
        selectors: { selectedProjectPermissions: selectedProjectPermissionsSelector },
    } = projectState;
    const {
        selectors: {
            currentSubscription: currentSubscriptionSelector,
            subscriptions: subscriptionsSelector,
        },
    } = userState;
    const projectPermissions = useSelector(selectedProjectPermissionsSelector);
    const currentSubscription = useSelector(currentSubscriptionSelector);
    const subscriptions = useSelector(subscriptionsSelector);
    const currentSubscriptionObject = getSubscriptionObject(currentSubscription, subscriptions);
    const hasDrillholePermission = !isEmpty(projectPermissions)
        ? (projectPermissions[MODULES.DRILL_HOLES] ?? []).length > 0
        : true;
    const hasPointsPermission = !isEmpty(projectPermissions)
        ? (projectPermissions[MODULES.POINT_SAMPLES] ?? []).length > 0
        : true;
    const { mxdSiteSettings, mxdProjectOverview } = useFlags();
    return (
        <>
            <RemoveTrailingSlash />
            <Routes>
                <Route
                    path={PathConstants.BASE.ROOT}
                    element={<Navigate to={PathConstants.HOME.ROOT} />}
                />
                <Route path={PathConstants.HOME.ROOT} element={<Home />} />
                <Route path={PREFERENCES_PATH} element={<Preferences />} />
                <Route
                    path={PathConstants.LOGIN.ROOT}
                    element={<Navigate to={PathConstants.HOME.ROOT} />}
                />
                <Route path={PathConstants.LOGOUT.ROOT} element={<Logout />} />
                <Route path={PathConstants.LOGOUT_SUCCESS.ROOT} element={<Logout />} />
                <Route path={PathConstants.NOTIFICATIONS.ROOT} element={<Notifications />} />
                <Route path={PathConstants.PROJECTS.ROOT} element={<ProjectListing />} />
                <Route path={SELECTED_PROJECT_PATH} element={<ProjectLanding />}>
                    {(hasDrillholePermission || hasPointsPermission) && (
                        <>
                            {/* This is used to put the routes in correctly depending on the overview flag */}
                            ...{mxdProjectOverview && <Route index element={<ProjectOverview />} />}
                            ...
                            {!mxdProjectOverview && hasDrillholePermission ? (
                                <Route
                                    index
                                    element={
                                        <Navigate to={PathConstants.PROJECTS.DRILLHOLE.ROOT} />
                                    }
                                />
                            ) : (
                                <Route
                                    index
                                    element={<Navigate to={PathConstants.PROJECTS.POINTS.ROOT} />}
                                />
                            )}
                            {hasDrillholePermission && (
                                <Route
                                    path={PathConstants.PROJECTS.DRILLHOLE.ROOT}
                                    element={<DrillholeListing />}
                                />
                            )}
                            {hasPointsPermission && (
                                <Route
                                    path={PathConstants.PROJECTS.POINTS.ROOT}
                                    element={<PointDataListing />}
                                />
                            )}
                        </>
                    )}
                </Route>
                {hasDrillholePermission && (
                    <>
                        <Route path={SELECTED_DRILLHOLE_PATH} element={<DrillHoleTableLanding />} />
                        <Route
                            path={SELECTED_DRILLHOLE_TABLEVIEW_PATH}
                            element={<DrillHoleTableLanding />}
                        />
                    </>
                )}
                {hasPointsPermission && (
                    <>
                        <Route path={SELECTED_POINTS_PATH} element={<DrillHoleTableLanding />} />
                        <Route
                            path={SELECTED_POINTS_TABLEVIEW_PATH}
                            element={<DrillHoleTableLanding />}
                        />
                    </>
                )}
                <Route path={PathConstants.EVENTS.ROOT} element={<EventLanding />}>
                    <Route index element={<Navigate to={PathConstants.EVENTS.JOBS.ROOT} />} />
                    <Route path={PathConstants.EVENTS.JOBS.ROOT} element={<JobListing />} />
                </Route>
                {currentSubscriptionObject?.role === 'admin' && (
                    <>
                        <Route
                            path={PathConstants.CONFIGURATION.ROOT}
                            element={<ConfigurationLanding />}
                        />
                        <Route path={CONFIGURATION_LOGGING_PATH} element={<ConfigurationLogging />}>
                            <Route
                                index
                                element={
                                    <Navigate to={PathConstants.CONFIGURATION.LOGGING.LISTS.ROOT} />
                                }
                            />
                            <Route
                                path={PathConstants.CONFIGURATION.LOGGING.LISTS.ROOT}
                                element={
                                    <ConfigurationLists configType={CONFIGURATION_TYPES.LISTS} />
                                }
                            />
                            <Route
                                path={PathConstants.CONFIGURATION.LOGGING.FIELDS.ROOT}
                                element={
                                    <ConfigurationFields configType={CONFIGURATION_TYPES.FIELDS} />
                                }
                            />
                            <Route
                                path={PathConstants.CONFIGURATION.LOGGING.HEADERS.ROOT}
                                element={
                                    <ConfigurationHeaders
                                        configType={CONFIGURATION_TYPES.HEADERS}
                                    />
                                }
                            />
                            <Route
                                path={PathConstants.CONFIGURATION.LOGGING.TABLES.ROOT}
                                element={<ConfigurationTables />}
                            />
                        </Route>
                        <Route
                            path={CONFIGURATION_LISTS_ITEM_PATH}
                            element={<ListsMasterDetail configType={CONFIGURATION_TYPES.LISTS} />}
                        />
                        <Route
                            path={CONFIGURATION_LISTS_IMPORT_PATH}
                            element={<ImportMasterDetail />}
                        />
                        <Route
                            path={CONFIGURATION_TABLES_ITEM_PATH}
                            element={<TablesMasterDetail />}
                        />
                        <Route
                            path={CONFIGURATION_FIELDS_ITEM_PATH}
                            element={<FieldsMasterDetail />}
                        />
                        <Route
                            path={CONFIGURATION_HEADERS_ITEM_PATH}
                            element={
                                <HeadersMasterDetail configType={CONFIGURATION_TYPES.HEADERS} />
                            }
                        />
                        <Route
                            path={CONFIGURATION_ACTIVITIES_PROJECTS_PATH}
                            element={<ConfigurationActivitiesProjects />}
                        >
                            <Route
                                index
                                element={
                                    <Navigate
                                        to={
                                            PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS
                                                .ACTIVITIES.ROOT
                                        }
                                    />
                                }
                            />
                            <Route
                                path={
                                    PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ACTIVITIES.ROOT
                                }
                                element={<ConfigurationActivities />}
                            />
                            <Route
                                path={PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.PROJECTS.ROOT}
                                element={<ConfigurationProjects />}
                            />
                            {mxdSiteSettings && (
                                <>
                                    <Route
                                        path={CONFIGURATION_ACTIVITY_GROUPS_PATH}
                                        element={<ConfigurationActivityGroups />}
                                    />
                                    <Route
                                        path={
                                            PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.GRIDS
                                                .ROOT
                                        }
                                        element={<ConfigurationGrids />}
                                    />
                                </>
                            )}
                        </Route>
                        <Route
                            path={CONFIGURATION_ACTIVITIES_ITEM_PATH}
                            element={<ActivitiesMasterDetail />}
                        />
                        <Route
                            path={CONFIGURATION_PROJECTS_ITEM_PATH}
                            element={<ProjectsMasterDetail />}
                        />
                        <Route
                            path={CONFIGURATION_ACTIVITIES_ITEM_CONFIGURE_LISTS_PATH}
                            element={
                                <ActivitiesMoreDetails activitiesMoreDetailType="configure-lists" />
                            }
                        />
                        <Route
                            path={CONFIGURATION_ACTIVITIES_ITEM_ASSOCIATE_COLUMNS_PATH}
                            element={
                                <ActivitiesMoreDetails activitiesMoreDetailType="associate-columns" />
                            }
                        />
                        <Route
                            path={CONFIGURATION_EDIT_PROJECT_BOUNDARIES_PATH}
                            element={
                                <ProjectsMoreDetails projectsMoreDetailType="edit-boundaries" />
                            }
                        />
                        <Route
                            path={CONFIGURATION_EDIT_PROJECT_PERMISSIONS_PATH}
                            element={
                                <ProjectsMoreDetails projectsMoreDetailType="edit-permissions" />
                            }
                        />
                        {mxdSiteSettings && (
                            <>
                                <Route
                                    path={CONFIGURATION_SAMPLING_PATH}
                                    element={<ConfigurationSampling />}
                                >
                                    <Route
                                        index
                                        element={
                                            <Navigate
                                                to={PathConstants.CONFIGURATION.SAMPLING.LISTS.ROOT}
                                            />
                                        }
                                    />
                                    <Route
                                        path={PathConstants.CONFIGURATION.SAMPLING.LISTS.ROOT}
                                        element={
                                            <ConfigurationLists
                                                configType={CONFIGURATION_TYPES.SAMPLE_LISTS}
                                            />
                                        }
                                    />
                                    <Route
                                        path={
                                            PathConstants.CONFIGURATION.SAMPLING.DISPATCH_FIELDS
                                                .ROOT
                                        }
                                        element={
                                            <ConfigurationFields
                                                configType={CONFIGURATION_TYPES.SAMPLE_FIELDS}
                                            />
                                        }
                                    />
                                    <Route
                                        path={
                                            PathConstants.CONFIGURATION.SAMPLING.DISPATCH_HEADERS
                                                .ROOT
                                        }
                                        element={
                                            <ConfigurationHeaders
                                                configType={CONFIGURATION_TYPES.SAMPLE_HEADERS}
                                            />
                                        }
                                    />
                                    <Route
                                        path={
                                            PathConstants.CONFIGURATION.SAMPLING.ANALYSIS_WORKFLOWS
                                                .ROOT
                                        }
                                        element={<ConfigurationSamplingAnalysisWorkflows />}
                                    />
                                </Route>
                                <Route
                                    path={CONFIGURATION_SAMPLING_LISTS_ITEM_PATH}
                                    element={
                                        <ListsMasterDetail
                                            configType={CONFIGURATION_TYPES.SAMPLE_LISTS}
                                        />
                                    }
                                />
                                <Route
                                    path={CONFIGURATION_SAMPLING_LISTS_IMPORT_FROM_LIBRARY_PATH}
                                    element={<ConfigurationSampleListsImportFromLibrary />}
                                />
                                <Route
                                    path={CONFIGURATION_SAMPLING_DISPATCH_HEADERS_ITEM_PATH}
                                    element={
                                        <HeadersMasterDetail
                                            configType={CONFIGURATION_TYPES.SAMPLE_HEADERS}
                                        />
                                    }
                                />
                                <Route
                                    path={CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PATH}
                                    element={<ConfigurationSamplingAnalysisWorkflowsMasterDetail />}
                                />
                                <Route
                                    path={
                                        CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PREP_PACKAGES
                                    }
                                    element={
                                        <ConfigurationSamplingAnalysisWorkflowsMoreDetails
                                            serviceType={SAMPLE_PREP_VALUE}
                                        />
                                    }
                                />
                                <Route
                                    path={
                                        CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_ANALYSIS_PACKAGES
                                    }
                                    element={
                                        <ConfigurationSamplingAnalysisWorkflowsMoreDetails
                                            serviceType={SAMPLE_ANALYSIS_VALUE}
                                        />
                                    }
                                />
                                <Route
                                    path={CONFIGURATION_GRIDS_ITEM_PATH}
                                    element={<GridsMasterDetail />}
                                />
                                <Route
                                    path={CONFIGURATION_GRIDS_PREVIEW_PATH}
                                    element={<GridsMoreDetail />}
                                />
                                <Route
                                    path={CONFIGURATION_GRIDS_SELECT_FROM_LIST}
                                    element={<SelectGridsFromList />}
                                />
                                <Route
                                    path={CONFIGURATION_ACCOUNT_PATH}
                                    element={<ConfigurationAccount />}
                                >
                                    <Route
                                        index
                                        element={
                                            <Navigate
                                                to={PathConstants.CONFIGURATION.ACCOUNT.USERS.ROOT}
                                            />
                                        }
                                    />
                                    <Route
                                        path={PathConstants.CONFIGURATION.ACCOUNT.USERS.ROOT}
                                        element={<ConfigurationUsers />}
                                    />
                                    <Route
                                        path={PathConstants.CONFIGURATION.ACCOUNT.CATEGORIES.ROOT}
                                        element={<ConfigurationCategories />}
                                    />
                                </Route>
                            </>
                        )}
                    </>
                )}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};
