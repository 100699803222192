import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadDrillholeUrl, buildLoadPointUrl } from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRetrieveRequest } from '../../../utils';
import { Drillhole, DrillholeResponse } from '../types';

export const loadDrillhole = (
    drillholeId: string,
    type: ACTIVITY_TYPES,
    fields: string[] = [],
): Observable<Drillhole> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildLoadDrillholeUrl(drillholeId, fields)
            : buildLoadPointUrl(drillholeId, fields);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<DrillholeResponse>) => {
            const result = convertToCamelWithSpecialParameters<Drillhole>(response, {
                id: 'uid',
            });
            return observableOf(result);
        }),
    );
};
