import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildExportTableUrl } from 'state-domains/network';

import { convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const exportConfigurationTables = (payload: any): Observable<any> => {
    const obs = sendRequestWithXSRFToken(buildExportTableUrl(), convertToSnake(payload));
    return obs.pipe(switchMap(({ response }: AjaxResponse<any>) => observableOf({ response })));
};
