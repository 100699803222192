import { isEmpty } from 'lodash-es';
import store from 'store';

import {
    ACTIVITY_TYPES,
    COORDINATES_ID,
    COORDINATES_LABEL,
    DRILL_HOLE_STATES,
    MODULES,
    TableEditStatus,
    XRF_COLUMN,
} from 'state-domains/constants';
import {
    Activity,
    ActivityMap,
    Drillhole,
    DrillholeUser,
    DrillholeUserGroup,
    Project,
    Table,
    TableList,
    TableView,
    UserType,
    UserWithType,
} from 'state-domains/domain';

import {
    DrillholeTableInfo,
    DrillholeTableOptions,
} from 'src/components/Drillhole/Drillhole.utils.types';
import { ReadOnlyReasons } from 'src/utilities/types';

import {
    DEFAULT_PREFERENCES,
    Preferences,
} from '../Preferences/PreferencesContent/PreferencesContent.types';

export const checkCollarModulePermission = (
    currentProjectActivity: Activity,
    userId: string,
    permission: string,
    type: ACTIVITY_TYPES,
) => {
    const userPermissionObject = currentProjectActivity?.users?.[userId]?.userPermissions;
    if (userPermissionObject) {
        const moduleActions =
            userPermissionObject[
                type === ACTIVITY_TYPES.DRILLING ? MODULES.DRILL_HOLES : MODULES.POINT_SAMPLES
            ]?.moduleActions ?? [];
        for (const action of moduleActions) {
            if (action.code === permission) {
                return action.access;
            }
        }
    }
    return true;
};

export const getPreferences = (currentUserId: string): Preferences => {
    const preferencesKey = `preferences_${currentUserId}`;
    return store?.get(preferencesKey, DEFAULT_PREFERENCES);
};

export const setStorePreferences = (currentUserId: string, value: Preferences) => {
    const preferencesKey = `preferences_${currentUserId}`;
    store.set(preferencesKey, value);
};

const findTableViewsInActivity = (activity: ActivityMap) =>
    Array.from(
        new Set([
            ...(activity.samples ? [String(activity.samples)] : []),
            ...(activity.survey ? [String(activity.survey)] : []),
            ...(activity.lithology ? [String(activity.lithology)] : []),
            ...(activity.header ? [String(activity.header)] : []),
            ...Object.keys(activity.tableViewsSpecs),
        ]),
    );

export const tableContainsXRFColumn = (tableView: TableView, tables: { [id: string]: Table }) => {
    if (!isEmpty(tableView)) {
        if (tableView.singleTable) {
            if (XRF_COLUMN in (tables[tableView.singleTable]?.columns ?? {})) return true;
        } else {
            for (const tableId in tableView.tables) {
                if (XRF_COLUMN in (tables[tableId]?.columns ?? {})) return true;
            }
        }
    }
    return false;
};

export const isTableReadOnly = (
    drillhole: Drillhole,
    activity: ActivityMap,
    tableView: TableView,
    tables: { [id: string]: Table },
    tablePermissions: { [id: string]: { status: string; reason: { [id: string]: any } } },
    tableId: string,
    users?: { [id: string]: UserWithType },
) => {
    const tablePermission = tablePermissions?.[tableId];

    if (tableContainsXRFColumn(tableView, tables)) {
        return {
            readOnly: true,
            reason: { ...tablePermission.reason, [ReadOnlyReasons.XRF_TABLE]: {} },
        };
    }

    let readOnly = false;
    const reason: { [key in ReadOnlyReasons]?: { users: string; type: 'header' | 'table' } } = {};
    const assignedUser: any = drillhole.assignedUser;

    if (tablePermissions?.[tableId]?.status) {
        if (assignedUser) {
            const user = assignedUser.user;
            const groupUsers = assignedUser.groupUsers;

            if (
                user ||
                (groupUsers && Object.keys(groupUsers).includes(tablePermissions[tableId]?.status))
            ) {
                readOnly = activity.readOnlyOffline ? !activity.readOnlyOffline[tableId] : true;

                if (readOnly && users) {
                    const determineUserInfo = (user?: UserWithType) =>
                        (user?.profile?.name ? user.profile.name : user?.email) ?? '';

                    const userName: string = determineUserInfo(
                        user
                            ? users[user]
                            : users[
                                  groupUsers?.[tablePermissions[tableId]?.status ?? '']?.user ?? ''
                              ],
                    );
                    reason[ReadOnlyReasons.ASSIGNED_USER] = {
                        users: userName,
                        type: [activity.header, `${activity.header}-${COORDINATES_ID}`].includes(
                            tableId,
                        )
                            ? 'header'
                            : 'table',
                    };
                }
            }
        }
    } else {
        readOnly = true;
    }

    return { readOnly, reason: { ...tablePermission?.reason, ...reason } };
};

const determineTablePermissionsForGroup = (activity: ActivityMap, groups: string[]) => {
    let tablePermissions: {
        [key: string]: {
            status: string;
            reason: {
                [ReadOnlyReasons.ACTIVITY_GROUP]?: { activity: string; name: 'header' | 'table' };
            };
        };
    } = {};

    groups.forEach((groupId) => {
        Object.entries(activity.groups![groupId]).forEach(([tableId, permission]) => {
            if (!tablePermissions[tableId]?.status) {
                tablePermissions = {
                    ...tablePermissions,
                    [tableId]:
                        permission === TableEditStatus.READ_WRITE
                            ? { status: groupId, reason: {} }
                            : {
                                  status: '',
                                  reason: {
                                      [ReadOnlyReasons.ACTIVITY_GROUP]: {
                                          activity: activity.name,
                                          name: tableId === activity.header ? 'header' : 'table',
                                      },
                                  },
                              },
                };
            }
        });
    });

    return tablePermissions;
};

const assignedGroupTables = (project: Project, activity: ActivityMap, user: string) => {
    const hasProjectPermission = !isEmpty(project.users) && !!project.users[user];
    let hasActivityFullAccess = false;
    let permittedGroups: string[] = [];

    if (project.activities?.[activity.id]?.users?.[user]) {
        const activityPermission = project.activities[activity.id].users[user];

        if (activityPermission?.fullAccess) hasActivityFullAccess = activityPermission.fullAccess;
    }

    if (hasProjectPermission || hasActivityFullAccess)
        permittedGroups = Object.keys(activity.groups!);
    else if (project.activities?.[activity.id]?.groups) {
        const groups = project.activities[activity.id].groups;
        permittedGroups = Object.keys(activity.groups!).filter(
            (groupId) => groups?.[groupId]?.users?.[user],
        );
    }

    return determineTablePermissionsForGroup(activity, permittedGroups);
};

export const sortTables = (activity: ActivityMap, tableListOptions: string[]) => {
    tableListOptions.sort((a, b) => {
        const getIndex = (id: string) => {
            switch (id) {
                case activity.lithology:
                    return activity.lithologyListSpecs?.index;
                case activity.survey:
                    return activity.surveyListSpecs?.index;
                case activity.samples:
                    return activity.samplesListSpecs?.index;
                case activity.header:
                    return Number.MIN_SAFE_INTEGER;
                default:
                    return activity.tableViewsSpecs[id].index;
            }
        };

        let aIndex = getIndex(a);
        let bIndex = getIndex(b);

        if (!aIndex) aIndex = 0;
        if (!bIndex) bIndex = 0;

        if (aIndex > bIndex) return 1;

        return -1;
    });
};

export const getTableList = (project: Project, activity: ActivityMap, user: UserWithType) => {
    let tableListOptions = findTableViewsInActivity(activity);
    let tableListPermissions: { [id: string]: { status: string; reason: { [id: string]: any } } } =
        {};

    if (!isEmpty(activity.groups)) {
        tableListPermissions = assignedGroupTables(project, activity, user.id);
        tableListOptions = tableListOptions.filter((x) =>
            Object.keys(tableListPermissions).includes(x),
        );
    } else {
        Object.assign(
            tableListPermissions,
            ...[
                ...tableListOptions.map((x) => ({
                    [x]: { status: TableEditStatus.READ_WRITE, reason: {} },
                })),
                { sampleResults: { status: TableEditStatus.READ_ONLY, reason: {} } },
            ],
        );
    }

    if (user.type === UserType.Reviewer) {
        Object.assign(
            tableListPermissions,
            ...Object.entries(tableListPermissions).map(([key, value]) => ({
                [key]: { status: '', reason: { ...value.reason, [ReadOnlyReasons.REVIEWER]: {} } },
            })),
        );
    }

    sortTables(activity, tableListOptions);

    return { tableListOptions, tableListPermissions };
};

export const getTablesInfo = (
    allTables: string[],
    allTablesReadonly: {
        [id: string]: { readOnly: boolean; reason: { key: string; values: any }[] };
    },
    activity: ActivityMap,
    tableViews: { [id: string]: TableView },
    headerName: string,
    coordinatesLabel: string,
) => {
    const sortedOptions: DrillholeTableInfo[] = [];
    allTables.forEach((table) => {
        if (table !== activity.header) {
            const tableView = tableViews[table];
            const item = {
                id: table,
                name: tableView.name,
                label: tableView.label,
                isSample: activity.samples === tableView.id,
                intervalType: tableView.intervalType,
                readOnly: allTablesReadonly[tableView.id]?.readOnly,
                reason: allTablesReadonly[tableView.id]?.reason,
                linkedToLithology: tableView.linkedToLithology,
            };
            sortedOptions.push(item);
        } else {
            sortedOptions.push(
                {
                    id: activity.header,
                    name: headerName,
                    label: 'Header',
                    isSample: false,
                    intervalType: 'Header',
                    readOnly: allTablesReadonly[activity.header]?.readOnly,
                    reason: allTablesReadonly[activity.header]?.reason,
                    linkedToLithology: false,
                },
                {
                    id: `${activity.header}-${COORDINATES_ID}`,
                    name: coordinatesLabel,
                    label: coordinatesLabel,
                    isSample: false,
                    intervalType: 'Header',
                    readOnly: allTablesReadonly[activity.header]?.readOnly,
                    reason: allTablesReadonly[activity.header]?.reason,
                    linkedToLithology: false,
                },
            );
        }
    });
    return sortedOptions;
};

export const generateTableViewOptions =
    (
        drillhole: Drillhole,
        project: Project,
        activity: ActivityMap,
        users: { [id: string]: UserWithType },
    ) =>
    (
        tableViews: { [id: string]: TableView },
        tables: { [id: string]: Table },
        headerName: string,
        tablesInActivities: TableList,
        coordinatesLabel = COORDINATES_LABEL,
    ): DrillholeTableOptions => {
        const { tableListOptions, tableListPermissions } = tablesInActivities;

        const tableListReadOnly = Object.assign(
            {},
            ...tableListOptions.map((x) =>
                drillhole.state === DRILL_HOLE_STATES.ACTIVE
                    ? {
                          [x]: isTableReadOnly(
                              drillhole,
                              activity,
                              tableViews[x] ?? {},
                              tables,
                              tableListPermissions,
                              x,
                              users,
                          ),
                      }
                    : {
                          [x]: {
                              readOnly: true,
                              reason: {
                                  ...tableListPermissions[x]?.reason,
                                  [ReadOnlyReasons.INVALID_DRILLHOLE_STATE]: {
                                      state:
                                          drillhole.state === DRILL_HOLE_STATES.LOCKED
                                              ? DRILL_HOLE_STATES.LOCKED
                                              : DRILL_HOLE_STATES.ARCHIVED,
                                  },
                              },
                          },
                      },
            ),
        );

        const tableListInfo = getTablesInfo(
            tableListOptions,
            tableListReadOnly,
            activity,
            tableViews,
            headerName,
            coordinatesLabel,
        );

        return { tableListReadOnly, tableListInfo };
    };

export const getOwnerUser = (
    drillHole: Drillhole,
    allUsers: { [id: string]: UserWithType },
    tableView: TableView,
    activity: Activity,
    subActivity: ActivityMap,
): { user: string; device: string; groupId?: string } | null => {
    // user or user group id
    const userOrGroup = drillHole?.assignedUser;

    if (userOrGroup?.hasOwnProperty('user')) {
        const drillHoleUser = userOrGroup as DrillholeUser;
        return {
            user: allUsers[drillHoleUser.user]?.profile?.name,
            device: drillHoleUser.device,
        };
    }
    if (
        !userOrGroup?.hasOwnProperty('groupUsers') ||
        !drillHole?.activity ||
        !tableView?.id ||
        !activity?.groups
    )
        return null;

    const activityGroup = findActivityGroupForTableView(tableView.id, subActivity);
    if (!activityGroup) return null;

    const userDetails = (userOrGroup as DrillholeUserGroup).groupUsers[activityGroup];
    if (!userDetails) return null;
    return {
        user: allUsers[userDetails.user]?.profile.name,
        device: userDetails.device,
        groupId: activityGroup,
    };
};

const findActivityGroupForTableView = (tableViewId: string, activity: ActivityMap) => {
    for (const k in activity.groups) {
        if (!activity.groups.hasOwnProperty(k)) {
            continue;
        }

        for (const tId in activity.groups[k]) {
            if (!activity.groups[k].hasOwnProperty(tId)) {
                continue;
            }

            if (tId === tableViewId && activity.groups[k][tId] === TableEditStatus.READ_WRITE) {
                return k;
            }
        }
    }

    return null;
};
