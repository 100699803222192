import L, { tileLayer } from 'leaflet';
import AutoGraticule from 'leaflet-auto-graticule';
import * as React from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';

import { mapState } from 'state-domains/domain';
import { useLoadAzureMapTokenQuery } from 'state-domains/domain/map/service/AzureMapTokenApi';
import { projectState } from 'state-domains/domain/project';

import { openStreetMapUrl, openStreetMapAttribution } from 'src/config';
import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';

import { styles } from './AddMapLayers.styles';
import { AuthenticationType } from './AzureMaps/Authentication/AuthenticationType';
import { AzureMaps } from './AzureMaps/AzureMaps';
import { STORE_MAP_BOUNDARY_STATE, STORE_MAP_LABEL_STATE } from './DrillholeMap';
import { AddMapLayersProps, MapLayer } from './DrillholeMap.types';

export function AddMapLayersBase({
    setShowLabels,
    setShowBounds,
    stopFitBounds,
    setIsSatellite,
    hideLayerSelection = false,
    showLayersOnly = false,
    previewGrid = false,
}: AddMapLayersProps) {
    const map = useMap();
    const dispatch = useDispatch();
    const { isLoading, data } = useLoadAzureMapTokenQuery();

    const {
        actions: { updateMapLayer },
        selectors: { mapLayer },
    } = projectState;
    const {
        actions: { loadMapTokenFail: setSatelliteMapsFailed },
        selectors: { azureMapsErrorSelector },
    } = mapState;

    const azureMapsError = !!useSelector(azureMapsErrorSelector);
    const initialCurrentMapLayer = useSelector(mapLayer);
    const layersSet = React.useRef(false);
    const graticuleRef = React.useRef<any>();

    const currentMapLayer = React.useMemo(() => {
        if (initialCurrentMapLayer === MapLayer.SATELLITE && azureMapsError) {
            return MapLayer.STREET;
        }
        return initialCurrentMapLayer;
    }, [initialCurrentMapLayer, azureMapsError]);

    const createLayerControl = (
        key: string,
        buttonId: string,
        group: string,
        defaultSetting: boolean,
        disabled: boolean,
    ) => {
        const container = L.DomUtil.create('div');
        const radioButton = L.DomUtil.create('div');
        const radioButtonSelected = L.DomUtil.create('div');
        const title = L.DomUtil.create('div');

        container.style.cssText = styles.buttonControlContainer;
        radioButton.style.cssText = styles.radioButtonContainer;
        radioButtonSelected.style.cssText = styles.radioButtonSelectedCircle;

        radioButtonSelected.className = group;
        radioButton.className = `${buttonId} ${key}`;
        radioButton.appendChild(radioButtonSelected);
        title.innerHTML = key;
        if (defaultSetting) {
            radioButtonSelected.style.display = 'block';
            key === MapLayer.SATELLITE ? setAzureLayer() : setOsmLayer();
            title.style.color = '#265C7F';
        }
        if (disabled) {
            container.style.opacity = '0.6';
        }

        radioButton.onclick = () => {
            /* eslint no-param-reassign: "error" */
            stopFitBounds.current = true;
            const id = radioButtonSelected.className;
            if (radioButtonSelected.style.display === 'none' && !disabled) {
                radioButton.style.cssText = `${styles.radioButtonContainer} ${styles.radioButtonSelected}`;
                radioButtonSelected.style.display = 'block';
                title.style.color = '#265C7F';
                key === MapLayer.SATELLITE ? setAzureLayer() : setOsmLayer();
                setIsSatellite(key === MapLayer.SATELLITE);
                const elements = Array.from(
                    document.getElementsByClassName(id) as HTMLCollectionOf<HTMLElement>,
                );
                for (const element of elements) {
                    const parent = element.parentElement;
                    if (parent?.className !== radioButton.className) {
                        element.style.display = 'none';
                        if (parent) {
                            parent.style.cssText = styles.radioButtonContainer;
                            (parent.nextSibling as HTMLElement).style.color = '#4a4a4c';
                        }
                    }
                }
            }
        };

        container.appendChild(radioButton);
        container.appendChild(title);

        return container;
    };

    const createToggleControl = (
        id: string,
        initValue: string,
        callback?: (val: boolean) => void,
    ) => {
        const container = L.DomUtil.create('div');
        const checkbox = L.DomUtil.create('div');
        const checkboxToggle = L.DomUtil.create('div');
        const title = L.DomUtil.create('div');

        container.style.cssText = styles.buttonControlContainer;
        checkbox.style.cssText = styles.checkboxContainer;
        checkboxToggle.style.cssText = styles.checkboxToggle;

        checkbox.className = `toggle-${id}`;
        checkbox.appendChild(checkboxToggle);
        title.innerHTML = initValue;
        title.className = `toggle-${id}`;
        if (initValue === 'YES') {
            checkboxToggle.style.cssText = `${styles.checkboxToggle} ${styles.selected}`;
            title.style.color = '#265C7F';
        }

        checkbox.onclick = () => {
            stopFitBounds.current = true;
            const id = checkbox.className;
            const elements = Array.from(
                document.getElementsByClassName(id) as HTMLCollectionOf<HTMLElement>,
            );
            if (checkboxToggle.style.left === '2px') {
                checkboxToggle.style.cssText = `${styles.checkboxToggle} ${styles.selected}`;
                if (elements.length > 1) {
                    elements[1].innerHTML = 'YES';
                    elements[1].style.color = '#265C7F';
                }
                if (callback) callback(true);
            } else {
                checkboxToggle.style.cssText = styles.checkboxToggle;
                if (elements.length > 1) {
                    elements[1].innerHTML = 'NO';
                    elements[1].style.color = '#4a4a4c';
                }
                if (callback) callback(false);
            }
        };

        container.appendChild(checkbox);
        container.appendChild(title);

        return container;
    };

    const createControlCategory = (
        name: string,
        toggle: boolean,
        toggleOptions: string[],
        callback?: (val: boolean) => void,
    ) => {
        const boundary = L.DomUtil.create('div');
        const title = L.DomUtil.create('div');

        boundary.style.cssText = styles.controlBoundaryContainer;
        title.style.cssText = styles.boundaryTitle;

        title.innerHTML = name;
        boundary.appendChild(title);

        if (toggle) {
            for (let i = 0; i < toggleOptions.length; i += 1) {
                // Creates a unique id for each section and for every button in that section
                // Converts all spaces to '-' and then to lower case for readability
                const id = `${name.replace(/\s+/g, '-')}-${i.toString()}`.toLowerCase();
                if (callback) {
                    boundary.append(createToggleControl(id, toggleOptions[i], callback));
                }
            }
        }
        if (!toggle) {
            const id = `${name.replace(/\s+/g, '-')}-0`.toLowerCase();
            const keys = Object.keys(toggleOptions);
            for (const key of keys) {
                const disabled = key === MapLayer.SATELLITE && azureMapsError;
                boundary.append(
                    createLayerControl(key, id, 'mapLayers', key === currentMapLayer, disabled),
                );
            }
        }

        return boundary;
    };

    const getControlCssText = (showLayersOnly: boolean, previewGrid: boolean) => {
        if (previewGrid) {
            return 'height: 148px; bottom: 148px;';
        }
        if (!showLayersOnly) {
            return 'height: 220px; bottom: 219px;';
        }
        return 'height: 96px; bottom: 96px;';
    };

    const createControlFn = (layers: any) => {
        const control = new L.Control({ position: 'bottomright' });
        control.onAdd = function () {
            const container = L.DomUtil.create('div');
            const dropdown = L.DomUtil.create('div');
            dropdown.id = 'dropdownContainer';
            container.id = 'mapControlContainer';

            container.style.cssText = styles.mapControlButton;
            dropdown.style.cssText = `${styles.mapDropdownContainer} ${getControlCssText(showLayersOnly, previewGrid)}`;

            container.onmouseenter = () => {
                dropdown.style.visibility = 'visible';
            };
            container.onmouseleave = () => {
                dropdown.style.visibility = 'hidden';
            };
            container.ondblclick = (ev: MouseEvent) => {
                ev.stopPropagation();
            };
            dropdown.ondblclick = (ev: MouseEvent) => {
                ev.stopPropagation();
            };

            const initLabelState = store.get(STORE_MAP_LABEL_STATE) ?? false;
            const initBoundaryState = store.get(STORE_MAP_BOUNDARY_STATE) ?? true;

            container.appendChild(dropdown);
            dropdown.appendChild(createControlCategory('Layers', false, layers));
            if (!showLayersOnly) {
                dropdown.appendChild(
                    createControlCategory(
                        'Show Project Boundary',
                        true,
                        [initBoundaryState ? 'YES' : 'NO'],
                        showBoundaryCallback,
                    ),
                );
                dropdown.appendChild(
                    createControlCategory(
                        'Show Labels',
                        true,
                        [initLabelState ? 'YES' : 'NO'],
                        showLabelsCallback,
                    ),
                );
            }

            if (previewGrid) {
                dropdown.appendChild(
                    createControlCategory('Show Graticule', true, ['YES'], showGrateculeCallback),
                );
            }

            return container;
        };
        return control;
    };

    const showLabelsCallback = (val: boolean) => {
        setShowLabels(val);
        store.set(STORE_MAP_LABEL_STATE, val);
    };

    const showBoundaryCallback = (val: boolean) => {
        setShowBounds(val);
        store.set(STORE_MAP_BOUNDARY_STATE, val);
    };

    const showGrateculeCallback = (val: boolean) => {
        if (val) {
            graticuleRef.current.addTo(map);
        } else {
            graticuleRef.current.removeFrom(map);
        }
    };

    const setAzureLayer = () => {
        if (data) {
            setIsSatellite(true);
            const authOptions = {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: data.accessToken,
                clientId: data.azureClientId,
                failedfn: (value: any) => dispatch(setSatelliteMapsFailed(value)),
            };
            const azureLayer = new AzureMaps({
                authOptions,
                tilesetId: 'microsoft.imagery',
            });
            map.addLayer(azureLayer);
            onLayerChange(MapLayer.SATELLITE);
        }
    };

    const setOsmLayer = () => {
        setIsSatellite(false);
        const osmLayer = tileLayer(openStreetMapUrl(), {
            attribution: openStreetMapAttribution(),
        });
        map.addLayer(osmLayer); // adding makes this the default
        updateMapLayer(MapLayer.STREET)(dispatch);
        onLayerChange(MapLayer.STREET);
    };

    const onLayerChange = React.useCallback((newLayer: MapLayer) => {
        const layer = newLayer === MapLayer.SATELLITE ? MapLayer.SATELLITE : MapLayer.STREET;
        updateMapLayer(layer)(dispatch);
        trackUserAction(TRACKING_COMPONENTS.MAP, EVENT_TYPES.CHANGE, `layer to ${newLayer}`);
    }, []);

    React.useEffect(() => {
        if (!isLoading || azureMapsError) {
            const layers = {
                [MapLayer.SATELLITE]: null,
                [MapLayer.STREET]: null,
            };
            if (!hideLayerSelection) {
                if (!layersSet.current) {
                    const ctrl = createControlFn(layers);
                    L.control
                        .zoom({ zoomInTitle: '', zoomOutTitle: '', position: 'bottomright' })
                        .addTo(map);
                    if (previewGrid) {
                        graticuleRef.current = new AutoGraticule();
                        graticuleRef.current.addTo(map);
                    }
                    map.addControl(ctrl);
                    layersSet.current = true;
                }
            } else if (!azureMapsError && currentMapLayer === MapLayer.SATELLITE) {
                setAzureLayer();
            } else {
                setOsmLayer();
            }
        }
    }, [isLoading, hideLayerSelection, azureMapsError]);

    // Update maps when control is pressed in a different instance
    React.useEffect(() => {
        if (!layersSet.current) {
            if (!azureMapsError && currentMapLayer === MapLayer.SATELLITE) {
                setAzureLayer();
            } else {
                setOsmLayer();
            }
        }
    }, [currentMapLayer]);

    // making this a react component makes the code cleaner
    return <></>;
}

export const AddMapLayers = AddMapLayersBase;
