import { SelectMenu, SelectMenuItem } from '@local/web-design-system';
import { Grid } from '@mui/material';
import classnames from 'classnames';
import * as React from 'react';

import { SortKeyOrder } from 'state-domains/types';
import { stringSort, StringSortProps } from 'state-domains/utils';

import { withStyles } from 'src/styles/utils';

import { styleMaxWidth, styles } from './DropdownSelect.styles';
import { DropdownProps, DropdownItem } from './DropdownSelect.types';

const defaultBase = '-1';

const generateItemList = (
    objectList: DropdownItem[],
    textCapitalize: boolean,
    allItemsLabel?: string,
    sortKeyOrder?: SortKeyOrder,
) => {
    let itemArray: SelectMenuItem[] = [];

    if (objectList) {
        if (sortKeyOrder) {
            stringSort({
                objectList,
                key: sortKeyOrder.key,
                order: sortKeyOrder.order,
            } as StringSortProps);

            // fix an issue with default item
            objectList.forEach((item: DropdownItem, i: number) => {
                if (item.id === -1) {
                    objectList.splice(i, 1);
                    objectList.unshift(item);
                }
            });
        }
        itemArray = objectList.map((item: any) =>
            convertDropdownItemToSelectMenuItem(item, textCapitalize),
        );
    }
    if (allItemsLabel) {
        return [{ key: defaultBase, label: allItemsLabel }, ...itemArray];
    }
    return itemArray;
};

const capitalizeFirstLetter = (str: string) =>
    str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

const convertDropdownItemToSelectMenuItem = (item: DropdownItem, textCapitalize: boolean) => ({
    key: item.id.toString(),
    label: textCapitalize ? capitalizeFirstLetter(item.name) : item.name,
});

export function DropdownSelectBase(props: DropdownProps) {
    const handleSelected = React.useCallback(
        (selected: string) => {
            props.selected(+selected);
        },
        [props.selected],
    );

    const {
        list,
        allItemsLabel,
        itemId,
        classes,
        className,
        disabled = false,
        setWidthLimit = false,
        attachMenu = false,
        popperClassName,
        paperClassName,
        omitMinWidth,
        textCapitalize = false,
        sortKeyOrder,
        automationId,
        applyTraceMessage,
        ...others
    } = props;

    const [width, setWidth] = React.useState(0);
    const targetRef: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleResize = () => {
            if (targetRef && targetRef.current) {
                const x = targetRef.current.getBoundingClientRect().x;
                setWidth(window.innerWidth - x - 20);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [targetRef]);

    const optionList = generateItemList(list, textCapitalize, allItemsLabel, sortKeyOrder);
    const { classes: styleWidth } = styleMaxWidth({ width });

    return (
        <Grid
            ref={targetRef}
            className={classnames(
                classes.dropdownText,
                {
                    [classes.dropdownWithMaxWidth]: setWidthLimit,
                    [classes.dropdownWithoutMinWidth]: omitMinWidth,
                },
                className,
            )}
        >
            <SelectMenu
                {...others}
                options={optionList}
                onSelect={handleSelected}
                selected={optionList.length > 1 ? itemId.toString() : defaultBase}
                popoverClasses={{
                    root: classes.root,
                    paper: classnames(paperClassName, styleWidth.setMaxWidth),
                }}
                applyTraceMessage={applyTraceMessage || automationId}
                disabled={disabled}
                shouldAttachMenuToAnchor={attachMenu}
                popperClassName={popperClassName || classes.dropdownPopper}
            />
        </Grid>
    );
}

export const DropdownSelect = withStyles(styles)(DropdownSelectBase);
