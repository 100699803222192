import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { seequentCloudConfigurationUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { SeequentCloudConfigurationResponse } from '../types';

export const seequentCloudConfiguration = (): Observable<SeequentCloudConfigurationResponse> => {
    const url = seequentCloudConfigurationUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<SeequentCloudConfigurationResponse>) => {
            const convertedResponse = convertToCamel<SeequentCloudConfigurationResponse>(response);
            return observableOf(convertedResponse);
        }),
    );
};
