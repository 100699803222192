import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildDeleteListRowsUrl } from 'state-domains/network/urls';
import { convertToSnake, sendRequestWithXSRFToken } from 'state-domains/utils';

import { DeleteListRowsPayload } from '../types';

export const deleteListRows = (deleteRequest: DeleteListRowsPayload): Observable<string[]> => {
    const url = buildDeleteListRowsUrl();
    const requestPayload = convertToSnake(deleteRequest);
    return sendRequestWithXSRFToken(url, requestPayload).pipe(
        switchMap(() => observableOf(deleteRequest.rowIds)),
    );
};
