import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildDownloadListFileUrl } from 'state-domains/network';

import { sendFileDownloadRequest } from '../../../utils';

export const downloadListFile = (groupId: string, fileId: string): Observable<any> => {
    const url = buildDownloadListFileUrl(groupId, fileId);
    return sendFileDownloadRequest(url).pipe(switchMap((blob: Blob) => observableOf(blob)));
};
